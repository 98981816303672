import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { space as styledSpace } from 'styled-system'
import { Formik } from 'formik'
import * as Yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, space } from 'theme'
import { ERROR_MESSAGE, LABELS_TYPE_MEDIA } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import TextArea from 'components/atoms/TextArea'
import Input from 'components/atoms/Input'
import { getLabels } from 'shared/LabelEditModal/helpers'
import LabelsSelect from 'shared/LabelEditModal/components/LabelsSelect'
import Select from 'shared/DropDown'
import { ROUTE_MEDIA } from 'routes/Calendar/consts'
import { getSelectedProfileGroupsAndTimezone } from 'routes/Calendar/helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483003 !important;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 500px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    flex-direction: column;
    height: auto;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledTextArea = styled(TextArea)`
  height: 100%;
  margin: 0;
  padding: ${space.s};
`

const { TITLE, DESCRIPTION, LABELS, ENTITIES } = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  LABELS: 'labels',
  ENTITIES: 'entities',
}

const MAX_TITLE_TEXT_LENGTH = 200
const MAX_DESCRIPTION_TEXT_LENGTH = 1000

const ValidationSchema = () => {
  return Yup.object().shape({
    [TITLE]: Yup.string()
      .max(MAX_TITLE_TEXT_LENGTH, `Title is too long - should be ${MAX_TITLE_TEXT_LENGTH} chars maximum.`)
      .required('Title is required.'),
    [DESCRIPTION]: Yup.string().max(
      MAX_DESCRIPTION_TEXT_LENGTH,
      `Description is too long - should be ${MAX_DESCRIPTION_TEXT_LENGTH} chars maximum.`
    ),
  })
}

const AddFolderModal = ({ user, isOpen, handleDismiss, selectedMedia, folderPath, ...props }) => {
  const formRef = useRef(null)

  const [isGettingData, setIsGettingData] = useState(true)
  const [isMediaSaving, setIsMediaSaving] = useState(false)
  const [media, setMedia] = useState({})
  const [labels, setLabels] = useState([])
  const [entities, setEntities] = useState([])

  const { id, title = '', description = '', labels: mediaLabels = [], entities: mediaEntities = [] } = media

  const collectData = async () => {
    if (selectedMedia) {
      setMedia({ ...selectedMedia })
    } else {
      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
      })

      setMedia({
        ...{ entities: selectedEntitiesWithSelectedProfiles.map(({ id, name }) => ({ value: id, label: name })) },
      })
    }

    setEntities(props.entities.map(({ id, name, type }) => ({ value: id, label: name, type })))

    await getLabels({ setLabels, type: LABELS_TYPE_MEDIA })

    setIsGettingData(false)
  }

  useEffect(() => {
    if (isOpen) {
      collectData()
    }
  }, [isOpen])

  const handleSubmitData = async (values) => {
    if (isMediaSaving) {
      Alert.warning('Please wait a bit while your folder is being saved.', { timeout: 5000 })
    } else {
      try {
        setIsMediaSaving(true)

        const data = {
          [TITLE]: values[TITLE],
          [DESCRIPTION]: values[DESCRIPTION],
          media_path: folderPath,
          [LABELS]: values[LABELS] ? values[LABELS].map(({ label }) => label) : [],
        }

        if (id) {
          data.id = id
        }

        if (!folderPath) {
          data.entity_gids = values[ENTITIES] ? values[ENTITIES].map(({ value }) => value) : []
        }

        const response = await request({
          path: `${ROUTE_MEDIA}/folder`,
          method: 'POST',
          body: data,
        })

        const { error, data: media_response } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setIsMediaSaving(false)
        } else {
          handleDismiss({ data: media_response })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: AddFolderModal.displayName,
          functionName: 'handleSubmitData',
        })
        setIsMediaSaving(false)
      }
    }
  }

  const handleClickCloseModal = () => {
    if (!isMediaSaving) {
      handleDismiss()
    } else {
      Alert.warning('Please wait a bit while your folder is being saved.', { timeout: 5000 })
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => { }}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" alignItems="center" justifyContent="space-between" $isTop>
            <H4 my="m">{id ? 'Edit folder settings' : 'Add folder'}</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flex="1" flexDirection="column" height="100%">
            {isGettingData ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Formik
                ref={formRef}
                initialValues={{
                  [TITLE]: title || '',
                  [DESCRIPTION]: description || '',
                  [LABELS]: mediaLabels || [],
                  [ENTITIES]: mediaEntities || [],
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmitData}
              >
                {({ values, errors, touched, setFieldValue }) => (
                  <Flex p="m" flexDirection="column" width="100%">
                    <Box>
                      <Input
                        placeholder="Title"
                        label="Title"
                        defaultValue={title}
                        onChange={(e) => {
                          const { value } = e.target
                          if (value.length <= MAX_TITLE_TEXT_LENGTH) {
                            setFieldValue(TITLE, e.target.value)
                          }
                        }}
                        width="100%"
                        maxLength={MAX_TITLE_TEXT_LENGTH}
                        error={errors[TITLE] && touched[TITLE] && errors[TITLE]}
                      />
                    </Box>

                    <Flex mt="m" width="100%" flexDirection="column">
                      <Text mb="xs" fontSize="s">
                        Description
                      </Text>
                      <StyledTextArea
                        defaultValue={description}
                        onChange={(e) => {
                          const { value } = e.target
                          if (value.length <= MAX_DESCRIPTION_TEXT_LENGTH) {
                            setFieldValue(DESCRIPTION, e.target.value)
                          }
                        }}
                        placeholder="Description"
                        rows={3}
                        maxLength={MAX_DESCRIPTION_TEXT_LENGTH}
                        error={errors[DESCRIPTION] && touched[DESCRIPTION] && errors[DESCRIPTION]}
                      />
                    </Flex>

                    <Flex mt="m" width="100%" flexDirection="column">
                      <Text mb="xs" fontSize="s">
                        Select labels
                      </Text>
                      <LabelsSelect
                        noOptionMessage="Label your folder by adding new labels"
                        setData={(media) => {
                          const { labels } = media
                          setFieldValue(LABELS, labels)
                        }}
                        selectedLabels={values[LABELS]}
                        labelOptions={labels}
                        setLabelOptions={setLabels}
                        data={{ [LABELS]: values[LABELS] }}
                        type={LABELS_TYPE_MEDIA}
                      />
                    </Flex>

                    {!folderPath && (
                      <Flex mt="m" width="100%" flexDirection="column">
                        <Select
                          placeholder="Select profile groups"
                          label="Select profile groups"
                          value={values[ENTITIES]}
                          onChange={(options) => {
                            setFieldValue(ENTITIES, options || [])
                          }}
                          options={entities}
                          openMenuOnFocus
                          isMulti
                        />
                      </Flex>
                    )}
                  </Flex>
                )}
              </Formik>
            )}
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal}>
              Close
            </Button.Gray>
            <ButtonWithLoading
              isSmall
              isLoading={isMediaSaving}
              onClick={() => {
                if (!isMediaSaving) {
                  formRef.current.handleSubmit()
                }
              }}
            >
              <Text fontSize="s">{isMediaSaving ? 'Saving' : 'Save'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

AddFolderModal.defaultProps = {
  entities: [],
  selectedMedia: null,
  folderPath: null,
}

AddFolderModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedMedia: PropTypes.object,
  entities: PropTypes.array,
  folderPath: PropTypes.array,
}

AddFolderModal.displayName = 'AddFolderModal'

export default AddFolderModal

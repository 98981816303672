import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import moment from 'moment-timezone'
import Alert from 'react-s-alert'
import { space as styledSpace } from 'styled-system'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, space } from 'theme'
import {
  ERROR_MESSAGE,
  VIDEO,
  IMAGE,
  AUDIO,
  DOCUMENT,
  LABELS_TYPE_MEDIA,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  MIXED_MEDIA,
} from 'consts'
import { getFileIconByExtension, truncate } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Icon from 'components/atoms/Icon'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import NoData from 'components/molecules/NoData'
import Input from 'components/atoms/Input'
import DynamicMedia from 'components/molecules/DynamicMedia'
import MediaPreviewComponent from 'routes/Media/components/MediaPreviewComponent'
import MediaFilters from 'routes/Media/components/MediaFilters'
import { DEFAULT_FILTERS, DEFAULT_MEDIA_USAGE_TYPES } from 'routes/Media/consts'
import { getSelectedProfileGroupsAndTimezone } from 'routes/Calendar/helpers'
import { getLabels } from 'shared/LabelEditModal/helpers'
import CalendarItemTooltip from './CalendarItemTooltip'
import {
  POST_IMAGE,
  POST_VIDEO,
  POST_DOCUMENT,
  ROUTE_MEDIA,
  MAX_IMAGES_ATTACHMENTS_ALLOWED,
  MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
  IMAGE_MIME_TYPES,
  VIDEO_MIME_TYPES,
  DOCUMENT_MIME_TYPES,
  POST_MIXED_MEDIA,
  MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
} from '../consts'
import SliderPostsPreview from './SliderPostsPreview'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 900px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const MediaSectionWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color};
`

const SelectWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : transparentize(0.2, theme.colors.border_color_light)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary : theme.colors.border_color)};
`

const MediaItemWrapper = styled(Flex)`
  width: 200px;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  margin: 0 ${space.s} ${space.s};
  cursor: pointer;
  ${({ disabled }) => disabled && `opacity:0.6;`}
  ${({ selected }) =>
    selected && `box-shadow: 0 0 5px 0 ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};`}
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
    ${SelectWrapper} {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }
`

const MediaWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};
  transition: all 0.5s ease-in-out;
  transform: scale(1);
  ${({ selected }) =>
    selected &&
    `border-bottom-right-radius: ${radius.l};  border-bottom-left-radius: ${radius.l}; transform: scale(0.8);`}
`

const MediaFolderWrapper = styled(Flex)`
  width: 100%;
  position: relative;
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  &:hover {
    box-shadow: 0 0 10px 5px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }
`

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
`

const StyledIconCheckmark = styled(Icon.Checkmark)`
  color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.border_color)};
`

const StyledDialogEnvironmentSelectedMediasWrapper = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
`

const StyledSelectedMediasWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    div {
      display: flex;
    }
  }
`

const SelectedMediasImage = styled(ImageWithFallback)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.m};
  vertical-align: inherit;
`

const StyledDismissWrapper = styled(Flex)`
  display: none;
  cursor: pointer;
  background: #000;
  z-index: 999;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
`

const StyledText = styled(Text)`
  text-align: center;
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${({ theme }) => transparentize(0.5, theme.colors.background_loading_progress)};
`

const StyledPublicationsCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: ${space.xs};
  right: ${space.xs};
  z-index: 2;
  border-radius: ${radius.l};
  padding: ${space.xs} ${space.s};
  cursor: pointer;
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
  text-align: center;
`

const StyledFolderWrapper = styled(Flex)`
  border-radius: ${radius.l};
  position: relative;
  cursor: pointer;
  justify-content: space-between;
  padding: ${space.sm};
`

const StyledFolderBackNavigationText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
`

const SearchInputComponent = forwardRef(({ handleFilterMediaBySearch }, inputSearchText) => {
  const [searchString, setSearchString] = useState('')

  useImperativeHandle(inputSearchText, () => ({
    getSearchString() {
      return searchString
    },
  }))

  const handleChangePostText = (text) => {
    setSearchString(text)
    handleFilterMediaBySearch(text)
  }

  return (
    <Input
      placeholder="Search"
      label=""
      value={searchString}
      onChange={(e) => {
        handleChangePostText(e.target.value)
      }}
      height="34px"
      width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
    />
  )
})

SearchInputComponent.propTypes = {
  handleFilterMediaBySearch: PropTypes.func.isRequired,
}

const MediaLibraryModal = ({
  user,
  isOpen,
  handleDismiss,
  handleClickAddMedias,
  type: postComponent = POST_IMAGE,
  postMedias,
  selectedEntities,
  selectedProfiles,
  blockDynamicMedias,
  maxImageAttachmentsAllowed,
  maxVideoAttachmentsAllowed,
  maxDocumentAttachmentsAllowed,
  maxMixedMediaAttachmentsAllowed,
  replaceMediasWithoutLimitError,
}) => {
  let DEFAULT_TYPES = [
    { value: IMAGE, label: 'Images' },
    { value: VIDEO, label: 'Videos' },
    { value: DOCUMENT, label: 'Documents' },
  ]

  if (postComponent === POST_IMAGE) {
    DEFAULT_TYPES = [{ value: IMAGE, label: 'Images' }]
  } else if (postComponent === POST_VIDEO) {
    DEFAULT_TYPES = [{ value: VIDEO, label: 'Videos' }]
  } else if (postComponent === POST_DOCUMENT) {
    DEFAULT_TYPES = [{ value: DOCUMENT, label: 'Documents' }]
  } else if (postComponent === POST_MIXED_MEDIA) {
    DEFAULT_TYPES = [
      { value: IMAGE, label: 'Images' },
      { value: VIDEO, label: 'Videos' },
    ]
  }

  const tooltipRef = useRef(null)
  const mediaFiltersRef = useRef(null)
  const inputSearchText = useRef(null)
  const scrollbarsRef = useRef(null)

  const [isGettingMedia, setIsGettingMedia] = useState(false)
  const [folderMedias, setFolderMedias] = useState([])
  const [uploadedMedias, setUploadedMedias] = useState([])
  const [selectedMedias, setSelectedMedias] = useState(postMedias)

  const [isFinalPage, setIsFinalPage] = useState(false)
  const [page, setPage] = useState(0)
  const [isInitial, setIsInitial] = useState(true)
  const [labels, setLabels] = useState([])
  const [extensions, setExtensions] = useState({ [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] })
  const [users, setUsers] = useState([])
  const [folderId, setFolderId] = useState()
  const [folder, setFolder] = useState(null)

  const [sliderPostsPreviewData, setSliderPostsPreviewData] = useState(null)

  const redirectToMediaLibrary = () => {
    setFolder(null)
    setFolderId(null)
  }

  const collectFiltersToQueryParameters = ({ selectedFilters }) => {
    const query_items = []

    if (!folder) {
      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({
        user,
        selectedEntities: selectedEntities && selectedEntities.length ? selectedEntities : null,
        selectedProfiles: selectedProfiles && selectedProfiles.length ? selectedProfiles : null,
      })

      if (selectedEntitiesWithSelectedProfiles.length !== 0) {
        query_items.push(`entities=${selectedEntitiesWithSelectedProfiles.map(({ id }) => id).join(',')}`)
      }
    } else {
      query_items.push(`media_path=${folder.media_path.join(',')}`)
    }

    let updatedSelectedFilters = selectedFilters || mediaFiltersRef.current.getFiltersData()

    const { filters } = updatedSelectedFilters || {}

    if (!filters || JSON.stringify(filters) === JSON.stringify(DEFAULT_FILTERS)) {
      updatedSelectedFilters = {
        filters: DEFAULT_FILTERS,
      }
    }

    const {
      filters: {
        selectedLabels = [],
        selectedExtensions = [],
        selectedUsers = [],
        selectedTypes = [],
        selectedUsage = [],
      } = {},
    } = updatedSelectedFilters || {}

    if (selectedTypes.length !== 0) {
      query_items.push(`types=${selectedTypes.map(({ value }) => value).join(',')}`)
    }

    if (selectedLabels.length !== 0) {
      query_items.push(`labels=${encodeURIComponent(selectedLabels.map(({ value }) => value).join(','))}`)
    }

    if (selectedExtensions.length !== 0) {
      query_items.push(`extensions=${selectedExtensions.map(({ mime }) => mime).join(',')}`)
    }

    if (selectedUsers.length !== 0) {
      query_items.push(`users=${selectedUsers.map(({ value }) => value).join(',')}`)
    }

    if (selectedUsage.length !== 0) {
      query_items.push(`usage=${selectedUsage.map(({ value }) => value).join(',')}`)
    }

    const searchTextValue = inputSearchText && inputSearchText.current ? inputSearchText.current.getSearchString() : ''

    query_items.push(`q=${encodeURIComponent(searchTextValue)}`)

    if (blockDynamicMedias) {
      query_items.push(`no_dynamic=true`)
    }

    return query_items.join('&')
  }

  const searchForUploadedMedias = async ({ selectedFilters, page }) => {
    try {
      if (tooltipRef && tooltipRef.current) {
        tooltipRef.current.handleHideTooltip()
      }

      setIsGettingMedia(true)

      let path = `${ROUTE_MEDIA}?is_initial=${isInitial}&page=${page}`

      path += `&${collectFiltersToQueryParameters({ selectedFilters })}`

      const response = await request({
        path,
      })

      const { error, data, usersForFilter, hasNextPage } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setIsFinalPage(!hasNextPage)

        if (page === 0) {
          setUploadedMedias([...data])
        } else {
          setUploadedMedias([...uploadedMedias, ...data])
        }

        if (isInitial) {
          setIsInitial(false)
        }

        if (usersForFilter) {
          setUsers(usersForFilter)
        }

        // const { 0: { total } = {} } = medias_count
        //
        // if (total) {
        //   setMediaCounter(total)
        // }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MediaLibraryModal.displayName,
        functionName: 'searchForUploadedMedias',
        errorMessage: `Can't get your uploaded medias. Please contact support.`,
      })
    } finally {
      setIsGettingMedia(false)
    }
  }

  const getExtensions = async () => {
    const updatedExtensions = { [IMAGE]: [], [VIDEO]: [], [AUDIO]: [], [DOCUMENT]: [] }

    let type = ''
    let available_mime_types = []
    let extension_type = ''

    if (postComponent === POST_IMAGE) {
      type = IMAGE
      available_mime_types = IMAGE_MIME_TYPES
      extension_type = IMAGE
    } else if (postComponent === POST_VIDEO) {
      type = VIDEO
      available_mime_types = VIDEO_MIME_TYPES
      extension_type = VIDEO
    } else if (postComponent === POST_DOCUMENT) {
      type = DOCUMENT
      available_mime_types = DOCUMENT_MIME_TYPES
      extension_type = DOCUMENT
    } else if (postComponent === POST_MIXED_MEDIA) {
      type = MIXED_MEDIA
    }

    try {
      const response = await request({
        path: `${ROUTE_MEDIA}/formats?type=${type}`,
      })

      if (response && !response.error) {
        response.forEach(({ mime }) => {
          if (type === MIXED_MEDIA) {
            if (IMAGE_MIME_TYPES[mime]) {
              updatedExtensions[IMAGE].push({
                value: IMAGE_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: IMAGE_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
            if (VIDEO_MIME_TYPES[mime]) {
              updatedExtensions[VIDEO].push({
                value: VIDEO_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: VIDEO_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
            if (DOCUMENT_MIME_TYPES[mime]) {
              updatedExtensions[DOCUMENT].push({
                value: DOCUMENT_MIME_TYPES[mime].map((item) => item.toLowerCase()).join(','),
                label: DOCUMENT_MIME_TYPES[mime][0].toUpperCase(),
                mime,
              })
            }
          } else if (available_mime_types[mime] && extension_type) {
            updatedExtensions[extension_type].push({
              value: available_mime_types[mime].map((item) => item.toLowerCase()).join(','),
              label: available_mime_types[mime][0].toUpperCase(),
              mime,
            })
          }
        })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MediaLibraryModal.displayName,
        functionName: 'getExtensions',
      })
    } finally {
      setExtensions(updatedExtensions)
    }
  }

  const getFolderMedias = async ({ selectedFilters }) => {
    try {
      let path = `${ROUTE_MEDIA}/folders`

      path += `?${collectFiltersToQueryParameters({ selectedFilters })}`

      const response = await request({
        path,
      })

      const { error, data } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setFolderMedias([...data])
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MediaLibraryModal.displayName,
        functionName: 'getFolderMedias',
        errorMessage: `Can't get your folders. Please contact support.`,
      })
    }
  }

  const getFolderData = async () => {
    try {
      const response = await request({
        path: `${ROUTE_MEDIA}/folder/${folderId}`,
      })

      const { error, data } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        redirectToMediaLibrary()
      } else {
        setFolder({ ...data })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MediaLibraryModal.displayName,
        functionName: 'getFolderData',
      })
      redirectToMediaLibrary()
    }
  }

  useEffect(() => {
    if (isOpen) {
      getLabels({
        setLabels,
        type: LABELS_TYPE_MEDIA,
      })
      getExtensions()
      setSelectedMedias([...postMedias])
    }
  }, [isOpen])

  useEffect(() => {
    if (folderId) {
      getFolderData()
    } else if (isInitial) {
      searchForUploadedMedias({ selectedFilters: { filters: { selectedTypes: DEFAULT_TYPES } }, page: 0 })
      getFolderMedias({ selectedFilters: { filters: { selectedTypes: DEFAULT_TYPES } } })
    } else {
      searchForUploadedMedias({ page: 0 })
      getFolderMedias({})
    }

    if (!isGettingMedia) {
      setIsGettingMedia(true)
    }

    if (isFinalPage) {
      setIsFinalPage(false)
    }

    if (page !== 0) {
      setPage(0)
    }
  }, [folderId])

  useEffect(() => {
    if (folder) {
      searchForUploadedMedias({ page })
      getFolderMedias({})
    }
  }, [folder])

  useEffect(() => {
    if (!isGettingMedia && !isFinalPage && !isInitial) {
      if (process.browser && page === 0 && scrollbarsRef && scrollbarsRef.current) {
        let top = 0

        const el = document.getElementById('media-section-wrapper')

        if (el) {
          const bounding = el.getBoundingClientRect()

          // eslint-disable-next-line prefer-destructuring
          top = bounding.top
        }

        scrollbarsRef.current.scrollTop(top)
      }

      searchForUploadedMedias({ page })
    }
  }, [page])

  const handleFilterMediaBySearch = debounce(() => {
    if (page === 0) {
      searchForUploadedMedias({ page })
      getFolderMedias({})
    } else {
      setIsFinalPage(false)
      setPage(0)
    }
  }, 300)

  const handleClickSelectMedia = (item) => {
    let maxAttachments
    let maxAttachmentsErrorText

    if (postComponent === POST_IMAGE) {
      maxAttachments = maxImageAttachmentsAllowed || MAX_IMAGES_ATTACHMENTS_ALLOWED

      if (maxAttachments === 1) {
        maxAttachmentsErrorText = `Only one image can be attached!`
      } else {
        maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} attachments!`
      }
    } else if (postComponent === POST_VIDEO) {
      maxAttachments = maxVideoAttachmentsAllowed || MAX_VIDEOS_ATTACHMENTS_ALLOWED
      if (maxAttachments === 1) {
        maxAttachmentsErrorText = `Only one video can be attached!`
      } else {
        maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} ${
          maxAttachments === 1 ? 'attachment' : 'attachments'
        }!`
      }
    } else if (postComponent === POST_DOCUMENT) {
      maxAttachments = maxDocumentAttachmentsAllowed || MAX_DOCUMENTS_ATTACHMENTS_ALLOWED
      maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} attachments!`
    } else if (postComponent === POST_MIXED_MEDIA) {
      maxAttachments = maxMixedMediaAttachmentsAllowed || MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED
      maxAttachmentsErrorText = `You have reached the limit of ${maxAttachments} attachments!`
    }

    const key = item.id ? 'id' : 'tempId'
    const foundMediaIndex = selectedMedias.findIndex((media) => item[key] === media[key])
    if (foundMediaIndex > -1) {
      selectedMedias.splice(foundMediaIndex, 1)
      setSelectedMedias([...selectedMedias])
    } else if (selectedMedias.length < maxAttachments) {
      selectedMedias.push(item)
      setSelectedMedias([...selectedMedias])
    } else if (replaceMediasWithoutLimitError) {
      let selectedMediasTemp = [...selectedMedias, item]
      const selectedMediasUpdated = []

      while (selectedMediasTemp.length > 0) {
        const temp = selectedMediasTemp.slice(0, maxAttachments)
        temp.forEach((item, index) => {
          selectedMediasUpdated[index] = item
        })
        selectedMediasTemp = selectedMediasTemp.slice(maxAttachments)
      }
      setSelectedMedias([...selectedMediasUpdated])
    } else {
      Alert.warning(maxAttachmentsErrorText)
    }
  }

  const handleMouseOver = ({ currentTarget, defaultXPosition, ...media }) => {
    if (tooltipRef && tooltipRef.current) {
      tooltipRef.current.handleShowTooltip({
        contentComp: <MediaPreviewComponent {...media} />,
        wrapperComp: currentTarget,
        defaultXPosition,
      })
    }
  }

  const debouncedMouseOver = debounce(handleMouseOver, 200)

  const handleClickFolderNavigation = ({ folder, type }) => {
    let folder_gid = null

    if (type === 'forward') {
      folder_gid = folder.media_gid
    } else if (type === 'back' && folder.parent) {
      folder_gid = folder.parent.media_gid
    }

    setFolder(null)
    setFolderId(folder_gid)
  }

  let selectText = 'medias'
  if (postComponent === POST_IMAGE) {
    selectText = 'images'
  } else if (postComponent === POST_VIDEO) {
    selectText = 'videos'
  } else if (postComponent === POST_DOCUMENT) {
    selectText = 'documents'
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" py="m" justifyContent="space-between" $isTop>
            <H4>Select {selectText}</H4>

            <Text>{selectedMedias.length} selected</Text>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1" flexDirection="column">
            <Flex my="s" px="s" justifyContent="space-between">
              <Flex alignItems="center">
                {!folderId && (
                  <Text color="primary" pl="s" fontSize="m">
                    Media library
                  </Text>
                )}

                {folder && (
                  <Flex px="s">
                    {folder.media_path.length > 1 && (
                      <Fragment>
                        <StyledFolderBackNavigationText
                          color="primary"
                          fontSize="m"
                          onClick={() => {
                            handleClickFolderNavigation({ type: 'back', folder: {} })
                          }}
                        >
                          Media library
                        </StyledFolderBackNavigationText>
                        <Text color="text_slash_divider_color" fontSize="m">
                          &nbsp;/&nbsp;
                        </Text>
                        {folder.media_path.length > 2 && (
                          <Fragment>
                            <Text color="primary" fontSize="m">
                              ...
                            </Text>
                            <Text color="text_slash_divider_color" fontSize="m">
                              &nbsp;/&nbsp;
                            </Text>
                          </Fragment>
                        )}
                      </Fragment>
                    )}

                    <StyledFolderBackNavigationText
                      color="primary"
                      fontSize="m"
                      onClick={() => {
                        handleClickFolderNavigation({ type: 'back', folder })
                      }}
                    >
                      {folder?.parent?.title || 'Media library'}
                    </StyledFolderBackNavigationText>
                    <Text color="text_slash_divider_color" fontSize="m">
                      &nbsp;/&nbsp;
                    </Text>
                    <Text fontWeight="bold" fontSize="m">
                      {folder.title}
                    </Text>
                  </Flex>
                )}
              </Flex>

              <Flex justifyContent="flex-start" alignItems="center">
                <SearchInputComponent handleFilterMediaBySearch={handleFilterMediaBySearch} ref={inputSearchText} />

                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  width={{ mobile: 'auto', tablet: '135px', desktopWide: '150px' }}
                  mx="s"
                >
                  <MediaFilters
                    ref={mediaFiltersRef}
                    handleFetchMedia={({ filters }) => {
                      if (page === 0) {
                        searchForUploadedMedias({
                          selectedFilters: { filters },
                          page,
                        })
                      } else {
                        setIsFinalPage(false)
                        setPage(0)
                      }

                      getFolderMedias({
                        selectedFilters: { filters },
                      })
                    }}
                    labels={labels}
                    extensions={extensions}
                    users={users}
                    usage={DEFAULT_MEDIA_USAGE_TYPES}
                    types={DEFAULT_TYPES}
                    defaultFilters={DEFAULT_TYPES ? { selectedTypes: DEFAULT_TYPES } : null}
                    showMobileIcon={{ mobile: 'flex', desktop: 'none' }}
                    removeMobileIcon={{ mobile: 'none', desktop: 'flex' }}
                    left="unset"
                    right="0px"
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex width="100%" height="100%" position="relative" flexDirection="column" id="media-section-wrapper">
              <StyledProgressWrapper
                display={isGettingMedia ? 'flex' : 'none'}
                alignItems="center"
                justifyContent="center"
                className="cliploader-wrapper"
              >
                <ClipLoader size="100" />
              </StyledProgressWrapper>
              <Fragment>
                <Scrollbars
                  ref={scrollbarsRef}
                  universal
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                  onScrollFrame={({ top }) => {
                    if (top >= 0.99) {
                      if (!isGettingMedia) {
                        setPage((page) => {
                          return page + 1
                        })
                      }
                    }
                  }}
                >
                  {folderMedias.length !== 0 && (
                    <Flex flexDirection="column" mb="m" width="100%" px="s">
                      <MediaSectionWrapper flexDirection="column" py="m" width="100%">
                        <Grid
                          gridTemplateColumns="repeat(4, 1fr)"
                          width="100%"
                          gridGap="m"
                          justifyItems="center"
                          mt="m"
                        >
                          {folderMedias.map((media) => {
                            const { id, title, description } = media

                            const mediaDescription = truncate(title || description, 25)

                            return (
                              <MediaFolderWrapper key={id} flexDirection="column">
                                <StyledFolderWrapper
                                  bg="white"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleClickFolderNavigation({ type: 'forward', folder: media })
                                  }}
                                >
                                  <Flex alignItems="center">
                                    <Icon.Folder width="20px" height="20px" stroke="icon_color_gray" />

                                    <Flex ml="s">
                                      <Text color="secondaryText" fontSize="xxs" textAlign="center">
                                        {mediaDescription}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </StyledFolderWrapper>
                              </MediaFolderWrapper>
                            )
                          })}
                        </Grid>
                      </MediaSectionWrapper>
                    </Flex>
                  )}

                  <Flex flexDirection="column" mb="m" width="100%" px="s">
                    <MediaSectionWrapper flexDirection="column" py="m" width="100%">
                      {uploadedMedias.length === 0 ? (
                        <Flex height="100%" alignItems="center" justifyContent="center" width="100%" px="m" bg="white">
                          <NoData
                            showHeaderText={false}
                            message={isGettingMedia ? '' : 'No medias found'}
                            image="/assets/noResultsIllustration.svg"
                          />
                        </Flex>
                      ) : (
                        <Grid gridTemplateColumns="repeat(4, 1fr)" width="100%" height="100%">
                          {uploadedMedias.map((item, index) => {
                            const {
                              url,
                              id,
                              thumbnail_url,
                              thumbnail_url_small,
                              createdBy,
                              metaInformation = '',
                              created,
                              title = '',
                              description,
                              filename,
                              labels,
                              entities,
                              type,
                              publications = [],
                              extension,
                              dynamic,
                              width,
                              height,
                              aspect_ratio,
                            } = item
                            const selected = selectedMedias.find((media) => media.id === id)
                            const column = Math.floor((index - Math.floor(index / 4) * 4) / 2)

                            let mediaDescription = description || title || filename || ''

                            if (type === AUDIO) {
                              mediaDescription = title || filename || ''
                            }

                            const publicationsCounter = publications ? publications.length : 0

                            return (
                              <MediaItemWrapper
                                key={id}
                                onClick={() => {
                                  handleClickSelectMedia(item)
                                }}
                                flexDirection="column"
                                selected={selected}
                              >
                                <Flex alignItems="center" justifyContent="center" width="100%">
                                  <Flex alignItems="center" justifyContent="center" width="100%">
                                    <Flex
                                      width="100%"
                                      height="150px"
                                      alignItems="center"
                                      justifyContent="center"
                                      bg="background_item_light"
                                      borderRadius={radius.l}
                                      onMouseEnter={(e) => {
                                        if (url || type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
                                          debouncedMouseOver({
                                            type,
                                            url,
                                            thumbnail_url,
                                            extension,
                                            title,
                                            description,
                                            labels,
                                            entities,
                                            currentTarget: e.currentTarget,
                                            defaultXPosition: column === 0 ? 'right' : 'left',
                                            dynamic,
                                            width,
                                            height,
                                            aspect_ratio,
                                          })
                                        }
                                      }}
                                      onMouseLeave={() => {
                                        debouncedMouseOver.cancel()
                                        tooltipRef.current.handleHideTooltip()
                                      }}
                                      position="relative"
                                    >
                                      <MediaWrapper selected={selected}>
                                        {type === IMAGE && (
                                          <StyledImage
                                            source={thumbnail_url_small || thumbnail_url || url}
                                            selected={selected}
                                            fallbackSource={extension === 'gif' ? url : '/assets/landscape.svg'}
                                            borderRadius={`${radius.l} ${radius.l} 0 0`}
                                          />
                                        )}

                                        {type === VIDEO && (
                                          <Flex
                                            alignItems="center"
                                            justifyContent="center"
                                            width="100%"
                                            height="100%"
                                            position="relative"
                                          >
                                            <Box position="absolute" top={space.xs} right={space.xs}>
                                              <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                                            </Box>
                                            <StyledImage
                                              source={thumbnail_url_small || thumbnail_url || '/assets/landscape.svg'}
                                              selected={selected}
                                              fallbackSource="/assets/landscape.svg"
                                              fallbackSourceWidth="50%"
                                              fallbackSourceHeight="50%"
                                              borderRadius={`${radius.l} ${radius.l} 0 0`}
                                            />
                                          </Flex>
                                        )}

                                        {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                                          <Flex
                                            flexDirection="column"
                                            alignItems="center"
                                            justifyContent="center"
                                            width="150px"
                                            height="150px"
                                            mx="auto"
                                          >
                                            <DynamicMedia media={item} isSmall showDynamicIcon />
                                          </Flex>
                                        )}

                                        {type === DOCUMENT && (
                                          <StyledImage
                                            source={
                                              thumbnail_url_small ||
                                              thumbnail_url ||
                                              getFileIconByExtension({ extension })
                                            }
                                            selected={selected}
                                            fallbackSource="/assets/file.svg"
                                            borderRadius={`${radius.l} ${radius.l} 0 0`}
                                          />
                                        )}
                                      </MediaWrapper>
                                      {publicationsCounter > 0 && (
                                        <StyledPublicationsCounterWrapper
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            setSliderPostsPreviewData({
                                              eventIds: publications,
                                              specialEvents: [],
                                            })
                                          }}
                                        >
                                          <Text fontSize="xxs" color="white">
                                            {publicationsCounter > 99 ? '99+' : publicationsCounter}
                                          </Text>
                                        </StyledPublicationsCounterWrapper>
                                      )}
                                    </Flex>
                                  </Flex>
                                </Flex>

                                <Excerpt color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                  {mediaDescription && truncate(mediaDescription, 35)}&nbsp;
                                </Excerpt>

                                {createdBy && (
                                  <StyledText color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                    {createdBy} on {moment(created).format('MMM D, YYYY')}
                                  </StyledText>
                                )}
                                {metaInformation && (
                                  <StyledText color="secondaryText" fontSize="xxs" mt="xs" px="xs">
                                    {metaInformation}
                                  </StyledText>
                                )}
                                <Box
                                  position="absolute"
                                  top={space.xs}
                                  left={space.xs}
                                  zIndex="2"
                                  width="100%"
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    handleClickSelectMedia(item)
                                  }}
                                >
                                  <SelectWrapper alignItems="center" justifyContent="center" selected={selected}>
                                    <StyledIconCheckmark selected={selected} width="10px" height="10px" />
                                  </SelectWrapper>
                                </Box>
                              </MediaItemWrapper>
                            )
                          })}
                        </Grid>
                      )}
                    </MediaSectionWrapper>
                  </Flex>
                </Scrollbars>
              </Fragment>
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Text fontSize="m">Selected {selectText}</Text>
            <Flex alignItems="center" mx="m" flex="1" maxWidth="500px">
              <StyledDialogEnvironmentSelectedMediasWrapper>
                {selectedMedias.map((item) => {
                  const { id, url, tempId, thumbnail_url, type, extension } = item
                  return (
                    <Flex alignItems="center" key={id || tempId} mr="m">
                      <StyledSelectedMediasWrapper
                        width="24px"
                        height="24px"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                      >
                        {type === VIDEO && (
                          <VideoWithFallback
                            url={url}
                            controls={false}
                            width="100%"
                            style={{ position: 'relative' }}
                            height="100%"
                            config={{
                              file: {
                                attributes: {
                                  poster: thumbnail_url || '',
                                },
                              },
                            }}
                          />
                        )}

                        {type === IMAGE && (
                          <SelectedMediasImage
                            source={thumbnail_url || url}
                            fallbackSource={extension === 'gif' ? url : '/assets/landscape.svg'}
                          />
                        )}

                        {type === IMAGE_DYNAMIC && <DynamicMedia media={item} isSmall />}

                        {type === DOCUMENT && (
                          <SelectedMediasImage
                            source={thumbnail_url || getFileIconByExtension({ extension })}
                            fallbackSource={getFileIconByExtension({ extension })}
                          />
                        )}

                        <StyledDismissWrapper
                          position="absolute"
                          onClick={() => {
                            handleClickSelectMedia(item)
                          }}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Image width="12px" height="12px" src="/assets/clear.svg" />
                        </StyledDismissWrapper>
                      </StyledSelectedMediasWrapper>
                    </Flex>
                  )
                })}
              </StyledDialogEnvironmentSelectedMediasWrapper>
            </Flex>
            <Flex alignItems="center" justifyContent="flex-end">
              <Button.Gray mr="m" isSmall onClick={handleDismiss}>
                Cancel
              </Button.Gray>
              <Button.Gradient
                onClick={() => {
                  handleClickAddMedias({ selectedMedias, postComponent })
                }}
                isSmall
              >
                <Text fontWeight="medium">Select</Text>
              </Button.Gradient>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>

      {sliderPostsPreviewData && (
        <SliderPostsPreview
          user={user}
          isOpen={!!sliderPostsPreviewData}
          data={sliderPostsPreviewData || {}}
          setSliderPostsPreviewData={setSliderPostsPreviewData}
          handleClickEvent={() => {}}
          timezone={
            getSelectedProfileGroupsAndTimezone({
              user,
              selectedEntities: selectedEntities && selectedEntities.length ? selectedEntities : null,
              selectedProfiles: selectedProfiles && selectedProfiles.length ? selectedProfiles : null,
            }).selectedTimezone
          }
          handleDeletePost={() => {}}
          isPostDeleting={false}
          handleUpdateCalendarPostLabels={() => {}}
          hasComponentControls={false}
        />
      )}

      <CalendarItemTooltip ref={tooltipRef} />
    </StyledDialogOverlay>
  )
}

MediaLibraryModal.defaultProps = {
  type: POST_IMAGE,
  postMedias: [],
  selectedEntities: [],
  selectedProfiles: [],
  blockDynamicMedias: false,
  maxImageAttachmentsAllowed: MAX_IMAGES_ATTACHMENTS_ALLOWED,
  maxVideoAttachmentsAllowed: MAX_VIDEOS_ATTACHMENTS_ALLOWED,
  maxDocumentAttachmentsAllowed: MAX_DOCUMENTS_ATTACHMENTS_ALLOWED,
  maxMixedMediaAttachmentsAllowed: MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED,
  replaceMediasWithoutLimitError: false,
}

MediaLibraryModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string,
  handleClickAddMedias: PropTypes.func.isRequired,
  postMedias: PropTypes.array,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  blockDynamicMedias: PropTypes.bool,
  maxImageAttachmentsAllowed: PropTypes.number,
  maxVideoAttachmentsAllowed: PropTypes.number,
  maxDocumentAttachmentsAllowed: PropTypes.number,
  maxMixedMediaAttachmentsAllowed: PropTypes.number,
  replaceMediasWithoutLimitError: PropTypes.bool,
}

MediaLibraryModal.displayName = 'MediaLibraryModal'

export default MediaLibraryModal

import moment from 'moment-timezone'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS } from 'theme'
import { colors as lightColors } from 'theme/light'
import { colors as darkColors } from 'theme/dark'

export const ERROR_MESSAGE = 'Whoops! Something went wrong.'
export const PLEASE_WAIT_MESSAGE = 'Please wait a bit longer while data is being processed.'

export const { LIGHT_THEME, DARK_THEME, SYSTEM_THEME } = {
  LIGHT_THEME: 'LIGHT',
  DARK_THEME: 'DARK',
  SYSTEM_THEME: 'SYSTEM',
}

export const COLOR_THEME_OPTIONS = {
  [LIGHT_THEME]: lightColors,
  [DARK_THEME]: darkColors,
}

export const LOCAL_STORAGE_KEY_THEME = 'VistaSocialThemeColor'

export const EXPORT_TO_PDF = 'pdf'

export const SOCIAL_NETWORKS = {
  CONSTANT_CONTACT: 'constantcontact',
  GOOGLE: 'googlebusiness',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  MAILCHIMP: 'mailchimp',
  YOUTUBE: 'youtube',
  TWITTER: 'twitter',
  AGENCYVISTA: 'agencyvista',
  CLUTCH: 'clutch',
  GOOGLE_ANALYTICS: 'googleanalytics',
  PINTEREST: 'pinterest',
  REDDIT: 'reddit',
  TRIPADVISOR: 'tripadvisor',
  TUMBLR: 'tumblr',
  VIMEO: 'vimeo',
  WORDPRESS: 'wordpress',
  YELP: 'yelp',
  BLOG: 'blog',
  BITLY: 'bitly',
  OPENTABLE: 'opentable',
  TRUSTPILOT: 'trustpilot',
  TIKTOK: 'tiktok',
  TIKTOKADS: 'tiktokads',
  SNAPCHAT: 'snapchat',
  VISTAPAGE: 'vistapage',
  THREADS: 'threads',
  BLUESKY: 'bluesky',
}

const {
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  LINKEDIN,
  GOOGLE,
  TWITTER,
  AGENCYVISTA,
  CLUTCH,
  GOOGLE_ANALYTICS,
  REDDIT,
  TRIPADVISOR,
  TUMBLR,
  VIMEO,
  WORDPRESS,
  YELP,
  MAILCHIMP,
  CONSTANT_CONTACT,
  BLOG,
  BITLY,
  PINTEREST,
  OPENTABLE,
  TRUSTPILOT,
  TIKTOK,
  TIKTOKADS,
  SNAPCHAT,
  VISTAPAGE,
  THREADS,
  BLUESKY,
} = SOCIAL_NETWORKS

export const SUPPORTED_SOCIAL_NETWORKS_MAJOR = [GOOGLE, FACEBOOK, INSTAGRAM, LINKEDIN, YOUTUBE, TWITTER]

export const SUPPORTED_SOCIAL_NETWORKS_MINOR = [
  GOOGLE_ANALYTICS,
  REDDIT,
  TRIPADVISOR,
  TUMBLR,
  VIMEO,
  WORDPRESS,
  YELP,
  MAILCHIMP,
  CONSTANT_CONTACT,
  BITLY,
  TIKTOK,
  SNAPCHAT,
  VISTAPAGE,
  THREADS,
  BLUESKY,
]

export {
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  LINKEDIN,
  GOOGLE,
  TWITTER,
  AGENCYVISTA,
  CLUTCH,
  GOOGLE_ANALYTICS,
  PINTEREST,
  REDDIT,
  TRIPADVISOR,
  TUMBLR,
  VIMEO,
  WORDPRESS,
  YELP,
  MAILCHIMP,
  CONSTANT_CONTACT,
  BLOG,
  BITLY,
  TRUSTPILOT,
  OPENTABLE,
  TIKTOK,
  TIKTOKADS,
  SNAPCHAT,
  VISTAPAGE,
  THREADS,
  BLUESKY,
}

export const NOTIFICATION_TYPES = {
  CONVERSATION: 'conversation',
  FAVORITES: 'favorites',
}

export const USER_TYPES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  ADMIN_SUPPORT_MANAGER: 'ADMIN_SUPPORT_MANAGER',
  ADMIN_SUPPORT: 'ADMIN_SUPPORT',
  ADMIN_MARKETING: 'ADMIN_MARKETING',
  ADMIN_DEVELOPER: 'ADMIN_DEVELOPER',
  VISITOR: 'VISITOR',
  APPSUMO: 'APPSUMO',
  ADVOCATE: 'ADVOCATE',
  CONNECT: 'CONNECT',
}

export const COLOR_SCHEMES = [
  {
    bg: COLOR_CONSTANTS.JUNGLE_GREEN,
    text: COLOR_CONSTANTS.WHITE,
  },
  {
    bg: transparentize(0.9, COLOR_CONSTANTS.JUNGLE_GREEN),
    text: COLOR_CONSTANTS.JUNGLE_GREEN,
  },
  {
    bg: COLOR_CONSTANTS.BOYZONE,
    text: COLOR_CONSTANTS.WHITE,
  },
  {
    bg: transparentize(0.9, COLOR_CONSTANTS.BOYZONE),
    text: COLOR_CONSTANTS.BOYZONE,
  },
  {
    bg: COLOR_CONSTANTS.COBALT,
    text: COLOR_CONSTANTS.WHITE,
  },
  {
    bg: transparentize(0.9, COLOR_CONSTANTS.COBALT),
    text: COLOR_CONSTANTS.COBALT,
  },
]

export const ERROR_COLOR_SCHEME = COLOR_SCHEMES[5]
export const SUCCESS_COLOR_SCHEME = COLOR_SCHEMES[1]

export const LOGO_STATIC = 'https://vistasocial.com/img/logo_small.png'

export const COOKIE_EXPIRATION_DAYS = 365

export const IS_COOKIES_ACCEPTED = 'isCookiesAccepted'

export const COOKIE_GET_STARTED_HIDDEN = 'getStartedHidden'

export const COOKIE_GET_STARTED_COLLAPSED = 'getStartedCollapsed'

export const COOKIE_TRENDING_SOUNDS_PROMPT_COLLAPSED = 'trendingSoundsPromptHidden'

export const COOKIE_ENTITY_SELECTOR_INSTRUCTIONS = 'entitySelectorInstructions'

export const COOKIE_PAGE_WARNING = 'pageWarning'

export const LINK_CODE = 'link_code'

export const UTM_QUERY = 'utm_query'

export const LINK_CODE_EXPIRES_DAYS = 30

export const REGISTRATION_TYPE = 'registration_type'

export const REGISTRATION_TYPE_EXPIRES_DAYS = 1

export const PROFILE_STATUSES = {
  ACTIVE: 'active',
  INITIALIZING: 'initializing',
  BLOCKED: 'error',
  // INACTIVE: 'inactive', inactive status is treated as blocked/error status
}

export const REVIEW_FILTERS = {
  NEWEST_TO_OLDEST: {
    id: 1,
    label: 'Newest to oldest',
  },
  OLDEST_TO_NEWEST: {
    id: 2,
    label: 'Oldest to newest',
  },
  HIGHEST_TO_LOWEST: {
    id: 3,
    label: 'Highest to lowest',
  },
  LOWEST_TO_HIGHEST: {
    id: 4,
    label: 'Lowest to highest',
  },
}

export const SEARCH_SORT_FILTERS = [
  {
    id: 1,
    name: 'Most Recommended',
  },
  {
    id: 2,
    name: 'Name A to Z',
  },
  {
    id: 3,
    name: 'Name Z to A',
  },
  {
    id: 4,
    name: 'Newest Added',
  },
]

export const COOKIE_NOTICE_HEIGHT = 30

export const AGENCY_STATUSES = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
  {
    value: 'SUSPENDED',
    label: 'Suspended',
  },
  {
    value: 'REVIEW',
    label: 'Review',
  },
]

export const SUSPENSION_REASONS = [
  {
    value: 'Terms of Use Violation',
    label: 'Terms of Use Violation',
  },
  {
    value: 'Non Agency Registration',
    label: 'Non Agency Registration',
  },
  {
    value: 'Agency Does Not Exist (site dead)',
    label: 'Agency Does Not Exist (site dead)',
  },
  {
    value: 'Removal Requested',
    label: 'Removal Requested',
  },
]

export const PARTNER_TYPES = [
  { value: 'PARTNERSHIP', label: 'Partnership' },
  { value: 'CERTIFICATE', label: 'Certificate' },
]

export const MAX_COMPARISON_AGENCIES = 5

export const LIST_IMPORT_TYPES = [{ value: 'PARTNER', label: 'PARTNER' }]

export const RFP_STATUSES = [
  {
    value: 'REVIEW',
    label: 'Pending review',
    tooltip: 'Agency Vista team is currently reviewing your proposal. Once reviewed it will be forwarded to agencies.',
    badgeColor: COLOR_CONSTANTS.COSMIC_ENERGY,
  },
  {
    value: 'APPROVED',
    label: 'Approved',
    tooltip: `Your request for proposal is approved and has been sent to agencies. They will respond once they review your requirements.`,
    badgeColor: COLOR_CONSTANTS.JUNGLE_GREEN,
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
    tooltip: `You have cancelled this request for proposal`,
    badgeColor: COLOR_CONSTANTS.COSMIC_ENERGY,
  },
  {
    value: 'REJECTED',
    label: 'Needs more information',
    tooltip: `Your request for proposal has been rejected as it needs improvement. Please review rejection notes and resubmit.`,
    badgeColor: COLOR_CONSTANTS.FLUORESCENT_RED,
  },
]

// export const USER_ROLES = {
//   ADMIN: 'ADMIN',
//   EDITOR: 'EDITOR',
//   CONTRIBUTOR: 'CONTRIBUTOR',
//   GUEST: 'GUEST',
// }

// const { ADMIN, EDITOR, CONTRIBUTOR, GUEST } = USER_ROLES

export const { USER_ROLE_NO_ACCESS, USER_ROLE_VIEW, USER_ROLE_MANAGE, USER_ROLE_CONTRIBUTOR, USER_ROLE_MANAGER } = {
  USER_ROLE_NO_ACCESS: 'NONE',
  USER_ROLE_MANAGE: 'MANAGE',
  USER_ROLE_VIEW: 'VIEW',
  USER_ROLE_CONTRIBUTOR: 'CONTRIBUTOR',
  USER_ROLE_MANAGER: 'MANAGER',
}

export const { ADMIN, RESTRICTED, ACCESS_NONE, ACCESS_VIEW, ACCESS_MANAGE } = {
  ADMIN: 'ADMIN',
  RESTRICTED: 'RESTRICTED',
  ACCESS_NONE: 'NONE',
  ACCESS_VIEW: 'VIEW',
  ACCESS_MANAGE: 'MANAGE',
}

export const DEFAULT_VIDEO_THUMBNAIL = '/assets/images/video_thumbnail.png'

export const DEFAULT_SOCIAL_PROFILE_SOURCE = '/assets/company.svg'

export const CALENDAR_VIEW_OPTIONS = [
  { value: 'dayGrid', icon: '/assets/vistasocial/calendar.svg' },
  { value: 'list', icon: '/assets/vistasocial/list.svg' },
]

export const CALENDAR_TIME_OPTIONS = [
  { value: 'Month', label: 'Monthly' },
  { value: 'Week', label: 'Weekly' },
  { value: 'Day', label: 'Daily' },
]

export const CALENDAR_OPTIONS = {
  time: CALENDAR_TIME_OPTIONS[0],
  viewTitle: moment().format('MMMM YYYY'),
  view: CALENDAR_VIEW_OPTIONS[0],
}

export const {
  LABELS_TYPE_INBOX,
  LABELS_TYPE_PUBLICATION,
  LABELS_TYPE_MEDIA,
  LABELS_TYPE_PUBLICATION_GROUP,
  LABELS_TYPE_IDEA,
  LABELS_TYPE_TASK,
  LABELS_TYPE_USER,
} = {
  LABELS_TYPE_INBOX: 'INBOX',
  LABELS_TYPE_PUBLICATION: 'PUBLICATION',
  LABELS_TYPE_MEDIA: 'MEDIA',
  LABELS_TYPE_PUBLICATION_GROUP: 'PUBLICATION_GROUP',
  LABELS_TYPE_IDEA: 'IDEA',
  LABELS_TYPE_TASK: 'TASK',
  LABELS_TYPE_USER: 'USER',
}

export const DEFAULT_TIME_ZONE = 'Etc/UTC'

export const {
  PASSWORD_CONDITION_LOWERCASE,
  PASSWORD_CONDITION_UPPERCASE,
  PASSWORD_CONDITION_DIGIT,
  PASSWORD_CONDITION_SPECIAL_SYMBOL,
  PASSWORD_CONDITION_LENGTH,
  PASSWORD_CONDITION_REGEXP,
} = {
  PASSWORD_CONDITION_LOWERCASE: {
    text: 'Contains 1 lowercase',
    match: /^(?=.*[a-z])/,
  },
  PASSWORD_CONDITION_UPPERCASE: {
    text: 'Contains 1 uppercase',
    match: /^(?=.*[A-Z])/,
  },
  PASSWORD_CONDITION_DIGIT: {
    text: 'Contains 1 number',
    match: /^(?=.*[0-9])/,
  },
  PASSWORD_CONDITION_SPECIAL_SYMBOL: {
    text: 'Contains 1 special character (!@#$%^&*)',
    match: /^(?=.*[!@#\$%\^&\*])/,
  },
  PASSWORD_CONDITION_LENGTH: { text: 'At least 8 characters long', match: /^(?=.{8,})/ },
  PASSWORD_CONDITION_REGEXP: {
    text:
      'Password must be at least 8 characters long, contain 1 uppercase, 1 lowercase, 1 number and 1 special case character',
    match: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-])(?=.{8,})/,
  },
}

export const FULL_PAGE_ROUTES = ['/calendar', '/inbox', '/reports']

export const NO_SSR_ROUTES = ['/dashboard', '/calendar', '/reports']

export const {
  STEP_HELPER_CALENDAR,
  STEP_HELPER_INBOX,
  STEP_HELPER_REVIEWS,
  STEP_HELPER_LISTENERS,
  STEP_HELPER_REPORTS,
  STEP_HELPER_TASKS,
} = {
  STEP_HELPER_CALENDAR: 'calendar',
  STEP_HELPER_INBOX: 'inbox',
  STEP_HELPER_REVIEWS: 'reviews',
  STEP_HELPER_LISTENERS: 'listeners',
  STEP_HELPER_REPORTS: 'reports',
  STEP_HELPER_TASKS: 'tasks',
}

const ENTIY_SELECTOR_INTRO =
  'Profile group and profile selector. Here you can choose connected profiles to work with. You can always connect more profiles.'

const ALL_DONE = `Excellent! Thank you for taking your time and viewing the tutorial. If you need more help and training, <a href='https://support.vistasocial.com/hc/en-us/requests/new' target='_blank'>just let us know</a>. Our team would love to answer your questions and possibly host a training session with you and your team.`

const TIMEZONE =
  'Currently active timezone. This timezone is based on the first profile group currently selected. If you need to alter the timezone you can click on this text.'
export const STEP_HELPERS = {
  [STEP_HELPER_CALENDAR]: [
    {
      element: '.special-empty-query-class',
      intro: `Welcome to Vista Social publishing calendar. Here you will find all the features you need to publish to social media profiles. We've created this brief tutorial to help you navigate this feature.`,
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.entity-selector',
      intro: ENTIY_SELECTOR_INTRO,
      tooltipClass: 'introjs-entity-selector-tooltip',
    },
    {
      element: '.calendar-calendar',
      intro:
        'Here you will find posts that have been scheduled, published, saved as drafts or pending review. You can mouse over the posts to see the details or click on it to find out even more.',
    },
    {
      element: '.calendar-settings',
      intro:
        'Change posts preview (no preview, small preview or large preview) and customize the first day of the calendar week (Sunday or Monday).',
    },
    {
      element: '.calendar-reload',
      intro: 'Quickly reload calendar to see the latest post changes or posts from other team members.',
    },
    {
      element: '.calendar-views',
      intro:
        'Change calendar view (monthly, weekly or daily) and navigate to other date ranges (i.e. prior month, prior week, etc).',
    },
    {
      element: '.calendar-timezone',
      intro: TIMEZONE,
    },
    {
      element: '.calendar-filters',
      intro:
        'Search for a post containing specific text, filter posts based on their status (i.e. published, scheduled, in review), published by a user or labeled.',
    },
    {
      element: '.calendar-export',
      intro:
        'Export publishing calendar to PDF and share it with clients and colleagues. You will be able to select which posts to export based on their status (i.e. only published posts) and date range.',
    },
    {
      element: '.calendar-publisher',
      intro:
        'Click Publish to schedule your posts. Here you will find ability to schedule individual posts, bulk publish, find content and enable smart publishing.',
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.special-empty-query-class',
      intro: ALL_DONE,
      tooltipClass: 'introjs-wide-modal',
      //      addButton: 'Schedule a post',
    },
  ],
  [STEP_HELPER_INBOX]: [
    {
      element: '.special-empty-query-class',
      intro: `Welcome to Vista Social Inbox! Here you will find all the features you need to respond to incoming comments, messages and reviews. We've created this brief tutorial to help you navigate this feature.`,
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.entity-selector',
      intro: ENTIY_SELECTOR_INTRO,
      tooltipClass: 'introjs-entity-selector-tooltip',
    },
    {
      element: '.inbox-message-types',
      intro: `By default you are looking at all messages. If you prefer you can switch to just the unread message view.`,
    },
    {
      element: '.inbox-filters',
      intro: `Search and filter conversations based on author, contents or type of message (i.e. a comment, a review, a dm, etc)`,
    },
    {
      element: '.inbox-conversations',
      intro: `This is a list of your conversations. Here you will see the name of the person, the network that this is from, and the last message from that person.`,
    },
    {
      element: '.inbox-detail',
      intro: `A conversation view. All the messages ever exchanged with this person on this network will show up here. Note that you can reply to a specific post and explore other options for each message such as viewing it on network, tagging and liking the post.`,
    },
    {
      element: '.inbox-reply',
      intro: `This is a place for replying to a message or leaving an internal note to your team member. For replies you can attach images, emojis and hashtags. For internal notes you can mention your team members to bring their attention to this conversation.`,
    },
    {
      element: '.special-empty-query-class',
      intro: ALL_DONE,
      tooltipClass: 'introjs-wide-modal',
    },
  ],
  [STEP_HELPER_LISTENERS]: [
    {
      element: '.special-empty-query-class',
      intro: `Welcome to Vista Social Listening! Here, you can create listeners that will monitor your network for conversation containing specific keywords.`,
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.entity-selector',
      intro: ENTIY_SELECTOR_INTRO,
      tooltipClass: 'introjs-entity-selector-tooltip',
    },
    {
      element: '.listening-types',
      intro: `Current active listeners. Note that the status will change from Initializing to Active a few minutes after you create your listener.`,
    },
    {
      element: '.listening-add',
      intro: `Click "Add listener" to create a new listener.`,
    },
    {
      element: '.listening-listeners',
      intro: `This is a list of your listeners. Here you will see the name of the listener, the number of profiles and the status of the listener.`,
    },
    {
      element: '.listening-filters',
      intro: `Search and filter listener based on the key words, contents or type of message (i.e. a comment, a review, a dm, etc)`,
    },
    {
      element: '.listening-detail',
      intro: `A listener view. All the messages ever exchanged with this person on this network will show up here.`,
    },
    {
      element: '.special-empty-query-class',
      intro: ALL_DONE,
      tooltipClass: 'introjs-wide-modal',
    },
  ],
  [STEP_HELPER_REVIEWS]: [
    {
      element: '.special-empty-query-class',
      intro: `Welcome to Vista Social Reviews. Here, you will be able to see and respond to reviews across connected profiles. Currently we monitor reviews from Facebook, Google, Yelp, TripAdvisor, Opentable and Trustpilot. If your brand has reviews on these networks, please be sure to connect them to Vista Social!`,
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.entity-selector',
      intro: ENTIY_SELECTOR_INTRO,
      tooltipClass: 'introjs-entity-selector-tooltip',
    },
    {
      element: '.reviews-types',
      intro: `By default you are looking at all reviews. If you prefer you can switch to reviews that have either not been responded to or reviews that you have already responded to.`,
    },
    {
      element: '.reviews-filters',
      intro: `Search for reviews based on specific keywords or phrases or sort them based on their age and rating.`,
    },
    {
      element: '.reviews-export',
      intro: 'Export reviews to PDF to share them with your team or clients.',
    },
    {
      element: '.reviews-reviews',
      intro: `This is a list of your reviews. Here you will see the name of the person, the network that this is from, rating and the review from that person. You can reply to the person from the review section.`,
    },
    {
      element: '.special-empty-query-class',
      intro: ALL_DONE,
      tooltipClass: 'introjs-wide-modal',
    },
  ],
  [STEP_HELPER_REPORTS]: [
    {
      element: '.special-empty-query-class',
      intro: `Welcome to Vista Social Reports. Here you will find all the features you need to run analytics, build custom reports and automate their generation and delivery.`,
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.entity-selector',
      intro: ENTIY_SELECTOR_INTRO,
      tooltipClass: 'introjs-entity-selector-tooltip',
    },
    {
      element: '.report-calendar',
      intro: 'These are reports that have been scheduled, generated and ready for download.',
    },
    {
      element: '.report-settings',
      intro: 'Customize the first day of the calendar week (Sunday or Monday).',
    },
    {
      element: '.report-reload',
      intro: 'Quickly reload calendar.',
    },
    {
      element: '.report-views',
      intro: 'Change calendar view and navigate to other date ranges.',
    },
    {
      element: '.report-timezone',
      intro: TIMEZONE,
    },
    {
      element: '.report-filters',
      intro: 'Filter reports based on their status: Scheduled, Ready for download or Failed.',
    },
    {
      element: '.report-run',
      intro:
        'Click Run Report to schedule your reports. Please note that here you will find ability to run reports, build custom reports, and schedule reports for automatic delivery.',
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.special-empty-query-class',
      intro: ALL_DONE,
      tooltipClass: 'introjs-wide-modal',
    },
  ],
  [STEP_HELPER_TASKS]: [
    {
      element: '.special-empty-query-class',
      intro: `Welcome to Vista Social Tasks! Here, you can manage tasks assigned to you and others.`,
      tooltipClass: 'introjs-wide-modal',
    },
    {
      element: '.entity-selector',
      intro: ENTIY_SELECTOR_INTRO,
      tooltipClass: 'introjs-entity-selector-tooltip',
    },
    {
      element: '.tasks-types',
      intro: `By default you are looking at all open tasks assigned to you. If you prefer you can switch to your priority or closed tasks. You can also view tasks assigned to other team members.`,
    },
    {
      element: '.tasks-filters',
      intro: `Search and filter tasks based on the key words or type of task (i.e. general, sales, support, etc)`,
    },
    {
      element: '.tasks-list',
      intro: `Here you will see the actual tasks and be able to work on them.`,
    },
    // {
    //   element: '.listening-detail',
    //   intro: `A listener view. All the messages ever exchanged with this person on this network will show up here.`,
    // },
    {
      element: '.special-empty-query-class',
      intro: ALL_DONE,
      tooltipClass: 'introjs-wide-modal',
    },
  ],
}

export const {
  PERMISSION_PUBLISH,
  PERMISSION_REPORTS,
  PERMISSION_INBOX,
  PERMISSION_REVIEWS,
  PERMISSION_LISTEN,
  PERMISSION_PROFILES,
  PERMISSION_TEAM,
  PERMISSION_VISTA_PAGES,
  PERMISSION_IDEAS,
} = {
  PERMISSION_PUBLISH: 'publish',
  PERMISSION_REPORTS: 'reports',
  PERMISSION_INBOX: 'inbox',
  PERMISSION_REVIEWS: 'reviews',
  PERMISSION_LISTEN: 'listen',
  PERMISSION_PROFILES: 'profiles',
  PERMISSION_TEAM: 'team',
  PERMISSION_VISTA_PAGES: 'pages',
  PERMISSION_IDEAS: 'ideas',
}

export const PERMISSIONS = {
  PERMISSION_PUBLISH,
  PERMISSION_REPORTS,
  PERMISSION_INBOX,
  PERMISSION_REVIEWS,
  PERMISSION_LISTEN,
  PERMISSION_PROFILES,
  PERMISSION_TEAM,
  PERMISSION_VISTA_PAGES,
  PERMISSION_IDEAS,
}

export const { PERMISSION_ERROR, PERMISSION_ERROR_NO_ENTITIES, PERMISSION_ERRORS } = {
  PERMISSION_ERROR: 'Please contact your account admin',
  PERMISSION_ERROR_NO_ENTITIES: `Looks like you don't have access to any profile groups or profiles. Please contact your account administrator.`,
  PERMISSION_ERRORS: {
    [PERMISSION_PUBLISH]: {
      ACCESS: { [ACCESS_NONE]: 'No publish access', [ACCESS_VIEW]: 'No publish access', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to create posts. Please contact your account admin.',
    },
    [PERMISSION_LISTEN]: {
      ACCESS: { [ACCESS_NONE]: 'No access to listening', [ACCESS_VIEW]: 'No access to listening', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to manage listeners. Please contact your account admin.',
    },
    [PERMISSION_TEAM]: {
      ACCESS: { [ACCESS_NONE]: 'No team access', [ACCESS_VIEW]: 'No team access', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to invite team members. Please contact your account admin.',
    },
    [PERMISSION_PROFILES]: {
      ACCESS: { [ACCESS_NONE]: 'No profiles access', [ACCESS_VIEW]: 'No profiles access', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to manage profiles. Please contact your account admin.',
      NO_ACCESS_MULTIPLE_PROFILES: 'You do not have access to the following profiles:',
    },
    [PERMISSION_REPORTS]: {
      ACCESS: { [ACCESS_NONE]: 'No reports access', [ACCESS_VIEW]: 'No reports access', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You are not authorized to manage reports. Please contact your account admin.',
      NO_ACCESS_BUILDER: 'You are not authorized to build custom reports. Please contact your account admin.',
      NO_ACCESS_SCHEDULE: 'You are not authorized to schedule reports. Please contact your account admin.',
    },
    [PERMISSION_REVIEWS]: {
      ACCESS: { [ACCESS_NONE]: 'No reviews access', [ACCESS_VIEW]: 'No reviews access', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to manage reviews. Please contact your account admin.',
    },
    [PERMISSION_INBOX]: {
      ACCESS: { [ACCESS_NONE]: 'No inbox access', [ACCESS_VIEW]: 'No inbox access', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to manage messages. Please contact your account admin.',
    },
    [PERMISSION_IDEAS]: {
      ACCESS: { [ACCESS_NONE]: 'No ideas access', [ACCESS_VIEW]: 'No manage access for ideas', [ACCESS_MANAGE]: '' },
      NO_ACCESS: 'You do not have access to manage ideas. Please contact your account admin.',
    },
  },
}

export const { TASK_TYPE_MESSAGE, TASK_TYPE_GENERAL, TASK_TYPE_PUBLICATION, TASK_TYPE_REPORT, TASK_TYPE_IDEA } = {
  TASK_TYPE_MESSAGE: 'message',
  TASK_TYPE_GENERAL: 'general',
  TASK_TYPE_PUBLICATION: 'publication',
  TASK_TYPE_REPORT: 'report',
  TASK_TYPE_IDEA: 'idea',
}

export const { IMAGE, VIDEO, AUDIO, IMAGE_DYNAMIC, VIDEO_DYNAMIC, DOCUMENT, MIXED_MEDIA, FOLDER } = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE_DYNAMIC: 'image_dynamic',
  VIDEO_DYNAMIC: 'video_dynamic',
  DOCUMENT: 'document',
  MIXED_MEDIA: 'mixed_media',
  FOLDER: 'folder',
}

export const {
  PLAN_FREE,
  PLAN_PRO,
  PLAN_UPGRADE,
  FEATURE_BULK_SCHEDULING,
  FEATURE_SMART_SCHEDULING,
  FEATURE_FIND_CONTENT,
  FEATURE_NETWORK_PLANNER,
  FEATURE_LINK_SHORTENER,
  FEATURE_UTM_TRACKING,
  FEATURE_UNIVERSAL_MENTIONS,
  FEATURE_ADD_TO_QUEUE,
  FEATURE_SCHEDULE_REPORTS,
  FEATURE_EXPORT_REPORT_TO_PDF,
  FEATURE_POST_PERFORMANCE_REPORTS,
  FEATURE_REVIEW_PERFORMANCE_REPORTS,
  FEATURE_COMPETITOR_ANALYSIS_REPORTS,
  FEATURE_FACEBOOK_ADS_REPORTS,
  FEATURE_TASK_REPORTS,
  FEATURE_BRANDED_REPORTS,
  FEATURE_BUILDER_REPORTS,
  FEATURE_SOCIAL_INBOX,
  FEATURE_INTERNAL_NOTES,
  FEATURE_ENGAGEMENT_TOOLS,
  FEATURE_LISTENING,
  FEATURE_REVIEW_PROFILES,
  FEATURE_SHARE_CALENDAR_VIA_LINK,
  FEATURE_DYNAMIC_MEDIA,
  FEATURE_PROFILE_VARIABLES,
  FEATURE_EXTERNAL_CALENDARS,
  FEATURE_WHITE_LABEL_SETUP,
  FEATURE_API_KEY,
  FEATURE_QUEUE_LABELS,
  FEATURE_WORKFLOW,
  FEATURE_MULTI_WORKFLOW,
  FEATURE_WEB_ANALYTICS,
  FEATURE_VISTA_PAGE_ADVANCED,
  FEATURE_CANVA,
  FEATURE_DRIVES,
  FEATURE_POST_TAGGING,
  FEATURE_POST_ENGAGEMENT,
  FEATURE_POST_TARGETING,
  FEATURE_NOTIFICATION_SLACK,
  FEATURE_SENTIMENT,
  FEATURE_INDUSTRY_BENCHMARK_REPORTS,
  FEATURE_CONNECT_LINK,
  FEATURE_COMPLIANCE,
} = {
  PLAN_FREE: 'Free',
  PLAN_PRO: 'Pro',
  PLAN_UPGRADE: 'Upgrade plan',
  FEATURE_BULK_SCHEDULING: 'bulk_scheduling',
  FEATURE_SMART_SCHEDULING: 'smart_scheduling',
  FEATURE_FIND_CONTENT: 'find_content',
  FEATURE_NETWORK_PLANNER: 'network_planner',
  FEATURE_LINK_SHORTENER: 'link_shortener',
  FEATURE_UTM_TRACKING: 'utm_tracking',
  FEATURE_UNIVERSAL_MENTIONS: 'universal_mentions', // not used
  FEATURE_ADD_TO_QUEUE: 'add_to_queue',
  FEATURE_SCHEDULE_REPORTS: 'schedule_reports',
  FEATURE_EXPORT_REPORT_TO_PDF: 'export_report_to_pdf',
  FEATURE_POST_PERFORMANCE_REPORTS: 'post_performance_reports',
  FEATURE_REVIEW_PERFORMANCE_REPORTS: 'review_performance_reports',
  FEATURE_COMPETITOR_ANALYSIS_REPORTS: 'competitor_analysis_reports',
  FEATURE_FACEBOOK_ADS_REPORTS: 'facebook_ads_report',
  FEATURE_TASK_REPORTS: 'task_reports',
  FEATURE_BRANDED_REPORTS: 'branded_reports', // not used
  FEATURE_BUILDER_REPORTS: 'builder_reports',
  FEATURE_SOCIAL_INBOX: 'social_inbox',
  FEATURE_INTERNAL_NOTES: 'internal_notes',
  FEATURE_ENGAGEMENT_TOOLS: 'engagement_tools', // not used
  FEATURE_LISTENING: 'listening',
  FEATURE_REVIEW_PROFILES: 'review_profiles',
  FEATURE_SHARE_CALENDAR_VIA_LINK: 'share_calendar_via_link',
  FEATURE_DYNAMIC_MEDIA: 'dynamic_media',
  FEATURE_PROFILE_VARIABLES: 'profile_variables',
  FEATURE_EXTERNAL_CALENDARS: 'external_calendars',
  FEATURE_WHITE_LABEL_SETUP: 'white_label_setup',
  FEATURE_API_KEY: 'api_key',
  FEATURE_QUEUE_LABELS: 'queue_labels',
  FEATURE_WORKFLOW: 'workflow',
  FEATURE_MULTI_WORKFLOW: 'multi_workflow',
  FEATURE_WEB_ANALYTICS: 'web_analytics',
  FEATURE_VISTA_PAGE_ADVANCED: 'vista_page_advanced',
  FEATURE_CANVA: 'canva',
  FEATURE_DRIVES: 'drives',
  FEATURE_POST_TAGGING: 'post_tagging',
  FEATURE_POST_ENGAGEMENT: 'post_engagement',
  FEATURE_POST_TARGETING: 'post_targeting',
  FEATURE_NOTIFICATION_SLACK: 'notification_slack',
  FEATURE_SENTIMENT: 'sentiment',
  FEATURE_INDUSTRY_BENCHMARK_REPORTS: 'industry_benchmark_reports',
  FEATURE_CONNECT_LINK: 'connect_link',
  FEATURE_COMPLIANCE: 'compliance',
}

export const PLAN_FEATURES = {
  [FEATURE_BULK_SCHEDULING]: `Your plan does not offer bulk scheduling. Please upgrade.`,
  [FEATURE_SMART_SCHEDULING]: `Your plan does not offer smart scheduling. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_FIND_CONTENT]: `Your plan does not offer find content feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_NETWORK_PLANNER]: `Your plan does not offer network planner feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_LINK_SHORTENER]: `Your plan does not offer link shortening. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_UTM_TRACKING]: `Your plan does not offer utm tracking. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_UNIVERSAL_MENTIONS]: `Your plan does not offer saved mentions. Please upgrade.`,
  [FEATURE_ADD_TO_QUEUE]: `Your plan does not offer publishing queues. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_SCHEDULE_REPORTS]: `Your plan does not offer report scheduling.`,
  [FEATURE_EXPORT_REPORT_TO_PDF]: `Your plan does not offer report exporting. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_POST_PERFORMANCE_REPORTS]: `Your plan does not offer post performance report. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_REVIEW_PERFORMANCE_REPORTS]: `Your plan does not offer review performance report. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_COMPETITOR_ANALYSIS_REPORTS]: `Your plan does not offer competitor analysis report. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_FACEBOOK_ADS_REPORTS]: `Your plan does not offer Facebook ad account report. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_TASK_REPORTS]: `Your plan does not offer task performance report. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_BUILDER_REPORTS]: `Your plan does not offer custom reports. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_SOCIAL_INBOX]: `Your plan does not offer inbox engagement actions. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_INTERNAL_NOTES]: `Your plan does not offer internal inbox notes.`,
  [FEATURE_LISTENING]: `Your plan does not offer listening. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_REVIEW_PROFILES]: `Your plan does not offer connecting profiles from this network. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_SHARE_CALENDAR_VIA_LINK]: `Your plan does not offer share calendar feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_DYNAMIC_MEDIA]: `<a href="/pricing" target="_blank">Please upgrade</a> to Pro+ plan to be able to remove this.`,
  [FEATURE_PROFILE_VARIABLES]: `Your plan does not offer custom field feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_PROFILE_VARIABLES]: `Your plan does not offer custom fields feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_EXTERNAL_CALENDARS]: `Your plan does not offer external calendars feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_WHITE_LABEL_SETUP]: `Your plan does not offer white label setup. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_API_KEY]: `Your plan does not offer API key based integrations. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_QUEUE_LABELS]: `Your plan does not offer queue labels feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_WORKFLOW]: `Publication workflow is not included with your subscription. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_MULTI_WORKFLOW]: `Multi-stage workflows are not included with your subscription. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_WEB_ANALYTICS]: `Your plan does not offer web analytics feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_VISTA_PAGE_ADVANCED]: `Your plan does not offer advanced Vista Page features. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_CANVA]: `Your plan does not offer Canva integration feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_DRIVES]: `Your plan does not offer 3rd party cloud storage integration feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_POST_TAGGING]: `Your plan does not offer post tagging feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_POST_ENGAGEMENT]: `Your plan does not offer post engagement feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_POST_TARGETING]: `Your plan does not offer post targeting feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_NOTIFICATION_SLACK]: `Your plan does not offer notifications via Slack. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_SENTIMENT]: `Your plan does not offer sentiment analysis feature. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_INDUSTRY_BENCHMARK_REPORTS]: `Your plan does not offer industry benchmark report. <a href="/pricing" target="_blank">Please upgrade</a>.`,
  [FEATURE_COMPLIANCE]: `Your plan does not offer brand safety & compliance. <a href="/pricing" target="_blank">Please upgrade</a>.`,
}

export const WARNING_PROFILE_LINKS_MAPPING = {
  'The account owner has disabled access to Instagram direct messages.': {
    message: 'The account owner has disabled access to Instagram direct messages. Click to learn more.',
    link: 'https://support.vistasocial.com/hc/en-us/articles/4444967221019-Instagram-Allow-Access-to-Messages',
  },
}

export const CONTAINER_MAX_WIDTH = '1280px'

export const {
  SITE_LINK_PUBLISHING,
  SITE_LINK_SOLUTIONS_AGENCIES,
  SITE_LINK_SOLUTIONS_CREATORS,
  SITE_LINK_SOLUTIONS_ENTERPRISE,
  SITE_LINK_SOLUTIONS_MULTI_LOCATION_BRANDS,
  SITE_LINK_SOLUTIONS_SMALL_BUSINESSES,
  SITE_LINK_ANALYTICS,
  SITE_LINK_LISTENING,
  SITE_LINK_REVIEWS,
  SITE_LINK_ENGAGEMENT,
  SITE_LINK_INTEGRATIONS,
  SITE_LINK_CUSTOMER_STORIES,
  SITE_LINK_AI,
  SITE_LINK_VISTA_PAGE,
  SITE_LINK_ADVOCACY,
} = {
  SITE_LINK_PUBLISHING: '/social-media-publishing/',
  SITE_LINK_SOLUTIONS_AGENCIES: '/agencies/',
  SITE_LINK_SOLUTIONS_CREATORS: '/creators/',
  SITE_LINK_SOLUTIONS_ENTERPRISE: '/enterprise/',
  SITE_LINK_SOLUTIONS_MULTI_LOCATION_BRANDS: '/multi-location-brands/',
  SITE_LINK_SOLUTIONS_SMALL_BUSINESSES: '/small-medium-businesses/',
  SITE_LINK_ANALYTICS: '/social-media-analytics/',
  SITE_LINK_LISTENING: '/social-media-listening/',
  SITE_LINK_REVIEWS: '/review-management/',
  SITE_LINK_ENGAGEMENT: '/social-media-engagement/',
  SITE_LINK_INTEGRATIONS: '/integrations/',
  SITE_LINK_CUSTOMER_STORIES: '/customers/',
  SITE_LINK_AI: '/ai-assistant/',
  SITE_LINK_VISTA_PAGE: '/vista-page/',
  SITE_LINK_ADVOCACY: '/employee-advocacy/',
}

export const SOCIAL_NETWORKS_COLORS = {
  FACEBOOK: 'rgb(24,119,242)',
  GOOGLE: 'rgb(244,180,0)',
  INSTAGRAM: 'rgb(193,53,132)',
  LINKEDIN: 'rgb(40,103,178)',
  REDDIT: 'rgb(255, 86, 0)',
  TWITTER: 'rgb(29,161,242)',
  VIMEO: 'rgb(25, 183, 234)',
  YOUTUBE: 'rgb(255,0,0)',
  PINTEREST: 'rgb(230,0,35)',
  YELP: 'rgb(244,13,21)',
  OPENTABLE: 'rgb(218,55,67)',
  TRUSTPILOT: 'rgb(0,182,122)',
  TRIPADVISOR: 'rgb(52,224,161)',
  TIKTOK: 'rgb(22, 24, 35)',
  GOOGLE_ANALYTICS: 'rgb(237,117,10)',
  WORDPRESS: 'rgb(0,38,34)',
  TUMBLR: 'rgb(54,70,93)',
  MAILCHIMP: 'rgb(159,136,0)',
  CONSTANT_CONTACT: 'rgb(0,111,159)',
  BLOG: 'rgb(143,0,159)',
  BITLY: 'rgb(159,48,255)',
  SNAPCHAT: 'rgb(255,252,0)',
  VISTAPAGE: 'rgb(0,46,105)',
  THREADS: 'rgb(0, 0, 0)',
  BLUESKY: 'rgb(16, 131, 254)',
}

export const {
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_TEXT,
  MAX_VIDEO_SIZE,
  MAX_VIDEO_SIZE_TEXT,
  MAX_AUDIO_SIZE,
  MAX_AUDIO_SIZE_TEXT,
  MAX_DOCUMENT_SIZE,
  MAX_DOCUMENT_SIZE_TEXT,
} = {
  MAX_IMAGE_SIZE: 20480000,
  MAX_IMAGE_SIZE_TEXT: '20MB',
  MAX_VIDEO_SIZE: 2097152000,
  MAX_VIDEO_SIZE_TEXT: '2GB',
  MAX_AUDIO_SIZE: 2097152000,
  MAX_AUDIO_SIZE_TEXT: '2GB',
  MAX_DOCUMENT_SIZE: 102400000,
  MAX_DOCUMENT_SIZE_TEXT: '100MB',
}

export const { MEDIA_TYPE_USED, MEDIA_TYPE_UNUSED } = {
  MEDIA_TYPE_USED: 'used_media',
  MEDIA_TYPE_UNUSED: 'unused_media',
}

export const { AI_TYPE_POST, AI_TYPE_REPLY, AI_TYPE_COMMENT } = {
  AI_TYPE_POST: 'caption',
  AI_TYPE_REPLY: 'reply',
  AI_TYPE_COMMENT: 'comment',
}

export const { SNIPPET_TYPE_CAPTION, SNIPPET_TYPE_REPLY, SNIPPET_TYPE_TAG, SNIPPET_TYPE_COMMENT } = {
  SNIPPET_TYPE_CAPTION: 'caption',
  SNIPPET_TYPE_REPLY: 'reply',
  SNIPPET_TYPE_TAG: 'tags',
  SNIPPET_TYPE_COMMENT: 'comment',
}

export const {
  PROFILE_TYPE_PROFILE,
  PROFILE_TYPE_PAGE,
  PROFILE_TYPE_GROUP,
  PROFILE_TYPE_BUSINESS,
  PROFILE_TYPE_CREATOR,
  PROFILE_TYPE_TWITTER_FREE,
  PROFILE_TYPE_TWITTER_PAID,
} = {
  PROFILE_TYPE_PROFILE: 'PROFILE',
  PROFILE_TYPE_PAGE: 'PAGE',
  PROFILE_TYPE_GROUP: 'GROUP',
  PROFILE_TYPE_BUSINESS: 'BUSINESS',
  PROFILE_TYPE_CREATOR: 'CREATOR',
  PROFILE_TYPE_TWITTER_FREE: 'FREE',
  PROFILE_TYPE_TWITTER_PAID: 'PAID',
}

export const { ROUTE_COMMENTS } = {
  ROUTE_COMMENTS: 'comment',
}

export const { COMMENT_TYPE_PUBLICATION, COMMENT_TYPE_IDEA, COMMENT_TYPE_MESSAGE, COMMENT_TYPE_NOTE } = {
  COMMENT_TYPE_PUBLICATION: 'PUBLICATION',
  COMMENT_TYPE_IDEA: 'IDEA',
  COMMENT_TYPE_MESSAGE: 'MESSAGE',
  COMMENT_TYPE_NOTE: 'NOTE',
}

export const FACEBOOK_INSTAGRAM_PROFILE_CONNECT_SCOPES =
  'email,pages_show_list,instagram_basic,instagram_content_publish,instagram_manage_insights,instagram_manage_comments,instagram_manage_messages,pages_manage_posts,pages_read_engagement,pages_messaging,read_insights,pages_manage_metadata,pages_read_user_content,pages_manage_engagement,catalog_management,instagram_shopping_tag_products,pages_manage_ads,ads_management,business_management,ads_read'

export const DEFAULT_SEARCH_PROMPT_TEXT = 'Start typing to search'

export const { SENTIMENT_TYPE_POSITIVE, SENTIMENT_TYPE_NEGATIVE, SENTIMENT_TYPE_NEUTRAL, SENTIMENT_TYPE_MIXED } = {
  SENTIMENT_TYPE_POSITIVE: 'positive',
  SENTIMENT_TYPE_NEGATIVE: 'negative',
  SENTIMENT_TYPE_NEUTRAL: 'neutral',
  SENTIMENT_TYPE_MIXED: 'mixed',
}

export const SENTIMENT_TYPE_OPTIONS = [
  { value: SENTIMENT_TYPE_POSITIVE, label: 'Positive' },
  { value: SENTIMENT_TYPE_NEGATIVE, label: 'Negative' },
  { value: SENTIMENT_TYPE_NEUTRAL, label: 'Neutral' },
  { value: SENTIMENT_TYPE_MIXED, label: 'Mixed' },
]

export const SENTIMENT_ICONS_TEMPLATE = {
  [SENTIMENT_TYPE_POSITIVE]: { image: '/assets/emoji_positive.svg', description: 'Positive' },
  [SENTIMENT_TYPE_NEGATIVE]: { image: '/assets/emoji_negative.svg', description: 'Negative' },
  [SENTIMENT_TYPE_NEUTRAL]: { image: '/assets/emoji_neutral.svg', description: 'Neutral' },
  [SENTIMENT_TYPE_MIXED]: { image: '/assets/emoji_mixed.svg', description: 'Mixed' },
}

import {
  FACEBOOK,
  GOOGLE,
  INSTAGRAM,
  LINKEDIN,
  REDDIT,
  TWITTER,
  VIMEO,
  YOUTUBE,
  PINTEREST,
  YELP,
  TRIPADVISOR,
  OPENTABLE,
  TRUSTPILOT,
  TIKTOK,
  TIKTOKADS,
  BITLY,
  SOCIAL_NETWORKS_COLORS,
  SNAPCHAT,
  VISTAPAGE,
  GOOGLE_ANALYTICS,
  TUMBLR,
  THREADS,
  BLUESKY,
} from 'consts'
import { COLOR_CONSTANTS } from 'theme'

// TODO:: VIMEO has been removed
export const TABS = [
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
  GOOGLE,
  REDDIT,
  PINTEREST,
  TIKTOK,
  SNAPCHAT,
  VISTAPAGE,
  TUMBLR,
  THREADS,
  BLUESKY,
]

export const BLOCKED_PUBLISHING_SOCIAL_PROFILES = [
  OPENTABLE,
  TRUSTPILOT,
  TRIPADVISOR,
  YELP,
  BITLY,
  GOOGLE_ANALYTICS,
  TIKTOKADS,
]

export const URL_REGEXP = new RegExp(
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
) // fragment locator

export const MENTION_GROUP_NAME_REGEX = /[^0-9_A-Za-z]/g

export const MAX_ATTACHMENTS_ALLOWED = 50
export const MAX_IMAGES_ATTACHMENTS_ALLOWED = 50
export const MAX_VIDEOS_ATTACHMENTS_ALLOWED = 10
export const MAX_DOCUMENTS_ATTACHMENTS_ALLOWED = 10
export const MAX_MIXED_MEDIAS_ATTACHMENTS_ALLOWED = 50

export const IMAGE_EXTENSIONS = [
  '.bmp',
  '.gif',
  '.iff',
  '.jfif',
  '.jpeg',
  '.jpg',
  '.png',
  '.pjpeg',
  '.pjp',
  '.tif',
  '.tiff',
  '.webp',
  '.heic',
  '.svg',
]

export const VIDEO_EXTENSIONS = [
  //  '.3g2',
  '.3gp',
  '.3gpp',
  '.asf',
  '.avi',
  // '.dat',
  // '.divx',
  //  '.dv',
  '.flv',
  '.gif',
  //  '.m2ts',
  '.m4v',
  '.mkv',
  // '.mod',
  '.mov',
  '.mp4',
  '.mpe',
  '.mpeg',
  '.mpg',
  // '.mts',
  // '.nsv',
  // '.ogm',
  // '.ogv',
  //  '.qt',
  // '.tod',
  // '.ts',
  // '.vob',
  '.webm',
  '.wmv',
]

export const AUDIO_EXTENSIONS = [
  '.aac',
  '.mp3',
  '.wav',
  '.wave',
  '.weba',
  '.ogg',
  '.flac',
  '.wma',
  '.aiff',
  '.aif',
  '.m4a',
]

export const DOCUMENT_EXTENSIONS = ['.docx', '.doc', '.pptx', '.ppt', '.pdf']

export const IMAGE_MIME_TYPES = {
  bmp: ['bmp'],
  gif: ['gif'],
  'x-iff': ['iff'],
  jpeg: ['jpeg', 'jfif', 'jpg', 'pjpeg', 'pjp'],
  png: ['png'],
  tiff: ['tif', 'tiff'],
  webp: ['webp'],
  heic: ['heic'],
}

export const AUDIO_MIME_TYPES = {
  aac: ['aac'],
  mpeg: ['mp3'],
  'x-wav': ['wav'],
  wav: ['wav'],
  wave: ['wav'],
  webm: ['weba'],
  ogg: ['ogg'],
  'x-flac': ['flac'],
  'x-ms-wma': ['wma'],
  'x-aiff': ['aiff', 'aif'],
  m4a: ['m4a'],
}

export const VIDEO_MIME_TYPES = {
  '3gpp': ['3gp', '3gpp'],
  'x-ms-as': ['asf'],
  'x-msvideo': ['avi'],
  'x-flv': ['flv'],
  gif: ['gif'],
  'x-m4v': ['m4v'],
  'x-matroska': ['mkv'],
  quicktime: ['mov'],
  mp4: ['mp4'],
  mpeg: ['mpeg', 'mpe', 'mpg'],
  webm: ['webm'],
  'x-ms-wmv': ['wmv'],
}

export const DOCUMENT_MIME_TYPES = {
  msword: ['doc'],
  'vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
  'vnd.ms-powerpoint': ['ppt'],
  'vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
  pdf: ['pdf'],
}

export const NETWORK_LIMITS = [
  {
    code: FACEBOOK,
    postTextMaxLimit: 5000,
    commentTextMaxLimit: 8000,
    maxImages: 35,
    maxImageSize: 10000000,
    maxImageSizeString: '10 MB',
    maxImagesInComments: 1,
    maxVideos: 1,
    videoExtensions: ['.mov', '.mp4'],
    maxVideoSize: 4000000000,
    maxVideoSizeString: '4 GB',
    maxVideoDuration: 14400,
    minVideoDuration: 1,
    // minImageAspectRatio: '2:3',
    // maxImageAspectRatio: '16:9',
    // minVideoAspectRatio: '2:3',
    // maxVideoAspectRatio: '16:9',
    slideshowMinImages: 3,
    slideshowMaxImages: 35,
    carouselMaxImages: 10,
    hasMobileNotificationOption: true,
  },
  {
    code: INSTAGRAM,
    postTextMaxLimit: 2200,
    commentTextMaxLimit: 2200,
    maxImages: 10,
    maxImageSize: 8000000,
    maxImageSizeString: '8 MB',
    maxImagesInComments: 1,
    minMixedMediasInCarousel: 2,
    maxVideos: 10,
    maxHashTags: 30,
    // https://developers.facebook.com/docs/instagram-api/reference/ig-user/media/
    // instead 4:5 - valid range 9:16
    minImageAspectRatio: '9:16',
    maxImageAspectRatio: '1.91:1',
    minImageWidth: 320,
    minVideoAspectRatio: '4:5', // this is for video_processing on and off the same
    maxVideoAspectRatio: '16:9', // this is for video_processing on and off the same
    // minReelAspectRatio: '9:16', // removed from code everywhere
    // maxReelAspectRatio: '9:16', // removed from code everywhere
    // minStoryAspectRatio: '9:16', // story validation uses reel aspect ratio. This parameter is not required
    // maxStoryAspectRatio: '9:16', // story validation uses reel aspect ratio. This parameter is not required
    minCarouselVideoAspectRatio: '9:16',
    maxCarouselVideoAspectRatio: '16:9',
    minReelAspectRatioVideoProcessingOff: '0.01:1', // story validation uses reel aspect ratio. This parameter is not required
    maxReelAspectRatioVideoProcessingOff: '10:1', // story validation uses reel aspect ratio. This parameter is not required
    // minStoryAspectRatioVideoProcessingOff: '0.01:1', // story validation uses reel aspect ratio. This parameter is not required
    // maxStoryAspectRatioVideoProcessingOff: '10:1', // story validation uses reel aspect ratio. This parameter is not required
    maxVideoSize: 4000000000,
    maxVideoSizeString: '4 GB',
    videoExtensions: ['.mov', '.mp4'],
    // maxVideoDuration: 600,
    // minVideoDuration: 3,
    hasMobileNotificationOption: true,
    maxOriginalAudioTitleLength: 100,
  },
  {
    code: LINKEDIN,
    postTextMaxLimit: 3000,
    commentTextMaxLimit: 1750,
    maxImages: 20,
    maxImageSize: 3000000,
    maxImageSizeString: '3 MB',
    maxImagesInComments: 1,
    maxVideos: 1,
    maxVideoSize: 5000000000,
    maxVideoSizeString: '5 GB',
    videoExtensions: ['.asf', '.avi', '.flv', '.mov', '.mpeg', '.mpe', '.mkv', '.mp4', '.webm'],
    maxVideoDuration: 600,
    minVideoDuration: 3,
    // Vitaly removed image validation because after testing it seems that linkedin allows images outside of these demensions just fine 9/7/22
    // Gennady removed comments, we received error Images must be at least 640 x 360 and no larger than 7680 x 4320 pixels,  23/08/2022
    minImageWidth: 640,
    minImageHeight: 360,
    maxImageWidth: 7680,
    maxImageHeight: 4320,
    hasMobileNotificationOption: true,
    maxDocuments: 1,
    maxDocumentSize: 100000000,
    maxDocumentSizeString: '100 MB',
    documentExtensions: ['.doc', '.docx', '.ppt', '.pptx', '.pdf'],
  },
  {
    code: TWITTER,
    postTextMaxLimit: 280,
    postTextMaxLimitPaid: 25000,
    commentTextMaxLimit: 280,
    commentTextMaxLimitPaid: 25000,
    maxImages: 4,
    maxImageSize: 5000000,
    maxImageSizeString: '5 MB',
    maxImagesInComments: 1,
    maxVideos: 1,
    maxVideoSize: 500000000,
    maxVideoSizeString: '512 MB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 140,
    minVideoDuration: 1,
    hasMobileNotificationOption: true,
  },
  {
    code: YOUTUBE,
    postTextMaxLimit: 5000,
    commentTextMaxLimit: 10000,
    videoTitleLimit: 100,
    maxImages: 0,
    maxVideos: 1,
    maxVideoSize: 100000000000,
    maxVideoSizeString: '128 GB',
    videoExtensions: ['.mov', '.mpeg', '.mpe', '.mp4', '.avi', '.wmv', '.flv', '.3gpp', '.webm'],
    maxVideoDuration: 43200,
    minVideoDuration: 1,
    maxTagsLength: 500,
    hasMobileNotificationOption: false,
  },
  {
    code: GOOGLE,
    postTextMaxLimit: 1500,
    maxImages: 1,
    maxImageSize: 10000000,
    maxImageSizeString: '10 MB',
    maxVideos: 0,
    eventTitleMaxLimit: 58,
    hasMobileNotificationOption: false,
  },
  {
    code: REDDIT,
    postTextMaxLimit: 40000,
    titleLimit: 300,
    maxImages: 1,
    maxImageSize: 10000000,
    maxImageSizeString: '10 MB',
    maxVideos: 1,
    maxVideoSize: 1000000000,
    maxVideoSizeString: '1 GB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 900,
    minVideoDuration: 1,
    flairTextMaxLimit: 64,
    hasMobileNotificationOption: true,
  },
  {
    code: VIMEO,
    postTextMaxLimit: 5000,
    videoTitleLimit: 128,
    commentTextMaxLimit: 5000,
    maxVideos: 1,
    maxImages: 0,
    maxVideoDuration: 43200,
    minVideoDuration: 1,
    hasMobileNotificationOption: false,
    disabled: true,
  },
  {
    code: PINTEREST,
    postTextMaxLimit: 500,
    titleLimit: 100,
    commentTextMaxLimit: 500,
    maxImages: 1,
    maxImageSize: 20000000,
    maxImageSizeString: '20 MB',
    maxVideos: 1,
    maxVideoSize: 2000000000,
    maxVideoSizeString: '2 GB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 900,
    minVideoDuration: 4,
    hasMobileNotificationOption: true,
  },
  {
    code: TIKTOK,
    postTextMaxLimit: 2000,
    commentTextMaxLimit: 150,
    minImages: 1,
    maxImages: 35,
    maxImageSize: 10000000,
    maxImageSizeString: '10 MB',
    maxImageWidth: 1080,
    maxImageHeight: 1920,
    imageExtensions: ['.jpg', '.jpeg', '.webp'],
    maxVideos: 1,
    maxVideoSize: 1000000000,
    maxVideoSizeString: '1 GB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 600,
    minVideoDuration: 3,
    imagesTitleLimit: 150,
    hasMobileNotificationOption: true,
  },
  {
    code: SNAPCHAT,
    postTextMaxLimit: 80,
    maxVideos: 1,
    minImages: 1,
    maxImages: 1,
    minImageWidth: 1080,
    minImageHeight: 1920,
    imageExtensions: ['.jpg', '.jpeg', '.png'],
    maxVideoSize: 1000000000,
    maxVideoSizeString: '1 GB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 180,
    minVideoDuration: 4,
    spotlightDescriptionTextMaxLimit: 160,
    savedStoryTitleTextMaxLimit: 40,
    hasMobileNotificationOption: false,
  },
  {
    code: VISTAPAGE,
    postTextMaxLimit: 1500,
    maxImages: 1,
    maxImageSize: 10000000,
    maxImageSizeString: '10 MB',
    maxVideos: 0,
    hasMobileNotificationOption: false,
  },
  {
    code: TUMBLR,
    postTextMaxLimit: 2000,
    titleMaxLimit: 100,
    slugMaxLimit: 20,
    maxVideos: 1,
    maxImageSize: 10000000,
    maxImageSizeString: '10 MB',
    maxImages: 10,
    maxVideoSize: 500000000,
    maxVideoSizeString: '500 MB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 6000,
    minVideoDuration: 10,
    maxTagsLimit: 30,
    hasMobileNotificationOption: false,
  },
  {
    code: THREADS,
    postTextMaxLimit: 500,
    commentTextMaxLimit: 500,
    maxImages: 20,
    maxImageSize: 8000000,
    maxImageSizeString: '8 MB',
    minMixedMediasInCarousel: 2,
    maxVideos: 10,
    minImageAspectRatio: '0.01:1',
    maxImageAspectRatio: '10:1',
    minVideoAspectRatio: '0.01:1', // this is for video_processing on and off the same
    maxVideoAspectRatio: '10:1', // this is for video_processing on and off the same
    maxVideoSize: 1000000000,
    maxVideoSizeString: '1 GB',
    videoExtensions: ['.mov', '.mp4'],
    maxVideoDuration: 300,
    minVideoDuration: 0,
    maxTags: 1, // this is not a hashtag, it's a tag that allows to search inside threads (a hashtag without a hash)
    hasMobileNotificationOption: false,
    maxImagesInComments: 10,
    maxVideosInComments: 10,
    maxMixedMediasInComments: 10,
  },
  {
    code: BLUESKY,
    postTextMaxLimit: 300,
    commentTextMaxLimit: 300,
    maxImages: 4,
    maxImageSize: 976000,
    maxImageWidth: 2000,
    maxImageHeight: 2000,
    maxImageSizeString: '976 KB',
    minMixedMediasInCarousel: 2,
    maxVideos: 1,
    maxVideoSize: 10000000000, // https://bsky.social/about/blog/09-11-2024-video
    maxVideoSizeString: '10 GB',
    videoExtensions: ['.mov', '.mp4', '.mpeg', '.webm', '.gif'], // https://github.com/bluesky-social/social-app/blob/main/src/lib/constants.ts#L153
    maxVideoDuration: 60,
    minVideoDuration: 0.1,
    // minVideoAspectRatio: '0.01:1',
    // maxVideoAspectRatio: '10:1',
    minImageAspectRatio: '0.01:1',
    maxImageAspectRatio: '10:1',
    hasMobileNotificationOption: false,
    maxVideosInComments: 0,
    maxMixedMediasInComments: 4,
  },
]

export const {
  POST_IMAGE,
  POST_EMOJI,
  POST_VIDEO,
  POST_HASHTAG,
  POST_LINK,
  POST_TEXT,
  POST_AT,
  POST_TEXT_EDITOR,
  POST_CAROUSEL,
  POST_REEL,
  POST_SLIDESHOW,
  POST_GIF,
  POST_MULTIPLE_IMAGES,
  POST_SNIPPET,
  POST_VARIABLE,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
} = {
  POST_IMAGE: 'post_image',
  POST_EMOJI: 'post_emoji',
  POST_VIDEO: 'post_video',
  POST_HASHTAG: 'post_hashtag',
  POST_LINK: 'post_link',
  POST_TEXT: 'post_text',
  POST_AT: 'post_at',
  POST_TEXT_EDITOR: 'post_text_editor',
  POST_CAROUSEL: 'post_carousel',
  POST_REEL: 'post_reel',
  POST_SLIDESHOW: 'post_slideshow',
  POST_GIF: 'post_gif',
  POST_MULTIPLE_IMAGES: 'post_multiple_images',
  POST_SNIPPET: 'post_snippet',
  POST_VARIABLE: 'post_variable',
  POST_DOCUMENT: 'post_document',
  POST_MIXED_MEDIA: 'post_mixed_media',
}

export const { QUEUE_NEXT, QUEUE_LAST, PER_CSV, NOW, IDEA } = {
  QUEUE_NEXT: 'queue_next',
  QUEUE_LAST: 'queue_last',
  PER_CSV: 'per_csv',
  NOW: 'now',
  IDEA: 'idea',
}

export const DATE_FORMAT = 'YYYY-MM-DD'

export const LOCAL_STORAGE_VISTA_SOCIAL = 'VistaSocial'
export const LOCAL_STORAGE_SELECTED_PROFILES_KEY = 'VistaSocialSelectedProfiles'
export const LOCAL_STORAGE_SELECTED_ENTITIES_KEY = 'VistaSocialSelectedEntities'
export const LOCAL_STORAGE_PUBLISH_POST_MODAL = 'VistaSocialPublishPostModal'
export const LOCAL_STORAGE_EDIT_POST_MODAL = 'VistaSocialEditPostModal'
export const LOCAL_STORAGE_SMART_POST_MODAL = 'VistaSocialSmartPostModal'
export const LOCAL_STORAGE_BULK_POST_MODAL = 'VistaSocialBulkPostModal'
export const LOCAL_STORAGE_SELECTED_MEDIA_FILTERS = 'VistaSocialSelectedMediaFilters'
export const LOCAL_STORAGE_SHARED_CALENDAR_USER_DATA = 'VistaSocialSharedCalendarUserData'
export const LOCAL_STORAGE_IDEA_MODAL = 'VistaSocialIdeaModal'
export const LOCAL_STORAGE_TWITTER_THREADS_MAKER_MODAL = 'VistaSocialTwitterThreadsMakerModal'

export const LOCAL_STORAGE_TIME_INTERVAL = 2000

export const {
  ROUTE_ENTITIES,
  ROUTE_CALENDAR,
  ROUTE_PUBLISH,
  ROUTE_MEDIA,
  ROUTE_URL,
  ROUTE_YOUTUBE_CATEGORIES,
  ROUTE_YOUTUBE_PLAYLISTS,
  ROUTE_PINTEREST_BOARDS,
  ROUTE_PINTEREST_SECTIONS,
  ROUTE_INSTAGRAM_MEDIAS,
  ROUTE_FACEBOOK_GROUP_OPTIONS,
  ROUTE_TIKTOK_MEDIAS,
  ROUTE_REDDIT_FLAIRS,
  ROUTE_QUEUE,
  ROUTE_LABELS,
  ROUTE_BULK_RSS,
  ROUTE_BULK_CSV,
  ROUTE_BULK_PROCESS,
  ROUTE_MENTIONS,
  ROUTE_TRACKING,
  ROUTE_CONTENT,
  ROUTE_APPROVERS,
  ROUTE_PENDING_APPROVAL,
  ROUTE_SEARCH_ACCOUNTS,
  ROUTE_SMART,
  ROUTE_DEVICES,
  ROUTE_PUBLISHING_OPTIMAL,
  ROUTE_SAVED_SEARCHES,
  ROUTE_POST_COMMENTS,
  ROUTE_WORKFLOW,
  ROUTE_SNIPPETS,
  ROUTE_AI,
  ROUTE_VARIABLE,
  ROUTE_BOOST_CONFIGURATION,
} = {
  ROUTE_ENTITIES: 'entities',
  ROUTE_CALENDAR: 'publishing/calendar',
  ROUTE_PUBLISH: 'publishing',
  ROUTE_MEDIA: 'publishing/media',
  ROUTE_URL: 'publishing/url',
  ROUTE_YOUTUBE_CATEGORIES: 'youtube/categories',
  ROUTE_YOUTUBE_PLAYLISTS: 'youtube/playlists',
  ROUTE_PINTEREST_BOARDS: 'pinterest/boards',
  ROUTE_PINTEREST_SECTIONS: 'pinterest/sections',
  ROUTE_INSTAGRAM_MEDIAS: 'instagram/medias',
  ROUTE_FACEBOOK_GROUP_OPTIONS: 'facebook/groupOptions',
  ROUTE_TIKTOK_MEDIAS: 'tiktok/medias',
  ROUTE_REDDIT_FLAIRS: 'reddit/flair',
  ROUTE_QUEUE: 'publishing/queue',
  ROUTE_LABELS: 'label',
  ROUTE_BULK_RSS: 'publishing/bulk/rss',
  ROUTE_BULK_CSV: 'publishing/bulk/csv',
  ROUTE_BULK_PROCESS: 'publishing/bulk/process',
  ROUTE_MENTIONS: 'publishing/mention',
  ROUTE_TRACKING: 'publishing/tracking',
  ROUTE_CONTENT: 'publishing/content',
  ROUTE_APPROVERS: 'publishing/approvers',
  ROUTE_PENDING_APPROVAL: 'publishing/pending_approval',
  ROUTE_SEARCH_ACCOUNTS: 'publishing/search',
  ROUTE_SMART: 'publishing/smart',
  ROUTE_DEVICES: 'publishing/devices',
  ROUTE_PUBLISHING_OPTIMAL: 'publishing/optimal',
  ROUTE_SAVED_SEARCHES: 'publishing/search/saved',
  ROUTE_POST_COMMENTS: 'publishing/comment',
  ROUTE_WORKFLOW: 'publishing/workflow',
  ROUTE_SNIPPETS: 'snippet',
  ROUTE_AI: 'ai',
  ROUTE_VARIABLE: 'variable',
  ROUTE_BOOST_CONFIGURATION: 'boost',
}

export const {
  APPROVED,
  DRAFT,
  FAILED,
  REVIEW,
  PUBLISHED,
  REJECTED,
  DELETED,
  NONE,
  REMINDER_SENT,
  REMINDER_REJECTED,
  REMINDER_PUBLISHED,
  PROCESSING,
  SPECIAL_UI_STATUS_PUBLISHING,
  SPECIAL_UI_STATUS_REVIEW_REJECTED,
  PLANNED,
  ADVOCACY,
  ADVOCACY_DRAFT,
  ADVOCACY_INACTIVE,
  ADVOCACY_SCHEDULED,
} = {
  APPROVED: 'APPROVED',
  DRAFT: 'DRAFT',
  FAILED: 'FAILED',
  REVIEW: 'REVIEW',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  DELETED: 'DELETED',
  NONE: 'NONE',
  REMINDER_SENT: 'REMINDER_SENT',
  REMINDER_REJECTED: 'REMINDER_REJECTED',
  REMINDER_PUBLISHED: 'REMINDER_PUBLISHED',
  PROCESSING: 'PROCESSING',
  SPECIAL_UI_STATUS_REVIEW_REJECTED: 'SPECIAL_UI_STATUS_REVIEW_REJECTED',
  SPECIAL_UI_STATUS_PUBLISHING: 'SPECIAL_UI_STATUS_PUBLISHING',
  PLANNED: 'PLANNED',
  ADVOCACY: 'ADVOCACY',
  ADVOCACY_DRAFT: 'ADVOCACY_DRAFT',
  ADVOCACY_INACTIVE: 'ADVOCACY_INACTIVE',
  ADVOCACY_SCHEDULED: 'ADVOCACY_SCHEDULED',
}

export const MENTIONS_NETWORKS = [
  { code: FACEBOOK, input_type: 'dropdown', active: true },
  { code: INSTAGRAM, input_type: 'dropdown', active: true },
  { code: LINKEDIN, input_type: 'dropdown', active: true },
  { code: TWITTER, input_type: 'dropdown', active: true },
  { code: BLUESKY, input_type: 'dropdown', active: true },
  { code: TIKTOK, input_type: 'text', active: true },
  // { code: REDDIT, input_type: 'dropdown', active: true }, // not supported, has a copy in src/routes/Calendar/components/MentionSelector/MentionsModal.js
  // { code: PINTEREST, input_type: 'text', active: true }, // not supported, has a copy in src/routes/Calendar/components/MentionSelector/MentionsModal.js
  { code: SNAPCHAT, input_type: 'text', active: true },
  // { code: TUMBLR, input_type: 'dropdown', active: true }, // not supported, has a copy in src/routes/Calendar/components/MentionSelector/MentionsModal.js
  { code: YOUTUBE, input_type: 'text', active: true },
  { code: THREADS, input_type: 'text', active: true },
]

export const STATUS_COLORS = {
  [DRAFT]: '#BDBDBD',
  [FAILED]: '#DA615C',
  [REVIEW]: '#AE70D1',
  [PUBLISHED]: '#55AB68',
  [REJECTED]: '#FF9533',
  [APPROVED]: '#64A7DB',
  [PROCESSING]: '#64A7DB', // same color as APPROVED
  [DELETED]: '#333333',
  [NONE]: '#FFFFFF',
  [REMINDER_REJECTED]: '#DA615C', // same color as FAILED
  [REMINDER_SENT]: '#DADD73',
  [REMINDER_PUBLISHED]: '#55AB68', // same color as PUBLISHED
  [PLANNED]: '#6682A5',
  [ADVOCACY]: '#55AB68', // same color as PUBLISHED
  [ADVOCACY_DRAFT]: '#BDBDBD', // same color as DRAFT
  [ADVOCACY_INACTIVE]: '#333333', // same color as DELETED
  [ADVOCACY_SCHEDULED]: '#64A7DB', // same color as APPROVED
}
export const {
  BULK_POST_TYPE_ARTICLE,
  BULK_POST_TYPE_MESSAGE,
  BULK_POST_TYPE_IMAGE,
  BULK_POST_TYPE_VIDEO,
  BULK_POST_TYPE_DOCUMENT,
  BULK_POST_TYPE_MIXED_MEDIA,
} = {
  BULK_POST_TYPE_ARTICLE: 'article',
  BULK_POST_TYPE_MESSAGE: 'message',
  BULK_POST_TYPE_IMAGE: 'image',
  BULK_POST_TYPE_VIDEO: 'video',
  BULK_POST_TYPE_DOCUMENT: 'document',
  BULK_POST_TYPE_MIXED_MEDIA: 'mixed_media',
}

export const { BOOST_STATUS_BOOSTED, BOOST_STATUS_NOT_BOOSTED } = {
  BOOST_STATUS_BOOSTED: 'boosted',
  BOOST_STATUS_NOT_BOOSTED: 'not_boosted',
}

export const { ADVOCACY_STATUS_ADVOCACY, ADVOCACY_STATUS_NOT_ADVOCACY } = {
  ADVOCACY_STATUS_ADVOCACY: 'advocacy',
  ADVOCACY_STATUS_NOT_ADVOCACY: 'not_advocacy',
}

export const DELETE_POST_NETWORK_LIMITATIONS = [INSTAGRAM, TIKTOK]

export const STATUS_FILTERS = [
  { id: DRAFT, name: 'Draft' },
  { id: FAILED, name: 'Failed' },
  { id: REVIEW, name: 'In review' },
  { id: PUBLISHED, name: 'Published' },
  { id: REJECTED, name: 'Rejected' },
  { id: APPROVED, name: 'Scheduled' },
  { id: REMINDER_SENT, name: 'Reminder sent' },
  { id: REMINDER_REJECTED, name: 'Reminder rejected' },
  { id: REMINDER_PUBLISHED, name: 'Reminder published' },
  { id: PROCESSING, name: 'Processing' },
]

export const POST_CONTENT_TYPE_FILTERS = [
  { id: POST_TEXT, name: 'Text' },
  { id: POST_LINK, name: 'Link' },
  { id: POST_IMAGE, name: 'Image' },
  { id: POST_VIDEO, name: 'Video' },
  { id: POST_DOCUMENT, name: 'Document' },
  { id: POST_MIXED_MEDIA, name: 'Mixed media' },
]

export const BOOST_STATUS_FILTERS = [
  { id: BOOST_STATUS_BOOSTED, name: 'Boosted' },
  { id: BOOST_STATUS_NOT_BOOSTED, name: 'Not boosted' },
]

export const ADVOCACY_STATUS_FILTERS = [
  { id: ADVOCACY_STATUS_ADVOCACY, name: 'Yes' },
  { id: ADVOCACY_STATUS_NOT_ADVOCACY, name: 'No' },
]

export const CUSTOM_TYPE = 'CUSTOM'

export const DEFAULT_UTM_PARAMS = [
  { name: 'utm_campaign', type: CUSTOM_TYPE, value: '' },
  { name: 'utm_medium', type: CUSTOM_TYPE, value: '' },
  { name: 'utm_source', type: CUSTOM_TYPE, value: '' },
]

export const {
  FACEBOOK_COLORS,
  GOOGLE_COLORS,
  INSTAGRAM_COLORS,
  LINKEDIN_COLORS,
  REDDIT_COLORS,
  TWITTER_COLORS,
  VIMEO_COLORS,
  YOUTUBE_COLORS,
  PINTEREST_COLORS,
  YELP_COLORS,
  OPENTABLE_COLORS,
  TRUSTPILOT_COLORS,
  TRIPADVISOR_COLORS,
  TIKTOK_COLORS,
  SNAPCHAT_COLORS,
  VISTAPAGE_COLORS,
  GOOGLEANALYTICS_COLORS,
  TUMBLR_COLORS,
  THREADS_COLORS,
  BLUESKY_COLORS,
} = {
  FACEBOOK_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(24,119,242)', // link
    TARNISHED_SILVER: 'rgb(101,103,107)', // timestamp font
    GLUON_GREY: 'rgb(5,5,5)', // body font
    LIGHT_GREY: 'rgb(240,242,245)', // link background
    MAIN: SOCIAL_NETWORKS_COLORS.FACEBOOK, // could be not used, but let it stay
    SECONDARY: 'rgb(123, 100, 192)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.FACEBOOK, // main color for charts
    CHART_SECONDARY: 'rgb(123, 100, 192)', // secondary color for charts
    network: FACEBOOK,
  },
  GOOGLE_COLORS: {
    TARNISHED_SILVER: 'rgb(112,117,122)', // timestamp font
    GLUON_GREY: 'rgb(32,33,36)', // body font
    LAST_LIGHT_BLUE: 'rgb(20,25,164)', // link
    MAIN: SOCIAL_NETWORKS_COLORS.GOOGLE, // could be not used, but let it stay
    SECONDARY: 'rgb(15,157,88)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.GOOGLE, // main color for charts
    CHART_SECONDARY: 'rgb(15,157,88)', // secondary color for charts
    network: GOOGLE,
  },
  INSTAGRAM_COLORS: {
    LAST_LIGHT_BLUE: 'rgba(0,55,107,1)', // link
    TARNISHED_SILVER: 'rgba(168,168,168,1)', // timestamp font
    GLUON_GREY: 'rgba(38,38,38,1)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.INSTAGRAM, // could be not used, but let it stay
    SECONDARY: 'rgb(247,119,55)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.INSTAGRAM, // main color for charts
    CHART_SECONDARY: 'rgb(247,119,55)', // secondary color for charts
    BACKGROUND: 'rgb(250, 250, 250)',
    network: INSTAGRAM,
  },
  LINKEDIN_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(0,115,177)', // link
    TARNISHED_SILVER: 'rgb(101,103,107)', // timestamp font
    GLUON_GREY: 'rgba(0,0,0,.6)', // body font
    BLACK: 'rgba(0,0,0,.9)',
    MAIN: SOCIAL_NETWORKS_COLORS.LINKEDIN, // could be not used, but let it stay
    SECONDARY: 'rgb(0,68,113)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.LINKEDIN, // main color for charts
    CHART_SECONDARY: 'rgb(0,68,113)', // secondary color for charts
    network: LINKEDIN,
  },
  REDDIT_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(0,121,211)', // link
    TARNISHED_SILVER: 'rgb(34,34,34)', // title font
    GLUON_GREY: 'rgb(28,28,28)', // body font
    LIGHT_GRAY: 'rgb(135, 138, 140)',
    MAIN: SOCIAL_NETWORKS_COLORS.REDDIT, // could be not used, but let it stay
    SECONDARY: 'rgb(128, 0, 255)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.REDDIT, // main color for charts
    CHART_SECONDARY: 'rgb(128, 0, 255)', // secondary color for charts
    MENTION: 'rgb(191, 87, 0)',
    network: REDDIT,
  },
  TWITTER_COLORS: {
    BLACK: 'rgb(15, 20, 25)', // username
    LAST_LIGHT_BLUE: 'rgb(29, 155, 240)', // link
    TARNISHED_SILVER: 'rgb(83, 100, 113)', // timestamp font
    GLUON_GREY: 'rgb(28,30,32)', // body font
    HASHTAG: 'rgb(27, 149, 224)',
    MAIN: SOCIAL_NETWORKS_COLORS.TWITTER, // could be not used, but let it stay
    SECONDARY: 'rgb(101, 119, 134)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.TWITTER, // main color for charts
    CHART_SECONDARY: 'rgb(101, 119, 134)', // secondary color for charts
    PURPLE: 'rgb(249, 24, 128)',
    network: TWITTER,
  },
  VIMEO_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(26, 46, 59)', // link
    TARNISHED_SILVER: 'rgb(132, 152, 164)', // timestamp font
    GLUON_GREY: 'rgb(26,46,59)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.VIMEO, // could be not used, but let it stay
    SECONDARY: 'rgb(22, 34, 33)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.VIMEO, // main color for charts
    CHART_SECONDARY: 'rgb(22, 34, 33)', // secondary color for charts
    network: VIMEO,
  },
  YOUTUBE_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(69,93,145)', // link
    TARNISHED_SILVER: 'rgb(96,96,96)', // timestamp font
    GLUON_GREY: 'rgb(3,3,3)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.YOUTUBE, // could be not used, but let it stay
    SECONDARY: 'rgb(40,40,40)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.YOUTUBE, // main color for charts
    CHART_SECONDARY: 'rgb(40,40,40)', // secondary color for charts
    network: YOUTUBE,
  },
  PINTEREST_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(17,17,17)', // link
    TARNISHED_SILVER: 'rgb(118,118,118)', // timestamp font
    GLUON_GREY: 'rgb(17,17,17)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.PINTEREST, // could be not used, but let it stay
    SECONDARY: 'rgb(118,118,118)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.PINTEREST, // main color for charts
    CHART_SECONDARY: 'rgb(118,118,118)', // secondary color for charts
    MENTION: 'rgb(0, 116, 232)',
    network: PINTEREST,
  },
  YELP_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(0,131,143)', // link
    TARNISHED_SILVER: 'rgb(117,114,128)', // timestamp font
    GLUON_GREY: 'rgb(43,39,60)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.YELP, // could be not used, but let it stay
    SECONDARY: 'rgb(117,114,128)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.YELP, // main color for charts
    CHART_SECONDARY: 'rgb(117,114,128)', // secondary color for charts
    network: YELP,
  },
  TRIPADVISOR_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(51,51,51)', // link
    TARNISHED_SILVER: 'rgb(0,0,0)', // timestamp font
    GLUON_GREY: 'rgb(0,0,0)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.TRIPADVISOR, // could be not used, but let it stay
    SECONDARY: 'rgb(250,241,237)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.TRIPADVISOR, // main color for charts
    CHART_SECONDARY: 'rgb(250,241,237)', // secondary color for charts
    network: TRIPADVISOR,
  },
  OPENTABLE_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(218,55,67)', // link
    TARNISHED_SILVER: 'rgb(45,51,63)', // timestamp font
    GLUON_GREY: 'rgb(45,51,63)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.OPENTABLE, // could be not used, but let it stay
    SECONDARY: 'rgb(36,127,158)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.OPENTABLE, // main color for charts
    CHART_SECONDARY: 'rgb(36,127,158)', // secondary color for charts
    network: OPENTABLE,
  },
  TRUSTPILOT_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(27,27,33)', // link
    TARNISHED_SILVER: 'rgb(115,115,143)', // timestamp font
    GLUON_GREY: 'rgb(50,50,61)', // body font
    MAIN: SOCIAL_NETWORKS_COLORS.TRUSTPILOT, // could be not used, but let it stay
    SECONDARY: 'rgb(0,0,50)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.TRUSTPILOT, // main color for charts
    CHART_SECONDARY: 'rgb(0,0,50)', // secondary color for charts
    network: TRUSTPILOT,
  },
  TIKTOK_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(22, 24, 35)',
    TARNISHED_SILVER: 'rgba(22, 24, 35, 0.5)',
    GLUON_GREY: 'rgba(22, 24, 35, 0.75)',
    MAIN: SOCIAL_NETWORKS_COLORS.TIKTOK, // could be not used, but let it stay
    SECONDARY: 'rgb(254, 44, 85)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.TIKTOK, // main color for charts
    CHART_SECONDARY: 'rgb(254, 44, 85)', // secondary color for charts
    network: TIKTOK,
  },
  SNAPCHAT_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(15,173,255)',
    TARNISHED_SILVER: 'rgba(22, 24, 35, 0.5)',
    GLUON_GREY: 'rgba(0, 0, 0, 0)',
    MAIN: SOCIAL_NETWORKS_COLORS.SNAPCHAT, // this is used in snapchat preview
    SECONDARY: 'rgb(0, 0, 0)', // could be not used, but let it stay
    CHART_MAIN: 'rgb(189, 168, 0)', // main color for charts
    CHART_SECONDARY: SOCIAL_NETWORKS_COLORS.SNAPCHAT, // secondary color for charts
    network: SNAPCHAT,
  },
  VISTAPAGE_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(0, 99, 227)', // link color
    TARNISHED_SILVER: 'rgb(109, 124, 143)',
    GLUON_GREY: 'rgba(96, 97, 110)',
    MAIN: SOCIAL_NETWORKS_COLORS.VISTAPAGE, // this is used in vistapage preview
    SECONDARY: 'rgb(109, 124, 143)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.VISTAPAGE, // main color for charts
    CHART_SECONDARY: 'rgb(109, 124, 143)', // secondary color for charts
    network: VISTAPAGE,
  },
  GOOGLEANALYTICS_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(15,173,255)',
    TARNISHED_SILVER: 'rgba(22, 24, 35, 0.5)',
    GLUON_GREY: 'rgba(0, 0, 0, 0)',
    MAIN: SOCIAL_NETWORKS_COLORS.GOOGLE_ANALYTICS, // this is used in snapchat preview
    SECONDARY: 'rgb(0, 0, 0)', // could be not used, but let it stay
    CHART_MAIN: 'rgb(237, 117, 10)', // main color for charts
    CHART_SECONDARY: SOCIAL_NETWORKS_COLORS.GOOGLE_ANALYTICS, // secondary color for charts
    network: GOOGLE_ANALYTICS,
  },
  TUMBLR_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(0, 184, 255)', // link
    TARNISHED_SILVER: 'rgba(0, 0, 0, 0.65)', // title font
    GLUON_GREY: 'rgb(0, 0, 0)', // body font
    LIGHT_GRAY: 'rgb(135, 138, 140)',
    MAIN: SOCIAL_NETWORKS_COLORS.TUMBLR, // could be not used, but let it stay
    SECONDARY: 'rgb(217, 94, 64)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.TUMBLR, // main color for charts
    CHART_SECONDARY: 'rgb(217, 94, 64)', // secondary color for charts
    network: TUMBLR,
  },
  THREADS_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(0, 149, 246)', // link
    TARNISHED_SILVER: 'rgba(153, 153, 153)', // title font
    GLUON_GREY: 'rgb(0, 0, 0)', // body font
    LIGHT_GRAY: 'rgb(135, 138, 140)',
    MAIN: SOCIAL_NETWORKS_COLORS.THREADS, // could be not used, but let it stay
    SECONDARY: 'rgb(217, 94, 64)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.THREADS, // main color for charts
    CHART_SECONDARY: 'rgb(0, 149, 246)', // secondary color for charts
    network: THREADS,
  },
  BLUESKY_COLORS: {
    LAST_LIGHT_BLUE: 'rgb(16, 131, 254)', // link
    TARNISHED_SILVER: 'rgb(66, 87, 108)',
    GLUON_GREY: 'rgb(11, 15, 20)', // body font
    LIGHT_GRAY: 'rgb(212, 219, 226)',
    MAIN: SOCIAL_NETWORKS_COLORS.BLUESKY, // could be not used, but let it stay
    SECONDARY: 'rgb(66, 87, 108)', // could be not used, but let it stay
    CHART_MAIN: SOCIAL_NETWORKS_COLORS.BLUESKY, // main color for charts
    CHART_SECONDARY: 'rgb(66, 87, 108)', // secondary color for charts
    PURPLE: 'rgb(236, 72, 153)',
    network: BLUESKY,
  },
}

export const POST_NETWORK_COLORS = [
  FACEBOOK_COLORS,
  GOOGLE_COLORS,
  INSTAGRAM_COLORS,
  LINKEDIN_COLORS,
  REDDIT_COLORS,
  TWITTER_COLORS,
  VIMEO_COLORS,
  YOUTUBE_COLORS,
  PINTEREST_COLORS,
  YELP_COLORS,
  TRUSTPILOT_COLORS,
  OPENTABLE_COLORS,
  TRIPADVISOR_COLORS,
  TIKTOK_COLORS,
  SNAPCHAT_COLORS,
  VISTAPAGE_COLORS,
  GOOGLEANALYTICS_COLORS,
  TUMBLR_COLORS,
  THREADS_COLORS,
  BLUESKY_COLORS,
]

export const {
  CALENDAR_POST_PREVIEW_DETAILED_NO_PREVIEW,
  CALENDAR_POST_PREVIEW_DETAILED_SMALL_PREVIEW,
  CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW,
  CALENDAR_POST_PREVIEW_CONDENSED,
  CALENDAR_WEEK_DAY_SUNDAY,
  CALENDAR_WEEK_DAY_MONDAY,
} = {
  CALENDAR_POST_PREVIEW_DETAILED_NO_PREVIEW: 'DETAILED_NO_PREVIEW',
  CALENDAR_POST_PREVIEW_DETAILED_SMALL_PREVIEW: 'DETAILED_SMALL_PREVIEW',
  CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW: 'DETAILED_LARGE_PREVIEW',
  CALENDAR_POST_PREVIEW_CONDENSED: 'CONDENSED',
  CALENDAR_WEEK_DAY_SUNDAY: 'SUNDAY',
  CALENDAR_WEEK_DAY_MONDAY: 'MONDAY',
}

export const CALENDAR_POST_PREVIEWS = [
  { label: 'Without preview', value: CALENDAR_POST_PREVIEW_DETAILED_NO_PREVIEW },
  { label: 'Small preview', value: CALENDAR_POST_PREVIEW_DETAILED_SMALL_PREVIEW },
  { label: 'Large preview', value: CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW },
  { label: 'Condensed view', value: CALENDAR_POST_PREVIEW_CONDENSED },
]

export const CALENDAR_WEEK_START_DAYS = [
  { label: 'Sunday', value: CALENDAR_WEEK_DAY_SUNDAY },
  { label: 'Monday', value: CALENDAR_WEEK_DAY_MONDAY },
]

export const { VIEW_FEED, VIEW_GRID } = { VIEW_FEED: 'feed', VIEW_GRID: 'grid' }

export const VIEW_OPTIONS = [
  { value: VIEW_FEED, icon: '/assets/landscape.svg' },
  { value: VIEW_GRID, icon: '/assets/grid.svg' },
]

export const {
  IMPORT_FROM_CSV,
  IMPORT_FROM_RSS,
  IMPORT_FROM_SEARCH_CONTENT,
  IMPORT_FROM_MULTIPLE_MEDIA,
  IMPORT_FROM_INSTAGRAM_HASHTAG,
  IMPORT_FROM_INSTAGRAM_USER,
  IMPORT_FROM_FIND_IMAGES,
  IMPORT_FROM_FIND_GIFS,
  IMPORT_FROM_FIND_VIDEOS,
} = {
  IMPORT_FROM_CSV: 'import_from_csv',
  IMPORT_FROM_RSS: 'import_from_rss',
  IMPORT_FROM_SEARCH_CONTENT: 'import_from_search_content',
  IMPORT_FROM_MULTIPLE_MEDIA: 'import_from_multiple_media',
  IMPORT_FROM_INSTAGRAM_HASHTAG: 'import_from_instagram_hashtag',
  IMPORT_FROM_INSTAGRAM_USER: 'import_from_instagram_user',
  IMPORT_FROM_FIND_IMAGES: 'import_from_find_images',
  IMPORT_FROM_FIND_GIFS: 'import_from_find_gifs',
  IMPORT_FROM_FIND_VIDEOS: 'import_from_find_videos',
}

export const POST_MEDIA_CLASS_NAME = 'attached-post-media'
export const PREVIEW_MEDIA_CLASS_NAME = 'preview-post-media'

export const { POST_TYPE_HOLIDAY, POST_TYPE_SOCIAL_POST, POST_TYPE_EXTERNAL_CALENDAR_EVENT, POST_TYPE_NOTE } = {
  POST_TYPE_HOLIDAY: 'HOLIDAY',
  POST_TYPE_SOCIAL_POST: 'SOCIAL_POST',
  POST_TYPE_EXTERNAL_CALENDAR_EVENT: 'EXTERNAL_CALENDAR_EVENT',
  POST_TYPE_NOTE: 'NOTE',
}

export const { SCHEDULE_TYPE_DEFAULT, SCHEDULE_TYPE_NEW, SCHEDULE_TYPE_DUPLICATE } = {
  SCHEDULE_TYPE_DEFAULT: 'schedule_type_default',
  SCHEDULE_TYPE_NEW: 'schedule_type_new',
  SCHEDULE_TYPE_DUPLICATE: 'schedule_type_duplicate',
}

export const MAX_COMMENTS = 10

export const { CALENDAR_VIEW_MONTH, CALENDAR_VIEW_WEEK, CALENDAR_VIEW_DAY } = {
  CALENDAR_VIEW_MONTH: 'MONTH',
  CALENDAR_VIEW_WEEK: 'WEEK',
  CALENDAR_VIEW_DAY: 'DAY',
}

export const {
  PUBLISH_AS_IMAGE,
  PUBLISH_AS_VIDEO,
  PUBLISH_AS_CAROUSEL,
  PUBLISH_AS_SLIDESHOW,
  PUBLISH_AS_REELS,
  PUBLISH_AS_STORY,
  PUBLISH_AS_SAVED_STORY,
  PUBLISH_AS_USER_TYPE_PERSONAL,
  PUBLISH_AS_ARTICLE,
  PUBLISH_AS_SPOTLIGHT,
  PUBLISH_AS_POST,
  PUBLISH_TO_IMAGE_GRID,
} = {
  PUBLISH_AS_IMAGE: 'IMAGE',
  PUBLISH_AS_VIDEO: 'VIDEO',
  PUBLISH_AS_CAROUSEL: 'CAROUSEL',
  PUBLISH_AS_SLIDESHOW: 'SLIDESHOW',
  PUBLISH_AS_REELS: 'REELS',
  PUBLISH_AS_STORY: 'STORY',
  PUBLISH_AS_SAVED_STORY: 'SAVED_STORY',
  PUBLISH_AS_USER_TYPE_PERSONAL: 'personal',
  PUBLISH_AS_ARTICLE: 'ARTICLE',
  PUBLISH_AS_SPOTLIGHT: 'SPOTLIGHT',
  PUBLISH_AS_POST: 'POST',
  PUBLISH_TO_IMAGE_GRID: 'IMAGE_GRID',
}

export const INSTAGRAM_REG_EXP = /[&\/\\#!,@+()$;\[\]\^~%`'":*?=|<>{}]/g

export const {
  TABS_TYPE_IMAGE_UNSPLASH,
  TABS_TYPE_IMAGE_PEXELS,
  TABS_TYPE_IMAGE_GIPHY,
  TABS_TYPE_IMAGE_HASHTAGS,
  TABS_TYPE_IMAGE_USERS,
  TABS_TYPE_VIDEO_PEXELS,
  TABS_TYPE_VIDEO_HASHTAGS,
  TABS_TYPE_VIDEO_USERS,
} = {
  TABS_TYPE_IMAGE_UNSPLASH: 'unsplash',
  TABS_TYPE_IMAGE_PEXELS: 'pexels',
  TABS_TYPE_IMAGE_GIPHY: 'giphy',
  TABS_TYPE_IMAGE_HASHTAGS: 'hashtags',
  TABS_TYPE_IMAGE_USERS: 'users',
  TABS_TYPE_VIDEO_PEXELS: 'pexels',
  TABS_TYPE_VIDEO_HASHTAGS: 'hashtags',
  TABS_TYPE_VIDEO_USERS: 'users',
}

export const { MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS, MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS } = {
  MIN_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS: 1,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS: 10,
}

export const {
  SAVED_SEARCH_TYPE_SUBREDDIT,
  SAVED_SEARCH_TYPE_FACEBOOK_LOCATION,
  SAVED_SEARCH_TYPE_FACEBOOK_COLLABORATOR_TAGS,
  SAVED_SEARCH_TYPE_INSTAGRAM_LOCATION,
  SAVED_SEARCH_TYPE_INSTAGRAM_COLLABORATOR_TAGS,
  SAVED_SEARCH_TYPE_LINKEDIN_LOCATION,
  SAVED_SEARCH_TYPE_TWITTER_TAGGED_USERS, // we don't save any user tagging, but we could add for twitter, instagram
  SAVED_SEARCH_TYPE_YOUTUBE_TAGS,
  YOUTUBE_CATEGORIES_AND_PLAYLISTS,
} = {
  SAVED_SEARCH_TYPE_SUBREDDIT: 'reddit_subreddit',
  SAVED_SEARCH_TYPE_FACEBOOK_LOCATION: 'facebook_location',
  SAVED_SEARCH_TYPE_FACEBOOK_COLLABORATOR_TAGS: 'facebook_collaborator_tags',
  SAVED_SEARCH_TYPE_INSTAGRAM_LOCATION: 'instagram_location',
  SAVED_SEARCH_TYPE_INSTAGRAM_COLLABORATOR_TAGS: 'instagram_collaborator_tags',
  SAVED_SEARCH_TYPE_LINKEDIN_LOCATION: 'linkedin_location',
  SAVED_SEARCH_TYPE_TWITTER_TAGGED_USERS: 'twitter_tagged_users',
  SAVED_SEARCH_TYPE_YOUTUBE_TAGS: 'youtube_tags',
  YOUTUBE_CATEGORIES_AND_PLAYLISTS: 'youtube_categories_and_playlists',
}

export const {
  CALENDAR_PAGE_VIEW_TYPE_CALENDAR,
  CALENDAR_PAGE_VIEW_TYPE_LIST_ALL_POSTS,
  CALENDAR_PAGE_VIEW_TYPE_LIST_SCHEDULED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_FAILED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_PUBLISHED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REJECTED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_DRAFTS,
  CALENDAR_PAGE_VIEW_TYPE_LIST_IN_REVIEW,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REMINDER_SENT,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REMINDER_REJECTED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REMINDER_PUBLISHED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_ARCHIVED,
} = {
  CALENDAR_PAGE_VIEW_TYPE_CALENDAR: 'calendar_page_view_calendar',
  CALENDAR_PAGE_VIEW_TYPE_LIST_ALL_POSTS: 'calendar_page_view_list_all_posts',
  CALENDAR_PAGE_VIEW_TYPE_LIST_SCHEDULED: APPROVED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_FAILED: FAILED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_PUBLISHED: PUBLISHED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REJECTED: REJECTED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_DRAFTS: DRAFT,
  CALENDAR_PAGE_VIEW_TYPE_LIST_IN_REVIEW: REVIEW,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REMINDER_SENT: REMINDER_SENT,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REMINDER_REJECTED: REMINDER_REJECTED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_REMINDER_PUBLISHED: REMINDER_PUBLISHED,
  CALENDAR_PAGE_VIEW_TYPE_LIST_ARCHIVED: DELETED,
}

export const LIST_CALENDAR_VIEWS = [
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_ALL_POSTS, name: 'All posts', hasControls: true },
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_DRAFTS, name: 'Drafts', hasControls: false }, // hasControls === false would remove < Date > controls and get all posts with status = id
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_FAILED, name: 'Failed posts', hasControls: true },
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_IN_REVIEW, name: 'Posts in review', hasControls: false },
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_PUBLISHED, name: 'Published posts', hasControls: true },
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_REJECTED, name: 'Rejected posts', hasControls: true },
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_SCHEDULED, name: 'Scheduled posts', hasControls: true },
  { type: CALENDAR_PAGE_VIEW_TYPE_LIST_ARCHIVED, name: 'Archived posts', hasControls: true },
]

export const IMPRESSIONS_DESC = 'Number of times this post has been seen.'

export const { THEME_DARK, THEME_LIGHT } = { THEME_DARK: 'DARK', THEME_LIGHT: 'LIGHT' }

export const { PALETTE_TYPE_COLOR, PALETTE_TYPE_GRADIENT, PALETTE_TYPE_MEDIA } = {
  PALETTE_TYPE_COLOR: 'COLOR',
  PALETTE_TYPE_GRADIENT: 'GRADIENT',
  PALETTE_TYPE_MEDIA: 'MEDIA',
}

export const THEMES = [
  { value: THEME_LIGHT, label: 'Light theme', color: COLOR_CONSTANTS.WHITE },
  { value: THEME_DARK, label: 'Dark theme', color: COLOR_CONSTANTS.BLACK },
]

export const COLORS_PALETTE = [
  { color: '#F5A623' },
  { color: '#F8E71C' },
  { color: '#8B572A' },
  { color: '#7ED321' },
  { color: '#417505' },
  { color: '#BD10E0' },
  { color: '#9013FE' },
  { color: '#4A90E2' },
  { color: '#50E3C2' },
  { color: '#B8E986' },
  { color: '#000000' },
  { color: '#4A4A4A' },
  { color: '#9B9B9B' },
  { color: '#FFFFFF', hasBorder: true },
]

export const GRADIENT_PALETTE = [
  { color: 'linear-gradient(135deg, rgb(121, 40, 202), rgb(255, 0, 128))', title: 'Blue Violet' },
  { color: 'linear-gradient(135deg, rgb(254, 194, 6), rgb(255, 98, 167))', title: 'Golden Yellow' },
  { color: 'linear-gradient(135deg, rgb(102, 126, 234), rgb(118, 75, 162))', title: 'Cornflower Blue' },
  { color: 'linear-gradient(120deg, rgb(246, 211, 101), rgb(253, 160, 133))', title: 'Light Gold' },
  { color: 'linear-gradient(120deg, rgb(224, 195, 252), rgb(142, 197, 252))', title: 'Lavender' },
  { color: 'linear-gradient(90deg, rgb(79, 172, 254), rgb(0, 242, 254))', title: 'Sky Blue' },
  { color: 'linear-gradient(90deg, rgb(255, 236, 210), rgb(252, 182, 159))', title: 'Peach Puff' },
  { color: 'linear-gradient(120deg, rgb(161, 196, 253), rgb(194, 233, 251))', title: 'Light Sky Blue' },
  { color: 'linear-gradient(135deg, rgb(245, 247, 250), rgb(195, 207, 226))', title: 'Alice Blue' },
  { color: 'linear-gradient(rgb(253, 219, 146), rgb(209, 253, 255))', title: 'Light Golden Yellow' },
  { color: 'linear-gradient(rgb(250, 208, 196), rgb(255, 209, 255))', title: 'Peach' },
  { color: 'linear-gradient(rgb(168, 237, 234), rgb(254, 214, 227))', title: 'Light Cyan' },
  {
    color: 'linear-gradient(rgb(255, 154, 158) 0%, rgb(254, 207, 239) 99%, rgb(254, 207, 239) 100%)',
    title: 'Light Salmon',
  },
  { color: 'linear-gradient(rgb(48, 207, 208), rgb(51, 8, 103))', title: 'Turquoise' },
  { color: 'linear-gradient(rgb(251, 194, 235), rgb(166, 193, 238))', title: 'Lavender Blush' },
  { color: 'linear-gradient(120deg, rgb(252, 203, 144), rgb(213, 126, 235))', title: 'Light Apricot' },
  {
    color:
      'linear-gradient(90deg, rgb(255, 129, 119) 0%, rgb(255, 140, 127) 21%, rgb(249, 145, 133) 52%, rgb(177, 42, 91) 100%)',
    title: 'Salmon Pink',
  },
  {
    color: 'linear-gradient(rgb(253, 203, 241) 0%, rgb(253, 203, 241) 10%, rgb(230, 222, 233) 100%)',
    title: 'Cotton Candy Pink',
  },
  { color: 'linear-gradient(120deg, rgb(240, 147, 251), rgb(245, 87, 108))', title: 'Orchid' },
  { color: 'linear-gradient(90deg, rgb(250, 112, 154), rgb(254, 225, 64))', title: 'Watermelon' },
  { color: 'linear-gradient(90deg, rgb(67, 233, 123), rgb(56, 249, 215))', title: 'Light Green' },
  { color: 'linear-gradient(rgb(207, 217, 223), rgb(226, 235, 240))', title: 'Light Grey' },
  { color: 'linear-gradient(135deg, rgb(253, 252, 251), rgb(226, 209, 195))', title: 'Ghost White' },
  {
    color: 'linear-gradient(45deg, rgb(255, 154, 158) 0%, rgb(250, 208, 196) 99%, rgb(250, 208, 196) 100%)',
    title: 'Light Peach',
  },
  { color: 'linear-gradient(120deg, rgb(132, 250, 176), rgb(143, 211, 244))', title: 'Mint Green' },
  { color: 'linear-gradient(rgb(161, 140, 209), rgb(251, 194, 235))', title: 'Light Purple' },
  { color: 'linear-gradient(rgb(152, 144, 227), rgb(177, 244, 207))', title: 'Periwinkle' },
  { color: 'linear-gradient(120deg, rgb(212, 252, 121), rgb(150, 230, 161))', title: 'Light Lime' },
  { color: 'linear-gradient(120deg, rgb(166, 192, 254), rgb(246, 128, 132))', title: 'Light Periwinkle' },
  {
    color: 'linear-gradient(135deg, rgb(254, 194, 6) 2.24%, rgb(89, 195, 255) 50.72%, rgb(255, 98, 167) 96.25%)',
    title: 'Bright Gold',
  },
]

export const DEFAULT_QUEUE_LABEL = { value: '', label: 'All queue labels' }

export const { PUBLISHING_OPTION_DIRECT, PUBLISHING_OPTION_NOTIFICATION } = {
  PUBLISHING_OPTION_DIRECT: 'DIRECT',
  PUBLISHING_OPTION_NOTIFICATION: 'NOTIFICATION',
}

export const PUBLISHING_OPTIONS = [
  { value: PUBLISHING_OPTION_DIRECT, label: 'Auto publish' },
  { value: PUBLISHING_OPTION_NOTIFICATION, label: 'Send reminder notification' },
]

export const CUSTOMIZATION_TRANSITION_TIMEOUT = 500

const FACEBOOK_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4430269950619--Web-Facebook-Publishing-with-Vista-Social'

const GMB_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4444478381851-Google-My-Business-Publishing-with-Vista-Social'

const YOUTUBE_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4475062292763-Youtube-Publishing-with-Vista-Social'

const REDDIT_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4531883893659-Reddit-Publishing-with-Vista-Social'

const TIKTOK_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4419165964827--Web-TikTok-Publishing-with-Vista-Social'

const TWITTER_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4430435758235--Web-Twitter-Publishing-with-Vista-Social'

const PINTEREST_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4444514208667--Web-Pinterest-Publishing-with-Vista-Social'

const LINKEDIN_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4432388717595--Web-LinkedIn-Publishing-with-Vista-Social'

const INSTAGRAM_HELP_LINK =
  'https://support.vistasocial.com/hc/en-us/articles/4430358197275--Web-Instagram-Publishing-with-Vista-Social'

const SNAPCHAT_HELP_LINK = ''

const VISTAPAGE_HELP_LINK = ''

const TUMBLR_HELP_LINK = ''

const THREADS_HELP_LINK = ''

const BLUESKY_HELP_LINK = ''

export const NETWORK_CUSTOMIZATIONS_PROMPT_TEXT = {
  [FACEBOOK]: {
    caption: {
      text: 'Customize Facebook caption.',
      link: FACEBOOK_HELP_LINK,
    },
    publishing_page_options: {
      text: 'Publish as a user or publish as a group.',
      link: FACEBOOK_HELP_LINK,
    },
    publishing_post_options: {
      text: 'Select post type that is going to be published.',
      link: FACEBOOK_HELP_LINK,
    },
    location: {
      text: 'Tag your post with a location.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/16101181997083-Organic-Targeting-for-Facebook-LinkedIn',
    },
    country_targeting: {
      text: 'Narrow down your audience based on specific countries.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/16101181997083-Organic-Targeting-for-Facebook-LinkedIn',
    },
    call_to_action: {
      text: 'Call to action buttons appear in the post and make the post more engaging.',
      link: '',
    },
    relationship_status: {
      text: 'Refine your audience based on their relationship status.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/16101181997083-Organic-Targeting-for-Facebook-LinkedIn',
    },
    gender: {
      text: 'Tailor your content to specific genders.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/16101181997083-Organic-Targeting-for-Facebook-LinkedIn',
    },
    age: {
      text: 'Refine your audience based on specific age ranges.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/16101181997083-Organic-Targeting-for-Facebook-LinkedIn',
    },
    collaborators: {
      text:
        'When invited collaborators accept the invitation, the reel will immediately be published on their Facebook Page if the reel has been published, or the reel will be published on their Page when you publish the reel on your Page.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/30621568692251',
    },
  },

  [GOOGLE]: {
    caption: {
      text: 'Customize Google caption.',
      link: GMB_HELP_LINK,
    },
    publish_as_image: {
      text: 'Publish you link picture as an image post.',
      link: GMB_HELP_LINK,
    },
    call_to_action: {
      text: `Call to action buttons appear in the post and make the post more engaging.`,
      link: GMB_HELP_LINK,
    },
    post_type: {
      text:
        'Each post type provides your audience with specific details and allows you to add an action button to your post.',
      link: GMB_HELP_LINK,
    },
    alert_type: {
      text: 'Alert type options.',
      link: GMB_HELP_LINK,
    },
    event_title: {
      text: 'Title for your event.',
      link: GMB_HELP_LINK,
    },
    event_start_date: {
      text: 'Starting date and time for your event.',
      link: GMB_HELP_LINK,
    },
    event_end_date: {
      text: 'Ending date and time for your event.',
      link: GMB_HELP_LINK,
    },
    offer_coupon: {
      text: 'Offer code that is usable in store or online.',
      link: GMB_HELP_LINK,
    },
    offer_terms: {
      text: 'Offer terms and conditions.',
      link: GMB_HELP_LINK,
    },
  },

  [INSTAGRAM]: {
    caption: {
      text: 'Customize Instagram caption.',
      link: INSTAGRAM_HELP_LINK,
    },
    publishing_post_options: {
      text: 'Select post type that is going to be published.',
      link: INSTAGRAM_HELP_LINK,
    },
    share_to_feed: {
      text: 'Share your reel to feed.',
      link: INSTAGRAM_HELP_LINK,
    },
    location: {
      text: 'Tag your post with a location.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/5441272145435',
    },
    comments_enabled: {
      text: 'Enable or disable post commenting.',
      link: INSTAGRAM_HELP_LINK,
    },
    collaborator_tags: {
      text:
        'Only public Instagram profiles can be invited as collaborators. Once the post is published, invited users will receive a request and need to accept it.',
      link: 'https://support.vistasocial.com/hc/en-us/articles/20049112684443',
    },
  },

  [LINKEDIN]: {
    caption: {
      text: 'Customize your Linkedin post caption.',
      link: LINKEDIN_HELP_LINK,
    },
    publish_as_image: {
      text: 'Publish you link post as an image post.',
      link: LINKEDIN_HELP_LINK,
    },
    like: {
      text: 'Automatically like the post once published.',
      link: LINKEDIN_HELP_LINK,
    },
    location: {
      text: 'Reach a specific audience based on location.',
      link: LINKEDIN_HELP_LINK,
    },
    language: {
      text: 'Reach a specific audience based on language.',
      link: LINKEDIN_HELP_LINK,
    },
  },

  [PINTEREST]: {
    message: {
      text: 'Customize your Pinterest pin description.',
      link: PINTEREST_HELP_LINK,
    },
    title: {
      text: 'Customize your Pinterest pin title.',
      link: PINTEREST_HELP_LINK,
    },
    pin_link: {
      text: 'Links may not be of Pinterest pins or boards.',
      link: PINTEREST_HELP_LINK,
    },
    board: {
      text: 'Board selection is required when publishing Pinterest pins.',
      link: PINTEREST_HELP_LINK,
    },
    board_section: {
      text: 'Sections to categorize and organize your Pinterest board content.',
      link: PINTEREST_HELP_LINK,
    },
  },

  [REDDIT]: {
    caption: {
      text: 'Customize Reddit caption.',
      link: REDDIT_HELP_LINK,
    },
    subreddit: {
      text: 'User-created areas of interest where discussions on Reddit are organized.',
      link: REDDIT_HELP_LINK,
    },
    flair: {
      text:
        'Help users understand the category to which the posts belong to and help readers filter specific kind of posts based on their preferences.',
      link: REDDIT_HELP_LINK,
    },
    title: {
      text: 'Use descriptive thread title when making a posts.',
      link: REDDIT_HELP_LINK,
    },
  },

  [TIKTOK]: {
    caption: {
      text: 'Customize TikTok video caption.',
      link: TIKTOK_HELP_LINK,
    },
    comments_enabled: {
      text: 'Enable or disable post commenting.',
      link: TIKTOK_HELP_LINK,
    },
    duets_enabled: {
      text:
        'Enable or disable duets. Allows your post to be used by another creator with another side-by-side video on TikTok.',
      link: TIKTOK_HELP_LINK,
    },
    stitches_enabled: {
      text: `Enable or disable stitches. Stitch is a creation tool that allows you to combine another video on TikTok with one you're creating`,
      link: TIKTOK_HELP_LINK,
    },
    is_promotional_content: {
      text: `Video will be labeled as Brand Organic Content, indicating you are promoting yourself or your own business. A "Promotional content" label will be attached to the video.`,
      link: TIKTOK_HELP_LINK,
    },
    is_paid_partnership: {
      text: `Video will be labeled as Branded Content, indicating you are in a paid partnership with a brand. A "Paid partnership" label will be attached to the video.`,
      link: TIKTOK_HELP_LINK,
    },
    privacy_level: {
      text: 'Control who can view your post.',
      link: TIKTOK_HELP_LINK,
    },
    title: {
      text: 'Customize TikTok post title.',
      link: TIKTOK_HELP_LINK,
    },
    auto_add_music: {
      text: 'Automatically add recommended music by TikTok.',
      link: TIKTOK_HELP_LINK,
    },
  },

  [TWITTER]: {
    caption: {
      text: 'Customize Twitter caption.',
      link: TWITTER_HELP_LINK,
    },
    like: {
      text: 'Automatically like the post once published.',
      link: TWITTER_HELP_LINK,
    },
    publish_as_image: {
      text: 'Publish you link picture as an image post.',
      link: TWITTER_HELP_LINK,
    },
    for_super_followers_only: {
      text: 'Posting for super followers only posts. This feature requires a paid Twitter subscription.',
      link: TWITTER_HELP_LINK,
    },
    reply_settings: {
      text: 'Control who can reply to a tweet.',
      link: TWITTER_HELP_LINK,
    },
  },

  [VIMEO]: {
    video_title: {
      text: 'Video title summarizes what your video is about.',
      link: '',
    },
  },

  [YOUTUBE]: {
    caption: {
      text: 'Customize Youtube caption.',
      link: YOUTUBE_HELP_LINK,
    },
    video_title: {
      text: 'Video title summarizes what your video is about.',
      link: YOUTUBE_HELP_LINK,
    },
    privacyStatus: {
      text: 'Control where your video can appear and who can watch it.',
      link: YOUTUBE_HELP_LINK,
    },
    category_name: {
      text:
        'YouTube categories are very important for YouTube creators because they can help you get more clicks and make more money from ads.',
      link: YOUTUBE_HELP_LINK,
    },
    playlist: {
      text: 'Playlists are a group of related YouTube videos that tend to be organized by topic or theme.',
      link: YOUTUBE_HELP_LINK,
    },
    tags: {
      text: 'Tags are descriptive keywords you can add to your video to help viewers find your content.',
      link: YOUTUBE_HELP_LINK,
    },
    like: {
      text: 'Automatically like the video once published.',
      link: YOUTUBE_HELP_LINK,
    },
    embeddable: {
      text: 'Allow others to embed your video on their sites.',
      link: YOUTUBE_HELP_LINK,
    },
    notifySubscribers: {
      text: 'YouTube sends alerts to the subscribers of a channel when a creator posts a new video.',
      link: YOUTUBE_HELP_LINK,
    },
    madeForKids: {
      text:
        'Prevents underage users from watching this video. This also removes the ability to monetize or promote your video through different ad formats.',
      link: YOUTUBE_HELP_LINK,
    },
  },

  [SNAPCHAT]: {
    caption: {
      text: 'Customize Snapchat video caption.',
      link: SNAPCHAT_HELP_LINK,
    },
    publish_as: {
      text: 'Select post type that is going to be published.',
      link: SNAPCHAT_HELP_LINK,
    },
    save_to_profile: { text: 'Feature your Spotlight submissions on your Public Profile.', link: SNAPCHAT_HELP_LINK },
    description: { text: 'Description for your Spotlight.', link: SNAPCHAT_HELP_LINK },
    locale: { text: 'Language and country preferences.', link: SNAPCHAT_HELP_LINK },
    saved_story_title: { text: 'Title for your Saved Story.', link: SNAPCHAT_HELP_LINK },
  },
  [VISTAPAGE]: {
    caption: {
      text: 'Customize Vista Page caption.',
      link: VISTAPAGE_HELP_LINK,
    },
    publish_as_image: {
      text: 'Publish you link picture as an image post.',
      link: VISTAPAGE_HELP_LINK,
    },
  },

  [TUMBLR]: {
    caption: {
      text: 'Customize Tumblr caption.',
      link: TUMBLR_HELP_LINK,
    },
    slug: {
      text: 'User-created areas of interest where discussions on Tumblr are organized.',
      link: TUMBLR_HELP_LINK,
    },
    title: {
      text: 'Use descriptive thread title when making a posts.',
      link: TUMBLR_HELP_LINK,
    },
    tags: {
      text: 'Tags are descriptive keywords you can add to your post to help viewers find your content.',
      link: TUMBLR_HELP_LINK,
    },
  },

  [THREADS]: {
    caption: {
      text: 'Customize Threads caption.',
      link: THREADS_HELP_LINK,
    },
    publish_as_image: {
      text: 'Publish you link picture as an image post.',
      link: THREADS_HELP_LINK,
    },
    tags: {
      text: 'Tag is a descriptive keyword you can add to your post to help viewers find your content.',
      link: THREADS_HELP_LINK,
    },
    reply_control: {
      text: 'Control who can reply to a thread.',
      link: THREADS_HELP_LINK,
    },
    country_targeting: {
      text: 'Display this content to people living in selected countries.',
      link: THREADS_HELP_LINK,
    },
    hide_replies: {
      text: 'Hide or show post replies.',
      link: THREADS_HELP_LINK,
    },
  },

  [BLUESKY]: {
    caption: {
      text: 'Customize Bluesky caption.',
      link: BLUESKY_HELP_LINK,
    },
    like: {
      text: 'Automatically like the post once published.',
      link: BLUESKY_HELP_LINK,
    },
    publish_as_image: {
      text: 'Publish you link picture as an image post.',
      link: BLUESKY_HELP_LINK,
    },
  },

  caption: {
    text: 'Network custom caption.',
    link: '',
  },
  media: {
    text: 'Edit the selection of media items that will post to this profile',
    link: '',
  },
  publishing_option: {
    text: 'Auto publish or send reminder to a mobile app.',
    link: 'https://support.vistasocial.com/hc/en-us/articles/14736118034715-Auto-Publishing-vs-Notification-Reminders',
  },
  device: {
    text: 'To register a device, please install our mobile application.',
    link: 'https://support.vistasocial.com/hc/en-us/articles/14938988567963',
  },
  comments: {
    text: 'Schedule additional comments.',
    link:
      'https://support.vistasocial.com/hc/en-us/articles/6524866650139-Scheduling-a-First-Comment-and-additional-comments',
  },
  boost: {
    text: 'Select boost settings to automatically boost this post upon publishing.',
    link: 'https://support.vistasocial.com/hc/en-us/articles/23687755431835-Boosting-Posts-with-Vista-Social',
  },
}

export const { PUBLISH_NOW, PUBLISH_WHEN_READY, PUBLISH_LATER, PUBLISH_DRAFT, PUBLISH_QUEUE, PUBLISH_SAVE_CHANGES } = {
  PUBLISH_DRAFT: 'publishDraft',
  PUBLISH_NOW: 'publishNow',
  PUBLISH_WHEN_READY: 'publishWhenReady',
  PUBLISH_LATER: 'publishLater',
  PUBLISH_QUEUE: 'publishQueue',
  PUBLISH_SAVE_CHANGES: 'publishSaveChanges',
}

export const MENTION_URL_NETWORK_START = {
  [FACEBOOK]: 'https://facebook.com/',
  [INSTAGRAM]: 'https://instagram.com/',
  [LINKEDIN]: 'https://linkedin.com/',
  [TWITTER]: 'https://x.com/',
  [YOUTUBE]: 'https://youtube.com/@',
  [TIKTOK]: 'https://tiktok.com/@',
  [SNAPCHAT]: 'https://www.snapchat.com/add/',
  [REDDIT]: 'https://reddit.com/user/',
  [PINTEREST]: 'https://pinterest.com/',
  [TUMBLR]: 'https://www.tumblr.com/@',
  [THREADS]: 'https://www.threads.net/@',
  [BLUESKY]: 'https://bsky.app/profile/',
}

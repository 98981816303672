import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { radius } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Input from 'components/atoms/Input'
import Checkbox from 'components/atoms/Checkbox'
import Link from 'components/atoms/Link'
import Icon from 'components/atoms/Icon'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 700px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 330px;
    top: 40%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CheckBoxWrapper = styled(Box)`
  cursor: pointer;
`

const QuestionMarkOutlined = styled(Icon.QuestionMarkOutlined)`
  fill: ${({ theme }) => theme.colors.secondaryText};
  cursor: pointer;
  height: 16px;
  width: 16px;
`

const { HANDLE, PASSWORD, FOLLOW } = {
  HANDLE: 'handle',
  PASSWORD: 'password',
  FOLLOW: 'follow',
  NAME: 'name',
  URL: 'url',
}

const url_pattern = /^[a-zA-Z0-9_-]+\.bsky\.social$/
const password_pattern = /^[a-zA-Z0-9_-]{4}[-]{1}[a-zA-Z0-9_-]{4}[-]{1}[a-zA-Z0-9_-]{4}[-]{1}[a-zA-Z0-9_-]{4}$/

const ConnectingBlueskyModal = ({ isOpen, handleDismiss, handleAddProfile, entityId, profileHandle = '' }) => {
  const formRef = useRef(null)
  const tooltipRef = useRef(null)

  const [isConnectingBluesky, setIsConnectingBluesky] = useState(false)

  const handleSubmitForm = async (values) => {
    setIsConnectingBluesky(true)
    try {
      const body = {
        [HANDLE]: values[HANDLE],
        [PASSWORD]: values[PASSWORD],
        [FOLLOW]: values[FOLLOW],
        entity_gid: entityId,
      }

      const response = await request({
        method: 'POST',
        body,
        path: `connect/bluesky`,
      })

      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        setIsConnectingBluesky(false)
      } else {
        Alert.success('Bluesky profile connected successfully.', { timeout: 5000 })
        handleAddProfile()
      }
    } catch (error) {
      errorHelper({ error, componentName: ConnectingBlueskyModal.displayName, functionName: 'handleSubmitForm' })
      setIsConnectingBluesky(false)
    }
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  const createTaskValidationSchema = () => {
    return Yup.object().shape({
      [HANDLE]: Yup.string()
        .max(200, 'Bluesky handle is too long - should be 200 chars maximum.')
        .test(
          'test-handle',
          'Please enter a valid Bluesky profile handle (eg. name.bsky.social or domain.com)',
          (value) => value && value.split('.').length >= 2
        )
        .required(`Bluesky handle is required`),
      [PASSWORD]: Yup.string()
        .matches(
          password_pattern,
          'App Password should match xxxx-xxxx-xxxx-xxxx pattern. Please note that this is not your account password.'
        )
        // .min(19, 'Password is too short - should be 19 chars minimum.')
        // .max(19, 'Password is too long - should be 19 chars maximum.')
        .required('Password is required'),
    })
  }

  const handleClickCloseModal = () => {
    if (!isConnectingBluesky) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Connect to Bluesky</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" mt="m" px="m" flex="1">
            <Flex flexDirection="column" width="100%">
              <Formik
                ref={formRef}
                initialValues={{
                  [HANDLE]: profileHandle || '',
                  [PASSWORD]: '',
                  [FOLLOW]: true,
                }}
                validationSchema={createTaskValidationSchema}
                onSubmit={handleSubmitForm}
              >
                {({ values, touched, errors, setFieldValue }) => (
                  <Flex width="100%" height="100%" flexDirection="column">
                    <Box>
                      <Flex mb="xs" alignItems="center">
                        <Text fontSize="s" pr="m">
                          Handle
                        </Text>
                        <Flex alignItems="center">
                          <Flex alignItems="center">
                            <Flex
                              alignItems="center"
                              onMouseEnter={(e) => {
                                tooltipRef.current.handleShowTooltip({
                                  defaultXPosition: 'right',
                                  contentComp: (
                                    <Box p="s" maxWidth="300px">
                                      <Text>
                                        On Bluesky, click on your avatar.
                                        <br />
                                        Your handle is below your display name and looks like this: name.bsky.social or
                                        domain.com
                                      </Text>
                                    </Box>
                                  ),
                                  wrapperComp: e.currentTarget,
                                })
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              <QuestionMarkOutlined />
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Input
                        label=""
                        placeholder="Looks like this: name.bsky.social or domain.com"
                        value={values[HANDLE]}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^a-zA-Z0-9_.-]/g, '')

                          setFieldValue(HANDLE, value)
                        }}
                        error={errors[HANDLE] && touched[HANDLE] && errors[HANDLE]}
                        width="100%"
                      />
                    </Box>

                    <Box mt="m">
                      <Text fontSize="s">Bluesky app password</Text>

                      <Text my="xs" fontSize="s" color="secondaryText">
                        Use an app password to connect safely with Vista Social without giving full access to your
                        account. This is not your account password.{' '}
                        <Link href="https://bsky.app/settings/app-passwords" target="_blank" color="primary">
                          Generate app password in Bluesky
                        </Link>
                      </Text>

                      <Input
                        label=""
                        placeholder="xxxx-xxxx-xxxx-xxxx"
                        value={values[PASSWORD]}
                        onChange={(e) => {
                          const { value } = e.target
                          setFieldValue(PASSWORD, value)
                        }}
                        error={errors[PASSWORD] && touched[PASSWORD] && errors[PASSWORD]}
                        width="100%"
                      />
                    </Box>

                    <Box my="m">
                      <CheckBoxWrapper>
                        <Checkbox
                          isChecked={values[FOLLOW]}
                          onClick={() => {
                            setFieldValue(FOLLOW, !values[FOLLOW])
                          }}
                        >
                          <Text ml="l">Follow Vista Social on Bluesky</Text>
                        </Checkbox>
                      </CheckBoxWrapper>
                    </Box>
                  </Flex>
                )}
              </Formik>
            </Flex>
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isConnectingBluesky}>
              Cancel
            </Button.Gray>
            <ButtonWithLoading
              isSmall
              onClick={handleSubmitFormFromButton}
              isLoading={isConnectingBluesky}
              type="submit"
            >
              {isConnectingBluesky ? 'Connecting' : 'Connect'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        <CalendarItemTooltip ref={tooltipRef} />
      </Box>
    </StyledDialogOverlay>
  )
}

ConnectingBlueskyModal.defaultProps = {
  profileHandle: '',
}

ConnectingBlueskyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleAddProfile: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  profileHandle: PropTypes.string,
}

ConnectingBlueskyModal.displayName = 'ConnectingBlueskyModal'

export default ConnectingBlueskyModal

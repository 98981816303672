import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import twitter from 'twitter-text'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { transparentize } from 'polished'
import { radius, space } from 'theme'
import { FACEBOOK, INSTAGRAM, AI_TYPE_COMMENT, TWITTER, BLUESKY } from 'consts'
import { stringifyNumber } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import Counter from 'components/molecules/Counter'
import { MAX_COMMENTS, NETWORK_LIMITS } from 'routes/Calendar/consts'
import { blueskyGetPostLength } from 'routes/Calendar/helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './TextAreaComponentWithControls'

const StyledAddCommentWrapper = styled(Flex)`
  cursor: pointer;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 8px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
  box-shadow: 0 0px 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledAccordion = styled(Accordion)`
  background: ${({ theme }) => theme.colors.background_modal} !important;
  border-radius: ${radius.l} !important;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 0;

  .MuiAccordionDetails-root {
    padding: ${space.m};
    color: ${({ theme }) => theme.colors.primaryText};
  }

  .MuiIconButton-root {
    padding: 0;
    margin-right: ${space.xs};
  }

  &:before {
    display: none;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 !important;
  min-height: 38px !important;
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
`

const StyledArrowWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledCommentIconWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledCommentCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const CommentsComponent = ({
  user,
  network,
  profileId,
  comments,
  post,
  handleUpdateCustomization,
  handleUpdateCustomizationDebounce,
  commentTextMaxLimitNumber,
  selectedEntities,
  selectedProfiles,
  textVariantOffsets,
  showMediaPicker,
  showImageComponent,
  showLinkComponent,
  showVideoComponent,
  showDocumentComponent,
  showMixedMediaComponent,
  hasPreview,
  ...flags
}) => {
  const [isFormExpanded, setIsFormExpanded] = useState(false)

  const { commentTextMaxLimit, hashtagsCommentLengthLimit } = flags

  const commentsLength = comments
    ? comments.filter(
        ({ message, postImages, postVideos, postMixedMedias }) =>
          message ||
          (postImages && postImages.length !== 0) ||
          (postVideos && postVideos.length !== 0) ||
          (postMixedMedias && postMixedMedias.length !== 0)
      ).length
    : 0

  const networkLimits = NETWORK_LIMITS.find(({ code }) => code === network)

  const maxImageAttachmentsAllowed = networkLimits ? networkLimits.maxImagesInComments || 0 : 0
  const maxVideoAttachmentsAllowed = networkLimits ? networkLimits.maxVideosInComments || 0 : 0
  const maxMixedMediaAttachmentsAllowed = networkLimits ? networkLimits.maxMixedMediasInComments || 0 : 0

  return (
    <StyledAccordion defaultExpanded expanded={isFormExpanded}>
      <StyledAccordionSummary
        onClick={() => {
          setIsFormExpanded(!isFormExpanded)
        }}
        expandIcon={
          <StyledArrowWrapper>
            <Icon.VistaSocialChevronDown />
          </StyledArrowWrapper>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <Flex alignItems="center" pl="s" width="100%" justifyContent="space-between">
          <Text fontSize="xs" color={commentsLength === 0 ? 'secondaryText' : 'denimText'}>
            Post comments
          </Text>
          {commentsLength !== 0 && (
            <StyledCommentIconWrapper ml="s" position="relative">
              <Image src="/assets/vistasocial/dashboard/comment_filled.svg" width="18px" height="18px" />
              <StyledCommentCounterWrapper>
                <Text fontSize="xxs" color="ternaryText">
                  {commentsLength}
                </Text>
              </StyledCommentCounterWrapper>
            </StyledCommentIconWrapper>
          )}
        </Flex>
      </StyledAccordionSummary>
      <AccordionDetails>
        <Flex flexDirection="column" width="100%" mt={`-${space.m}`}>
          {comments.map((comment, index) => {
            const { message = '', postComponent, postImages = [], postVideos = [], postMixedMedias = [] } = comment

            let errorText = ''
            let commentPlaceholder = ''

            if (network === FACEBOOK) {
              commentPlaceholder = `Leave ${stringifyNumber(index + 1)} comment (pages only)`
            } else {
              commentPlaceholder = `Leave ${stringifyNumber(index + 1)} comment`
            }

            if (network === INSTAGRAM) {
              errorText =
                commentTextMaxLimit && commentTextMaxLimit === index + 1
                  ? 'Your comment is too long'
                  : hashtagsCommentLengthLimit && hashtagsCommentLengthLimit === index + 1
                  ? `You can only include 30 hashtags when commenting on Instagram`
                  : ''
            } else {
              errorText = commentTextMaxLimit && commentTextMaxLimit === index + 1 ? 'Your comment is too long' : ''
            }

            let commentLength = message ? message.length : 0

            if (network === TWITTER) {
              commentLength = twitter.getTweetLength(message)
            } else if (network === BLUESKY) {
              commentLength = blueskyGetPostLength({ text: message, post })
            }

            return (
              <Flex mb="m" flexDirection="column" position="relative" key={comment.id || index}>
                {index !== 0 && (
                  <CloseIconWrapper
                    onClick={() => {
                      comments.splice(index, 1)
                      handleUpdateCustomization({ type: 'comments', value: comments })
                    }}
                  >
                    <Image width="8px" height="8px" src="/assets/clear.svg" />
                  </CloseIconWrapper>
                )}

                <TextAreaComponentWithControls
                  user={user}
                  handleUpdateText={(text) => {
                    if (text !== message) {
                      comments[index].message = text
                      handleUpdateCustomizationDebounce({ type: 'comments', value: comments })
                    }
                  }}
                  text={message || ''}
                  textLength={commentLength}
                  textMaxLimitNumber={commentTextMaxLimitNumber}
                  textVariantOffsets={textVariantOffsets}
                  textareaPlaceholder={commentPlaceholder}
                  errorText={errorText}
                  selectedEntities={selectedEntities}
                  selectedProfiles={selectedProfiles}
                  typeAI={AI_TYPE_COMMENT}
                  counterComp={
                    network === TWITTER ? <Counter counter={commentLength} max={commentTextMaxLimitNumber} /> : null
                  }
                  showOnLeft={hasPreview}
                  showOnTop
                  network={network}
                  profileId={profileId}
                  dataForMediaPicker={{ postComponent, postText: message, postImages, postVideos, postMixedMedias }}
                  showMediaPicker={showMediaPicker}
                  showImageComponent={showImageComponent}
                  showLinkComponent={showLinkComponent}
                  showVideoComponent={showVideoComponent}
                  showDocumentComponent={showDocumentComponent}
                  showMixedMediaComponent={showMixedMediaComponent}
                  specialPostKey={`comments_${comment.id}`}
                  maxImageAttachmentsAllowed={maxImageAttachmentsAllowed}
                  maxVideoAttachmentsAllowed={maxVideoAttachmentsAllowed}
                  maxMixedMediaAttachmentsAllowed={maxMixedMediaAttachmentsAllowed}
                />
              </Flex>
            )
          })}

          <Flex alignItems="center" justifyContent="flex-end">
            {comments.length < MAX_COMMENTS ? (
              <StyledAddCommentWrapper
                alignItems="center"
                onClick={() => {
                  comments.push({ id: new Date().getTime().toString(), message: '' })
                  handleUpdateCustomization({ type: 'comments', value: comments })
                }}
              >
                <Image source="/assets/plus.svg" mr="xs" width="16px" height="16px" />
                <Text color="primary" fontSize="xs">
                  Add another comment
                </Text>
              </StyledAddCommentWrapper>
            ) : (
              <Text color="secondaryText" fontSize="xs">
                You've reach maximum number of comments
              </Text>
            )}
          </Flex>
        </Flex>
      </AccordionDetails>
    </StyledAccordion>
  )
}

CommentsComponent.defaultProps = {
  post: null,
  comments: [],
  commentTextMaxLimit: false,
  selectedEntities: [],
  selectedProfiles: [],
  textVariantOffsets: [],
  showMediaPicker: false,
  showImageComponent: false,
  showLinkComponent: false,
  showVideoComponent: false,
  showDocumentComponent: false,
  showMixedMediaComponent: false,
  hasPreview: true,
}

CommentsComponent.propTypes = {
  user: PropTypes.object.isRequired,
  network: PropTypes.string.isRequired,
  profileId: PropTypes.number.isRequired,
  post: PropTypes.object,
  handleUpdateCustomizationDebounce: PropTypes.func.isRequired,
  handleUpdateCustomization: PropTypes.func.isRequired,
  comments: PropTypes.array,
  commentTextMaxLimit: PropTypes.bool,
  commentTextMaxLimitNumber: PropTypes.number.isRequired,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  textVariantOffsets: PropTypes.array,
  showMediaPicker: PropTypes.bool,
  showImageComponent: PropTypes.bool,
  showLinkComponent: PropTypes.bool,
  showVideoComponent: PropTypes.bool,
  showDocumentComponent: PropTypes.bool,
  showMixedMediaComponent: PropTypes.bool,
  hasPreview: PropTypes.bool,
}

export default CommentsComponent

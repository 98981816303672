import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { width as styledWidth } from 'styled-system'
import ClipLoader from 'react-spinners/ClipLoader'
import Alert from 'react-s-alert'
import { fontSizes, fontWeights, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import { ROUTE_CALENDAR } from '../consts'
import ApprovalWorkflowViewModal from '../../Settings/PublishingSettings/components/ApprovalWorkflowViewModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 900px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 500px;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const cellStyle = `
  padding-left: ${space.m};
  border-width: 1px 0;
`

const StyledTable = styled('table')`
  width: 100%;
  font-size: ${fontSizes.s};
  text-align: left;
`

const Row = styled('tr')`
  height: 50px;
`

const HeaderCell = styled('th')`
  ${styledWidth};
  font-weight: ${fontWeights.medium};
  border: solid ${({ theme }) => theme.colors.border_color};
  ${cellStyle};
  white-space: nowrap;
`

const BodyCell = styled('td')`
  border: solid ${({ theme }) => theme.colors.border_color};
  ${styledWidth};
  ${cellStyle};
  & > * {
    vertical-align: middle;
  }
`

const StyledActionWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
    svg {
      fill: ${({ theme }) => theme.colors.primaryText};
    }
  }
`

const DEFAULT_WORKFLOW_MODAL_OPTIONS = {
  isOpen: false,
  data: null,
}

const HistoryPostModal = ({ isOpen, handleClickCloseModal, post, timezone }) => {
  const [history, setHistory] = useState([])
  const [isGettingHistory, setIsGettingHistory] = useState(false)
  const [workflowViewModalOptions, setWorkflowViewModalOptions] = useState({ ...DEFAULT_WORKFLOW_MODAL_OPTIONS })

  const getHistory = async () => {
    const { id, draftId } = post

    const searchPostId = id || draftId

    if (searchPostId) {
      setIsGettingHistory(true)
      try {
        const response = await request({
          path: `${ROUTE_CALENDAR}/${searchPostId}/history?timezone=${timezone}`,
        })
        const { error, history } = response
        if (error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setHistory([...history])
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: HistoryPostModal.displayName,
          functionName: 'getHistory',
          errorMessage: `Can't get post history. Please contact support.`,
        })
      } finally {
        setIsGettingHistory(false)
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      getHistory()
    }
  }, [isOpen])

  const handleClickAction = ({ action }) => {
    const { type, id } = action

    if (type === 'workflow') {
      setWorkflowViewModalOptions({ ...{ isOpen: true, data: { id } } })
    } else {
      Alert.error(`Unknown history action. Please contact support.`, { timeout: 5000 })
    }
  }

  const handleClickCloseApprovalWorkflowViewModal = () => {
    setWorkflowViewModalOptions({ ...DEFAULT_WORKFLOW_MODAL_OPTIONS })
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Post history</H4>
          </StyledDialogEnvironmentWrapper>
          {isGettingHistory ? (
            <Flex alignItems="center" justifyContent="center" height="100%" width="100%" className="cliploader-wrapper">
              <ClipLoader size="50" />
            </Flex>
          ) : (
            <Flex flexDirection="column" height="100%" pb="m">
              <Scrollbars universal>
                <StyledTable>
                  <thead>
                    <Row>
                      <HeaderCell width="200px">Date</HeaderCell>
                      <HeaderCell>Description</HeaderCell>
                      <HeaderCell />
                    </Row>
                  </thead>
                  <tbody>
                    {history.map(({ created_at, description, notes, action }, index) => {
                      return (
                        <Row key={index}>
                          <BodyCell width="200px">
                            <Text>{created_at}</Text>
                          </BodyCell>
                          <BodyCell>
                            <Flex flexDirection="column">
                              <Text>{description}</Text>
                              {notes && (
                                <Fragment>
                                  {description ? (
                                    <Text mt="s" fontSize="s" color="secondaryText">
                                      “{notes}”
                                    </Text>
                                  ) : (
                                    <Text fontSize="s">{notes}</Text>
                                  )}
                                </Fragment>
                              )}
                            </Flex>
                          </BodyCell>
                          <BodyCell>
                            {action && (
                              <StyledActionWrapper
                                onClick={() => {
                                  handleClickAction({ action })
                                }}
                              >
                                <Icon.OpenInNew fill="secondaryText" width="12px" height="12px" />
                              </StyledActionWrapper>
                            )}
                          </BodyCell>
                        </Row>
                      )
                    })}
                  </tbody>
                </StyledTable>
              </Scrollbars>
            </Flex>
          )}
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        {workflowViewModalOptions.isOpen && (
          <ApprovalWorkflowViewModal
            handleDismiss={handleClickCloseApprovalWorkflowViewModal}
            isOpen={workflowViewModalOptions.isOpen}
            data={workflowViewModalOptions.data}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}
HistoryPostModal.defaultProps = {}

HistoryPostModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClickCloseModal: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
}

HistoryPostModal.displayName = 'HistoryPostModal'

export default HistoryPostModal

import React, { createContext, useContext, useReducer } from 'react'
// eslint-disable-next-line import/no-cycle
import {
  generateSyncMediaKeys,
  syncComposerMediasWithCustomizationMedias,
  handleUpdateCustomizationBulk,
} from '../helpers'

const PostCreationContext = createContext(null)
const PostCreationDispatchContext = createContext(null)

const collectedSyncMediaFunction = ({ postInStore, action }) => {
  if (!action.specialPostKey) {
    if (action.network && action.profileId) {
      handleUpdateCustomizationBulk({
        post: postInStore,
        selectedProfiles: action.data.selectedProfiles,
        network: action.network,
        profileId: action.profileId,
      })
    } else if (action.data.selectedProfiles) {
      generateSyncMediaKeys({ selectedProfiles: action.data.selectedProfiles, post: postInStore })
    }
  }
}

const setPostAsChanged = ({ postInStore }) => {
  postInStore.hasBeenChanged = true
}

const mediaProcessingIntervalsReducer = (mediaProcessingIntervals, action) => {
  switch (action.type) {
    case 'add_interval': {
      if (!mediaProcessingIntervals[action.id]) {
        mediaProcessingIntervals[action.id] = { interval: action.data.interval, counter: action.data.counter }

        action.data.interval_func()

        return { ...mediaProcessingIntervals }
      }

      return mediaProcessingIntervals
    }
    case 'change_interval': {
      if (mediaProcessingIntervals[action.id]) {
        mediaProcessingIntervals[action.id] = { ...mediaProcessingIntervals[action.id], ...action.data }
        return { ...mediaProcessingIntervals }
      }

      return mediaProcessingIntervals
    }
    case 'remove_interval': {
      if (mediaProcessingIntervals[action.id] && mediaProcessingIntervals[action.id].interval) {
        clearInterval(mediaProcessingIntervals[action.id].interval)
        return { ...mediaProcessingIntervals }
      }

      return mediaProcessingIntervals
    }
    case 'remove': {
      if (mediaProcessingIntervals[action.id]) {
        if (mediaProcessingIntervals[action.id].interval) {
          clearInterval(mediaProcessingIntervals[action.id].interval)
        }
        delete mediaProcessingIntervals[action.id]
        return { ...mediaProcessingIntervals }
      }

      return mediaProcessingIntervals
    }
    case 'remove_all': {
      for (const media_gid in mediaProcessingIntervals) {
        if (mediaProcessingIntervals.hasOwnProperty(media_gid)) {
          if (mediaProcessingIntervals[media_gid].interval) {
            clearInterval(mediaProcessingIntervals[media_gid].interval)
          }
          delete mediaProcessingIntervals[media_gid]
        }
      }

      return { ...mediaProcessingIntervals }
    }
    default: {
      return mediaProcessingIntervals
    }
  }
}

const addCustomizationsKeys = ({ postInStore, action }) => {
  if (!postInStore.customizations) {
    postInStore.customizations = {}
  }

  if (!postInStore.customizations[action.network]) {
    postInStore.customizations[action.network] = {}
  }

  if (!postInStore.customizations[action.network][action.profileId]) {
    postInStore.customizations[action.network][action.profileId] = {}
  }

  return postInStore.customizations[action.network][action.profileId]
}

const updateMediasWithSameData = ({ media_gids, medias, data }) => {
  media_gids.forEach((media_gid) => {
    medias.forEach((media, index) => {
      const { id } = media
      if (media_gid === id) {
        medias[index] = { ...media, ...data }
      }
    })
  })
}

const getPostInStore = ({ postInStore, action }) => {
  let postInStoreTemp = postInStore

  const { specialPostKey, network, profileId } = action

  if (network && profileId && specialPostKey && specialPostKey.includes('comments_')) {
    const { customizations } = postInStoreTemp
    const { [network]: networkCustomizations } = customizations || {}
    const { [profileId]: profileCustomizations } = networkCustomizations || {}

    const { comments = [] } = profileCustomizations || {}

    const commentId = specialPostKey.split('_')[1]

    const foundComment = comments.find(({ id }) => id === commentId)

    if (foundComment) {
      postInStoreTemp = foundComment
    } else {
      postInStoreTemp = null
    }
  }

  return postInStoreTemp
}

const postReducer = (postInStore, action) => {
  // console.log('action', action)
  const { specialPostKey } = action

  switch (action.type) {
    case 'post':
      return { ...action.data }
    case 'post_component':
      // eslint-disable-next-line no-case-declarations
      let postInStorePostComponent = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStorePostComponent = addCustomizationsKeys({ postInStore, action })
      }

      if (postInStorePostComponent) {
        postInStorePostComponent.postComponent = action.data.postComponent

        collectedSyncMediaFunction({ postInStore, action })

        setPostAsChanged({ postInStore })
      }

      return { ...postInStore }
    case 'text':
      if ((postInStore.postText || '') !== action.data.text) {
        setPostAsChanged({ postInStore })
      }

      postInStore.postText = action.data.text

      return { ...postInStore }
    case 'link':
      postInStore.link = action.data.link

      setPostAsChanged({ postInStore })

      return { ...postInStore }
    case 'customizations':
      postInStore.customizations = action.data.customizations

      collectedSyncMediaFunction({ postInStore, action })

      if (!action.data.isDefault) {
        setPostAsChanged({ postInStore })
      }

      return { ...postInStore }
    case 'profile_customizations':
      addCustomizationsKeys({ postInStore, action })

      postInStore.customizations[action.network][action.profileId] = action.data.customizations

      // setPostAsChanged({ postInStore })  // removing changed post saving from profile_customizations changes

      return { ...postInStore }
    case 'remove_temporary_media':
      // eslint-disable-next-line no-case-declarations
      let postInStoreRemoveTemporaryMedia = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStoreRemoveTemporaryMedia = addCustomizationsKeys({ postInStore, action })
      }

      // eslint-disable-next-line no-case-declarations
      const foundPostMediaForRemoveIndex = postInStoreRemoveTemporaryMedia[action.data.post_media_key].findIndex(
        (media) => (media.tempId || media.uploadingId) === action.data.media_temp_id
      )

      if (foundPostMediaForRemoveIndex > -1) {
        postInStoreRemoveTemporaryMedia[action.data.post_media_key].splice(foundPostMediaForRemoveIndex, 1)
      }

      collectedSyncMediaFunction({ postInStore, action })

      return { ...postInStore }
    case 'update_temporary_media':
      // eslint-disable-next-line no-case-declarations
      let postInStoreUpdateTemporaryMedia = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStoreUpdateTemporaryMedia = addCustomizationsKeys({ postInStore, action })
      }

      if (!postInStoreUpdateTemporaryMedia[action.data.post_media_key]) {
        postInStoreUpdateTemporaryMedia[action.data.post_media_key] = []
      }

      // eslint-disable-next-line no-case-declarations
      const foundPostMediaForUpdateIndex = postInStoreUpdateTemporaryMedia[action.data.post_media_key].findIndex(
        (media) => (media.tempId || media.uploadingId) === action.data.media_temp_id
      )

      if (foundPostMediaForUpdateIndex > -1) {
        postInStoreUpdateTemporaryMedia[action.data.post_media_key][foundPostMediaForUpdateIndex] = action.data.media
      }

      collectedSyncMediaFunction({ postInStore, action })

      setPostAsChanged({ postInStore })

      return { ...postInStore }
    case 'add_media':
      // eslint-disable-next-line no-case-declarations
      let postInStoreAddMedia = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStoreAddMedia = addCustomizationsKeys({ postInStore, action })
      }

      if (!postInStoreAddMedia[action.data.post_media_key]) {
        postInStoreAddMedia[action.data.post_media_key] = []

        // this is a workaround when user uploads a media in customizations when a media in the composer exists.

        // here is also a way (BUG) to pass this check. Central composer has an image and a video, central postComponent is POST_VIDEO,
        // in customizations we switch to postComponent = POST_IMAGE and upload an image. Customizations medias preview would not include the central image.
        // To fix this issue, we must create sync_media_keys for each type of postComponent, postImages_123_456 <= 2 images and check the sync below
        // it's a rare situation, and the media can be again fetched from media library
        if (
          !specialPostKey &&
          action.network &&
          action.profileId &&
          postInStore[action.data.post_media_key] &&
          (postInStore.postComponent === postInStoreAddMedia.postComponent ||
            typeof postInStoreAddMedia.postComponent === 'undefined') &&
          postInStore.sync_media_keys === postInStoreAddMedia.sync_media_keys
        ) {
          postInStoreAddMedia[action.data.post_media_key] = [...postInStore[action.data.post_media_key]]
        }
      }

      postInStoreAddMedia[action.data.post_media_key] = [
        ...postInStoreAddMedia[action.data.post_media_key],
        ...action.data.medias,
      ]

      collectedSyncMediaFunction({ postInStore, action })

      setPostAsChanged({ postInStore })

      return { ...postInStore }
    case 'rewrite_medias':
      // eslint-disable-next-line no-case-declarations
      let postInStoreRewriteMedias = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStoreRewriteMedias = addCustomizationsKeys({ postInStore, action })
      }

      postInStoreRewriteMedias[action.data.post_media_key] = action.data.medias

      collectedSyncMediaFunction({ postInStore, action })

      setPostAsChanged({ postInStore })

      return { ...postInStore }
    case 'update_existing_media':
      // eslint-disable-next-line no-case-declarations
      let postInStoreUpdateExistingMedias = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStoreUpdateExistingMedias = addCustomizationsKeys({ postInStore, action })
      }

      if (!postInStoreUpdateExistingMedias[action.data.post_media_key]) {
        postInStoreUpdateExistingMedias[action.data.post_media_key] = [
          ...(postInStore[action.data.post_media_key] || []),
        ]
      }

      // eslint-disable-next-line no-case-declarations
      const foundPostExistingMediaUpdateIndex = postInStoreUpdateExistingMedias[action.data.post_media_key].findIndex(
        ({ media_gid }) => media_gid === action.data.media.media_gid
      )

      if (foundPostExistingMediaUpdateIndex > -1) {
        postInStoreUpdateExistingMedias[action.data.post_media_key][foundPostExistingMediaUpdateIndex] =
          action.data.media

        if (!specialPostKey) {
          syncComposerMediasWithCustomizationMedias({
            postInStore,
            selectedProfiles: action.data.selectedProfiles,
            post_media_key: action.data.post_media_key,
            media: action.data.media,
          })
        }
      }

      collectedSyncMediaFunction({ postInStore, action })

      setPostAsChanged({ postInStore })

      return { ...postInStore }
    case 'remove_existing_media':
      collectedSyncMediaFunction({ postInStore, action })

      // eslint-disable-next-line no-case-declarations
      let postInStoreRemoveExistingMedias = getPostInStore({ postInStore, action })

      if (action.network && action.profileId && !specialPostKey) {
        postInStoreRemoveExistingMedias = addCustomizationsKeys({ postInStore, action })
      }

      if (!postInStoreRemoveExistingMedias[action.data.post_media_key]) {
        postInStoreRemoveExistingMedias[action.data.post_media_key] = [
          ...(postInStore[action.data.post_media_key] || []),
        ]
      }

      // eslint-disable-next-line no-case-declarations
      const foundPostExistingMediaRemoveIndex = postInStoreRemoveExistingMedias[action.data.post_media_key].findIndex(
        ({ media_gid }) => media_gid === action.data.media.media_gid
      )

      if (foundPostExistingMediaRemoveIndex > -1) {
        postInStoreRemoveExistingMedias[action.data.post_media_key].splice(foundPostExistingMediaRemoveIndex, 1)
      }

      collectedSyncMediaFunction({ postInStore, action })

      setPostAsChanged({ postInStore })

      return { ...postInStore }

    case 'processed_urls':
      action.data.medias.forEach(({ media_gid, s3_urls }) => {
        if (postInStore.postVideos) {
          postInStore.postVideos.forEach((media) => {
            if (media.media_gid === media_gid) {
              media.s3_urls = s3_urls
            }
          })
        }

        if (postInStore.customizations) {
          for (const network in postInStore.customizations) {
            if (postInStore.customizations.hasOwnProperty(network) && postInStore.customizations[network]) {
              for (const profile_gid in postInStore.customizations[network]) {
                if (
                  postInStore.customizations[network].hasOwnProperty(profile_gid) &&
                  postInStore.customizations[network][profile_gid]
                ) {
                  if (postInStore.customizations[network][profile_gid].postVideos) {
                    postInStore.customizations[network][profile_gid].postVideos.forEach((media) => {
                      if (media.media_gid === media_gid) {
                        media.s3_urls = s3_urls
                      }
                    })
                  }
                }
              }
            }
          }
        }
      })

      return { ...postInStore }

    case 'update_medias_with_same_data':
      if (postInStore[action.data.post_media_key] && postInStore[action.data.post_media_key].length !== 0) {
        updateMediasWithSameData({
          media_gids: action.data.media_gids,
          medias: postInStore[action.data.post_media_key],
          data: action.data.media_data,
        })
      }

      action.data.selectedProfiles.forEach((profile) => {
        const { id, code } = profile

        const { customizations } = postInStore
        const { [code]: networkCustomizations } = customizations || {}
        const { [id]: profileCustomizations } = networkCustomizations || {}

        if (
          profileCustomizations &&
          profileCustomizations[action.data.post_media_key] &&
          profileCustomizations[action.data.post_media_key].length !== 0
        ) {
          updateMediasWithSameData({
            media_gids: action.data.media_gids,
            medias: profileCustomizations[action.data.post_media_key],
            data: action.data.media_data,
          })
        }
      })

      return { ...postInStore }

    case 'labels':
      postInStore.labels = action.data.labels

      setPostAsChanged({ postInStore })

      return { ...postInStore }
    case 'agency_mentions':
      postInStore.agencyMentions = action.data.agencyMentions
      return { ...postInStore }
    case 'agency_mentions_skipped':
      postInStore.agencyMentionsSkipped = action.data.agencyMentionsSkipped
      return { ...postInStore }
    case 'profile_variables':
      postInStore.profile_variables = action.data.profileVariables
      return { ...postInStore }
    case 'boost_options':
      postInStore.boost_options = action.data.boost_options
      return { ...postInStore }
    case 'advocacy':
      postInStore.advocacy = action.data.advocacy

      //  setPostAsChanged({ postInStore }) // removing changed post saving from advocacy changes

      return { ...postInStore }
    case 'using_ai':
      postInStore.using_ai = action.data.using_ai
      return { ...postInStore }
    default:
      return { ...postInStore }
  }
}

// eslint-disable-next-line react/prop-types
export const PostCreationProvider = ({ children }) => {
  const [mediaProcessingIntervals, mediaProcessingIntervalsDispatch] = useReducer(mediaProcessingIntervalsReducer, {})
  const [post, postDispatch] = useReducer(postReducer, {})

  return (
    <PostCreationContext.Provider value={{ mediaProcessingIntervals, post }}>
      <PostCreationDispatchContext.Provider value={{ mediaProcessingIntervalsDispatch, postDispatch }}>
        {children}
      </PostCreationDispatchContext.Provider>
    </PostCreationContext.Provider>
  )
}

export function usePostCreationContext() {
  return useContext(PostCreationContext)
}

export function usePostCreationDispatch() {
  return useContext(PostCreationDispatchContext)
}

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'
import { transparentize } from 'polished'
import { space, radius } from 'theme'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import { PUBLISH_AS_STORY, PUBLISH_AS_CAROUSEL } from 'routes/Calendar/consts'

const DropdownMenuWrapper = styled(Flex)`
  z-index: 10;
  position: absolute; 
  top: ${({ top }) => (top === 'unset' ? 'unset' : top)}; 
  flex-direction: column;
  ${({ right }) =>
    right &&
    `
      right: ${right};
  `}
  ${({ left }) =>
    left &&
    `
      left: ${left};
  `}
    ${({ bottom }) =>
      bottom &&
      `
      bottom: ${bottom};
  `}
  width: fit-content;
  padding: ${space.xs} 0;
  box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)}; 
  background-color: ${({ theme }) => theme.colors.dropdown_component_background};
  border: 1px solid ${({ theme }) => theme.colors.border_color};  
  border-radius: ${radius.l};
  `

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -12px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledText = styled(Text)`
  max-width: ${({ labelMaxWidth }) => labelMaxWidth || '150px'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

class InstagramTagDropdown extends React.Component {
  render() {
    const {
      mousePositionClick,
      setMousePositionClick,
      handleClickAddProductTag,
      handleClickAddUserTag,
      handleClickAddCollaboratorTags,
      isBlocked,
      media_type,
    } = this.props

    let blockedAddMediaOptions = {}

    if (isBlocked) {
      blockedAddMediaOptions = {
        iconNameEnding: 'Lock',
        iconWidthEnding: '14px',
        iconColorEnding: 'error',
      }
    }

    return (
      <DropdownMenuWrapper
        display={
          mousePositionClick.mouseClickPositionX !== -1 && mousePositionClick.mouseClickPositionY !== -1
            ? 'block'
            : 'none'
        }
        top={`${mousePositionClick.mouseClickPositionY}px`}
        left={`${mousePositionClick.mouseClickPositionX}px`}
      >
        <Flex position="relative">
          <CloseIconWrapper
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setMousePositionClick({ mouseClickPositionX: -1, mouseClickPositionY: -1 })
            }}
          >
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </Flex>
        <DropdownMenu.Item
          labelMaxWidth="160px"
          LabelComp={
            <Flex flexDirection="column" width="130px">
              <StyledText fontSize="xs">Tag a user</StyledText>
            </Flex>
          }
          onClick={() => {
            if (media_type !== PUBLISH_AS_STORY) {
              handleClickAddUserTag()
            } else {
              Alert.error(`Tagging users is not available for stories.`)
            }
          }}
          {...blockedAddMediaOptions}
        />
        <DropdownMenu.Item
          labelMaxWidth="160px"
          LabelComp={
            <Flex flexDirection="column" width="130px">
              <StyledText fontSize="xs">Tag a product</StyledText>
            </Flex>
          }
          onClick={() => {
            if (media_type !== PUBLISH_AS_STORY) {
              handleClickAddProductTag()
            } else {
              Alert.error(`Tagging products is not available for stories.`)
            }
          }}
          {...blockedAddMediaOptions}
        />
        <DropdownMenu.Item
          label="Invite collaborators"
          labelMaxWidth="160px"
          LabelComp={
            <Flex flexDirection="column" width="130px">
              <StyledText fontSize="xs">Invite collaborators</StyledText>
            </Flex>
          }
          onClick={() => {
            if (media_type !== PUBLISH_AS_STORY) {
              handleClickAddCollaboratorTags()
            } else {
              Alert.error(`Inviting collaborators is not available for stories.`)
            }
          }}
          {...blockedAddMediaOptions}
        />
      </DropdownMenuWrapper>
    )
  }
}

InstagramTagDropdown.defaultProps = {
  media_type: '',
}

InstagramTagDropdown.propTypes = {
  mousePositionClick: PropTypes.object.isRequired,
  handleClickAddProductTag: PropTypes.func.isRequired,
  handleClickAddUserTag: PropTypes.func.isRequired,
  handleClickAddCollaboratorTags: PropTypes.func.isRequired,
  setMousePositionClick: PropTypes.func.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  media_type: PropTypes.string,
}

export default InstagramTagDropdown

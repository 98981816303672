import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { ERROR_MESSAGE, IMAGE, MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_TEXT } from 'consts'
import { convertToBase64String, countBase64ImageSize } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { IMAGE_EXTENSIONS, POST_IMAGE, ROUTE_MEDIA } from '../consts'
import ImageEditModal from './ImageEditModal'
import MediaLibraryModal from './MediaLibraryModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 80vw;
    height: 95vh;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogTitleWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  box-shadow: 0 2px 3px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l} ${radius.l} 0 0;
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const StyledDialogFooterWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  box-shadow: 0 2px 3px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: 0 0 ${radius.l} ${radius.l};
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const ThumbnailImagesWrapper = styled(Flex)`
  overflow-x: hidden;
`

const ThumbnailImageWrapper = styled(Flex)`
  border-radius: ${radius.l};
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  text-align: center;
  height: 108px;
  width: 100px;
  min-width: 100px;
  transition-property: box-shadow;
  transition-duration: 0.25s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 10px 5px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    border: 1px solid ${theme.colors.primary};
  `}
`

const ThumbnailPreviewWrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  align-self: center;
  min-height: 160px;
  max-height: 160px;
  width: 218px;
  box-shadow: 0 0 20px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  border-radius: ${radius.l};
  margin-top: ${space.m};
  margin-bottom: ${space.s};
  padding: ${space.m} ${space.m} ${space.s};
`

const StyledEditImageIconWrapper = styled(Button.Gray)`
  position: absolute;
  top: ${space.s};
  right: ${space.s};
  width: 32px;
  height: 32px;
  min-width: 32px;
`

const ThumbnailPreviewImage = styled(Image)`
  max-width: 100%;
  max-height: 110px;
  object-fit: contain;
  border-radius: ${radius.s};
`

const StyledVideo = styled.video`
  background-color: ${COLOR_CONSTANTS.BLACK};
  min-height: 250px;
  margin-bottom: ${space.m};
  border-radius: ${radius.s};
  height: calc(100% - 196px);
  width: 100%;
  position: absolute;
`

const MAX_THUMBNAILS = 10
const INTERVAL = 1

const videoInformation = {
  videoObjectUrl: '',
  videoId: '',
}

const MEDIA_LIBRARY_MODAL_OPTIONS = {
  isOpen: false,
  postComponent: null,
}

const PREVIEW_ID = 'thumbnail-preview-image'

const VideoThumbnailModal = ({ user, isOpen, handleDismiss, isThumbnailUpdating, handleUpdate, ...props }) => {
  const thumbnailFile = useRef(null)
  const videoPlayerRef = useRef(null)

  const [selectedVideo, setSelectedVideo] = useState({})
  const [thumbnails, setThumbnails] = useState([])
  const [selectedThumbnail, setSelectedThumbnail] = useState(null)
  const [isChoosingFrameFromVideo, setIsChoosingFrameFromVideo] = useState(false)
  const [dimensionError, setDimensionsError] = useState('')
  const [isOpenImageEditModal, setIsOpenImageEditModal] = useState(false)
  const [mediaLibraryOptions, setMediaLibraryOptions] = useState({ ...MEDIA_LIBRARY_MODAL_OPTIONS })

  const [offset, setOffset] = useState(0)

  const { url, id, thumbnail_url = '', thumb_offset, width, height } = selectedVideo
  const videoThumbnailUrl = thumbnail_url || ''

  useEffect(() => {
    if (isOpen) {
      setSelectedVideo({ ...props.selectedVideo })
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedThumbnail && selectedThumbnail.time === -1) {
      if (width && height) {
        const thumbMedia = document.getElementById(PREVIEW_ID)

        if (thumbMedia) {
          const { naturalWidth, naturalHeight } = thumbMedia

          if (naturalWidth !== width && naturalHeight !== height) {
            setDimensionsError(
              `Custom thumbnail size ${
                naturalWidth && naturalHeight ? `${naturalWidth} x ${naturalHeight} ` : ''
              }must match the size of the video ${width} x ${height}.`
            )
          } else {
            setDimensionsError('')
          }
        }
      }
    } else if (dimensionError) {
      setDimensionsError('')
    }
  }, [selectedThumbnail])

  const getFrames = async () => {
    try {
      const response = await request({
        path: `${ROUTE_MEDIA}/${id}/thumbnail_generate`,
        method: 'POST',
        body: { offset },
      })

      const { error, images, offset: response_offset } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        const updatedThumbnails = []

        if (images) {
          for (const item of images) {
            const thumbnail = {
              time: Number(item.time / 1000),
              thumb: `data:image/png;base64,${item.image}`,
            }
            updatedThumbnails.push(thumbnail)
          }
        }

        setThumbnails([...updatedThumbnails])

        setOffset(response_offset)
      }
    } catch (error) {
      errorHelper({ error, componentName: VideoThumbnailModal.displayName, functionName: 'getFrames' })
    }
  }

  useEffect(() => {
    setSelectedThumbnail(null)

    if (id) {
      const updatedThumbnails = []
      let currentTime = 0
      let index = 0
      while (currentTime < MAX_THUMBNAILS) {
        updatedThumbnails[index] = { time: currentTime, tempId: new Date().getTime() }
        currentTime += INTERVAL
        index++
      }
      setThumbnails([...updatedThumbnails])

      getFrames({})
    } else {
      setThumbnails([])
    }
  }, [selectedVideo.id])

  const handleClickSaveThumbnail = async () => {
    const data = { id }

    if (selectedThumbnail) {
      if (selectedThumbnail.time === -1) {
        data.base64url = selectedThumbnail.url
        data.thumb_offset = -1
      } else {
        data.base64url = selectedThumbnail.thumb
        data.thumb_offset = Number(selectedThumbnail.time.toFixed(3)) * 1000
      }

      let error = ''
      if (data.base64url && countBase64ImageSize({ image: data.base64url }) > MAX_IMAGE_SIZE) {
        error = `The size of the thumbnail must be less than ${MAX_IMAGE_SIZE_TEXT}!`
      }

      if (dimensionError) {
        error = 'Please correct all errors before continuing.'
      } else {
        setDimensionsError('')
      }

      if (error) {
        Alert.error(error, { timeout: 5000 })
      } else {
        handleUpdate(data)
      }
    } else {
      // eslint-disable-next-line no-use-before-define
      handleClickCloseModal()
    }
  }

  const handleThumbnailImageFileChange = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      setDimensionsError('')
      let extensionError = false
      let sizeError = false

      const file = event.currentTarget.files[0]
      const { name, size } = file
      let base64url
      if (IMAGE_EXTENSIONS.includes(name.substring(name.lastIndexOf('.')))) {
        if (size <= MAX_IMAGE_SIZE) {
          base64url = await convertToBase64String(file)
        } else {
          sizeError = true
        }
      } else {
        extensionError = true
      }

      if (extensionError) {
        Alert.error(`Only ${IMAGE_EXTENSIONS.join(', ')} files allowed!`)
      } else if (sizeError) {
        Alert.error(`The size of the image must be less than ${MAX_IMAGE_SIZE_TEXT}!`)
      } else {
        setSelectedThumbnail({ ...{ url: base64url, time: -1 } })
      }
    }
  }

  const handleClickThumbnail = ({ time, thumb }) => {
    if (time > -1 && videoPlayerRef) {
      videoPlayerRef.current.currentTime = time
    }
    setSelectedThumbnail({ time, thumb })
    setDimensionsError('')
  }

  const handleClickSelectFrame = async () => {
    if (videoPlayerRef.current) {
      try {
        setIsChoosingFrameFromVideo(true)

        const response = await request({
          path: `${ROUTE_MEDIA}/${id}/thumbnail_generate`,
          method: 'POST',
          body: { offset: videoPlayerRef.current.currentTime * 1000, count: 1 },
        })

        const { error, images } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (images && images[0]) {
          const { image, time } = images[0]

          setSelectedThumbnail({ time: Number(time / 1000), thumb: `data:image/png;base64,${image}` })
        }
      } catch (error) {
        errorHelper({ error, componentName: VideoThumbnailModal.displayName, functionName: 'getFrames' })
      } finally {
        setIsChoosingFrameFromVideo(false)
        setDimensionsError('')
      }
    }
  }

  const handleClickOpenImageEditModal = () => {
    setIsOpenImageEditModal(true)
  }

  const handleClickCloseImageEditModal = () => {
    setIsOpenImageEditModal(false)
  }

  const handleUpdateEditedThumbnail = ({ data: { base64url } }) => {
    setSelectedThumbnail({ ...{ url: base64url, time: -1 } })
    handleClickCloseImageEditModal()
  }

  const handleClickOpenMediaLibraryModal = () => {
    setMediaLibraryOptions({ ...{ isOpen: true, postComponent: POST_IMAGE } })
  }

  const handleClickCloseMediaLibraryModal = () => {
    setMediaLibraryOptions({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
  }

  const handleClickThumbnailFromMediaLibrary = ({ selectedMedias }) => {
    if (selectedMedias.length !== 0) {
      const { 0: { id, url } = {} } = selectedMedias

      const xhr = new XMLHttpRequest()

      xhr.onload = () => {
        const reader = new FileReader()
        reader.onloadend = () => {
          setSelectedThumbnail({ ...{ url: reader.result, time: -1, id } })

          handleClickCloseMediaLibraryModal()
        }
        reader.readAsDataURL(xhr.response)
      }

      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.send()
    }
  }

  const handleClickCloseModal = () => {
    if (!isThumbnailUpdating && !isChoosingFrameFromVideo) {
      videoInformation.videoId = null
      videoInformation.videoObjectUrl = ''
      handleDismiss()
    } else {
      Alert.error('Please wait a bit longer while thumbnails are being generated.', { timeout: 5000 })
    }
  }

  // eslint-disable-next-line prefer-const
  let { url: selectedThumbnailUrl, thumb: selectedThumbUrl } = selectedThumbnail || {}

  selectedThumbnailUrl = selectedThumbUrl || selectedThumbnailUrl

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Flex m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogTitleWrapper px="m" flexDirection="column">
            <H4 my="m">Thumbnail picker</H4>
          </StyledDialogTitleWrapper>

          <StyledDialogBodyWrapper p="m" flex="1">
            <Flex minWidth="220px" width="220px" height="100%" flexDirection="column" mr="l">
              <Flex flexDirection="column" pr="m">
                <Text fontSize="m">Custom thumbnail</Text>
                <Text color="secondaryText">
                  Upload your own thumbnail or select one from the video with the "Choose frame from video" button.
                </Text>
              </Flex>

              <ThumbnailPreviewWrapper
                flex="1"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                position="relative"
              >
                {(selectedThumbnailUrl || videoThumbnailUrl) && (
                  <Fragment>
                    <StyledEditImageIconWrapper onClick={handleClickOpenImageEditModal}>
                      <Icon.Edit width="16px" height="16px" />
                    </StyledEditImageIconWrapper>
                    <Flex width="100%" alignItems="center" justifyContent="center" height="110px">
                      <ThumbnailPreviewImage
                        src={selectedThumbnailUrl || videoThumbnailUrl}
                        id={PREVIEW_ID}
                        onLoad={() => {
                          setSelectedThumbnail({ ...selectedThumbnail, imageLoaded: new Date().getTime() })
                        }}
                      />
                    </Flex>
                    <Text color="primary" fontWeight="bold" mt="xs">
                      Selected thumbnail
                    </Text>
                  </Fragment>
                )}
              </ThumbnailPreviewWrapper>
              {dimensionError && (
                <Text color="error" mt="s">
                  {dimensionError}
                </Text>
              )}
              <Flex flexDirection="column" mt="m">
                <Button.Gray
                  onClick={() => {
                    thumbnailFile.current.click()
                  }}
                >
                  <Flex alignItems="center">
                    <Image src="/assets/publish_gray.svg" width="16px" height="16px" />
                    <Text fontSize="s" ml="s" color="secondaryText" fontWeight="bold">
                      Upload your own
                    </Text>
                  </Flex>
                </Button.Gray>
                <Text color="secondaryText" my="s">
                  or
                </Text>

                <Button.Gray onClick={handleClickOpenMediaLibraryModal}>
                  <Flex alignItems="center">
                    <Icon.VistaSocialLandscape width="16px" height="16px" stroke="icon_color_gray" />
                    <Text fontSize="s" ml="s" color="secondaryText" fontWeight="bold">
                      Select from Library
                    </Text>
                  </Flex>
                </Button.Gray>

                <Text color="secondaryText" my="s">
                  or
                </Text>
                <ButtonWithLoading
                  buttonComp={Button.Gray}
                  onClick={handleClickSelectFrame}
                  isLoading={isChoosingFrameFromVideo}
                  loaderColor={COLOR_CONSTANTS.SAPPHIRE}
                >
                  <Text fontSize="s" color="secondaryText" fontWeight="bold">
                    {isChoosingFrameFromVideo ? 'Selecting frame' : 'Choose frame from video'}
                  </Text>
                </ButtonWithLoading>
                <Text mt="s" color="secondaryText">
                  Scroll through the video and then click the button to capture the selection.
                </Text>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              m="0 auto"
              width="100%"
              maxWidth="100%"
              height="100%"
              minHeight="450px"
              flex="1"
            >
              <Flex flex="1" maxHeight="100%" position="relative" width="100%">
                <StyledVideo
                  id="uploadedVideoPlayer"
                  src={url}
                  controls
                  controlsList="nodownload nofullscreen"
                  ref={videoPlayerRef}
                  poster={videoThumbnailUrl}
                />

                <Flex
                  position="absolute"
                  flexDirection="column"
                  height="auto"
                  maxWidth="100%"
                  width="100%"
                  minHeight="180px"
                  bottom="0"
                >
                  <Fragment>
                    <Text fontSize="m">Custom thumbnail</Text>
                    <ThumbnailImagesWrapper py="m" height="156px">
                      <Scrollbars universal>
                        <Flex>
                          {thumbnails.map(({ time, tempId, thumb }, index) => {
                            let isActive = false

                            if (selectedThumbnail) {
                              if (selectedThumbnail.time === time) {
                                isActive = true
                              }
                            } else if (time === thumb_offset) {
                              isActive = true
                            }

                            return (
                              <ThumbnailImageWrapper
                                key={time}
                                mr="m"
                                flexDirection="column"
                                justifyContent="space-around"
                                alignItems="center"
                                isActive={isActive}
                                onClick={() => {
                                  if (!tempId) {
                                    handleClickThumbnail({ time, thumb })
                                  }
                                }}
                              >
                                {tempId ? (
                                  <Flex
                                    alignItems="center"
                                    justifyContent="center"
                                    width="80px"
                                    height="80px"
                                    className="cliploader-wrapper"
                                  >
                                    <ClipLoader size="50" />
                                  </Flex>
                                ) : (
                                  <Image source={thumb} width="80px" height="80px" />
                                )}
                                <Text color="primary" fontWeight={isActive ? 'bold' : 'normal'}>
                                  {index + 1}/{thumbnails.length}
                                </Text>
                              </ThumbnailImageWrapper>
                            )
                          })}
                        </Flex>
                      </Scrollbars>
                    </ThumbnailImagesWrapper>
                  </Fragment>
                </Flex>
              </Flex>
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogFooterWrapper p="m" justifyContent="flex-end">
            <Button.Gray
              mr="m"
              isSmall
              onClick={handleClickCloseModal}
              isDisabled={isThumbnailUpdating || isChoosingFrameFromVideo}
            >
              Cancel
            </Button.Gray>
            <ButtonWithLoading onClick={handleClickSaveThumbnail} isSmall isLoading={isThumbnailUpdating}>
              <Text fontWeight="medium">Save</Text>
            </ButtonWithLoading>
          </StyledDialogFooterWrapper>

          <CloseIconWrapper
            className="modal-close-icon"
            onClick={handleClickCloseModal}
            isDisabled={isThumbnailUpdating || isChoosingFrameFromVideo}
          >
            <ImageWithFallback
              width="10px"
              height="10px"
              source="/assets/clear.svg"
              fallbackSource="/assets/clear.svg"
            />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Flex>

      {isOpenImageEditModal && (
        <ImageEditModal
          handleDismiss={handleClickCloseImageEditModal}
          handleUpdate={handleUpdateEditedThumbnail}
          isOpen={isOpenImageEditModal}
          selectedImage={{ url: selectedThumbnailUrl || videoThumbnailUrl, id, size: 0 }} // size is from the db size
          // selectedImage={selectedThumbnailUrl}
          isImageUpdating={false}
          minWidth={width || 0}
          minHeight={height || 0}
        />
      )}

      {mediaLibraryOptions.isOpen && (
        <MediaLibraryModal
          user={user}
          handleDismiss={handleClickCloseMediaLibraryModal}
          isOpen={mediaLibraryOptions.isOpen}
          type={mediaLibraryOptions.postComponent}
          handleClickAddMedias={handleClickThumbnailFromMediaLibrary}
          postMedias={
            selectedThumbnail && selectedThumbnail.id
              ? [{ id: selectedThumbnail.id, url: selectedThumbnail.url, type: IMAGE }]
              : []
          }
          maxImageAttachmentsAllowed={1}
        />
      )}

      <input
        hidden
        type="file"
        accept={IMAGE_EXTENSIONS.join(',')}
        onChange={(e) => handleThumbnailImageFileChange(e)}
        ref={thumbnailFile}
        onClick={(event) => {
          event.target.value = null
        }}
      />
    </StyledDialogOverlay>
  )
}

VideoThumbnailModal.displayName = 'VideoThumbnailModal'

VideoThumbnailModal.defaultProps = {
  selectedVideo: {},
}

VideoThumbnailModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  selectedVideo: PropTypes.object,
  isThumbnailUpdating: PropTypes.bool.isRequired,
}

export default VideoThumbnailModal

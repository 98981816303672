import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { transparentize } from 'polished'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-s-alert'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius } from 'theme'
import { IMAGE, VIDEO, AUDIO, ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import { H4, Text } from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { ROUTE_MEDIA } from '../consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 450px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledInput = styled(Input)`
  z-index: 2;
`

const URL = 'url'

const ValidationSchema = Yup.object().shape({
  [URL]: Yup.string()
    .required('URL is required.')
    .url('Must be a valid URL address.'),
})

const MEDIA_POLLING_INTERVAL = 3000
const MEDIA_POLLING_COUNTER_MAX = 100
let mediaGettingProgressInterval

const MediaUploadByLinkModal = ({ isOpen, handleDismiss, handleClickAddMedias, type, postMedias, hasError }) => {
  let searchType = 'media'
  let helperText = ''

  if (type.includes(IMAGE)) {
    searchType = IMAGE
  } else if (type.includes(VIDEO)) {
    searchType = VIDEO
  } else if (type.includes(AUDIO)) {
    searchType = AUDIO
    helperText = `This can be a link to an audio file, a link to an mp4/mov file that we will extract the sound from.`
  }

  const formRef = useRef(null)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [progress, setProgress] = useState(null)
  const [counter, setCounter] = useState(-1)
  const [isCheckingVideoProgress, setIsCheckingVideoProgress] = useState(false)

  useEffect(() => {
    if (hasError) {
      setError(hasError)
      setIsLoading(false)
    }
  }, [hasError])

  useEffect(() => {
    if (progress) {
      setCounter(counter + 1)

      const { status } = progress

      if (status === 'failed' || counter > MEDIA_POLLING_COUNTER_MAX) {
        // eslint-disable-next-line no-use-before-define
        clearProcessingInterval({ error: 'Failed to process uploaded video.' })
      } else if (status === 'done') {
        clearInterval(mediaGettingProgressInterval)

        handleClickAddMedias({
          selectedMedias: [...postMedias, progress.media],
          postComponent: type,
        })

        handleDismiss()
      }
    }
  }, [progress])

  const clearProcessingInterval = ({ error }) => {
    clearInterval(mediaGettingProgressInterval)
    setError(error || ERROR_MESSAGE)
    setIsCheckingVideoProgress(false)
  }

  const getMediaProgress = async ({ media_gid }) => {
    try {
      if (!isCheckingVideoProgress) {
        setIsCheckingVideoProgress(true)
      }

      const response = await request({
        method: 'POST',
        path: `${ROUTE_MEDIA}/progress`,
        body: { media_gids: [media_gid] },
      })

      const { error, data } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })

        clearProcessingInterval({ error })
      } else {
        const { percent, status, media } = data[0]

        let updated_percent = percent

        if (updated_percent === 100) {
          updated_percent = 95
        }

        setProgress({
          ...{
            ...progress,
            percent: updated_percent,
            status,
            media,
          },
        })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MediaUploadByLinkModal.displayName,
        functionName: 'getMediaProgress',
      })

      clearProcessingInterval({ error: ERROR_MESSAGE })
    }
  }

  const handleSubmitForm = async () => {
    formRef.current.handleSubmit()
  }

  const handleSubmitData = async (values) => {
    if (isLoading || isCheckingVideoProgress) {
      Alert.error('Please wait a bit longer while your media is uploaded.', { timeout: 5000 })
    } else {
      setError('')

      if (searchType === VIDEO) {
        try {
          setIsLoading(true)

          const tempId = new Date().getTime()

          const response = await request({
            method: 'POST',
            body: { mediaURL: values[URL], mediaType: searchType },
            path: `${ROUTE_MEDIA}?tempId=${tempId}`,
          })

          const { error, media_gid, status, progress: percent, media } = response || {}

          if (!response || error) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
            setError(error)
          } else {
            if (status === 'done') {
              handleClickAddMedias({
                selectedMedias: [...postMedias, media],
                postComponent: type,
              })

              handleDismiss()
            } else {
              setProgress({ media_gid, tempId, status, percent })

              setIsCheckingVideoProgress(true)

              mediaGettingProgressInterval = setInterval(() => {
                getMediaProgress({ media_gid })
              }, MEDIA_POLLING_INTERVAL)
            }
          }
        } catch (error) {
          errorHelper({
            error,
            componentName: MediaUploadByLinkModal.displayName,
            functionName: 'handleSubmitData',
          })
        } finally {
          setIsLoading(false)
        }
      } else {
        handleClickAddMedias({
          selectedMedias: [...postMedias, { [URL]: values[URL], tempId: new Date().getTime(), type: searchType }],
          postComponent: type,
        })

        handleDismiss()
      }
    }
  }

  const handleClickCloseModal = () => {
    if (isLoading || isCheckingVideoProgress) {
      Alert.error('Please wait a bit longer while your media is uploaded.', { timeout: 5000 })
    } else {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" $isTop>
            <H4 my="m">Upload {searchType} from URL</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex>
            <Formik
              ref={formRef}
              initialValues={{ [URL]: '' }}
              validationSchema={ValidationSchema}
              onSubmit={handleSubmitData}
            >
              {({ values, errors, touched, setFieldValue }) => (
                <Flex m="m" flexDirection="column" width="100%">
                  {helperText && <Text mb="m">{helperText}</Text>}
                  <StyledInput
                    value={values[URL]}
                    onChange={({ target: { value } }) => {
                      if (error) {
                        setError('')
                      }
                      setFieldValue(URL, value)
                    }}
                    label=""
                    error={(errors[URL] && touched[URL] && errors[URL]) || error}
                    placeholder={`Please enter ${searchType} URL`}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        handleSubmitForm()
                      }
                    }}
                  />
                </Flex>
              )}
            </Formik>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </Button.Gray>

            {isCheckingVideoProgress ? (
              <Flex alignItems="center">
                <Box className="cliploader-wrapper">
                  <ClipLoader size="10" />
                </Box>
                <Text ml="s" color="primary">
                  Uploading: {progress.percent}%
                </Text>
              </Flex>
            ) : (
              <ButtonWithLoading onClick={handleSubmitForm} isSmall isLoading={isLoading} type="button">
                <Text fontWeight="medium">{isLoading ? 'Uploading' : 'Upload'}</Text>
              </ButtonWithLoading>
            )}
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

MediaUploadByLinkModal.defaultProps = {
  type: '',
  postMedias: [],
  hasError: '',
}

MediaUploadByLinkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  type: PropTypes.string,
  handleClickAddMedias: PropTypes.func.isRequired,
  postMedias: PropTypes.array,
  hasError: PropTypes.string,
}

export default MediaUploadByLinkModal

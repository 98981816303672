import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Badge from 'components/atoms/Badge'
import GradientBadge from 'components/molecules/GradientBadge'
import { ROUTE_WORKFLOW } from 'routes/Calendar/consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 560px;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    max-height: 600px;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledStepper = styled(Stepper)`
  padding: ${space.m} 0 !important;
  color: inherit !important;
  background: transparent !important;

  .MuiStepConnector-line {
    border-color: ${({ theme }) => theme.colors.primaryText};
  }

  .MuiStepConnector-root {
    height: 16px;
  }
`

const StyledStepLabel = styled(StepLabel)`
  .MuiStepIcon-root {
    color: ${({ theme }) => theme.colors.primaryText} !important;
  }

  .MuiStepIcon-text {
    fill: ${({ theme }) => theme.colors.primary_text_reverted} !important;
  }
`

const StyledStepContent = styled(StepContent)`
  padding-right: 0 !important;
  cursor: inherit !important;
  border-left-color: ${({ theme }) => theme.colors.primaryText} !important;
`

const StyledStepBodyWrapper = styled(Flex)`
  margin-top: -${space.l};
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: ${space.m};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
`

const ApprovalWorkflowViewModal = ({ isOpen, handleDismiss, data }) => {
  const [isGettingData, setIsGettingData] = useState(true)
  const [workflow, setWorkflow] = useState({})

  const getData = async () => {
    try {
      const response = await request({ path: `${ROUTE_WORKFLOW}/${data.id}` })

      const { error, data: workflow } = response || {}

      if (error || !response) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setWorkflow({ ...workflow })
      }
    } catch (error) {
      errorHelper({ error, componentName: ApprovalWorkflowViewModal.displayName, functionName: 'getData' })
    } finally {
      setIsGettingData(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      getData()
    }
  }, [isOpen])

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  const { name, entity, steps = [], active, default: isDefault, deleted } = workflow

  const { label: entityName } = entity || {}

  let status_color = 'background_badge_active'
  let status_text = 'Active'

  if (deleted) {
    status_color = 'error'
    status_text = 'Removed'
  } else if (!active) {
    status_color = 'background_badge_inactive'
    status_text = 'Inactive'
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">View approval workflow</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" flex="1">
            {isGettingData ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Scrollbars universal>
                <Flex flexDirection="column" p="m">
                  <Flex alignItems="center">
                    <Text mr="s">{name}</Text>
                    <Badge.Status color={status_color}>{status_text}</Badge.Status>
                  </Flex>

                  <Flex mt="s">
                    <Text mr="s">Profile group: {entityName}</Text>
                    {isDefault && <Badge.Status color="background_badge_inactive">Default</Badge.Status>}
                  </Flex>

                  <StyledStepper orientation="vertical">
                    {steps.map((step, index) => {
                      const {
                        name,
                        users = [],
                        labels = [],
                        shared_calendar = false,
                        anyone_can_approve = false,
                      } = step

                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Step key={index} expanded completed={false}>
                          <StyledStepLabel />
                          <StyledStepContent>
                            <StyledStepBodyWrapper>
                              <Text>{name}</Text>

                              {anyone_can_approve ? (
                                <Text mt="xs" color="secondaryText">
                                  Anyone can approve
                                </Text>
                              ) : (
                                <Fragment>
                                  {users.length > 0 && (
                                    <Flex flexWrap="wrap">
                                      {users.map(({ label, value }) => (
                                        <Badge.Status key={value} mr="s" mt="xs" color={COLOR_CONSTANTS.SAPPHIRE}>
                                          <Text fontSize="xs" textAlign="center">
                                            {label}
                                          </Text>
                                        </Badge.Status>
                                      ))}
                                    </Flex>
                                  )}

                                  {labels.length > 0 && (
                                    <Flex flexWrap="wrap">
                                      {labels.map((label) => (
                                        <GradientBadge label={label} mr="s" mt="xs" key={label.value} />
                                      ))}
                                    </Flex>
                                  )}
                                </Fragment>
                              )}

                              {shared_calendar && (
                                <Text mt="xs" color="secondaryText">
                                  Shared calendar users can approve
                                </Text>
                              )}
                            </StyledStepBodyWrapper>
                          </StyledStepContent>
                        </Step>
                      )
                    })}
                  </StyledStepper>
                </Flex>
              </Scrollbars>
            )}
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal} mr="s">
              Close
            </Button.Gray>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ApprovalWorkflowViewModal.defaultProps = {}

ApprovalWorkflowViewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

ApprovalWorkflowViewModal.displayName = 'ApprovalWorkflowViewModal'

export default ApprovalWorkflowViewModal

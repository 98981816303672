import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { radius } from 'theme'
import {
  ERROR_MESSAGE,
  LABELS_TYPE_INBOX,
  LABELS_TYPE_MEDIA,
  LABELS_TYPE_PUBLICATION,
  LABELS_TYPE_PUBLICATION_GROUP,
} from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { ROUTE_LABELS, ROUTE_MEDIA, ROUTE_PUBLISH } from 'routes/Calendar/consts'
import LabelsSelect from './components/LabelsSelect'
import { getLabels } from './helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 560px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 300px;
    top: 40%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const LabelEditModal = ({
  isOpen,
  handleDismiss,
  handleUpdateDataLabels,
  type,
  specialType,
  modalTitle,
  modalDescription,
  ...props
}) => {
  const [data, setData] = useState({})
  const [entityLabels, setEntityLabels] = useState([])
  const [isDataLabelsUpdating, setIsDataLabelsUpdating] = useState(false)

  const { labels = [], id, ids } = data || {}

  let noOptionMessage = ''

  let updatedType = type

  if (updatedType === LABELS_TYPE_PUBLICATION_GROUP) {
    updatedType = LABELS_TYPE_PUBLICATION
  }

  if (updatedType === LABELS_TYPE_PUBLICATION) {
    noOptionMessage = 'Label your posts by adding new labels'
  } else if (updatedType === LABELS_TYPE_INBOX) {
    noOptionMessage = 'Label your message by adding new labels'
  }

  useEffect(() => {
    if (isOpen) {
      getLabels({ setLabels: setEntityLabels, type: updatedType })
      setData({ ...props.data })
    }
  }, [isOpen])

  const handleClickSave = async () => {
    const { type: postType = '' } = data || {}

    setIsDataLabelsUpdating(true)
    try {
      const requestData = []

      requestData.push(
        request({
          method: 'POST',
          path: ROUTE_LABELS,
          body: { labels: labels ? labels.map(({ label }) => label) : [], type },
        })
      )

      let successMessage = ''

      let requestOptions = {
        method: 'POST',
        path: '',
        body: { labels },
      }

      if (type === LABELS_TYPE_PUBLICATION_GROUP) {
        successMessage = 'Posts labels have been updated.'
        const { ids } = data // ids contain publication_group_id, id and type of the post

        const body = { posts: ids, labels }

        requestOptions = {
          method: 'POST',
          body,
          path: `${ROUTE_PUBLISH}/label`,
        }
      } else if (type === LABELS_TYPE_PUBLICATION) {
        successMessage = 'Post labels have been updated.'

        requestOptions = {
          method: 'POST',
          body: { labels, posts: [{ id, type: postType }] },
          path: `${ROUTE_PUBLISH}/label`,
        }
      } else if (type === LABELS_TYPE_INBOX) {
        successMessage = 'Message labels have been updated'
        requestOptions.path = `inbox/label/${id}`
      } else if (type === LABELS_TYPE_MEDIA) {
        const { ids } = data

        const body = []

        ids.forEach((media_gid) => {
          body.push({ media_gid, labels })
        })

        requestOptions = {
          method: 'PUT',
          body,
          path: `${ROUTE_MEDIA}/batch`,
        }
        successMessage = 'Media labels have been updated'
      }

      if (requestOptions.path) {
        requestData.push(request(requestOptions))
      }

      await Promise.all(requestData)

      Alert.success(successMessage, { timeout: 5000 })
      setIsDataLabelsUpdating(false)
      handleUpdateDataLabels({ id, labels, ids })
    } catch (error) {
      errorHelper({ error, componentName: LabelEditModal.displayName, functionName: 'handleClickSave' })
      setIsDataLabelsUpdating(false)
    }
  }

  const handleClickCloseModal = () => {
    if (!isDataLabelsUpdating) {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">{modalTitle}</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" my="m" px="m" flex="1">
            {modalDescription && (
              <Text mb="xs" fontSize="s">
                {modalDescription}
              </Text>
            )}
            <Flex flexDirection="column" width="100%">
              <LabelsSelect
                noOptionMessage={noOptionMessage}
                setData={setData}
                selectedLabels={labels}
                labelOptions={entityLabels}
                setLabelOptions={setEntityLabels}
                data={data}
                type={updatedType}
                calledFromModal
              />
            </Flex>
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray mr="m" isSmall onClick={handleClickCloseModal} isDisabled={isDataLabelsUpdating}>
              Cancel
            </Button.Gray>
            <ButtonWithLoading isSmall onClick={handleClickSave} isLoading={isDataLabelsUpdating}>
              {isDataLabelsUpdating ? 'Saving' : 'Save'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

LabelEditModal.defaultProps = {
  modalTitle: 'Label this post',
  modalDescription: ' Track this post in calendar filters as:',
  specialType: '',
}

LabelEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleUpdateDataLabels: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  specialType: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
}

LabelEditModal.displayName = 'LabelEditModal'

export default LabelEditModal

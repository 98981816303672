import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactPlayer from 'react-player/lazy'
import { space } from 'theme'
import { Box, Flex } from './Layout'
import ImageWithFallback from './ImageWithFallback'
import Icon from './Icon'
import Image from './Image'

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const StyledIconVideoCamera = styled(Image)`
  max-width: 39px;
  max-height: 40px;
`

const UNSUPPORTED_VIDEO_FORMATS = ['mkv', 'mov']

const transformVideoUrlToProcessedVideo = ({ url, processedVideoEnding = '_twitter' }) => {
  let updatedUrl = url

  if (updatedUrl) {
    try {
      const { origin, pathname, search } = new URL(updatedUrl)
      if (origin === process.env.NEXT_PUBLIC_CLOUDFRONT_URL) {
        const { 0: pathname_without_ext, 1: ext } = pathname.split('.')
        if (UNSUPPORTED_VIDEO_FORMATS.includes(ext)) {
          updatedUrl = `${origin}${pathname_without_ext}${processedVideoEnding}.mp4${search}`
        }
      }
    } catch (e) {}
  }
  return updatedUrl
}

const VideoWithFallback = ({ fallbackSourceImage, url, processedVideoEnding, ...props }) => {
  const [showFallback, setShowFallback] = useState(!url)
  const [isPlaying, setIsPlaying] = useState(false)

  const { config, name, style, playIcon, id, controls, playOnClickOnPreview = false } = props || {}
  const { file } = config || {}
  const { attributes } = file || {}
  const { poster: thumbnail_url = '' } = attributes || {}

  const playingData = {}

  if (!controls) {
    playingData.playing = isPlaying
  }

  return (
    <Fragment>
      {showFallback ? (
        <Flex width="100%" height="100%" alignItems="center" justifyContent="center" {...style}>
          {fallbackSourceImage && (
            <Fragment>
              {playIcon || (
                <Box position="absolute" top={space.xs} right={space.xs} draggable="false">
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
              )}
            </Fragment>
          )}
          {fallbackSourceImage ? (
            <ImageWithFallback
              id={id}
              source={fallbackSourceImage || '/assets/videocamera.svg'}
              fallbackSource="/assets/videocamera.svg"
              width="100%"
              height="100%"
              name={name}
              {...style}
            />
          ) : (
            <StyledIconVideoCamera src="/assets/videocamera.svg" id={id} width="100%" height="100%" />
          )}
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          onClick={() => {
            if (!controls || playOnClickOnPreview) {
              setIsPlaying(!isPlaying)
            }
          }}
        >
          <ReactPlayer
            onError={() => {
              setShowFallback(true)
            }}
            url={transformVideoUrlToProcessedVideo({ url, processedVideoEnding })}
            light={thumbnail_url}
            playIcon={
              showFallback ? (
                <Flex />
              ) : (
                <Box position="absolute" top={space.xs} right={space.xs} draggable="false">
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
              )
            }
            playing={isPlaying}
            name={name}
            {...props}
          />
        </Flex>
      )}
    </Fragment>
  )
}

VideoWithFallback.defaultProps = {
  fallbackSourceImage: '',
  url: '',
  processedVideoEnding: '_twitter',
}

VideoWithFallback.propTypes = {
  url: PropTypes.string,
  fallbackSourceImage: PropTypes.string,
  processedVideoEnding: PropTypes.string,
}

export default VideoWithFallback

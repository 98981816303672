import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'

const StyledBox = styled(Box)` 
  // box-shadow: 0 0 8px ${({ theme }) => transparentize(0.85, theme.colors.box_shadow)}; 
  border: 1px solid ${({ theme }) => theme.colors.border_color}; 
  border-radius: ${radius.l};
`

const Card = ({ children, pt, pb, px, className }) => (
  <StyledBox className={className}>
    <Flex pt={pt} pb={pb} px={px} bg="background_card" flexDirection="column" borderRadius={radius.l}>
      {children}
    </Flex>
  </StyledBox>
)

Card.defaultProps = {
  pt: 'm',
  pb: 'xxl',
  px: 'l',
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  className: PropTypes.node,
}

export default Card

import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import useDrivePicker from 'react-google-drive-picker-no-default-scope'
import { COLOR_CONSTANTS } from 'theme'
import {
  DOCUMENT,
  ERROR_MESSAGE,
  FEATURE_FIND_CONTENT,
  FEATURE_CANVA,
  FEATURE_DRIVES,
  FEATURE_LINK_SHORTENER,
  IMAGE,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_TEXT,
  VIDEO,
} from 'consts'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Divider from 'components/atoms/Divider'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import { Flex } from 'components/atoms/Layout'
import {
  DOCUMENT_EXTENSIONS,
  DOCUMENT_MIME_TYPES,
  IMAGE_EXTENSIONS,
  IMAGE_MIME_TYPES,
  POST_DOCUMENT,
  POST_IMAGE,
  POST_VIDEO,
  ROUTE_MEDIA,
  VIDEO_EXTENSIONS,
  VIDEO_MIME_TYPES,
} from 'routes/Calendar/consts'
import {
  dropboxPickerConfiguration,
  googleDrivePickerConfiguration,
  oneDrivePickerConfiguration,
  uploadMediaToServerByFormDataHelper,
} from 'routes/Calendar/helpers'
import CanvaDesignModal from 'routes/Calendar/components/CanvaDesignModal'
import MediaUploadByLinkModal from 'routes/Calendar/components/MediaUploadByLinkModal'
import MediaLibraryModal from 'routes/Calendar/components/MediaLibraryModal'
import MediaDiscoverModal from 'routes/Calendar/components/MediaDiscoverModal'
import MediaSettings from 'routes/Media/MediaSettings'

const StyledAddMediaWrapper = styled(Flex)`
  cursor: pointer;
`

const IMAGE_EXTENSIONS_JOINED = IMAGE_EXTENSIONS.join(',')

const MEDIA_LIBRARY_MODAL_OPTIONS = {
  isOpen: false,
}

const MEDIA_DISCOVER_MODAL_OPTIONS = {
  isOpen: false,
  isAddingMedia: false,
}

const MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS = {
  isOpen: false,
  hasError: '',
}

const MEDIA_SETTINGS_MODAL_OPTIONS = {
  isOpen: false,
  uploadedMedias: [],
}

const DEFAULT_BLOCK_MEDIA_OPTIONS = {
  iconNameEnding: 'Lock',
  iconWidthEnding: '14px',
  iconColorEnding: 'error',
}

const MediaUploadComponent = ({
  user,
  data,
  vistaPage,
  handleChangeBlockComponent,
  mediaType,
  maxAttachmentsAllowed: MAX_ATTACHMENTS_ALLOWED,
  showOnTop,
}) => {
  const { page_id, block_id, medias, medias_storage_key } = data

  const { entity = {} } = vistaPage

  const inputImageFileRef = useRef(null)

  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_FIND_CONTENT]: { enabled: true },
    [FEATURE_CANVA]: { enabled: true },
    [FEATURE_DRIVES]: { enabled: true },
  })
  const [newMediasViaInput, setNewMediasViaInput] = useState([])
  const [openPicker, authResponse] = useDrivePicker()
  const [savedGoogleDocsAuthResponse, setSavedGoogleDocsAuthResponse] = useState({})
  const [sharedDocsForUpload, setSharedDocsForUpload] = useState([])
  const [mediaLibraryOptions, setMediaLibraryOptions] = useState({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
  const [mediaDiscoverOptions, setMediaDiscoverOptions] = useState({ ...MEDIA_DISCOVER_MODAL_OPTIONS })
  const [mediaUploadByLinkOptions, setMediaUploadByLinkOptions] = useState({ ...MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS })
  const [mediaSettingsOptions, setMediaSettingsOptions] = useState({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
  const [canvaDesignOptions, setCanvaDesignOptions] = useState({ isOpen: false })

  useEffect(() => {
    setFeaturesEnabled({
      ...checkIfPlanHasFeatureEnabled({ user, features: [FEATURE_FIND_CONTENT, FEATURE_CANVA, FEATURE_DRIVES] }),
    })
  }, [])

  const onChangeDispatchPost = ({ type, data }) => {
    data.page_id = page_id
    data.block_id = block_id

    data.medias_storage_key = medias_storage_key

    handleChangeBlockComponent({ action_type: type, action_data: data })
  }

  useEffect(() => {
    if (authResponse && authResponse.access_token) {
      setSavedGoogleDocsAuthResponse({ ...authResponse })
    }
  }, [authResponse])

  useEffect(() => {
    if (sharedDocsForUpload && sharedDocsForUpload.length !== 0) {
      // eslint-disable-next-line no-use-before-define
      handleClickAddMedias({
        selectedMedias: [
          ...medias,
          ...sharedDocsForUpload.map(({ url }) => ({
            url: savedGoogleDocsAuthResponse.access_token
              ? `${url}&access_token=${savedGoogleDocsAuthResponse.access_token}`
              : url,
            tempId: new Date().getTime() + Math.floor(Math.random() * 1000),
          })),
        ],
        mediaType,
      })
      setSharedDocsForUpload([])
    }
  }, [sharedDocsForUpload])

  useEffect(() => {
    let foundUploadedMedias = []
    if (data.medias) {
      for (let i = 0; i < data.medias.length; i++) {
        if (medias[i].tempId) {
          foundUploadedMedias = []
          break
        } else if (medias[i].isNew) {
          foundUploadedMedias.push({ ...medias[i], ...{ type: mediaType } })
        }
      }
    }
    if (foundUploadedMedias.length !== 0 && !mediaSettingsOptions.isOpen) {
      setMediaSettingsOptions({ ...{ isOpen: true, uploadedMedias: foundUploadedMedias } })
    }
  }, [data])

  useEffect(() => {
    if (newMediasViaInput.length !== 0) {
      newMediasViaInput.forEach((item) => {
        // eslint-disable-next-line no-use-before-define
        uploadMediaToServerByFormData(item)
      })

      setNewMediasViaInput([])
    }
  }, [newMediasViaInput])

  const uploadMediaToServerByStringData = async ({ base64url, mediaURL, uploadingId }) => {
    let tempId

    try {
      const response = await request({
        method: 'POST',
        body: { base64url, mediaURL, mediaType },
        path: `${ROUTE_MEDIA}?tempId=${uploadingId}`,
      })

      const { error, ...media } = response || {}

      ;({ tempId } = response || {})

      const { media_gid } = media || {}

      if (!response || error || !media_gid) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })

        if (mediaUploadByLinkOptions.isOpen) {
          mediaUploadByLinkOptions.hasError = error || ERROR_MESSAGE
          setMediaUploadByLinkOptions({ ...mediaUploadByLinkOptions })
        }
      } else if (mediaUploadByLinkOptions.isOpen) {
        // eslint-disable-next-line no-use-before-define
        handleClickCloseMediaUploadByLinkModal()
      }

      delete media.tempId

      if (error) {
        onChangeDispatchPost({
          type: 'remove_temporary_media',
          data: { media_temp_id: Number(tempId) },
        })
      } else {
        onChangeDispatchPost({
          type: 'update_temporary_media',
          data: {
            media_temp_id: Number(tempId),
            media: { ...media, id: media_gid, isNew: true, type: mediaType },
          },
        })
      }
    } catch (error) {
      onChangeDispatchPost({
        type: 'remove_temporary_media',
        data: { media_temp_id: Number(tempId) },
      })

      if (mediaUploadByLinkOptions.isOpen) {
        mediaUploadByLinkOptions.hasError = error || ERROR_MESSAGE
        setMediaUploadByLinkOptions({ ...mediaUploadByLinkOptions })
      }

      errorHelper({
        error,
        componentName: MediaUploadComponent.displayName,
        functionName: 'uploadMediaToServerByStringData',
      })
    }
  }

  const uploadMediaToServerByFormData = async ({ file, uploadingId, network }) => {
    const { tempId, media_gid, media, error } = await uploadMediaToServerByFormDataHelper({
      file,
      uploadingId,
    })

    delete media.tempId

    let foundMedia = false

    const foundPostMediaIndex = medias.findIndex((media) => (media.tempId || media.uploadingId) === Number(tempId))

    if (foundPostMediaIndex > -1) {
      foundMedia = true
      if (error) {
        onChangeDispatchPost({
          type: 'remove_temporary_media',
          data: { media_temp_id: Number(tempId) },
        })
      } else {
        onChangeDispatchPost({
          type: 'update_temporary_media',
          data: {
            media_temp_id: Number(tempId),
            media: { ...media, id: media_gid, isNew: true, type: mediaType, network },
          },
        })
      }
    }

    if (!foundMedia && !error) {
      if (media.type === mediaType) {
        onChangeDispatchPost({
          type: 'update_temporary_media',
          data: {
            media_temp_id: Number(tempId),
            media: { ...media, id: media_gid, isNew: true, type: mediaType, network },
          },
        })
      }
    }

    if (error) {
      Alert.error(error, { timeout: 5000 })
    }
  }

  const handlePostImageFileChange = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      const { files } = event.currentTarget
      let extensionError = false
      let sizeError = false

      let imagesCounter = 0
      const newImages = []
      for (const file of files) {
        if (imagesCounter <= MAX_ATTACHMENTS_ALLOWED) {
          const { name, size } = file
          if (IMAGE_EXTENSIONS.includes(name.substring(name.lastIndexOf('.')).toLowerCase())) {
            if (size <= MAX_IMAGE_SIZE) {
              const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
              newImages.push({ tempId: uploadingId, id: uploadingId, isNew: true })
              imagesCounter++
              newMediasViaInput.push({ file, uploadingId })
            } else {
              sizeError = true
            }
          } else {
            extensionError = true
          }
        }
      }
      if (extensionError) {
        Alert.error(`Only ${IMAGE_EXTENSIONS.join(', ')} files allowed!`)
      }
      if (sizeError) {
        Alert.error(`The size of the image must be less than ${MAX_IMAGE_SIZE_TEXT}!`)
      }

      if (newImages.length) {
        onChangeDispatchPost({
          type: 'add_media',
          data: { medias: newImages, maxAttachmentsAllowed: MAX_ATTACHMENTS_ALLOWED },
        })
      }

      if (newMediasViaInput.length !== 0) {
        setNewMediasViaInput([...newMediasViaInput])
      }
    }
  }

  const handleClickGoogleDrive = () => {
    let MIME_TYPES = ''
    let VIEW_ID = 'DOCS_IMAGES_AND_VIDEOS'

    if (mediaType === IMAGE) {
      MIME_TYPES = Object.keys(IMAGE_MIME_TYPES)
        .map((mime) => `image/${mime}`)
        .join(',')
    } else if (mediaType === VIDEO) {
      MIME_TYPES = Object.keys(VIDEO_MIME_TYPES)
        .map((mime) => `video/${mime}`)
        .join(',')
    } else if (mediaType === DOCUMENT) {
      MIME_TYPES = Object.keys(DOCUMENT_MIME_TYPES)
        .map((mime) => `application/${mime}`)
        .join(',')
      VIEW_ID = 'DOCS'
    }
    const { access_token } = authResponse || {}

    if (access_token) {
      savedGoogleDocsAuthResponse.access_token = access_token
      setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })
    }

    const { config } = googleDrivePickerConfiguration({
      access_token,
      mime_types: MIME_TYPES,
      view_id: VIEW_ID,
      callbackFunction: (data) => {
        const { docs = [] } = data
        setSharedDocsForUpload([...docs])
      },
      multiSelect: false,
    })

    openPicker(config)
  }

  const handleClickDropBox = () => {
    savedGoogleDocsAuthResponse.access_token = null
    setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

    let EXTENSIONS = []

    if (mediaType === IMAGE) {
      EXTENSIONS = IMAGE_EXTENSIONS
    } else if (mediaType === VIDEO) {
      EXTENSIONS = VIDEO_EXTENSIONS
    } else if (mediaType === DOCUMENT) {
      EXTENSIONS = DOCUMENT_EXTENSIONS
    }

    const { config } = dropboxPickerConfiguration({
      extensions: EXTENSIONS,
      callbackFunction: (files) => {
        setSharedDocsForUpload([...files.map(({ link }) => ({ url: link }))])
      },
      multiSelect: false,
    })

    // eslint-disable-next-line no-undef
    Dropbox.choose(config)
  }

  const handleClickOneDrive = () => {
    savedGoogleDocsAuthResponse.access_token = null
    setSavedGoogleDocsAuthResponse({ ...savedGoogleDocsAuthResponse })

    let EXTENSIONS = []

    if (mediaType === IMAGE) {
      EXTENSIONS = IMAGE_EXTENSIONS
    } else if (mediaType === VIDEO) {
      EXTENSIONS = VIDEO_EXTENSIONS
    } else if (mediaType === DOCUMENT) {
      EXTENSIONS = DOCUMENT_EXTENSIONS
    }

    const { config } = oneDrivePickerConfiguration({
      extensions: EXTENSIONS.join(','),
      callbackFunction: (files) => {
        if (files && files.value && files.value.length !== 0) {
          setSharedDocsForUpload([...files.value.map((item) => ({ url: item['@microsoft.graph.downloadUrl'] }))])
        }
      },
      multiSelect: false,
    })

    // eslint-disable-next-line no-undef
    OneDrive.open(config)
  }

  const handleClickOpenMediaLibraryModal = () => {
    mediaLibraryOptions.isOpen = true
    setMediaLibraryOptions({ ...mediaLibraryOptions })
  }

  const handleClickCloseMediaLibraryModal = () => {
    setMediaLibraryOptions({ ...MEDIA_LIBRARY_MODAL_OPTIONS })
  }

  const handleClickOpenMediaDiscoverModal = () => {
    mediaDiscoverOptions.isOpen = true
    setMediaDiscoverOptions({ ...mediaDiscoverOptions })
  }

  const handleClickCloseMediaDiscoverModal = () => {
    setMediaDiscoverOptions({ ...MEDIA_DISCOVER_MODAL_OPTIONS })
  }

  const handleClickOpenMediaUploadByLinkModal = () => {
    setMediaUploadByLinkOptions({ ...{ isOpen: true } })
  }

  const handleClickCloseMediaUploadByLinkModal = () => {
    setMediaUploadByLinkOptions({ ...MEDIA_UPLOAD_BY_LINK_MODAL_OPTIONS })
  }

  const handleClickCloseCanvaModal = () => {
    setCanvaDesignOptions({ isOpen: false })
  }

  const handleClickCloseMediaSettingsModal = () => {
    setMediaSettingsOptions({ ...MEDIA_SETTINGS_MODAL_OPTIONS })
  }

  const handleClickAddMedias = async ({ selectedMedias }) => {
    let uploadedMedias = []
    let mediasForUpload = []

    selectedMedias.forEach((item) => {
      const { id, media_gid, type, extension, url, thumbnail_url } = item
      if (id) {
        uploadedMedias.push({ id, media_gid, type, extension, url, thumbnail_url })
      } else {
        mediasForUpload.push(item)
      }
    })

    mediasForUpload = mediasForUpload.slice(0, MAX_ATTACHMENTS_ALLOWED)

    if (mediasForUpload.length === MAX_ATTACHMENTS_ALLOWED) {
      uploadedMedias = []
    } else {
      uploadedMedias = uploadedMedias.slice(mediasForUpload.length - MAX_ATTACHMENTS_ALLOWED)
    }

    const newMedias = []

    for (const media of mediasForUpload) {
      const { url } = media
      const uploadingId = new Date().getTime() + Math.floor(Math.random() * 1000)
      newMedias.push({ tempId: uploadingId, id: uploadingId, isNew: true })
      uploadMediaToServerByStringData({
        mediaURL: url,
        uploadingId,
      })
    }
    if (mediaDiscoverOptions.isOpen) {
      handleClickCloseMediaDiscoverModal()
    } else if (mediaLibraryOptions.isOpen) {
      handleClickCloseMediaLibraryModal()
    }

    onChangeDispatchPost({
      type: 'rewrite_medias',
      data: { medias: [...uploadedMedias, ...newMedias] },
    })
  }

  const handleAddDesignMedias = (url) => {
    handleClickAddMedias({
      selectedMedias: [...medias, { url, tempId: new Date().getTime() }],
    })
  }

  const handleUpdateUploadedMedias = ({ medias: updated_medias }) => {
    updated_medias.forEach((media) => {
      media.isNew = false

      const foundIndex = medias.findIndex(({ media_gid }) => media_gid === media.media_gid)

      if (foundIndex > -1) {
        delete media.isNew
        delete media.tempId
        delete media.uploadingId

        const { id, media_gid, type, extension, url, thumbnail_url } = media

        if (medias.length <= MAX_ATTACHMENTS_ALLOWED) {
          onChangeDispatchPost({
            type: 'update_existing_media',
            data: { media: { id, media_gid, type, extension, url, thumbnail_url } },
          })
        } else {
          onChangeDispatchPost({
            type: 'remove_existing_media',
            data: { media },
          })
        }
      }
    })
  }

  const checkIfMediaCanBeAttached = ({ func, checkFeature = true, feature = FEATURE_FIND_CONTENT }) => {
    let featureFindContentEnabled = true

    if (checkFeature && !featuresEnabled[feature].enabled) {
      featureFindContentEnabled = false
    }

    if (featureFindContentEnabled) {
      const foundUploadingMedia = medias ? medias.find(({ isNew }) => isNew) : null
      if (foundUploadingMedia) {
        Alert.warning(`Your ${mediaType} is still uploading. Please wait just a bit longer before continuing.`, {
          timeout: 5000,
        })
      } else {
        func()
      }
    } else {
      Alert.error(featuresEnabled[feature].description, { timeout: 5000 })
    }
  }

  let blockedAddMediaOptions = {}
  let blockedAddMediaCanvaOptions = {}
  let blockedAddMediaDriversOptions = {}

  if (!featuresEnabled[FEATURE_FIND_CONTENT].enabled) {
    blockedAddMediaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
  }
  if (!featuresEnabled[FEATURE_CANVA].enabled) {
    blockedAddMediaCanvaOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
  }
  if (!featuresEnabled[FEATURE_DRIVES].enabled) {
    blockedAddMediaDriversOptions = DEFAULT_BLOCK_MEDIA_OPTIONS
  }

  return (
    <Fragment>
      <DropdownMenu
        zIndex={16}
        WrapperComp={
          <StyledAddMediaWrapper alignItems="center">
            <Image source="/assets/plus.svg" mr="xs" width="16px" height="16px" />
            <Text color="primary" fontSize="xs">
              {(medias && medias.length === 0) || !medias
                ? 'Add an image'
                : `Change ${MAX_ATTACHMENTS_ALLOWED === 1 ? 'image' : 'images'}`}
            </Text>
          </StyledAddMediaWrapper>
        }
        isDismissedOnClickInside
        top={showOnTop ? 'unset' : undefined}
        bottom={showOnTop ? '20px' : undefined}
        isTriangleVisible={!showOnTop}
      >
        <DropdownMenu.Item
          label="Upload from computer"
          iconName="Upload"
          iconWidth="14px"
          iconColor="icon_color_gray"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                inputImageFileRef.current.click()
              },
              checkFeature: false,
            })
          }}
          isCursorPointer
        />
        <DropdownMenu.Item
          label="Upload from URL"
          iconName="Link"
          iconWidth="14px"
          iconColor="icon_color_gray"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                handleClickOpenMediaUploadByLinkModal()
              },
              checkFeature: false,
            })
          }}
          isCursorPointer
        />

        <Divider height="1px" />

        <DropdownMenu.Item
          label="Choose from Library"
          iconName="VistaSocialPicture"
          iconWidth="14px"
          iconColor="icon_color_gray"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                handleClickOpenMediaLibraryModal()
              },
              checkFeature: false,
            })
          }}
          isCursorPointer
        />
        <DropdownMenu.Item
          label="Discover"
          iconName="Search"
          iconWidth="14px"
          iconColor="icon_color_gray"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                handleClickOpenMediaDiscoverModal()
              },
            })
          }}
          isCursorPointer
          {...blockedAddMediaOptions}
        />

        <Divider height="1px" />

        <DropdownMenu.Item
          label="Google drive"
          iconName="GoogleDrive"
          iconWidth="14px"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                handleClickGoogleDrive()
              },
              feature: FEATURE_DRIVES,
            })
          }}
          isCursorPointer
          {...blockedAddMediaDriversOptions}
        />
        <DropdownMenu.Item
          label="Dropbox"
          iconName="Dropbox"
          iconWidth="14px"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                handleClickDropBox()
              },
              feature: FEATURE_DRIVES,
            })
          }}
          isCursorPointer
          {...blockedAddMediaDriversOptions}
        />
        <DropdownMenu.Item
          label="OneDrive"
          iconName="OneDrive"
          iconWidth="18px"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                handleClickOneDrive()
              },
              feature: FEATURE_DRIVES,
            })
          }}
          isCursorPointer
          {...blockedAddMediaDriversOptions}
        />

        <DropdownMenu.Item
          label="Design with Canva"
          iconName="Canva"
          iconWidth="14px"
          onClick={() => {
            checkIfMediaCanBeAttached({
              func: () => {
                setCanvaDesignOptions({ isOpen: true })
              },
              feature: FEATURE_CANVA,
            })
          }}
          isCursorPointer
          {...blockedAddMediaCanvaOptions}
        />
      </DropdownMenu>

      {mediaLibraryOptions.isOpen && (
        <MediaLibraryModal
          user={user}
          handleDismiss={handleClickCloseMediaLibraryModal}
          isOpen={mediaLibraryOptions.isOpen}
          type={mediaType === IMAGE ? POST_IMAGE : mediaType === VIDEO ? POST_VIDEO : POST_DOCUMENT}
          handleClickAddMedias={handleClickAddMedias}
          postMedias={medias || []}
          selectedEntities={[entity]}
          selectedProfiles={[]}
          blockDynamicMedias
          maxImageAttachmentsAllowed={MAX_ATTACHMENTS_ALLOWED}
          replaceMediasWithoutLimitError
        />
      )}

      {mediaDiscoverOptions.isOpen && (
        <MediaDiscoverModal
          handleDismiss={handleClickCloseMediaDiscoverModal}
          isOpen={mediaDiscoverOptions.isOpen}
          type={mediaType === IMAGE ? POST_IMAGE : mediaType === VIDEO ? POST_VIDEO : POST_DOCUMENT}
          handleClickAddMedias={handleClickAddMedias}
          postMedias={medias || []}
          maxImageAttachmentsAllowed={MAX_ATTACHMENTS_ALLOWED}
          replaceMediasWithoutLimitError
        />
      )}

      {mediaUploadByLinkOptions.isOpen && (
        <MediaUploadByLinkModal
          handleDismiss={handleClickCloseMediaUploadByLinkModal}
          isOpen={mediaUploadByLinkOptions.isOpen}
          type={mediaType === IMAGE ? POST_IMAGE : mediaType === VIDEO ? POST_VIDEO : POST_DOCUMENT}
          handleClickAddMedias={handleClickAddMedias}
          postMedias={medias || []}
          hasError={mediaUploadByLinkOptions.hasError}
        />
      )}

      {canvaDesignOptions.isOpen && (
        <CanvaDesignModal
          handleDismiss={handleClickCloseCanvaModal}
          isOpen={canvaDesignOptions.isOpen}
          handleClickAddMedias={handleAddDesignMedias}
        />
      )}

      {mediaSettingsOptions.isOpen && (
        <MediaSettings
          user={user}
          uploadedMedias={mediaSettingsOptions.uploadedMedias}
          isOpen={mediaSettingsOptions.isOpen}
          handleDismiss={handleClickCloseMediaSettingsModal}
          handleUpdateUploadedMedias={handleUpdateUploadedMedias}
          selectedEntities={[entity]}
        />
      )}

      {mediaType === IMAGE && (
        <input
          hidden
          type="file"
          multiple
          ref={inputImageFileRef}
          onChange={(e) => handlePostImageFileChange(e)}
          accept={IMAGE_EXTENSIONS_JOINED}
          onClick={(event) => {
            event.target.value = null
          }}
        />
      )}
    </Fragment>
  )
}

MediaUploadComponent.defaultProps = {
  mediaType: IMAGE,
  maxAttachmentsAllowed: 1,
  showOnTop: false,
}

MediaUploadComponent.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  vistaPage: PropTypes.object.isRequired,
  handleChangeBlockComponent: PropTypes.func.isRequired,
  mediaType: PropTypes.string,
  maxAttachmentsAllowed: PropTypes.number,
  showOnTop: PropTypes.bool,
}

MediaUploadComponent.displayName = 'MediaUploadComponent'

export default MediaUploadComponent

import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { space as styledSpace } from 'styled-system'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius } from 'theme'
import { LABELS_TYPE_MEDIA, IMAGE_DYNAMIC, VIDEO_DYNAMIC, VIDEO } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import Badge from 'components/atoms/Badge'
import { getLabels } from 'shared/LabelEditModal/helpers'
import MediasItem from './MediasItem'
import { ROUTE_MEDIA } from '../../Calendar/consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483003 !important;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 750px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    flex-direction: column;
    height: auto;
    top: 50%;
    transform: translate(0, -50%);
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const MAX_PROCESSED_URLS_CALLED_TIMES = 30
const PROCESSED_URLS_CALLED_TIMEOUT = 6000

let PROCESSED_URLS_INTERVAL = null

const { MEDIA_STATUS_CHECKING, MEDIA_STATUS_PROCESSED, MEDIA_STATUS_NOT_PROCESSED } = {
  MEDIA_STATUS_CHECKING: 'checking',
  MEDIA_STATUS_PROCESSED: 'processed',
  MEDIA_STATUS_NOT_PROCESSED: 'not_processed',
}

const MetaInformationModal = ({
  isOpen,
  handleDismiss,
  handleUpdate,
  selectedMedia,
  isMediaUpdating,
  postComponent,
  ...props
}) => {
  const [media, setMedia] = useState({})
  const [labels, setLabels] = useState([])
  const [entities, setEntities] = useState([])
  const [status, setStatus] = useState(MEDIA_STATUS_CHECKING)

  const checkMediaStatus = ({ s3_urls }) => {
    let counter = 0

    const checkerFunction = async () => {
      try {
        counter++

        const responses = await Promise.all(s3_urls.map((url) => fetch(url, { method: 'HEAD' })))

        const foundBadResponse = responses.find((response) => !response || response.status !== 200)

        if (!foundBadResponse) {
          clearInterval(PROCESSED_URLS_INTERVAL)
          setStatus(MEDIA_STATUS_PROCESSED)
        } else if (counter === MAX_PROCESSED_URLS_CALLED_TIMES) {
          clearInterval(PROCESSED_URLS_INTERVAL)
          setStatus(MEDIA_STATUS_NOT_PROCESSED)
        } else {
          setStatus(MEDIA_STATUS_NOT_PROCESSED)
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: MetaInformationModal.displayName,
          functionName: 'checkerFunction',
          showAlert: false,
        })
        clearInterval(PROCESSED_URLS_INTERVAL)
      }
    }

    PROCESSED_URLS_INTERVAL = setInterval(() => {
      checkerFunction()
    }, PROCESSED_URLS_CALLED_TIMEOUT)

    checkerFunction()
  }

  const getProcessingMediasUrlEndings = async ({ media_gids }) => {
    try {
      const response = await request({
        path: `${ROUTE_MEDIA}/processed-urls`,
        method: 'POST',
        body: { medias: media_gids },
      })
      const { medias = [] } = response || {}

      if (medias && medias.length !== 0) {
        const { 0: { s3_urls = [] } = {} } = medias

        if (s3_urls && s3_urls.length !== 0) {
          checkMediaStatus({ s3_urls })
        }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: MetaInformationModal.displayName,
        functionName: 'getProcessingMediasUrlEndings',
        showAlert: false,
      })
    }
  }

  const collectData = async () => {
    setMedia({ ...selectedMedia })
    setEntities(props.entities.map(({ id, name, type }) => ({ value: id, label: name, type })))

    await getLabels({ setLabels, type: LABELS_TYPE_MEDIA })

    if (selectedMedia.type === VIDEO && !selectedMedia.hibernated) {
      getProcessingMediasUrlEndings({ media_gids: [selectedMedia.media_gid] })
    }
  }

  useEffect(() => () => clearInterval(PROCESSED_URLS_INTERVAL), [])

  useEffect(() => {
    if (isOpen) {
      collectData()
    }
  }, [isOpen])

  const handleUpdateMedia = async () => {
    if (isMediaUpdating) {
      Alert.warning(`Please wait a bit while your media is being updated.`, { timeout: 5000 })
    } else {
      const { id, description = '', title = '', audio_name = '', entities = [], labels = [], filename } = media
      handleUpdate({
        id,
        data: {
          description,
          title,
          labels: labels.map(({ label }) => label),
          audio_name,
          entity_gids: entities.map(({ value }) => value),
          filename,
        },
        type: postComponent,
      })
    }
  }

  const handleClickCloseModal = () => {
    if (!isMediaUpdating) {
      handleDismiss()
    }
  }

  let modalTitle = 'Media settings'

  if (media.type) {
    if (media.type === IMAGE_DYNAMIC) {
      modalTitle = `Dynamic image settings`
    } else if (media.type === VIDEO_DYNAMIC) {
      modalTitle = `Dynamic video settings`
    } else {
      modalTitle = `${media.type.charAt(0).toUpperCase()}${media.type.substr(1)} settings`
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" alignItems="center" justifyContent="space-between" $isTop>
            <H4 my="m">{modalTitle}</H4>

            {!media.hibernated && media.type === VIDEO && (
              <Fragment>
                {status === MEDIA_STATUS_CHECKING ? (
                  <Box className="cliploader-wrapper">
                    <ClipLoader size="10" />
                  </Box>
                ) : (
                  <Fragment>
                    <Badge.Status color={status === MEDIA_STATUS_PROCESSED ? '#55AB68' : 'warning'}>
                      {status === MEDIA_STATUS_PROCESSED ? 'Processed' : 'Processing'}
                    </Badge.Status>
                  </Fragment>
                )}
              </Fragment>
            )}
          </StyledDialogEnvironmentWrapper>
          <Flex flex="1" flexDirection="column" height="100%">
            <Flex width="100%" height="100%" position="relative">
              <MediasItem
                media={media}
                labels={labels}
                setLabels={setLabels}
                entities={entities}
                handleUpdateMedia={({ media }) => {
                  setMedia({ ...media })
                }}
              />
            </Flex>
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" alignItems="center" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal}>
              Close
            </Button.Gray>
            <ButtonWithLoading
              isSmall
              isLoading={isMediaUpdating}
              onClick={() => {
                if (!isMediaUpdating) {
                  handleUpdateMedia()
                }
              }}
            >
              <Text fontSize="s">{isMediaUpdating ? 'Updating' : 'Update'}</Text>
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

MetaInformationModal.defaultProps = {
  entities: [],
  postComponent: null,
}

MetaInformationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  selectedMedia: PropTypes.object.isRequired,
  entities: PropTypes.array,
  isMediaUpdating: PropTypes.bool.isRequired,
  postComponent: PropTypes.string,
}

MetaInformationModal.displayName = 'MetaInformationModal'

export default MetaInformationModal

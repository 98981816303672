import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactPlayer from 'react-player/lazy'
import { space } from 'theme'
import { Box, Flex } from './Layout'
import ImageWithFallback from './ImageWithFallback'
import Image from './Image'

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const AudioWithFallback = ({ fallbackSourceImage, url, ...props }) => {
  const [showFallback, setShowFallback] = useState(!url)

  const { config, name, style, playIcon } = props || {}
  const { file } = config || {}
  const { attributes } = file || {}
  const { poster: thumbnail_url = '' } = attributes || {}

  return (
    <Fragment>
      {showFallback ? (
        <Flex width="100%" height="100%" alignItems="center" justifyContent="center" name={name} {...style}>
          {playIcon || (
            <Box position="absolute" top={space.xs} right={space.xs} draggable="false">
              <StyledPlayImage src="/assets/vistasocial/musical_note.svg" width="18px" height="18px" />
            </Box>
          )}
          {fallbackSourceImage && thumbnail_url && (
            <ImageWithFallback
              source={thumbnail_url || fallbackSourceImage}
              fallbackSource="/assets/vistasocial/musical_note.svg"
              width="100%"
              height="100%"
              {...style}
            />
          )}
        </Flex>
      ) : (
        <ReactPlayer
          onError={(e) => {
            setShowFallback(true)
          }}
          url={url}
          light={thumbnail_url}
          playIcon={
            showFallback ? (
              <Flex />
            ) : (
              <Box position="absolute" top={space.xs} right={space.xs} draggable="false">
                <StyledPlayImage src="/assets/vistasocial/musical_note.svg" width="18px" height="18px" />
              </Box>
            )
          }
          name={name}
          {...props}
        />
      )}
    </Fragment>
  )
}

AudioWithFallback.defaultProps = {
  fallbackSourceImage: '',
  url: '',
}

AudioWithFallback.propTypes = {
  url: PropTypes.string,
  fallbackSourceImage: PropTypes.string,
}

export default AudioWithFallback

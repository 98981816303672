import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import ClipLoader from 'react-spinners/ClipLoader'
import { transparentize } from 'polished'
import { radius, fontSizes, space, breakpoints } from 'theme'
import { GOOGLE, IMAGE, IMAGE_DYNAMIC, FEATURE_POST_ENGAGEMENT } from 'consts'
import { initializeStringArrayWithRange, linkTransformer } from 'helpers'
import errorHelper from 'utils/errorHelper'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Select from 'components/atoms/Select'
import Switch from 'components/atoms/Switch'
import Input from 'components/atoms/Input'
import Image from 'components/atoms/Image'
import Datepicker from 'components/molecules/Datepicker'
import Counter from 'components/molecules/Counter'
import DynamicMedia from 'components/molecules/DynamicMedia'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_VIDEO,
  POST_LINK,
  POST_MIXED_MEDIA,
  GOOGLE_COLORS,
  DATE_FORMAT,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  PREVIEW_MEDIA_CLASS_NAME,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import { transformTextVariantsToUnicodeVariant, variableTransformer } from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'

const StyledFlex = styled(Flex)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
`

const StyledMediaWrapper = styled(Flex)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const Thumbnail = styled(ImageWithFallback)`
  object-fit: contain;
  width: 100%;
  max-height: 405px;
  border-radius: 0.5em;
  ${({ hasError, theme }) => hasError && `border: 3px solid ${theme.colors.error};`}
`

const SourceWrapper = styled.div`
  width: 100%;
  max-height: 405px;
  height: 100%;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const BodyWrapper = styled(Box)`
  cursor: pointer;
  position: relative;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledSelect = styled(Select)`
  font-size: ${fontSizes.xs};
  color: ${({ color, theme }) => color || theme.colors.primaryText};

  option {
    color: ${({ optionColor, theme }) => optionColor || theme.colors.primaryText};
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const DEFAULT_CALL_TO_ACTION = 'LEARN_MORE'

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === GOOGLE)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  eventTitleMaxLimit: MAX_EVENT_TITLE_LENGTH,
  hasMobileNotificationOption,
} = networkLimits

const { POST_TYPE_ALERT, POST_TYPE_EVENT, POST_TYPE_OFFER } = {
  POST_TYPE_ALERT: 'ALERT',
  POST_TYPE_EVENT: 'EVENT',
  POST_TYPE_OFFER: 'OFFER',
}
const HOURS = initializeStringArrayWithRange({ start: 1, end: 12 })
const MINUTES = initializeStringArrayWithRange({ end: 59 })

const GooglePost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  isPostFormDirty,
  isEditable,
  noVideoAttachment,
  hasPreview,
  postErrors,
  featuresEnabled,
  selectedEntities,
  selectedProfiles,
  previewProfileNumber,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  isSmallPreview,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  // const [customizationsSchema, setCustomizationsSchema] = useState({ showOffer: false })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile
  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    zoneShort,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const { url, title, picture_url, id: linkId } = link || {}
  const { published_link } = result || {}

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const { hasErrors, flags: { notificationDeviceLengthLimit } = {}, flags, warning_flags } =
    profilesWithErrors[profileId] || {}

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let call_to_action = ''
  let coupon = ''
  let terms = ''
  let post_type = ''
  let alert_type = ''
  let event_title = ''
  let event_start = ''
  let event_end = ''
  let publish_as_image = false
  let device_gid = null
  let publishing_option = null
  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      call_to_action = '',
      offer: { coupon = '', terms = '' } = {},
      publish_as_image = false,
      post_type = '',
      alert_type = '',
      event_title = '',
      event_start = '',
      event_end = '',
      device_gid = null,
      publishing_option = null,
    } = profileCustomizations)
  }

  const { call_to_action: CALL_TO_ACTIONS = [], alerts: ALERTS = [], topic: POST_TYPES = [] } =
    dataForCustomizations[network] || {}

  const handleUpdateCustomization = ({ type, secondaryType, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    if (secondaryType) {
      if (!post.customizations[network][profileId][type]) {
        post.customizations[network][profileId][type] = {}
      }
      post.customizations[network][profileId][type][secondaryType] = value
    } else {
      post.customizations[network][profileId][type] = value
    }
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const { variableTransformedText, transformed_text_to_html_for_textarea } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: variableTransformedText,
      color: GOOGLE_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      transformHashstag: true,
      hashtagColor: GOOGLE_COLORS.LAST_LIGHT_BLUE,
    })

    return { variableTransformedText, transformed_text_to_html_for_textarea }
  }, [updatedPostText, savedVariables])

  event_start = event_start ? moment(event_start) : moment()
  const event_start_date = event_start.format(DATE_FORMAT)
  const event_start_hour = event_start.format('hh')
  const event_start_minute = event_start.format('mm')
  const event_start_interval = event_start.format('A')

  event_end = event_end ? moment(event_end) : moment().add(1, 'h')
  const event_end_date = event_end.format(DATE_FORMAT)
  const event_end_hour = event_end.format('hh')
  const event_end_minute = event_end.format('mm')
  const event_end_interval = event_end.format('A')

  const call_to_action_preview = call_to_action || DEFAULT_CALL_TO_ACTION

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: GooglePost.displayName,
        functionName: 'url',
        showAlert: false,
        sendError: false,
      })
      urlHost = url
    }
  }

  let updatedPostMedia = {}

  if (customizedPostComponent === POST_IMAGE) {
    const filtered_images = customizedPostImages.filter(({ tempId }) => !tempId)
    // eslint-disable-next-line prefer-destructuring
    updatedPostMedia = filtered_images[0]
  } else if (customizedPostComponent === POST_MIXED_MEDIA) {
    const filtered_images = customizedPostMixedMedias.filter(
      ({ tempId, type }) => !tempId && (type === IMAGE || type === IMAGE_DYNAMIC)
    )
    // eslint-disable-next-line prefer-destructuring
    updatedPostMedia = filtered_images[0]
  }

  const { type, url: pictureUrl, id: imageId, isNew, [network]: networkErrors = {} } = updatedPostMedia || {}

  const { hasSizeError } = networkErrors || {}
  const hasError = hasSizeError

  let sourceTypeTemplate = ''

  switch (customizedPostComponent) {
    case POST_IMAGE:
    case POST_MIXED_MEDIA:
      if (pictureUrl) {
        sourceTypeTemplate = (
          <StyledMediaWrapper>
            {isNew ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Thumbnail
                source={pictureUrl}
                fallbackSourceWidth="0px"
                fallbackSourceHeight="0px"
                name={imageId}
                id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${imageId}`}
                hasError={hasError}
              />
            )}
          </StyledMediaWrapper>
        )
      } else if (type === IMAGE_DYNAMIC) {
        sourceTypeTemplate = (
          <DynamicMedia media={updatedPostMedia || {}} post={post} profiles={[profile]} isSmall={smallPreview} />
        )
      }
      break
    case POST_LINK:
      if (publish_as_image && picture_url) {
        sourceTypeTemplate = (
          <SourceWrapper>
            <Flex flexDirection="column">
              <ImageWithFallback
                source={picture_url}
                width="100%"
                fallbackSourceWidth="0px"
                fallbackSourceHeight="0px"
                fallbackSource={null}
                name={linkId}
                id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${linkId}`}
              />
            </Flex>
          </SourceWrapper>
        )
      }
      break
    default:
      break
  }

  const handleChangeSelectedDate = ({ type, value, date_type }) => {
    let updatedValue = ''

    if (date_type === 'event_start') {
      updatedValue = event_start ? moment(event_start) : moment()
    } else if (date_type === 'event_end') {
      updatedValue = event_end ? moment(event_end) : moment()
    }

    let date = updatedValue.format(DATE_FORMAT)
    let hour = updatedValue.format('hh')
    let minute = updatedValue.format('mm')
    let interval = updatedValue.format('A')

    if (type === 'date') {
      date = moment(value).format(DATE_FORMAT)
    } else if (type === 'hour') {
      hour = value
    } else if (type === 'minute') {
      minute = value
    } else if (type === 'interval') {
      interval = value
    }

    handleUpdateCustomization({
      type: date_type,
      value: moment(`${date} ${hour}:${minute} ${interval}`, `${DATE_FORMAT} hh:mm A`).format(),
    })
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={customizedPostMixedMedias}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_post_preview' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex flexDirection="column" isEditable={smallPreview} isPostFormDirty={isPostFormDirty}>
                <Flex
                  flexDirection="column"
                  width="100%"
                  height="100%"
                  onClick={() => {
                    if (published_link) {
                      window.open(published_link)
                    }
                  }}
                >
                  {isPostFormDirty ? (
                    <Flex flexDirection="column" width="100%" height="100%" bg="white">
                      <Flex pl="1em" pt="0.5em" alignItems="center">
                        <Flex alignItems="center">
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                              width="2em"
                              height="2em"
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width="2em"
                              height="2em"
                            />
                          )}
                          <Flex ml="0.5em" flexDirection="column" justifyContent="center">
                            <Flex
                              alignItems="center"
                              onMouseEnter={(e) => {
                                if (!isMultipleProfilesPreview) {
                                  tooltipRef.current.handleShowTooltip({
                                    contentComp: (
                                      <ProfileItemTooltip
                                        profile={{
                                          code: network,
                                          picture_url: profilePictureUrl,
                                          profile_url: profileUrl,
                                          name: profileName,
                                          username: profileUsername,
                                          display,
                                          profile_id,
                                          type: profile_type,
                                        }}
                                        message=""
                                      />
                                    ),
                                    wrapperComp: e.currentTarget,
                                  })
                                }
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              <StyledLink
                                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                target="_blank"
                                color={GOOGLE_COLORS.GLUON_GREY}
                                fontWeight="bold"
                                fontSize="0.875em"
                              >
                                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                              </StyledLink>
                            </Flex>
                            {publish_at && (
                              <StyledLink
                                fontSize="0.75em"
                                color={GOOGLE_COLORS.TARNISHED_SILVER}
                                as={published_link ? 'a' : 'p'}
                                href={published_link || ''}
                                target="_blank"
                              >
                                {moment(publish_at).format('MMMM D, YYYY h:mm A')} {zoneShort || ''}
                              </StyledLink>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                      {customizedPostComponent ? <Box mt="0.5em">{sourceTypeTemplate}</Box> : <Box />}
                      <Flex flexDirection="column" ml="0.5em">
                        {transformed_text_to_html_for_textarea && (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: transformed_text_to_html_for_textarea,
                            }}
                            mt="0.5em"
                            ml="0.5em"
                            fontSize="1em"
                            whiteSpace="pre-line"
                            color={GOOGLE_COLORS.GLUON_GREY}
                          />
                        )}
                        {customizedPostComponent === POST_LINK && (picture_url || title || urlHost) && (
                          <StyledLink my="0.5em" fontSize="1em" ml="0.5em" color={GOOGLE_COLORS.MAIN}>
                            {`${call_to_action_preview.charAt(0)}${call_to_action_preview
                              .slice(1)
                              .toLowerCase()}`.replace(/_/g, ' ')}
                          </StyledLink>
                        )}
                      </Flex>
                    </Flex>
                  ) : (
                    <Box mt="m" px="s" mb="s" height="100%">
                      <NoPost />
                    </Box>
                  )}
                </Flex>
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper mr="s">
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={variableTransformedText.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              if (isEditable) {
                                setIsPostTextEditOpen(true)
                              }
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'}
                              />
                              <Box>
                                <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                              </Box>
                            </BodyWrapper>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={GOOGLE} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" minHeight="max-content" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      p={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                    >
                      {customizedPostComponent === POST_LINK && picture_url && (
                        <Flex mb="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <Switch
                              isOn={!!publish_as_image}
                              onClick={() => {
                                handleUpdateCustomization({
                                  type: 'publish_as_image',
                                  value: !publish_as_image,
                                })
                              }}
                              text="Publish as image"
                              fontWeight="normal"
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="publish_as_image" network={GOOGLE} />
                        </Flex>
                      )}

                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <StyledSelect
                            id="call_to_action"
                            name="call_to_action"
                            label="Select call to action"
                            placeholder="Select call to action"
                            onChange={({ target: { value } }) => {
                              if (value) {
                                handleUpdateCustomization({
                                  type: 'call_to_action',
                                  value,
                                })
                              } else {
                                delete post.customizations[network][profileId].call_to_action
                                onChangeUpdatePost(post)
                              }
                            }}
                            value={call_to_action}
                            color={call_to_action ? 'primaryText' : 'input_placeholder_color'}
                          >
                            <option key="" value="">
                              Select call to action
                            </option>
                            {CALL_TO_ACTIONS.map(({ label, value }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </StyledSelect>
                        </Flex>
                        <TooltipPromptComponent prompt_key="call_to_action" network={GOOGLE} />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <StyledSelect
                            id="post_type"
                            name="post_type"
                            label="Please select post type"
                            placeholder="Please select post type"
                            onChange={({ target: { value } }) => {
                              handleUpdateCustomization({
                                type: 'post_type',
                                value,
                              })
                              if (post_type !== value) {
                                let needToUpdatePost = false
                                if (value === POST_TYPE_EVENT) {
                                  if (post.customizations[network] && post.customizations[network][profileId]) {
                                    handleChangeSelectedDate({ type: '', value: null, date_type: 'event_start' })
                                    handleChangeSelectedDate({ type: '', value: null, date_type: 'event_end' })
                                  }
                                  needToUpdatePost = true
                                }

                                if (!value) {
                                  delete post.customizations[network][profileId].post_type
                                  needToUpdatePost = true
                                }

                                if (!value || value !== POST_TYPE_ALERT) {
                                  if (post.customizations[network] && post.customizations[network][profileId]) {
                                    delete post.customizations[network][profileId].alert_type
                                    needToUpdatePost = true
                                  }
                                }
                                if (!value || (value !== POST_TYPE_EVENT && value !== POST_TYPE_OFFER)) {
                                  if (post.customizations[network] && post.customizations[network][profileId]) {
                                    delete post.customizations[network][profileId].event_title
                                    delete post.customizations[network][profileId].event_start
                                    delete post.customizations[network][profileId].event_end
                                    needToUpdatePost = true
                                  }
                                }
                                if (!value || value !== POST_TYPE_OFFER) {
                                  if (post.customizations[network] && post.customizations[network][profileId]) {
                                    delete post.customizations[network][profileId].offer
                                    needToUpdatePost = true
                                  }
                                }

                                if (needToUpdatePost) {
                                  onChangeUpdatePost(post)
                                }
                              }
                            }}
                            value={post_type}
                            color={post_type ? 'primaryText' : 'input_placeholder_color'}
                          >
                            <option key="" value="">
                              Select post type
                            </option>
                            {POST_TYPES.map(({ label, value }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </StyledSelect>
                        </Flex>
                        <TooltipPromptComponent prompt_key="post_type" network={GOOGLE} />
                      </Flex>

                      {post_type === POST_TYPE_ALERT && (
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <StyledSelect
                              id="alert_type"
                              name="alert_type"
                              label="Please select alert type"
                              placeholder="Please select alert type"
                              onChange={({ target: { value } }) => {
                                handleUpdateCustomization({
                                  type: 'alert_type',
                                  value,
                                })
                              }}
                              value={alert_type}
                              color={alert_type ? 'primaryText' : 'input_placeholder_color'}
                            >
                              <option key="" value="">
                                Select alert type
                              </option>
                              {ALERTS.map(({ label, value }) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                            </StyledSelect>
                          </Flex>
                          <TooltipPromptComponent prompt_key="alert_type" network={GOOGLE} />
                        </Flex>
                      )}

                      {post_type === POST_TYPE_OFFER && (
                        <Flex flexDirection="column" width="100%">
                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Input
                                placeholder="Coupon code"
                                label=""
                                value={coupon}
                                name="coupon"
                                id="coupon"
                                onChange={(e) => {
                                  handleUpdateCustomization({
                                    type: 'offer',
                                    secondaryType: 'coupon',
                                    value: e.target.value,
                                  })
                                }}
                                width="100%"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="offer_coupon" network={GOOGLE} mt="21px" />
                          </Flex>

                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Input
                                placeholder="Terms conditions"
                                label=""
                                value={terms}
                                name="terms"
                                id="terms"
                                onChange={(e) => {
                                  handleUpdateCustomization({
                                    type: 'offer',
                                    secondaryType: 'terms',
                                    value: e.target.value,
                                  })
                                }}
                                width="100%"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="offer_terms" network={GOOGLE} mt="21px" />
                          </Flex>
                        </Flex>
                      )}

                      {(post_type === POST_TYPE_EVENT || post_type === POST_TYPE_OFFER) && (
                        <Flex flexDirection="column" width="100%">
                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Input
                                placeholder="Event title"
                                label=""
                                defaultValue={event_title}
                                name="event_title"
                                id="event_title"
                                onChange={(e) => {
                                  const { value } = e.target
                                  if (value.length <= MAX_EVENT_TITLE_LENGTH) {
                                    handleUpdateCustomizationDebounce({ type: 'event_title', value })
                                  }
                                }}
                                maxLength={MAX_EVENT_TITLE_LENGTH}
                                width="100%"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="event_title" network={GOOGLE} />
                          </Flex>

                          <Flex mt="m" width="100%" flexDirection="column">
                            <Flex alignItems="center" width="100%">
                              <Flex flexDirection="column" width="100%">
                                <Datepicker
                                  width={{ mobile: '125px', mobileLarge: '170px' }}
                                  label="Start date*"
                                  id="event_start"
                                  value={event_start_date ? moment(event_start_date).toDate() : event_start_date}
                                  onChange={(day) => {
                                    if (day) {
                                      handleChangeSelectedDate({ type: 'date', value: day, date_type: 'event_start' })
                                    }
                                  }}
                                />
                              </Flex>
                              <TooltipPromptComponent prompt_key="event_start_date" network={GOOGLE} mt="21px" />
                            </Flex>
                            <Flex flexDirection="column" width="100%" mt="m">
                              <Flex alignItems="center" justifyContent="flex-start">
                                <Flex alignItems="center">
                                  <Box width="70px">
                                    <StyledSelect
                                      name="hours"
                                      onChange={({ target: { value } }) => {
                                        handleChangeSelectedDate({ type: 'hour', value, date_type: 'event_start' })
                                      }}
                                      value={event_start_hour}
                                      color={event_start_hour ? 'primaryText' : 'input_placeholder_color'}
                                    >
                                      {HOURS.map((hour) => (
                                        <option key={hour} value={hour}>
                                          {hour}
                                        </option>
                                      ))}
                                    </StyledSelect>
                                  </Box>
                                  <Text fontSize="l" mx="s">
                                    :
                                  </Text>
                                  <Box width="70px">
                                    <StyledSelect
                                      name="minutes"
                                      onChange={({ target: { value } }) => {
                                        handleChangeSelectedDate({ type: 'minute', value, date_type: 'event_start' })
                                      }}
                                      value={event_start_minute}
                                      color={event_start_minute ? 'primaryText' : 'input_placeholder_color'}
                                    >
                                      {MINUTES.map((minute) => (
                                        <option key={minute} value={minute}>
                                          {minute}
                                        </option>
                                      ))}
                                    </StyledSelect>
                                  </Box>
                                </Flex>
                                <Box ml="s" width="70px">
                                  <StyledSelect
                                    name="interval"
                                    onChange={({ target: { value } }) => {
                                      handleChangeSelectedDate({ type: 'interval', value, date_type: 'event_start' })
                                    }}
                                    value={event_start_interval}
                                    color={event_start_interval ? 'primaryText' : 'input_placeholder_color'}
                                  >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </StyledSelect>
                                </Box>
                              </Flex>
                            </Flex>
                          </Flex>

                          <Flex mt="m" width="100%" flexDirection="column">
                            <Flex alignItems="center" width="100%">
                              <Flex flexDirection="column" width="100%">
                                <Datepicker
                                  width={{ mobile: '125px', mobileLarge: '170px' }}
                                  label="End date*"
                                  id="event_end"
                                  value={event_end_date ? moment(event_end_date).toDate() : event_end_date}
                                  onChange={(day) => {
                                    if (day) {
                                      handleChangeSelectedDate({ type: 'date', value: day, date_type: 'event_end' })
                                    }
                                  }}
                                />
                              </Flex>
                              <TooltipPromptComponent prompt_key="event_end_date" network={GOOGLE} mt="21px" />
                            </Flex>
                            <Flex flexDirection="column" width="100%" mt="m">
                              <Flex alignItems="center" justifyContent="flex-start">
                                <Flex alignItems="center">
                                  <Box width="70px">
                                    <StyledSelect
                                      name="hours"
                                      onChange={({ target: { value } }) => {
                                        handleChangeSelectedDate({ type: 'hour', value, date_type: 'event_end' })
                                      }}
                                      value={event_end_hour}
                                      color={event_end_hour ? 'primaryText' : 'input_placeholder_color'}
                                    >
                                      {HOURS.map((hour) => (
                                        <option key={hour} value={hour}>
                                          {hour}
                                        </option>
                                      ))}
                                    </StyledSelect>
                                  </Box>
                                  <Text fontSize="l" mx="s">
                                    :
                                  </Text>
                                  <Box width="70px">
                                    <StyledSelect
                                      name="minutes"
                                      onChange={({ target: { value } }) => {
                                        handleChangeSelectedDate({ type: 'minute', value, date_type: 'event_end' })
                                      }}
                                      value={event_end_minute}
                                      color={event_end_minute ? 'primaryText' : 'input_placeholder_color'}
                                    >
                                      {MINUTES.map((minute) => (
                                        <option key={minute} value={minute}>
                                          {minute}
                                        </option>
                                      ))}
                                    </StyledSelect>
                                  </Box>
                                </Flex>
                                <Box ml="s" width="70px">
                                  <StyledSelect
                                    name="interval"
                                    onChange={({ target: { value } }) => {
                                      handleChangeSelectedDate({ type: 'interval', value, date_type: 'event_end' })
                                    }}
                                    value={event_end_interval}
                                    color={event_end_interval ? 'primaryText' : 'input_placeholder_color'}
                                  >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </StyledSelect>
                                </Box>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

GooglePost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  isPostFormDirty: false,
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  postErrors: { networksErrors: {} },
  featuresEnabled: {},
  selectedEntities: [],
  selectedProfiles: [],
  previewProfileNumber: 1,
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  isSmallPreview: false,
}

GooglePost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  postErrors: PropTypes.object,
  featuresEnabled: PropTypes.object,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  previewProfileNumber: PropTypes.number,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  isSmallPreview: PropTypes.bool,
}

GooglePost.displayName = 'GooglePost'

export default GooglePost

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import withConfirm from 'utils/withConfirm'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import { Text, H4 } from 'components/atoms/Typography'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import GradientBadge from 'components/molecules/GradientBadge'
import {
  ROUTE_LABELS,
  COLORS_PALETTE,
  GRADIENT_PALETTE,
  PALETTE_TYPE_COLOR,
  PALETTE_TYPE_GRADIENT,
} from 'routes/Calendar/consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: transparent;
    z-index: 2147483004;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 600px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 690px;
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledInput = styled(Input)`
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
  width: 100%;
`

const StyledInputColor = styled.input`
  width: 100%;
  padding: ${space.xs} ${space.m};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  border-radius: ${radius.l};
  height: ${pxToRem(40)};
  background: transparent;
  cursor: pointer;
`

const StyledColorPaletteItemWrapper = styled(Box)`
  width: 28px;
  height: 28px;
  margin-right: 14px;
  margin-bottom: 14px;
  transform: scale(1);
  transition: transform 100ms ease 0s;
  &:hover {
    transform: scale(1.2);
  }
`

const StyledColorPaletteItem = styled(Box)`
  background: transparent;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 50%;
  box-shadow: ${({ boxColor }) => boxColor} 0px 0px 0px ${({ selected }) => (selected ? '3px' : '14px')} inset;
  transition: box-shadow 100ms ease 0s;
  border: 1px solid ${({ hasBorder, theme }) => (hasBorder ? theme.colors.border_color : 'transparent')};
`

const StyledGradientPaletteItemWrapper = styled(Flex)`
  width: 64px;
  height: 64px;
  border: 1px solid transparent;
  background-image: ${({ backgroundImage }) => backgroundImage};
  cursor: pointer;
  border-radius: ${radius.l};
  ${({ selected, theme }) =>
    selected && `border: 1px solid ${theme.colors.primaryText}; box-shadow: 0px 0px 5px 5px rgb(39 40 49 / 5%);`}
  &:hover {
    box-shadow: 0px 0px 5px 5px rgb(39 40 49 / 5%);
  }
`

const Tab = styled(Box)`
  margin-right: ${space.m};
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : 'transparent')};
  &:visited {
    color: initial;
  }
  &:hover {
    border-bottom: 2px solid ${({ isActive, theme }) => (isActive ? theme.colors.primary : theme.colors.secondaryText)};
  }
`

const PALETTE_TABS = [
  { name: 'Color', type: PALETTE_TYPE_COLOR },
  { name: 'Gradient', type: PALETTE_TYPE_GRADIENT },
]

const LabelModal = ({ isOpen, handleDismiss, labels, handleUpdateDataLabels, type, labelOptions, confirm }) => {
  const [label, setLabel] = useState({})
  const [activePaletteTab, setActivePaletteTab] = useState(PALETTE_TYPE_GRADIENT)
  const [isLabelSaving, setIsLabelSaving] = useState(false)

  const { label: text = '', background_color = '', background_type = '', text_color = 'primaryText' } = label

  let inputColor = COLOR_CONSTANTS.SAPPHIRE

  if (background_color && background_color[0] === '#') {
    inputColor = background_color
  }

  useEffect(() => {
    if (isOpen) {
      if (labelOptions.background_type) {
        setActivePaletteTab(labelOptions.background_type)
      }

      setLabel({ ...labelOptions })
    }
  }, [isOpen])

  const handleClickSaveLabel = async () => {
    if (!text) {
      Alert.error('Please provide label text.', { timeout: 5000 })
      return
    }

    if (text.length > 30) {
      Alert.error('Label text must be not longer than 30 chars.', { timeout: 5000 })
      return
    }

    let foundExistingLabelIndex = -1

    if (!labelOptions.label || labelOptions.label !== text) {
      foundExistingLabelIndex = labels.findIndex((item) => item.label === text)
    }

    if (foundExistingLabelIndex > -1) {
      Alert.error(`${type.charAt(0).toUpperCase()}${type.substring(1).toLowerCase()} label already exists.`, {
        timeout: 5000,
      })
      return
    }

    try {
      setIsLabelSaving(true)

      const body = {
        labels: [...labels],
        type,
      }

      label.value = text

      const foundLabelIndex = labelOptions.label
        ? body.labels.findIndex((item) => item.label === labelOptions.label)
        : -1

      if (labelOptions.label) {
        if (labelOptions.label !== text) {
          body.action = 'save'

          body.oldValue = labelOptions.label
          body.newValue = text
        } else {
          body.action = 'update'
        }

        body.labels[foundLabelIndex] = { ...label }
      } else {
        body.action = 'add'
        body.labels.unshift(label)
      }

      const response = await request({
        method: 'PUT',
        path: ROUTE_LABELS,
        body,
      })

      const { error } = response || {}

      setIsLabelSaving(false)

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        Alert.success(`${type.charAt(0).toUpperCase()}${type.substring(1).toLowerCase()} label has been updated.`, {
          timeout: 5000,
        })

        if (body.action === 'add') {
          labels.unshift(label)
        } else {
          labels[foundLabelIndex] = { ...label }

          if (body.oldValue) {
            labels[foundLabelIndex].oldValue = body.oldValue
          }
        }

        handleUpdateDataLabels({ labels })
      }
    } catch (error) {
      errorHelper({ error, componentName: LabelModal.displayName, functionName: 'handleClickSaveLabel' })
      setIsLabelSaving(false)
    }
  }

  const handleClickRemoveLabel = async () => {
    try {
      const foundLabelIndex = labels.findIndex((item) => item.value === labelOptions.label)

      const body = {
        labels: [...labels],
        type,
        action: 'remove',
        oldValue: labelOptions.label,
      }

      body.labels.splice(foundLabelIndex, 1)

      const response = await request({
        method: 'PUT',
        path: ROUTE_LABELS,
        body,
      })

      const { error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        Alert.success(`${type.charAt(0).toUpperCase()}${type.substring(1).toLowerCase()} label has been removed.`, {
          timeout: 5000,
        })

        labels[foundLabelIndex].removed = true

        handleUpdateDataLabels({ labels })
      }
    } catch (error) {
      errorHelper({ error, componentName: LabelModal.displayName, functionName: 'handleClickRemoveLabel' })
    }
  }

  const handleClickCloseModal = () => {
    if (!isLabelSaving) {
      handleDismiss()
    } else {
      Alert.error('Please wait a bit longer while label data is being processed.', { timeout: 5000 })
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper p="m" tabIndex={0} $isTop justifyContent="space-between">
            <H4>Label settings</H4>

            <GradientBadge label={{ ...label, label: text || 'your label preview' }} />
          </StyledDialogEnvironmentWrapper>
          <Scrollbars universal>
            <Flex flexDirection="column" my="m" px="m" flex="1" justifyContent="center">
              <Flex flexDirection="column" width="100%">
                <StyledInput
                  type="text"
                  placeholder="Enter label"
                  defaultValue={text}
                  onChange={(e) => {
                    label.label = e.target.value

                    setLabel({ ...label })
                  }}
                />
              </Flex>

              <Flex mt="m" flexDirection="column">
                <Text mb="xs" fontSize="s">
                  Select background color
                </Text>

                <Flex height="30px" alignItems="center">
                  {PALETTE_TABS.map(({ name, type }) => {
                    return (
                      <Tab
                        key={name}
                        onClick={() => {
                          setActivePaletteTab(type)
                        }}
                        isActive={type === activePaletteTab}
                      >
                        <Text color={type === activePaletteTab ? 'primary' : 'secondaryText'} fontSize="s">
                          {name}
                        </Text>
                      </Tab>
                    )
                  })}
                </Flex>

                {activePaletteTab === PALETTE_TYPE_COLOR && (
                  <Flex mt="s" flexDirection="column" width="100%">
                    <StyledInputColor
                      type="color"
                      value={inputColor}
                      onChange={(event) => {
                        label.background_color = event.target.value
                        label.background_type = PALETTE_TYPE_COLOR

                        setLabel({ ...label })
                      }}
                    />
                    <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(35px, max-content))">
                      {COLORS_PALETTE.map(({ color, hasBorder }) => (
                        <StyledColorPaletteItemWrapper key={color}>
                          <span>
                            <StyledColorPaletteItem
                              boxColor={color}
                              hasBorder={hasBorder}
                              selected={color === background_color}
                              onClick={() => {
                                label.background_color = color
                                label.background_type = PALETTE_TYPE_COLOR

                                setLabel({ ...label })
                              }}
                            />
                          </span>
                        </StyledColorPaletteItemWrapper>
                      ))}
                    </Grid>
                  </Flex>
                )}

                {activePaletteTab === PALETTE_TYPE_GRADIENT && (
                  <Grid mt="s" gridTemplateColumns="repeat(auto-fit, minmax(60px, max-content))" gridGap="s">
                    {GRADIENT_PALETTE.map(({ color }) => (
                      <StyledGradientPaletteItemWrapper
                        key={color}
                        selected={color === background_color}
                        onClick={() => {
                          label.background_color = color
                          label.background_type = PALETTE_TYPE_GRADIENT

                          setLabel({ ...label })
                        }}
                        backgroundImage={color}
                      />
                    ))}
                  </Grid>
                )}
              </Flex>

              <Flex mt="m" flexDirection="column">
                <Text mb="xs" fontSize="s">
                  Select text color
                </Text>

                <Flex flexDirection="column" width="100%">
                  <StyledInputColor
                    type="color"
                    value={text_color}
                    onChange={(event) => {
                      label.text_color = event.target.value

                      setLabel({ ...label })
                    }}
                  />
                  <Grid mt="m" gridTemplateColumns="repeat(auto-fit, minmax(35px, max-content))">
                    {COLORS_PALETTE.map(({ color, hasBorder }) => (
                      <StyledColorPaletteItemWrapper key={color}>
                        <span>
                          <StyledColorPaletteItem
                            boxColor={color}
                            hasBorder={hasBorder}
                            selected={color === text_color}
                            onClick={() => {
                              label.text_color = color

                              setLabel({ ...label })
                            }}
                          />
                        </span>
                      </StyledColorPaletteItemWrapper>
                    ))}
                  </Grid>
                </Flex>
              </Flex>
            </Flex>
          </Scrollbars>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Flex alignItems="center">
              <Button.Gray onClick={handleClickCloseModal} isSmall>
                Close
              </Button.Gray>

              {labelOptions.label && (
                <Button.Gray
                  ml="m"
                  buttonComp={Button.Gray}
                  isSmall
                  onClick={() => {
                    confirm({
                      fn: () => () => handleClickRemoveLabel(),
                      message: `Are you sure you want to remove this label: ${text}?`,
                      action: 'Remove',
                    })
                  }}
                >
                  Remove
                </Button.Gray>
              )}
            </Flex>

            <ButtonWithLoading isLoading={isLabelSaving} isSmall onClick={handleClickSaveLabel}>
              {isLabelSaving ? 'Saving' : 'Save'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

LabelModal.defaultProps = {
  labels: [],
}

LabelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  labelOptions: PropTypes.object.isRequired,
  labels: PropTypes.array,
  type: PropTypes.string.isRequired,
  handleUpdateDataLabels: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
}

LabelModal.displayName = 'LabelModal'

export default withConfirm(LabelModal)

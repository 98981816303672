import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import { radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import { Text, H4 } from 'components/atoms/Typography'
import Icon from 'components/atoms/Icon'
import GradientBadge from 'components/molecules/GradientBadge'
import LabelModal from './LabelModal'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ hasBackground, theme }) =>
      hasBackground ? transparentize(0.2, theme.colors.background_modal_overlay) : 'transparent'};
    z-index: 2147483004;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 580px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 500px;
    top: 50%;
    transform: translate(0, -50%);
    outline: none;
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledLabelItemWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.border_color_light};
  width: 100%;
  cursor: pointer;
`

const StyledRoundedButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const StyledInput = styled(Input)`
  padding-top: 0;
  padding-bottom: 0;
  height: 32px;
  width: 100%;
`

const LabelItem = ({ label, value, handleClickSaveLabel, handleClickRemoveLabel, isDataLabelsUpdating }) => {
  const inputRef = useRef(null)

  const [isEditing, setIsEditing] = useState(false)

  const handleClickSave = ({ label }) => {
    const updatedValue = label || value
    const allowed = handleClickSaveLabel({
      value,
      label: updatedValue,
      hasChanged: value !== updatedValue,
    })
    if (allowed) {
      setIsEditing(false)
    } else {
      Alert.error(`You already have a label ${updatedValue}.`)
    }
  }

  const handleClickOpenEdit = () => {
    if (!isEditing && !isDataLabelsUpdating) {
      setIsEditing(true)
    }
  }

  return (
    <Flex
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      py="xs"
      onClick={() => {
        handleClickOpenEdit()
      }}
    >
      <Flex maxWidth="calc(100% - 128px)" width="100%">
        {isEditing ? (
          <Flex flexDirection="column" width="100%">
            <StyledInput
              innerRef={inputRef}
              type="text"
              placeholder="Enter label"
              defaultValue={label}
              onChange={(e) => {
                const { value } = e.target
                inputRef.current.value = value
              }}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleClickSave({ label: inputRef.current.value })
                }
              }}
            />
          </Flex>
        ) : (
          <Excerpt as="span">{label}</Excerpt>
        )}
      </Flex>

      <Flex alignItems="center" ml="s" justifyContent="space-between">
        {isEditing ? (
          <StyledRoundedButton
            onClick={(e) => {
              e.stopPropagation()
              handleClickSave({ label: inputRef.current.value })
            }}
          >
            <Icon.Checkmark fill="icon_color_gray" width="13px" height="13px" />
          </StyledRoundedButton>
        ) : (
          <StyledRoundedButton
            onClick={(e) => {
              e.stopPropagation()
              handleClickOpenEdit()
            }}
          >
            <Icon.Edit fill="icon_color_gray" width="13px" height="13px" />
          </StyledRoundedButton>
        )}
        <StyledRoundedButton
          ml="s"
          onClick={(e) => {
            e.stopPropagation()
            if (!isDataLabelsUpdating) {
              handleClickRemoveLabel({ value })
            }
          }}
        >
          <Icon.Trash fill="icon_color_gray" width="13px" height="13px" />
        </StyledRoundedButton>
      </Flex>
    </Flex>
  )
}

LabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleClickSaveLabel: PropTypes.func.isRequired,
  handleClickRemoveLabel: PropTypes.func.isRequired,
  isDataLabelsUpdating: PropTypes.bool.isRequired,
}

const DEFAULT_LABEL_MODAL_OPTIONS = {
  isOpen: false,
  labelOptions: null,
}

const LabelsEditModal = ({ isOpen, handleDismiss, handleUpdateDataLabels, type, labelOptions, calledFromModal }) => {
  const [labels, setLabels] = useState(null)
  const [filteredLabels, setFilteredLabels] = useState([])
  const [searchString, setSearchString] = useState('')
  const [labelModalOptions, setLabelModalOptions] = useState({ ...DEFAULT_LABEL_MODAL_OPTIONS })

  useEffect(() => {
    if (isOpen) {
      setLabels([...labelOptions])
    }
  }, [isOpen])

  useEffect(() => {
    if (labels) {
      if (searchString) {
        setFilteredLabels(labels.filter((item) => item.label.toLowerCase().includes(searchString.toLowerCase())))
      } else {
        setFilteredLabels([...labels])
      }
    }
  }, [labels, searchString])

  const handleFilterLabelsBySearchDebounce = debounce((text) => {
    setSearchString(text)
  }, 300)

  const handleClickOpenLabelModal = ({ item }) => {
    if (item) {
      delete item.oldValue
    }

    setLabelModalOptions({ ...{ isOpen: true, labelOptions: item ? { ...item } : {} } })
  }

  const handleClickCloseLabelModal = () => {
    setLabelModalOptions({ ...DEFAULT_LABEL_MODAL_OPTIONS })
  }

  const handleUpdateLabels = ({ labels }) => {
    labels.sort((e1, e2) => (e1.label.toLowerCase() > e2.label.toLowerCase() ? 1 : -1))
    setLabels([...labels.filter(({ removed }) => !removed)])

    handleUpdateDataLabels({ labels })

    handleClickCloseLabelModal()
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}} hasBackground={!calledFromModal}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" tabIndex={0} $isTop>
            <H4 my="m">Manage labels</H4>
          </StyledDialogEnvironmentWrapper>
          <Scrollbars universal>
            <Flex flexDirection="column" my="m" px="m" flex="1" justifyContent="center">
              <Flex alignItems="center" width="100%" justifyContent="space-between" py="xs">
                <Flex flexDirection="column" maxWidth="calc(100% - 38px)" width="100%">
                  <StyledInput
                    type="text"
                    placeholder="Search for labels"
                    defaultValue=""
                    onChange={(e) => {
                      handleFilterLabelsBySearchDebounce(e.target.value)
                    }}
                  />
                </Flex>

                <StyledRoundedButton
                  onClick={() => {
                    handleClickOpenLabelModal({})
                  }}
                >
                  <Icon.Plus fill="icon_color_gray" width="13px" height="13px" />
                </StyledRoundedButton>
              </Flex>
              {filteredLabels.map((item) => {
                return (
                  <StyledLabelItemWrapper key={item.value} pl="s">
                    <Flex
                      alignItems="center"
                      width="100%"
                      justifyContent="space-between"
                      py="xs"
                      onClick={() => {
                        handleClickOpenLabelModal({ item })
                      }}
                    >
                      <GradientBadge label={item} />

                      <Flex alignItems="center" ml="s" justifyContent="space-between">
                        <StyledRoundedButton
                          onClick={(e) => {
                            e.stopPropagation()
                            handleClickOpenLabelModal({ item })
                          }}
                        >
                          <Icon.Edit fill="icon_color_gray" width="13px" height="13px" />
                        </StyledRoundedButton>
                      </Flex>
                    </Flex>

                    {/* <LabelItem */}
                    {/*   handleClickSaveLabel={handleClickSaveLabel} */}
                    {/*   label={label} */}
                    {/*   value={value} */}
                    {/*   handleClickRemoveLabel={handleClickRemoveLabel} */}
                    {/*   isDataLabelsUpdating={isDataLabelsUpdating} */}
                    {/* /> */}
                  </StyledLabelItemWrapper>
                )
              })}
            </Flex>
          </Scrollbars>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="flex-end" $isBottom>
            <Flex alignItems="center">
              <Button.Gray onClick={handleClickCloseModal} isSmall>
                Close
              </Button.Gray>
            </Flex>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        {labelModalOptions.isOpen && (
          <LabelModal
            isOpen={labelModalOptions.isOpen}
            handleDismiss={handleClickCloseLabelModal}
            type={type}
            labelOptions={labelModalOptions.labelOptions}
            handleUpdateDataLabels={handleUpdateLabels}
            labels={labels}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}

LabelsEditModal.defaultProps = {
  calledFromModal: false,
}

LabelsEditModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  labelOptions: PropTypes.array.isRequired,
  handleUpdateDataLabels: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  calledFromModal: PropTypes.bool,
}

LabelsEditModal.displayName = 'LabelsEditModal'

export default LabelsEditModal

import Alert from 'react-s-alert'
import UAParser from 'ua-parser-js'
import {
  PROFILE_STATUSES,
  WARNING_PROFILE_LINKS_MAPPING,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  VISTAPAGE,
  BLUESKY,
  PROFILE_TYPE_PROFILE,
  PROFILE_TYPE_PAGE,
  PROFILE_TYPE_GROUP,
  PROFILE_TYPE_BUSINESS,
  PROFILE_TYPE_CREATOR,
  FACEBOOK_INSTAGRAM_PROFILE_CONNECT_SCOPES,
  TWITTER,
  PROFILE_TYPE_TWITTER_PAID,
  PROFILE_TYPE_TWITTER_FREE,
} from 'consts'
import { originCleanUp } from 'helpers'
import errorHelper from 'utils/errorHelper'

const { RECONNECT_TYPE_FB, RECONNECT_TYPE_DOCUMENT, RECONNECT_TYPE_BLUESKY } = {
  RECONNECT_TYPE_FB: 'fb',
  RECONNECT_TYPE_DOCUMENT: 'document',
  RECONNECT_TYPE_BLUESKY: 'bluesky',
}

export const getProfileFlavor = ({ profile }) => {
  let profileType = ''

  if (profile) {
    const { code, profile_url, profile_id = '', type, network } = profile

    const { code: network_code } = network || {}

    // this strange fix is needed because network is in some places as network = code
    const updatedCode = code || network_code || network

    switch (updatedCode) {
      case FACEBOOK:
        profileType = PROFILE_TYPE_PAGE
        if (!profile_url) {
          profileType = PROFILE_TYPE_PROFILE
        } else if (profile_url && profile_url.includes('/groups/')) {
          profileType = PROFILE_TYPE_GROUP
        }
        break
      case INSTAGRAM:
        if (profile_id.startsWith('ig') || type === 'personal') {
          profileType = PROFILE_TYPE_PROFILE
        } else if (type === 'creator') {
          profileType = PROFILE_TYPE_CREATOR
        } else {
          profileType = PROFILE_TYPE_BUSINESS
        }
        break
      case LINKEDIN:
        profileType = PROFILE_TYPE_PROFILE
        if (profile_url && profile_url.includes('/company/')) {
          profileType = PROFILE_TYPE_PAGE
        }
        break
      case TWITTER:
        if (!type || type === 'None') {
          profileType = PROFILE_TYPE_TWITTER_FREE
        } else {
          profileType = PROFILE_TYPE_TWITTER_PAID
        }
        break
      default:
        break
    }
  }

  return profileType
}

export const getProfileDisplay = ({ profile }) => {
  const { code, display = '' } = profile

  const profileType = getProfileFlavor({ profile })

  let displayEnding = ''

  if (code === FACEBOOK) {
    displayEnding = ` Page`
    if (profileType === PROFILE_TYPE_GROUP) {
      displayEnding = ` Group`
    } else if (profileType === PROFILE_TYPE_PROFILE) {
      displayEnding = ` Profile`
    }
  } else if (code === INSTAGRAM) {
    if (profileType === PROFILE_TYPE_PROFILE) {
      displayEnding = ` Profile`
    } else if (profileType === PROFILE_TYPE_BUSINESS) {
      displayEnding = ` Business `
    } else if (profileType === PROFILE_TYPE_CREATOR) {
      displayEnding = ` Creator`
    }
  } else if (code === LINKEDIN) {
    displayEnding = ` Personal Profile`
    if (profileType === PROFILE_TYPE_PAGE) {
      displayEnding = ` Company Page`
    }
  }

  const updatedDisplay = `${display}${displayEnding}`

  return { display: updatedDisplay }
}

export const getProfileStatusTextAndColor = ({ profile, origin }) => {
  const {
    id,
    profile_gid,
    status,
    warning,
    error,
    banned,
    entityId,
    network,
    profile_url,
    profile_id,
    code: profile_code,
  } = profile

  const { code: network_code } = network || {}

  const code = profile_code || network_code

  // console.log(`status ${status}, warning ${warning}, error ${error}, banned ${banned}`)

  let statusColor = 'background_badge_active'
  let errorColor = 'error'
  let hoverMessage = ''

  const updatedOrigin = originCleanUp({ origin })

  const profileType = getProfileFlavor({ profile: { code, profile_url, profile_id } })

  let updatedCode = code

  let reconnectUrl
  let reconnectType

  if (code !== VISTAPAGE) {
    if (code === FACEBOOK || (code === INSTAGRAM && profileType !== PROFILE_TYPE_PROFILE)) {
      reconnectUrl = `/api/${code}/callback?state=${encodeURIComponent(
        JSON.stringify({
          id: entityId,
          origin: updatedOrigin,
          reconnecting_profile_gid: profile_gid || id,
        })
      )}`

      reconnectType = RECONNECT_TYPE_FB
    } else if (code === BLUESKY) {
      reconnectUrl = JSON.stringify({
        entityId,
        reconnecting_profile_gid: profile_gid || id,
        origin: updatedOrigin,
        profileHandle: profile.username,
      })

      reconnectType = RECONNECT_TYPE_BLUESKY
    } else {
      if (profileType === PROFILE_TYPE_PROFILE && code === INSTAGRAM) {
        updatedCode = 'instagramdisplay'
      }

      reconnectUrl = `/api/connect/${updatedCode}?origin=${updatedOrigin}`

      if (entityId) {
        reconnectUrl += `&entityId=${entityId}`
      }

      if (profile_gid || id) {
        reconnectUrl += `&reconnecting_profile_gid=${profile_gid || id}`
      }

      reconnectType = RECONNECT_TYPE_DOCUMENT
    }

    if (status === PROFILE_STATUSES.BLOCKED || warning) {
      hoverMessage = 'Access to profile has been lost. Click to reconnect it.'
      statusColor = 'error'

      if (banned) {
        hoverMessage =
          'This profile is suspended as it has been determined to be in violation with Vista Social terms of use.'
      } else if (warning) {
        hoverMessage = WARNING_PROFILE_LINKS_MAPPING[warning] ? WARNING_PROFILE_LINKS_MAPPING[warning].message : warning
        if (!error) {
          errorColor = 'warning'
          statusColor = 'warning'
        }
      }
    } else if (status === PROFILE_STATUSES.INITIALIZING) {
      statusColor = 'warning'
      hoverMessage = 'Profile is initializing. Please wait a bit longer.'
    }
  }

  return { statusColor, errorColor, hoverMessage, reconnectUrl, reconnectType }
}

export const checkIfFBLibraryIsNotBlocked = () => {
  const defaultFacebookError = `Third party libraries are being blocked by your browser. <a href="https://support.vistasocial.com/hc/en-us/articles/17562055815067" target="_blank">Learn more</a>.`

  let error = ''

  try {
    if (!window.FB) {
      const parser = new UAParser()
      const result = parser.getResult()

      const { name } = result.browser

      if (name === 'Edge') {
        error = `Detected Microsoft Edge tracking prevention. Please turn off and try again. <a href="https://support.vistasocial.com/hc/en-us/articles/17562055815067" target="_blank">Learn more</a>.`
      } else {
        error = defaultFacebookError
      }
    }
  } catch (e) {
    errorHelper({
      error: e,
      componentName: 'Profiles/helpers',
      functionName: 'checkIfFBLibraryIsNotBlocked',
      sendError: false,
      showAlert: false,
    })
    error = defaultFacebookError
  }

  if (error) {
    Alert.error(error, { timeout: 5000 })
    return false
  }

  return true
}

export const connectProfileFunction = ({ reconnectUrl, reconnectType }) => {
  try {
    if (reconnectType === RECONNECT_TYPE_FB) {
      if (checkIfFBLibraryIsNotBlocked()) {
        FB.login(
          async (response) => {
            const { status, authResponse } = response || {}
            const { accessToken } = authResponse || {}

            if (status === 'connected' && accessToken) {
              document.location = `${reconnectUrl}&access_token=${accessToken}`
            } else {
              console.log(`unable to login ${status}, ${JSON.stringify(response)}`)
              Alert.error(`Can't connect network [${status}]. Please contact support.`, { timeout: 5000 })
            }
          },
          {
            scope: FACEBOOK_INSTAGRAM_PROFILE_CONNECT_SCOPES,
          }
        )
      }
    } else if (reconnectType === RECONNECT_TYPE_BLUESKY) {
      window.dispatchEvent(
        new CustomEvent('openSharedModal', {
          detail: { type: 'bluesky_profile_connection', reconnect_data: reconnectUrl },
        })
      )
    } else {
      document.location = reconnectUrl
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Profiles/helpers',
      functionName: 'connectProfileFunction',
      showAlert: false,
      sendError: false,
    })
  }
}

export const PROFILE_EXPORT_FIELDS = [
  { description: 'Name', value: 'name' },
  { description: 'Username', value: 'username' },
  { description: 'Nickname', value: 'nickname' },
  { description: 'URL', value: 'profile_url' },
  { description: 'Status', value: 'status' },
  { description: 'Picture', value: 'picture_url' },
  { description: 'Profile groups', value: 'name' },
  { description: 'Network', value: 'network' },
  { description: 'Date connected', value: 'created' },
  { description: 'User', value: 'user' },
]

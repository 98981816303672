import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS } from 'theme'
import Badge from 'components/atoms/Badge'
import { Text } from 'components/atoms/Typography'
import { PALETTE_TYPE_COLOR, PALETTE_TYPE_GRADIENT } from 'routes/Calendar/consts'

const StyledBadgeStatus = styled(Badge.Status)`
  ${({ backgroundImage }) => backgroundImage && `background-image: ${backgroundImage}; background-color: unset;`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`}
`

const GradientBadge = ({ label, ...props }) => {
  const { label: text, background_color = '', background_type = '', text_color = 'primaryText' } = label

  let backgroundColor
  let backgroundImage

  if (background_type === PALETTE_TYPE_GRADIENT) {
    backgroundImage = background_color
  } else if (background_type === PALETTE_TYPE_COLOR) {
    backgroundColor = background_color || COLOR_CONSTANTS.SAPPHIRE
  }

  return (
    <StyledBadgeStatus
      color={text_color || 'secondaryText'}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      {...props}
    >
      <Text fontSize="xs" textAlign="center" color={text_color || 'primaryText'}>
        {text}
      </Text>
    </StyledBadgeStatus>
  )
}

GradientBadge.propTypes = {
  label: PropTypes.object.isRequired,
}

export default GradientBadge

import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import { radius, space, breakpoints } from 'theme'
import { VISTAPAGE, FEATURE_POST_ENGAGEMENT, IMAGE, IMAGE_DYNAMIC, SNAPCHAT } from 'consts'
import { linkTransformer } from 'helpers'
import errorHelper from 'utils/errorHelper'
import DropDown from 'shared/DropDown'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Switch from 'components/atoms/Switch'
import Image from 'components/atoms/Image'
import Link from 'components/atoms/Link'
import Counter from 'components/molecules/Counter'
import { ROUTE_VISTA_PAGE } from 'routes/VistaPage/consts'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_LINK,
  POST_MIXED_MEDIA,
  VISTAPAGE_COLORS,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  PREVIEW_MEDIA_CLASS_NAME,
  PUBLISH_AS_POST,
  PUBLISH_TO_IMAGE_GRID,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import {
  getPublishAsDropDownValuesForVistaPage,
  getVistaPageImagesGrid,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import ImagesGridTwitter from './components/ImagesGridTwitter'

const PROFILE_IMAGE_SIZE = '2.5em'
const MAX_MEDIA_HEIGHT = '16.5em'

const StyledFlex = styled(Flex)`
  border-radius: ${radius.m};
  border: 1px solid rgb(207, 217, 222);
  border-bottom: ${({ isPostFormDirty }) => (isPostFormDirty ? 'none' : 'inset')};
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
  width: ${PROFILE_IMAGE_SIZE};
  height: ${PROFILE_IMAGE_SIZE};
`

const SourceWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  height: 100%;
  border-color: rgb(207, 217, 222);
  border-radius: 16px;
  border-width: 1px;
  border-style: double;
  overflow: hidden;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledWrapper = styled(Flex)`
  border-bottom-width: 1px;
  border-bottom-color: rgb(207, 217, 222);
  border-bottom-style: solid;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.3, theme.colors.box_shadow)};
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

const StyledImageWithFallback = styled(ImageWithFallback)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
  aspect-ratio: 1;
`

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === VISTAPAGE)

const { maxImages: MAX_IMAGES, postTextMaxLimit: MAX_TEXT_LENGTH } = networkLimits

const VISTA_PAGE_GRID = 'vista_page_grid'

const VistaPagePost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  isEditable,
  isSmallPreview,
  noVideoAttachment,
  hasPreview,
  postErrors,
  featuresEnabled,
  selectedEntities,
  selectedProfiles,
  previewProfileNumber,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  // const [customizationsSchema, setCustomizationsSchema] = useState({ showOffer: false })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })

    if (isEditable) {
      getVistaPageImagesGrid({
        id: profile.profile_id,
        type: VISTA_PAGE_GRID,
        dataForCustomizations,
        setDataForCustomizations,
      })
    }
  }, [props.profile])

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    type: profile_type,
    profile_id,
  } = profile

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  const { url, title, description, picture_url, id: linkId } = link || {}
  const { published_link } = result || {}

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const { hasErrors, flags, warning_flags } = profilesWithErrors[profileId] || {}

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostMixedMedias = postMixedMedias
  let publish_as_image = false
  let publish_as = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({ publish_as_image = false, publish_as = null } = profileCustomizations)
  }

  const { customized_post_data } = useMemo(() => {
    return {
      customized_post_data: JSON.parse(
        JSON.stringify({
          postComponent: customizedPostComponent,
          postImages: customizedPostImages,
          postMixedMedias: customizedPostMixedMedias,
        })
      ),
    }
  }, [
    customizedPostImages,
    customizedPostImages.length,
    customizedPostMixedMedias,
    customizedPostMixedMedias.length,
    customizedPostComponent,
  ])

  const PUBLISH_AS_DROPDOWN_VALUES = getPublishAsDropDownValuesForVistaPage({
    postComponent: customizedPostComponent,
    post,
  })

  const { [profile_id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { typeData = {} } = networkDataForCustomizations || {}

  const { [VISTA_PAGE_GRID]: { grid_images = [] } = {} } = typeData || {}

  const handleUpdateCustomization = ({ type, secondaryType, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    if (secondaryType) {
      if (!post.customizations[network][profileId][type]) {
        post.customizations[network][profileId][type] = {}
      }
      post.customizations[network][profileId][type][secondaryType] = value
    } else {
      post.customizations[network][profileId][type] = value
    }
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const { variableTransformedText, transformed_text_to_html_for_textarea } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({
      str: updatedPostText,
      savedVariables,
      post,
      profileId,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: variableTransformedText,
      color: VISTAPAGE_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      transformHashstag: true,
      hashtagColor: VISTAPAGE_COLORS.LAST_LIGHT_BLUE,
      click_url: `${process.env.NEXT_PUBLIC_BACKEND_URL}/${ROUTE_VISTA_PAGE}/${profileId}/action`,
      post_id: post.id,
    })
    // clickAction: ` const xhr = new XMLHttpRequest(); xhr.open('POST', \`${click_url}\`, true); xhr.setRequestHeader('Content-Type', 'application/json'); xhr.send(${click_body}); `,

    return { variableTransformedText, transformed_text_to_html_for_textarea }
  }, [updatedPostText, savedVariables])

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: VistaPagePost.displayName,
        functionName: 'url',
        showAlert: false,
        sendError: false,
      })
      urlHost = url
    }
  }

  let updatedPostMedias = []

  if (customizedPostComponent === POST_IMAGE) {
    updatedPostMedias = customizedPostImages.filter(({ tempId }) => !tempId)
  } else if (customizedPostComponent === POST_MIXED_MEDIA) {
    updatedPostMedias = customizedPostMixedMedias.filter(
      ({ tempId, type }) => !tempId && (type === IMAGE || type === IMAGE_DYNAMIC)
    )
  }

  let sourceTypeTemplate = ''

  if (!publish_as || publish_as === PUBLISH_AS_POST) {
    switch (customizedPostComponent) {
      case POST_IMAGE:
      case POST_MIXED_MEDIA:
        if (updatedPostMedias.length !== 0) {
          sourceTypeTemplate = (
            <Flex flexDirection="column">
              <SourceWrapper>
                <ImagesGridTwitter
                  images={updatedPostMedias.slice(0, MAX_IMAGES)}
                  gridHeight="12.875em"
                  network={network}
                  socialPreviewProfiles={[profile]}
                  post={post}
                />
              </SourceWrapper>
            </Flex>
          )
        }
        break
      case POST_LINK:
        if (picture_url || urlHost || title || description) {
          sourceTypeTemplate = (
            <Flex flexDirection="column" position="relative">
              {picture_url && (
                <ImageWithFallback
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${linkId}`}
                  source={picture_url}
                  width="100%"
                  maxHeight={MAX_MEDIA_HEIGHT}
                  fallbackSourceWidth="0px"
                  fallbackSourceHeight="0px"
                  fallbackSource={null}
                  name={linkId}
                  borderRadius="1em"
                />
              )}

              {!publish_as_image && urlHost && (
                <Flex
                  alignItems="center"
                  position="absolute"
                  left="0.75em"
                  bottom="0.75em"
                  bg="rgba(0, 0, 0, 0.3)"
                  height="1.25em"
                  px="0.25em"
                  borderRadius="0.25em"
                >
                  <Link as="a" href={url} target="_blank" rel="noreferrer noopener" color="white" fontSize="0.8125em">
                    {urlHost}
                  </Link>
                </Flex>
              )}
            </Flex>
          )
        }
        break
      default:
        break
    }
  } else if (publish_as === PUBLISH_TO_IMAGE_GRID) {
    let medias = []

    if (customizedPostComponent === POST_IMAGE || customizedPostComponent === POST_MIXED_MEDIA) {
      medias = updatedPostMedias.slice(0, MAX_IMAGES).map((media) => {
        return { _id: media.id, data: { link: '', alt_text: media.description, medias: [media] } }
      })
    } else if (customizedPostComponent === POST_LINK) {
      medias = [
        {
          _id: linkId,
          data: { link: url, alt_text: '', medias: [{ id: linkId, media_gid: linkId, type: IMAGE, url: picture_url }] },
        },
      ]
    }

    if (grid_images && grid_images.length !== 0) {
      medias = [...medias, ...grid_images]
    }

    sourceTypeTemplate = (
      <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="xs">
        {medias.map(({ _id, data = {} }, index) => {
          const { medias = [], alt_text = '', link = '' } = data || {}
          const { 0: media } = medias || []

          const { url, type = IMAGE } = media || {}

          return (
            <StyledFlex
              key={_id || index}
              as={link ? 'a' : 'div'}
              href={link || ''}
              target={link ? '_blank' : ''}
              alt={alt_text}
            >
              {type === IMAGE && (
                <StyledImageWithFallback
                  source={url || '/assets/landscape.svg'}
                  fallbackSource="/assets/landscape.svg"
                  alt={alt_text}
                />
              )}
            </StyledFlex>
          )
        })}
      </Grid>
    )
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `282px calc(100% - 300px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={postVideos}
          customizedPostMixedMedias={customizedPostMixedMedias}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
        />
      )}
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_post_preview' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                flexDirection="column"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
              >
                {isPostFormDirty ? (
                  <StyledWrapper bg="white">
                    <Flex width="100%" px="1em" pt="1em" mb="0.25em">
                      <Box width={`${PROFILE_IMAGE_SIZE}`}>
                        {isEditable ? (
                          <StyledImg
                            source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                            fallbackSource={fallbackLogoImage}
                          />
                        ) : (
                          <SocialProfileImage
                            code={network}
                            picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                            width={`${PROFILE_IMAGE_SIZE}`}
                            height={`${PROFILE_IMAGE_SIZE}`}
                            imageBorderRadius={radius.pill}
                          />
                        )}
                      </Box>
                      <Flex pl="0.5em" ml="0.25em" flexDirection="column" width={`calc(100% - ${PROFILE_IMAGE_SIZE})`}>
                        <Flex flexDirection="column" justifyContent="center">
                          <Flex alignItems="center" flexWrap="wrap">
                            <Flex
                              alignItems="center"
                              onMouseEnter={(e) => {
                                if (!isMultipleProfilesPreview && isEditable) {
                                  tooltipRef.current.handleShowTooltip({
                                    contentComp: (
                                      <ProfileItemTooltip
                                        profile={{
                                          code: network,
                                          picture_url: profilePictureUrl,
                                          profile_url: profileUrl,
                                          name: profileName,
                                          username: profileUsername,
                                          display,
                                          profile_id: profileId,
                                          type: profile_type,
                                        }}
                                        message=""
                                      />
                                    ),
                                    wrapperComp: e.currentTarget,
                                  })
                                }
                              }}
                              onMouseLeave={() => {
                                tooltipRef.current.handleHideTooltip()
                              }}
                            >
                              <StyledLink
                                color={VISTAPAGE_COLORS.MAIN}
                                fontWeight="bold"
                                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                target="_blank"
                                fontSize="0.9375em"
                              >
                                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                              </StyledLink>
                            </Flex>
                            <Text as="span" color={VISTAPAGE_COLORS.TARNISHED_SILVER}>
                              &nbsp;·&nbsp;
                            </Text>
                            <StyledLink
                              as={published_link ? 'a' : 'span'}
                              href={published_link || ''}
                              target="_blank"
                              color={VISTAPAGE_COLORS.TARNISHED_SILVER}
                              fontSize="0.9375em"
                            >
                              {`${publish_at ? `${moment(publish_at).format('MMMM D')}` : 'Now'}`}
                            </StyledLink>
                          </Flex>
                        </Flex>

                        <Flex width="100%" flexDirection="column">
                          <Flex
                            flexDirection="column"
                            width="100%"
                            onClick={() => {
                              if (published_link) {
                                window.open(published_link)
                              }
                            }}
                          >
                            <Text
                              dangerouslySetInnerHTML={{
                                __html: transformed_text_to_html_for_textarea,
                              }}
                              mt="0.5em"
                              color={VISTAPAGE_COLORS.MAIN}
                              whiteSpace="pre-line"
                              fontSize="0.875em"
                            />
                            {customizedPostComponent && sourceTypeTemplate ? (
                              <Box mt="0.5em" mb="0.5em">
                                {sourceTypeTemplate}
                              </Box>
                            ) : (
                              <Box mt="0.5em" mb="0.5em" />
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </StyledWrapper>
                ) : (
                  <Box mt="m" px="s" mb="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper mr="s">
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={variableTransformedText.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              if (isEditable) {
                                setIsPostTextEditOpen(true)
                              }
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'}
                              />
                              <Box>
                                <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                              </Box>
                            </BodyWrapper>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={VISTAPAGE} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={customized_post_data}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            showVideoComponent={false}
                            mediasPreviewDisplay={updatedPostMedias.length !== 0 ? 'block' : 'none'}
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  {(customizedPostComponent === POST_IMAGE || customizedPostComponent === POST_MIXED_MEDIA) &&
                    updatedPostMedias.length > 0 && (
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <DropDown
                            placeholder="Select publishing option"
                            value={
                              publish_as
                                ? PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
                                : PUBLISH_AS_DROPDOWN_VALUES[0]
                            }
                            onChange={(option) => {
                              handleUpdateCustomization({
                                type: 'publish_as',
                                value: option.value,
                              })
                            }}
                            options={PUBLISH_AS_DROPDOWN_VALUES}
                            openMenuOnFocus
                            isSearchable={false}
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="publish_as" network={SNAPCHAT} />
                      </Flex>
                    )}

                  {customizedPostComponent === POST_LINK && picture_url && (
                    <Flex mt="m" minHeight="max-content" flexDirection="column" position="relative">
                      {featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                        !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                          <CustomizationsBlockComponent
                            showUpgradeMessage={false}
                            showUpgradePlan={false}
                            message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                          />
                        )}
                      <Flex
                        flexDirection="column"
                        px={
                          featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                            ? 's'
                            : '0'
                        }
                        py={
                          featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                            ? 'm'
                            : '0'
                        }
                      >
                        <Flex alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <Switch
                              isOn={!!publish_as_image}
                              onClick={() => {
                                handleUpdateCustomization({
                                  type: 'publish_as_image',
                                  value: !publish_as_image,
                                })
                              }}
                              text="Publish as image"
                              fontWeight="normal"
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="publish_as_image" network={VISTAPAGE} />
                        </Flex>
                      </Flex>
                    </Flex>
                  )}

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

VistaPagePost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  isEditable: true,
  isSmallPreview: false,
  noVideoAttachment: false,
  hasPreview: true,
  postErrors: { networksErrors: {} },
  featuresEnabled: {},
  selectedEntities: [],
  selectedProfiles: [],
  previewProfileNumber: 1,
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
}

VistaPagePost.propTypes = {
  user: PropTypes.object,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  isEditable: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  postErrors: PropTypes.object,
  featuresEnabled: PropTypes.object,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  previewProfileNumber: PropTypes.number,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
}

VistaPagePost.displayName = 'VistaPagePost'

export default VistaPagePost

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment-timezone'
import { fontSizes, radius, space } from 'theme'
import { DEFAULT_TIME_ZONE, ERROR_MESSAGE, PLEASE_WAIT_MESSAGE } from 'consts'
import { initializeStringArrayWithRange } from 'helpers'
import withConfirm from 'utils/withConfirm'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import Datepicker from 'components/molecules/Datepicker'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { DATE_FORMAT, POST_TYPE_SOCIAL_POST, ROUTE_BOOST_CONFIGURATION } from 'routes/Calendar/consts'
import BoostsDropdownComponent from './BoostsDropdownComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    outline: none;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledSelect = styled(Select)`
  padding: 0 ${space.xs};
  font-size: ${fontSizes.xs};
  background-position: calc(100% - ${space.s}) center;
`

const HOURS = initializeStringArrayWithRange({ start: 1, end: 12 })
const MINUTES = initializeStringArrayWithRange({ end: 59 })

const generateTimeData = ({ time, timezone = DEFAULT_TIME_ZONE }) => {
  const utc_time_timezone = time ? moment.utc(time).tz(timezone) : moment.utc().tz(timezone)

  const date = utc_time_timezone.format(DATE_FORMAT)
  const hour = utc_time_timezone.format('hh')
  const minute = utc_time_timezone.format('mm')
  const interval = utc_time_timezone.format('A')
  return {
    date,
    hour,
    minute,
    interval,
  }
}

const BoostScheduleManagementModal = ({
  user,
  isOpen,
  handleDismiss,
  boostId,
  boostScheduleId,
  boostScheduleAt,
  selectedProfile,
  publicationId,
  postType,
  confirm,
}) => {
  const [isGettingData, setIsGettingData] = useState(true) // true
  const [isUpdatingBoost, setUpdatingBoost] = useState(false)
  const [boosts, setBoosts] = useState([])

  const [selectedBoost, setSelectedBoost] = useState(boostId)

  const [scheduleTime, setScheduleTime] = useState(
    boostScheduleAt
      ? generateTimeData({ time: boostScheduleAt.time, timezone: boostScheduleAt.timezone })
      : generateTimeData({})
  )

  const getBoosts = async () => {
    try {
      const response = await request({ path: `${ROUTE_BOOST_CONFIGURATION}?include_configuration=true` })

      const { error, data } = response || {}

      if (error || !response) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setBoosts([...data.filter(({ profile: { id } = {} }) => id === selectedProfile.profileId)])
      }
    } catch (error) {
      errorHelper({ error, componentName: BoostScheduleManagementModal.displayName, functionName: 'getBoosts' })
    } finally {
      setIsGettingData(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      // setScheduleTime({ ...generateTimeData({ time: boostScheduleAt.time, timezone: boostScheduleAt.timezone }) })

      getBoosts()
    }
  }, [isOpen])

  const handleChangeSelectedDate = ({ type, value }) => {
    if (type === 'date') {
      scheduleTime[type] = moment(value).format(DATE_FORMAT)
    } else if (type === 'hour' || type === 'minute' || type === 'interval') {
      scheduleTime[type] = value
    }
    setScheduleTime({ ...scheduleTime })
  }

  const handleClickSaveScheduledBoost = async () => {
    if (!selectedBoost) {
      Alert.error('Please select boost configuration.', { timeout: 5000 })
    } else {
      const body = {
        timezone: boostScheduleAt.timezone,
        boost_gid: boostId,
        post_type_gid: publicationId,
      }

      if (postType === POST_TYPE_SOCIAL_POST) {
        body.post_type = 'external'
      }

      const date_raw = `${scheduleTime.date} ${scheduleTime.hour}:${scheduleTime.minute} ${scheduleTime.interval}`

      body.boost_schedule_at = moment(date_raw, `${DATE_FORMAT} hh:mm A`).format(`${DATE_FORMAT} HH:mm`)

      try {
        setUpdatingBoost(true)

        // console.log(scheduleTime)

        const { date, hour, minute, interval } = scheduleTime
        body.boost_schedule_at = `${date} ${hour}:${minute} ${interval}`

        if (postType === POST_TYPE_SOCIAL_POST) {
          body.post_type = 'external'
        }

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/boost-scheduled/${boostScheduleId}`,
          method: 'PATCH',
          body,
        })

        const { error, boost_schedule_gid, boost_schedule_at } = response || {}

        if (error || !response) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          handleDismiss({ method: 'update', data: { boost_gid: boostId, boost_schedule_gid, boost_schedule_at } })
        }
      } catch (error) {
        errorHelper({ error, componentName: BoostScheduleManagementModal.displayName, functionName: 'getBoosts' })
      } finally {
        setUpdatingBoost(false)
      }
    }
  }

  const handleClickRemoveScheduledBoost = async () => {
    try {
      let path = `${ROUTE_BOOST_CONFIGURATION}/boost-scheduled/${boostScheduleId}?post_type_gid=${publicationId}`

      if (postType === POST_TYPE_SOCIAL_POST) {
        path += `&post_type=external`
      }

      const response = await request({
        path,
        method: 'DELETE',
      })

      const { error } = response || {}

      if (error || !response) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        handleDismiss({ method: 'delete' })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: BoostScheduleManagementModal.displayName,
        functionName: 'handleClickRemoveScheduledBoost',
      })
    }
  }

  const handleClickCloseModal = () => {
    if (isGettingData || isUpdatingBoost) {
      Alert.error(PLEASE_WAIT_MESSAGE, { timeout: 5000 })
    } else {
      handleDismiss()
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex={0}>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Manage scheduled boost</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper heigth="100%" width="100%" flexDirection="column" justifyContent="center" p="m">
            {isGettingData ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                className="cliploader-wrapper"
              >
                <ClipLoader size="50" />
              </Flex>
            ) : (
              <Flex flexDirection="column" width="100%" height="100%">
                <Flex alignItems="center">
                  <Flex flexDirection="column">
                    <Box>
                      <Datepicker
                        minDate={new Date()}
                        label="Date"
                        value={scheduleTime.date}
                        onChange={(value) => {
                          if (value) {
                            handleChangeSelectedDate({ type: 'date', value })
                          }
                        }}
                        isClearDateEnabled={false}
                      />
                    </Box>
                  </Flex>

                  <Flex ml="m" flexDirection="column" width="100%" justifyContent="flex-start">
                    <Flex mb="xs">
                      <Text>Time</Text>
                    </Flex>
                    <Flex alignItems="center">
                      <Flex alignItems="center">
                        <Box width="50px">
                          <StyledSelect
                            name="hours"
                            onChange={({ target: { value } }) => {
                              handleChangeSelectedDate({ type: 'hour', value })
                            }}
                            value={scheduleTime.hour}
                          >
                            {HOURS.map((hour) => (
                              <option key={hour} value={hour}>
                                {hour}
                              </option>
                            ))}
                          </StyledSelect>
                        </Box>
                        <Text fontSize="l" mx="s">
                          :
                        </Text>
                        <Box width="50px">
                          <StyledSelect
                            name="minutes"
                            onChange={({ target: { value } }) => {
                              handleChangeSelectedDate({ type: 'minute', value })
                            }}
                            value={scheduleTime.minute}
                          >
                            {MINUTES.map((minute) => (
                              <option key={minute} value={minute}>
                                {minute}
                              </option>
                            ))}
                          </StyledSelect>
                        </Box>
                      </Flex>
                      <Box ml="s" width="50px">
                        <StyledSelect
                          name="interval"
                          onChange={({ target: { value } }) => {
                            handleChangeSelectedDate({ type: 'interval', value })
                          }}
                          value={scheduleTime.interval}
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </StyledSelect>
                      </Box>
                      <Flex alignItems="center">
                        <Text ml="s">{moment().tz(boostScheduleAt.timezone).format('z')}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex flexDirection="column">
                  <BoostsDropdownComponent
                    boosts={boosts && boosts.length !== 0 ? boosts : null}
                    handleUpdateCustomization={({ type, value }) => {
                      setSelectedBoost(value)
                    }}
                    boostId={selectedBoost}
                    user={user}
                    profile={selectedProfile}
                  />
                </Flex>
              </Flex>
            )}
          </StyledDialogBodyWrapper>

          <StyledDialogEnvironmentWrapper p="m" $isBottom justifyContent="space-between">
            <Flex alignItems="center">
              <Button.Gray onClick={handleClickCloseModal} isSmall mr="m">
                Cancel
              </Button.Gray>

              {!isGettingData && (
                <Button.Error
                  isSmall
                  onClick={() => {
                    confirm({
                      fn: () => () => handleClickRemoveScheduledBoost(),
                      message: `Are you sure you want to remove this scheduled boost from this post?`,
                      action: 'Remove',
                    })
                  }}
                >
                  Remove
                </Button.Error>
              )}
            </Flex>

            {!isGettingData && (
              <ButtonWithLoading isLoading={isUpdatingBoost} isSmall onClick={handleClickSaveScheduledBoost}>
                {isUpdatingBoost ? 'Saving' : 'Save'}
              </ButtonWithLoading>
            )}
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

BoostScheduleManagementModal.defaultProps = {}

BoostScheduleManagementModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  boostId: PropTypes.string.isRequired,
  boostScheduleId: PropTypes.string.isRequired,
  boostScheduleAt: PropTypes.object.isRequired,
  selectedProfile: PropTypes.object.isRequired,
  publicationId: PropTypes.string.isRequired,
  postType: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
}

BoostScheduleManagementModal.displayName = BoostScheduleManagementModal

export default withConfirm(BoostScheduleManagementModal)

import React from 'react'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import GradientBadge from 'components/molecules/GradientBadge'
import { ROUTE_LABELS } from 'routes/Calendar/consts'

export const getLabels = async ({ setLabels, type, isCommon = false }) => {
  try {
    const response = await request({
      method: 'GET',
      path: `${ROUTE_LABELS}?type=${type.toLowerCase()}&common=${isCommon ? true : ''}`,
    })
    if (response && !response.error) {
      setLabels([...response])
    }
  } catch (error) {
    errorHelper({ error, componentName: 'LabelEditModal/helpers', functionName: 'getLabels' })
  }
}

export const formatLabelOptionLabel = (data, { context }) => {
  if (context === 'value') {
    return data.label
  }

  // console.log('data', data)
  // console.log('other', other)
  return <GradientBadge label={data} />
}

import React from 'react'
import moment from 'moment-timezone'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import twitter from 'twitter-text'
import Router from 'next/router'
import Cookies from 'js-cookie'
import debounce from 'lodash.debounce'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { hasEntitiesAccess } from 'utils/feature'
import {
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
  GOOGLE,
  REDDIT,
  VIMEO,
  PINTEREST,
  ERROR_MESSAGE,
  DEFAULT_TIME_ZONE,
  TIKTOK,
  IMAGE,
  VIDEO,
  DOCUMENT,
  PERMISSION_PUBLISH,
  USER_ROLE_MANAGE,
  USER_ROLE_CONTRIBUTOR,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  COOKIE_ENTITY_SELECTOR_INSTRUCTIONS,
  PROFILE_TYPE_PROFILE,
  PROFILE_TYPE_CREATOR,
  PROFILE_TYPE_GROUP,
  SNAPCHAT,
  VISTAPAGE,
  TUMBLR,
  THREADS,
  BLUESKY,
} from 'consts'
import { sleep, countAlertTimeout } from 'helpers'
import { Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import { Text } from 'components/atoms/Typography'
import FacebookPost from './components/Posts/FacebookPost'
import InstagramPost from './components/Posts/InstagramPost'
import TwitterPost from './components/Posts/TwitterPost'
import YoutubePost from './components/Posts/YoutubePost'
import LinkedinPost from './components/Posts/LinkedinPost'
import GooglePost from './components/Posts/GooglePost'
import RedditPost from './components/Posts/RedditPost'
import VimeoPost from './components/Posts/VimeoPost'
import PinterestPost from './components/Posts/PinterestPost'
import TikTokPost from './components/Posts/TikTokPost'
import SnapchatPost from './components/Posts/SnapchatPost'
import VistaPagePost from './components/Posts/VistaPagePost'
import TumblrPost from './components/Posts/TumblrPost'
import ThreadsPost from './components/Posts/ThreadsPost'
import BlueskyPost from './components/Posts/BlueskyPost'
import {
  MENTIONS_NETWORKS,
  STATUS_COLORS,
  REVIEW,
  APPROVED,
  PUBLISHED,
  REJECTED,
  DRAFT,
  SPECIAL_UI_STATUS_PUBLISHING,
  ADVOCACY,
  DELETE_POST_NETWORK_LIMITATIONS,
  ROUTE_APPROVERS,
  LOCAL_STORAGE_VISTA_SOCIAL,
  LOCAL_STORAGE_SELECTED_ENTITIES_KEY,
  LOCAL_STORAGE_SELECTED_PROFILES_KEY,
  ROUTE_ENTITIES,
  ROUTE_PUBLISH,
  ROUTE_MEDIA,
  DATE_FORMAT,
  ROUTE_CALENDAR,
  ROUTE_PINTEREST_BOARDS,
  ROUTE_PINTEREST_SECTIONS,
  ROUTE_SEARCH_ACCOUNTS,
  ROUTE_INSTAGRAM_MEDIAS,
  ROUTE_TIKTOK_MEDIAS,
  ROUTE_REDDIT_FLAIRS,
  STATUS_FILTERS,
  POST_CONTENT_TYPE_FILTERS,
  BOOST_STATUS_FILTERS,
  ADVOCACY_STATUS_FILTERS,
  ROUTE_DEVICES,
  ROUTE_FACEBOOK_GROUP_OPTIONS,
  ROUTE_SAVED_SEARCHES,
  ROUTE_YOUTUBE_PLAYLISTS,
  ROUTE_YOUTUBE_CATEGORIES,
  PREVIEW_MEDIA_CLASS_NAME,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  POST_TYPE_SOCIAL_POST,
  PUBLISH_AS_IMAGE,
  PUBLISH_AS_STORY,
  PUBLISH_AS_SAVED_STORY,
  PUBLISH_AS_VIDEO,
  PUBLISH_AS_REELS,
  PUBLISH_AS_CAROUSEL,
  PUBLISH_AS_ARTICLE,
  PUBLISH_AS_SLIDESHOW,
  PUBLISH_AS_USER_TYPE_PERSONAL,
  PUBLISH_AS_SPOTLIGHT,
  PUBLISH_AS_POST,
  PUBLISH_TO_IMAGE_GRID,
  CALENDAR_PAGE_VIEW_TYPE_CALENDAR,
  CALENDAR_PAGE_VIEW_TYPE_LIST_ALL_POSTS,
  PUBLISHING_OPTION_NOTIFICATION,
  PUBLISHING_OPTION_DIRECT,
  NETWORK_LIMITS,
  MENTION_URL_NETWORK_START,
  MENTION_GROUP_NAME_REGEX,
  URL_REGEXP,
} from './consts'
import { getProfileFlavor } from '../Settings/Profiles/helpers'
import { ROUTE_VISTA_PAGE } from '../VistaPage/consts'

export const getPostComponentByNetworks = (network) => {
  if (network === FACEBOOK) {
    return FacebookPost
  } else if (network === INSTAGRAM) {
    return InstagramPost
  } else if (network === TWITTER) {
    return TwitterPost
  } else if (network === YOUTUBE) {
    return YoutubePost
  } else if (network === LINKEDIN) {
    return LinkedinPost
  } else if (network === GOOGLE) {
    return GooglePost
  } else if (network === REDDIT) {
    return RedditPost
  } else if (network === VIMEO) {
    return VimeoPost
  } else if (network === PINTEREST) {
    return PinterestPost
  } else if (network === TIKTOK) {
    return TikTokPost
  } else if (network === SNAPCHAT) {
    return SnapchatPost
  } else if (network === VISTAPAGE) {
    return VistaPagePost
  } else if (network === TUMBLR) {
    return TumblrPost
  } else if (network === THREADS) {
    return ThreadsPost
  } else if (network === BLUESKY) {
    return BlueskyPost
  }
  return FacebookPost
}

const latinAccentChars = /\xC0-\xD6\xD8-\xF6\xF8-\xFF\u0100-\u024F\u0253\u0254\u0256\u0257\u0259\u025B\u0263\u0268\u026F\u0272\u0289\u028B\u02BB\u0300-\u036F\u1E00-\u1EFF/
const validMentionPrecedingChars = /(?:^|[^a-zA-Z0-9_!#$%&*@＠/]|(?:^|[^a-zA-Z0-9_+~.-])(?:rt|RT|rT|Rt):?)/
const atSigns = /[@＠]/
const regexSupplant = (regex, map, flags) => {
  flags = flags || ''

  if (typeof regex !== 'string') {
    if (regex.global && flags.indexOf('g') < 0) {
      flags += 'g'
    }

    if (regex.ignoreCase && flags.indexOf('i') < 0) {
      flags += 'i'
    }

    if (regex.multiline && flags.indexOf('m') < 0) {
      flags += 'm'
    }

    regex = regex.source
  }

  return new RegExp(
    regex.replace(/#\{(\w+)\}/g, function(match, name) {
      let newRegex = map[name] || ''

      if (typeof newRegex !== 'string') {
        newRegex = newRegex.source
      }

      return newRegex
    }),
    flags
  )
}

const endMentionMatch = regexSupplant(/^(?:#{atSigns}|[#{latinAccentChars}]|:\/\/)/, {
  atSigns,
  latinAccentChars,
})

const validMentionOrList = regexSupplant(
  '(#{validMentionPrecedingChars})(#{atSigns})([a-zA-Z0-9._-]{1,50})(/[a-zA-Z][a-zA-Z0-9._-]{0,24})?',
  {
    validMentionPrecedingChars,
    atSigns,
  },
  'g'
)

export const extractMentionsOrListsWithIndices = (text) => {
  if (!text || !text.match(atSigns)) {
    return []
  }

  const possibleNames = []
  text.replace(validMentionOrList, function(match, before, atSign, screenName, slashListname, offset, chunk) {
    const after = chunk.slice(offset + match.length)

    if (!after.match(endMentionMatch)) {
      // eslint-disable-next-line no-param-reassign
      slashListname = slashListname || ''
      const startPosition = offset + before.length
      const endPosition = startPosition + screenName.length + slashListname.length + 1
      possibleNames.push({
        screenName,
        listSlug: slashListname,
        indices: [startPosition, endPosition],
      })
    }
  })
  return possibleNames
}

export const extractMentionsWithIndices = (text) => {
  const mentions = []
  let mentionOrList
  const mentionsOrLists = extractMentionsOrListsWithIndices(text)

  for (let i = 0; i < mentionsOrLists.length; i++) {
    mentionOrList = mentionsOrLists[i]

    if (mentionOrList.listSlug === '') {
      mentions.push({
        screenName: mentionOrList.screenName,
        indices: mentionOrList.indices,
      })
    }
  }

  return mentions
}

export const mentionsTransformer = ({
  body = '',
  color,
  fontWeight = 500,
  network,
  agencyMentions = [],
  agencyMentionsSkipped = [],
}) => {
  const extractMentionsWithIndicesTemp = extractMentionsWithIndices(body)
  let MENTION_SYMBOL = '@'
  const mentions = []
  const notFoundMentions = []
  let updatedText = body
  let updatedTextTransformedMentions = body
  let offset = 0
  let offsetTransformedMentions = 0

  if (extractMentionsWithIndicesTemp.length !== 0) {
    extractMentionsWithIndicesTemp.forEach(({ indices: { 0: startIndex, 1: endIndex }, screenName }) => {
      const screenNameCleaned = screenName.replace(MENTION_GROUP_NAME_REGEX, '').toLowerCase()

      const foundMention = agencyMentions
        ? agencyMentions.find(({ name }) => name.toLowerCase() === screenNameCleaned)
        : undefined

      let mentionDisplayInText = screenName

      const foundAgencyMentionSkipped = agencyMentionsSkipped
        ? agencyMentionsSkipped.find(({ name }) => name.toLowerCase() === screenNameCleaned)
        : undefined

      if (foundMention && foundMention[network] && Object.keys(foundMention[network]).length > 0) {
        if (!foundAgencyMentionSkipped) {
          mentions.push(foundMention)
        }

        if (network === LINKEDIN || network === FACEBOOK) {
          mentionDisplayInText = foundMention[network].name || foundMention[network].username
          MENTION_SYMBOL = ''
        } else {
          if (network === REDDIT) {
            MENTION_SYMBOL = 'u/'
          }

          mentionDisplayInText = foundMention[network].username || foundMention[network].name
        }
      } else if (!foundAgencyMentionSkipped) {
        notFoundMentions.push({ screenName, screenNameCleaned })
      }

      let mentionHTMLText = ''

      let url = ''

      if (foundMention && foundMention[network] && MENTION_URL_NETWORK_START[network]) {
        if (network === FACEBOOK || network === BLUESKY) {
          url = `${MENTION_URL_NETWORK_START[network]}${foundMention[network].id || mentionDisplayInText}`
        } else if (network === LINKEDIN) {
          if (foundMention[network].id) {
            if (!`${foundMention[network].id}`.includes('urn:li:person')) {
              url = `${MENTION_URL_NETWORK_START[network]}company/${foundMention[network].username ||
                foundMention[network].name}`
            }
          }
        } else {
          url = `${MENTION_URL_NETWORK_START[network]}${mentionDisplayInText}`
        }
      }

      if (url) {
        mentionHTMLText = `<a style="color:${color}; font-weight:${fontWeight}; text-decoration: none;" href="${url}" target="_blank">${MENTION_SYMBOL}${mentionDisplayInText}</a>`
      } else {
        mentionHTMLText = `<span style="color:${color}; font-weight:${fontWeight}; cursor: pointer;">${MENTION_SYMBOL}${mentionDisplayInText}</span>`
      }

      updatedText = `${updatedText.substring(0, startIndex + offset)}${mentionHTMLText}${updatedText.substring(
        endIndex + offset
      )}`

      offset += mentionHTMLText.length - screenName.length - 1

      const mentionHTMLTextTransformed = `${MENTION_SYMBOL}${mentionDisplayInText}`

      updatedTextTransformedMentions = `${updatedTextTransformedMentions.substring(
        0,
        startIndex + offsetTransformedMentions
      )}${mentionHTMLTextTransformed}${updatedTextTransformedMentions.substring(endIndex + offsetTransformedMentions)}`

      offsetTransformedMentions += mentionHTMLTextTransformed.length - screenName.length - 1
    })
  }

  return { updatedText, updatedTextTransformedMentions, mentionsFromPost: mentions, notFoundMentions }
}

export const formatTimezoneToGMT = (zone) => {
  if (!zone) {
    return { long: '', middle: '', short: '' }
  }

  const time = moment.tz(zone).format('Z')
  let zoneShort = moment()
    .tz(zone)
    .format('z')

  try {
    const numberTimezone = Number(zoneShort)
    if (numberTimezone) {
      zoneShort = ''
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'formatTimezoneToGMT',
      sendError: false,
      showAlert: false,
    })
  }

  const zoneFull = zone.replace(/_/g, ' ')
  return {
    long: `GMT ${time} ${zoneFull}`,
    longWithoutCountry: `GMT ${time} ${zoneFull.split('/')[1]}`,
    middle: `GMT ${time} ${zoneShort}`,
    short: zoneShort || `GMT ${time}`,
  }
}

export const checkPostTextForNotCreatedMentions = ({ post, selectedProfiles }) => {
  const { postText, agencyMentions = [], customizations = {}, agencyMentionsSkipped = [] } = post

  const checkedSelectedProfilesForMentions = []
  selectedProfiles.forEach(({ id, code, display }) => {
    if (MENTIONS_NETWORKS.find((mention_network) => mention_network.code === code && mention_network.active)) {
      let updatedPostText = postText
      if (customizations[code] && customizations[code][id]) {
        const comment = customizations[code][id].comment || ''
        updatedPostText = `${customizations[code][id].message || postText} ${comment}`
      }

      checkedSelectedProfilesForMentions.push({ network: code, body: updatedPostText, display, id })
    }
  })

  const networksWithoutMentions = []

  checkedSelectedProfilesForMentions.forEach(({ network, body, display }) => {
    const { notFoundMentions } = mentionsTransformer({ body, network, agencyMentions, agencyMentionsSkipped })
    if (notFoundMentions.length !== 0) {
      networksWithoutMentions.push({ network, notFoundMentions, display })
    }
  })

  let collectNetworksByMention = {}

  networksWithoutMentions.forEach(({ network, notFoundMentions, display }) => {
    notFoundMentions.forEach(({ screenName, screenNameCleaned }) => {
      if (!collectNetworksByMention[screenNameCleaned]) {
        const foundMention = agencyMentions
          ? agencyMentions.find(({ name }) => name.toLowerCase() === screenNameCleaned) || {}
          : {}
        collectNetworksByMention[screenNameCleaned] = {
          name: screenNameCleaned,
          screenName,
          profiles: [],
          ...foundMention,
        }
      }

      const foundProfile = collectNetworksByMention[screenNameCleaned].profiles.find((item) => item.network === network)
      if (!foundProfile) {
        collectNetworksByMention[screenNameCleaned].profiles.push({ network, display })
      }
    })
  })

  collectNetworksByMention = Object.values(collectNetworksByMention)

  return {
    shouldOpenMentionsModal: collectNetworksByMention.length !== 0,
    mentionsModalData: collectNetworksByMention[0],
  }
}

export const getHeaderTitleAndColor = ({ status, network, post }) => {
  const { result, customizations, profile_gid } = post || {}
  const { published_link } = result || {}

  const postCustomization = customizations && customizations[network] ? customizations[network][profile_gid] || {} : {}
  const { media_type, publish_as } = postCustomization

  const foundStatus = STATUS_FILTERS.find(({ id }) => id === status)

  let statusLabel = foundStatus ? foundStatus.name : ''

  let bgColor = STATUS_COLORS[status] || STATUS_COLORS[APPROVED]

  let eventBgColor = null

  if (status === REVIEW) {
    eventBgColor = transparentize(0.9, bgColor)
  }

  if (network === TIKTOK && status === PUBLISHED && !published_link) {
    statusLabel = 'Uploaded. Waiting for TikTok to finish processing.'
    bgColor = STATUS_COLORS[REJECTED]
  }

  if (status === ADVOCACY) {
    const foundStatus = STATUS_FILTERS.find(({ id }) => id === PUBLISHED)
    statusLabel = foundStatus.name
    bgColor = STATUS_COLORS[PUBLISHED]
  }

  let post_type = 'post'
  if ((media_type && media_type === PUBLISH_AS_STORY) || (publish_as && publish_as === PUBLISH_AS_STORY)) {
    post_type = 'story'
  }

  return { statusLabel: `${statusLabel} ${post_type}`, eventBgColor, bgColor }
}

export const formatOptionLabel = ({ value, label, username, picture_url, specialSymbol }) => (
  <Flex alignItems="center" key={value || username}>
    <ImageWithFallback source={picture_url} width="20px" height="20px" />
    <Flex ml="s" flexDirection="column">
      <Text>{label}</Text>
      {username && (
        <Text color="secondaryText" fontSize="xs">
          {`${specialSymbol || ''}${username}`}
        </Text>
      )}
    </Flex>
  </Flex>
)

export const getApproversData = async ({ selectedProfiles, publication_group_gid = null }) => {
  try {
    const response = await request({
      method: 'POST',
      path: ROUTE_APPROVERS,
      body: {
        targets: selectedProfiles.map(({ id, entityId, code }) => {
          const data = { profile_gid: id, network: code }
          data.entity_gid = entityId
          return data
        }),
        publication_group_gid,
      },
    })

    const { error } = response || {}
    if (!response || error) {
      Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
    } else {
      const { is_approver, approvers } = response
      return {
        is_approver,
        approvers: approvers
          .filter(({ last_login }) => last_login)
          .map(({ user_gid, first_name, last_name, picture, entity_gids = [] }) => {
            return {
              value: user_gid,
              label: `${first_name} ${last_name}`,
              picture_url: picture,
              entity_gids,
            }
          }),
      }
    }
    return {}
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getApproversData',
      errorMessage: `Unable to retrieve users for assignment. Please contact support.`,
    })
  }
  return {}
}

export const saveSelectedEntitiesToStorage = ({ data, user }) => {
  const { id } = user
  const entitiesTemp = {}
  const entitiesForSaveToStorage = {}
  data.forEach((item) => {
    const { id, type, isSample, timezone } = item

    if (!isSample) {
      entitiesTemp[id] = item
      entitiesForSaveToStorage[id] = { id, type, timezone }
    }
  })
  localStorage.setItem(
    `${LOCAL_STORAGE_SELECTED_ENTITIES_KEY}_${id}`,
    JSON.stringify(Object.values(entitiesForSaveToStorage))
  )
  return { entitiesTemp: Object.values(entitiesTemp) }
}

export const saveSelectedProfilesToStorage = ({ data, removedSocialProfiles = [], user }) => {
  const { id } = user
  const profilesTemp = {}
  const profilesForSaveToStorage = {}

  data.forEach((item) => {
    const { id, entityId, isSample, warning, status } = item
    const key = `${id}_${entityId}`
    if (!isSample) {
      profilesTemp[key] = item
      profilesForSaveToStorage[key] = { id, entityId, warning, status }
    }
  })

  removedSocialProfiles.forEach((item) => {
    const { id, entityId, warning, status } = item
    const key = `${id}_${entityId}`
    profilesForSaveToStorage[key] = { id, entityId, warning, status }
  })

  localStorage.setItem(
    `${LOCAL_STORAGE_SELECTED_PROFILES_KEY}_${id}`,
    JSON.stringify(Object.values(profilesForSaveToStorage))
  )
  return { profilesTemp: Object.values(profilesTemp) }
}

export const getSelectedProfilesFromStorage = ({ user }) => {
  let ProjectSelectedProfiles

  if (user && user.id) {
    try {
      ProjectSelectedProfiles = localStorage.getItem(`${LOCAL_STORAGE_SELECTED_PROFILES_KEY}_${user.id}`)
      if (ProjectSelectedProfiles) {
        ProjectSelectedProfiles = JSON.parse(ProjectSelectedProfiles) || []
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'getSelectedProfilesFromStorage',
        sendError: false,
        showAlert: false,
      })
    }
  }

  return ProjectSelectedProfiles
}

export const getSelectedEntitiesFromStorage = ({ user }) => {
  let ProjectSelectedEntities

  if (user && user.id) {
    try {
      ProjectSelectedEntities = localStorage.getItem(`${LOCAL_STORAGE_SELECTED_ENTITIES_KEY}_${user.id}`)
      if (ProjectSelectedEntities) {
        ProjectSelectedEntities = JSON.parse(ProjectSelectedEntities) || []
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'getSelectedEntitiesFromStorage',
        sendError: false,
        showAlert: false,
      })
    }
  }

  return ProjectSelectedEntities
}

export const getSelectedEntitiesAndProfilesFromStorage = async ({
  user,
  entities,
  setSelectedProfiles,
  setSelectedEntities,
  setEntitySelectorKey,
  handleSaveSelectedEntities,
  additionalProfileIds = [],
  mainProfileIdsForSelection = [],
  accessLevels,
  permission,
}) => {
  let ProjectSelectedEntities
  let ProjectSelectedProfiles
  try {
    ProjectSelectedEntities = getSelectedEntitiesFromStorage({ user })
    ProjectSelectedProfiles = getSelectedProfilesFromStorage({ user })
    // eslint-disable-next-line no-empty
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getSelectedEntitiesAndProfilesFromStorage',
      sendError: false,
      showAlert: false,
    })
  }

  const entitySelectorInstructions = await Cookies.get(COOKIE_ENTITY_SELECTOR_INSTRUCTIONS)

  if (entitySelectorInstructions) {
    entitySelectorInstructions.split(',').forEach((id) => {
      try {
        const id_number = Number(id)
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(id_number)) {
          additionalProfileIds.push(id_number)
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: 'Calendar/helpers',
          functionName: 'getSelectedEntitiesAndProfilesFromStorage',
          sendError: false,
          showAlert: false,
        })
      }
    })
    Cookies.remove(COOKIE_ENTITY_SELECTOR_INSTRUCTIONS)
  }

  const selectedEntitiesIds = ProjectSelectedEntities || []
  const selectedProfilesIds = ProjectSelectedProfiles || []

  const selectedProfilesIdsTemp = {}
  if (additionalProfileIds.length || mainProfileIdsForSelection.length) {
    const updatedProfilesForSelection = mainProfileIdsForSelection.length
      ? mainProfileIdsForSelection
      : additionalProfileIds

    updatedProfilesForSelection.forEach((id) => {
      let foundEntity = null
      for (let i = 0; i < entities.length; i++) {
        foundEntity = null
        const { profiles = [] } = entities[i]
        for (let j = 0; j < profiles.length; j++) {
          if (id === profiles[j].id) {
            foundEntity = entities[i]
            if (!selectedProfilesIdsTemp[id] || !selectedProfilesIdsTemp[id].entities.includes(foundEntity.id)) {
              if (!selectedProfilesIdsTemp[id]) {
                selectedProfilesIdsTemp[id] = { id, entities: [] }
              }
              selectedProfilesIdsTemp[id].entities.push(foundEntity.id)

              const selectedEntity = selectedEntitiesIds.find(({ id }) => id === foundEntity.id)
              if (!selectedEntity) {
                selectedEntitiesIds.push({ id: foundEntity.id, type: foundEntity.type })
              }
            }
            break
          }
        }
      }
    })

    if (!mainProfileIdsForSelection.length && additionalProfileIds.length) {
      selectedProfilesIds.forEach(({ id, entityId }) => {
        if (!selectedProfilesIdsTemp[id]) {
          selectedProfilesIdsTemp[id] = { id, entities: [] }
        }
        selectedProfilesIdsTemp[id].entities.push(entityId)
      })
    }
  } else {
    selectedProfilesIds.forEach(({ id, entityId }) => {
      if (!selectedProfilesIdsTemp[id]) {
        selectedProfilesIdsTemp[id] = { id, entities: [] }
      }
      selectedProfilesIdsTemp[id].entities.push(entityId)
    })
  }

  const selectedEntitiesTemp = []

  if (selectedEntitiesIds.length !== 0) {
    selectedEntitiesIds.forEach(({ id }) => {
      for (let i = 0; i < entities.length; i++) {
        if (entities[i].id === id) {
          entities[i].isOpen = true
          selectedEntitiesTemp.push(entities[i])
          break
        }
      }
    })
  }

  if (selectedEntitiesTemp.length === 0) {
    entities.forEach((item) => {
      item.isOpen = true
      selectedEntitiesTemp.push(item)
      if (item.profiles && item.profiles.length) {
        item.profiles.forEach(({ id, isSample }) => {
          if (!isSample) {
            if (!selectedProfilesIdsTemp[id]) {
              selectedProfilesIdsTemp[id] = { id, entities: [] }
            }
            selectedProfilesIdsTemp[id].entities.push(item.id)
          }
        })
      }
    })

    if (handleSaveSelectedEntities) {
      handleSaveSelectedEntities({ data: selectedEntitiesTemp })
    }
  }

  const selectedProfilesTemp = []
  if (Object.keys(selectedProfilesIdsTemp).length !== 0) {
    for (let i = 0; i < selectedEntitiesTemp.length; i++) {
      const { id: entityId, profiles = [], type } = selectedEntitiesTemp[i]
      profiles.forEach(
        ({
          name,
          username,
          id,
          picture_url,
          profile_url,
          network: { code, display } = {},
          status,
          scope,
          warning,
          isSample,
          profile_id,
          type: profileType,
          verified,
        }) => {
          if (selectedProfilesIdsTemp[id] && selectedProfilesIdsTemp[id].entities.includes(entityId)) {
            let hasAccess = true

            if (permission && accessLevels) {
              hasAccess = !hasEntitiesAccess({
                user,
                permission,
                entity_gid: entityId,
                accessLevels,
              })
            }

            if (hasAccess) {
              selectedProfilesTemp.push({
                name,
                entityId,
                entityType: type,
                id,
                picture_url,
                code,
                scope,
                profile_url,
                username,
                display,
                status,
                warning,
                isSample,
                profile_id,
                type: profileType,
                verified,
              })
            } else {
              delete selectedProfilesIdsTemp[id]
            }
          }
        }
      )
    }

    if (selectedProfilesTemp.length !== 0) {
      if (setSelectedProfiles) {
        setSelectedProfiles(selectedProfilesTemp)
      }
    }
  } else if (setSelectedProfiles) {
    setSelectedProfiles([])
  }

  if (setSelectedEntities) {
    setSelectedEntities(selectedEntitiesTemp)
  }

  if (setEntitySelectorKey) {
    setEntitySelectorKey(new Date().getTime())
  }

  return { selectedEntitiesTemp, selectedProfilesTemp, selectedProfilesIdsTemp }
}

export const clearLocalStorageBeforeLeaving = (user) => {
  for (const key in localStorage) {
    if (!key.includes(`${LOCAL_STORAGE_VISTA_SOCIAL}Selected`)) {
      //   if (user && user.id) {
      //     if (
      //       localStorage.hasOwnProperty(key) &&
      //       key.includes(LOCAL_STORAGE_VISTA_SOCIAL) &&
      //       key.includes(`_${user.id}`)
      //     ) {
      //       localStorage.removeItem(key)
      //     }
      //   } else
      if (localStorage.hasOwnProperty(key) && key.includes(LOCAL_STORAGE_VISTA_SOCIAL)) {
        localStorage.removeItem(key)
      }
    }
  }
}

export const getSelectedProfileGroupsAndTimezone = ({ user, selectedEntities, selectedProfiles }) => {
  const { entities: userEntities } = user

  let selectedEntitiesTemp = selectedEntities
  let selectedProfilesTemp = selectedProfiles

  if (!selectedEntitiesTemp) {
    selectedEntitiesTemp = getSelectedEntitiesFromStorage({ user })
  }

  if (!selectedProfilesTemp) {
    selectedProfilesTemp = getSelectedProfilesFromStorage({ user })
  }

  selectedEntitiesTemp = selectedEntitiesTemp || []
  selectedProfilesTemp = selectedProfilesTemp || []

  let selectedEntitiesWithSelectedProfiles = []

  for (let i = 0; i < selectedEntitiesTemp.length; i++) {
    const foundEntity = userEntities.find(({ id }) => id === selectedEntitiesTemp[i].id)

    if (foundEntity) {
      selectedEntitiesTemp[i].name = foundEntity.name
      selectedEntitiesTemp[i].picture_url = foundEntity.picture_url
    } else {
      selectedEntitiesTemp.splice(i, 1)
      i--
    }
  }

  if (selectedEntitiesTemp && selectedEntitiesTemp.length !== 0) {
    if (selectedProfilesTemp && selectedProfilesTemp.length !== 0) {
      const entitiesTemp = {}

      selectedProfilesTemp.forEach(({ entityId }) => {
        if (!entitiesTemp[entityId]) {
          const foundEntity = selectedEntitiesTemp.find(({ id }) => id === entityId)
          if (foundEntity) {
            entitiesTemp[entityId] = foundEntity
          }
        }
      })

      selectedEntitiesWithSelectedProfiles = Object.values(entitiesTemp)
    } else {
      selectedEntitiesWithSelectedProfiles = selectedEntitiesTemp
    }
  }

  let selectedTimezone = DEFAULT_TIME_ZONE

  if (selectedEntitiesWithSelectedProfiles.length !== 0) {
    selectedEntitiesWithSelectedProfiles.sort((a, b) => {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    })
    const { timezone } = selectedEntitiesWithSelectedProfiles[0]
    selectedTimezone = timezone || DEFAULT_TIME_ZONE
  }

  return {
    selectedTimezone,
    selectedEntitiesWithSelectedProfiles,
    selectedProfiles: selectedProfilesTemp,
    selectedEntities: selectedEntitiesTemp,
  }
}

export const getEntities = async ({
  user,
  setEntities,
  setUserEntities,
  setSelectedEntities,
  setSelectedProfiles,
  setEntitySelectorKey,
  handleSaveSelectedEntities,
  page,
  mock,
}) => {
  try {
    const params = []
    if (page) {
      params.push(`page=${page}`)
    }
    if (mock) {
      params.push(`mock=true`)
    }

    const response = await request({
      path: `${ROUTE_ENTITIES}${params.length !== 0 ? `?${params.join('&')}` : ''}`,
    })

    if (response) {
      if (response.error) {
        Alert.error(response.error, { timeout: 5000 })
      } else {
        const { entities, sampleEntities, alert } = response

        if (alert) {
          const { type, message } = alert

          if (type) {
            Alert[type](message, { timeout: 5000 })
          }
        }

        const updatedEntities = sampleEntities || entities

        if (sampleEntities && setUserEntities) {
          setUserEntities(entities)
        }

        setEntities(updatedEntities)

        let timezone = DEFAULT_TIME_ZONE

        let selectedEntities = []
        let selectedProfiles = []

        if (updatedEntities && updatedEntities.length > 0) {
          const { selectedEntitiesTemp, selectedProfilesTemp } = await getSelectedEntitiesAndProfilesFromStorage({
            user,
            entities: updatedEntities,
            setSelectedEntities,
            setSelectedProfiles,
            setEntitySelectorKey,
            handleSaveSelectedEntities,
          })

          const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({
            user,
            selectedEntities: selectedEntitiesTemp,
            selectedProfiles: selectedProfilesTemp,
          })

          timezone = selectedTimezone

          selectedEntities = selectedEntitiesTemp
          selectedProfiles = selectedProfilesTemp
        }

        return { timezone, entities: updatedEntities, selectedEntities, selectedProfiles }
      }
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getEntities',
    })
  }
}

export const handleSubmitPostsForCalendar = async ({
  body,
  type,
  modalOptions,
  setModalOptions,
  handleClickCloseModal,
  selectedEntities,
  selectedProfiles,
  handleSaveSelectedEntities,
  handleSaveSelectedProfiles,
  setEntitySelectorKey,
  getPendingApproval,
  selectedData,
  handleModalSuccessCallback,
  scheduleTypeData,
  loadingButton,
}) => {
  if (modalOptions && setModalOptions) {
    modalOptions.isPostSubmitting = true
    modalOptions.loadingButton = loadingButton
    setModalOptions({ ...modalOptions })
  }

  setTimeout(async () => {
    let response
    try {
      response = await request({
        method: 'POST',
        body,
        path: `${ROUTE_PUBLISH}?bulk=${type === 'bulkPublish' ? true : ''}`,
      })

      if (!response || response.error) {
        const { error = '' } = response || {}
        const errorText = error || ERROR_MESSAGE
        Alert.error(errorText, { timeout: countAlertTimeout({ text: errorText }) })

        if (modalOptions && setModalOptions) {
          modalOptions.isPostSubmitting = false
          modalOptions.loadingButton = null
          setModalOptions({ ...modalOptions })
        }
      } else {
        const { review, draft, save_changes, restart_workflow } = body

        const { approved } = review || {}

        let successMessage = 'Post changes have been saved.'

        if (save_changes) {
          successMessage = 'Post changes have been saved.'
        } else if (restart_workflow) {
          successMessage = 'Post has been sent for approval.'
        } else if (approved !== undefined) {
          if (approved) {
            successMessage = 'Post has been approved.'
          } else {
            successMessage = 'Post has been rejected.'
          }
        } else if (draft) {
          successMessage = 'Post has been saved as draft.'
        } else if (type === 'publish' || (type === 'bulkPublish' && body.length === 1)) {
          successMessage = 'Post has been scheduled.'
        } else if (type === 'advocacy_publish') {
          successMessage = 'Your share has been scheduled.'
        } else {
          successMessage = 'Posts have been scheduled.'
        }

        Alert.success(successMessage, { timeout: 5000 })

        if (handleModalSuccessCallback) {
          handleModalSuccessCallback()
        }
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'handleSubmitPostsForCalendar',
      })

      if (modalOptions && setModalOptions) {
        modalOptions.isPostSubmitting = false
        modalOptions.loadingButton = null
        setModalOptions({ ...modalOptions })
      }
    } finally {
      if (!response.error) {
        const selectedProfilesTemp = {}
        const selectedEntitiesTemp = {}

        selectedProfiles.forEach((item) => {
          selectedProfilesTemp[item.id] = item
        })
        // console.log('PUBLISHING selectedProfilesTemp', selectedData)
        selectedData.selectedProfiles.forEach((item) => {
          if (!selectedProfilesTemp[item.id]) {
            delete item.publishTimeData
            selectedProfilesTemp[item.id] = item
          }
        })

        selectedEntities.forEach((item) => {
          selectedEntitiesTemp[item.id] = item
        })
        selectedData.selectedEntities.forEach((item) => {
          if (!selectedEntitiesTemp[item.id]) {
            selectedEntitiesTemp[item.id] = item
          }
        })

        handleSaveSelectedEntities({ data: Object.values(selectedEntitiesTemp) })

        handleSaveSelectedProfiles({
          data: Object.values(selectedProfilesTemp),
          updateCalendar: false,
        })

        if (setEntitySelectorKey) {
          setEntitySelectorKey(moment().valueOf())
        }

        getPendingApproval({ getIndicators: true })

        if (handleClickCloseModal) {
          handleClickCloseModal({ scheduleTypeData })
        }
      }
    }
  }, 200)
}

export const getDataForCustomizations = async ({ setDataForCustomizations }) => {
  try {
    const response = await request({
      path: `publishing/form-data`,
    })
    if (response && !response.error) {
      const { data } = response
      setDataForCustomizations({ ...data })
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getDataForCustomizations',
      errorMessage: `Can't get customizations data. Please contact support.`,
    })
  }
}

export const getEvents = async ({
  user,
  profiles = [],
  calendarRef,
  setCalendarEvents,
  centralDate = '',
  selectedTimezone = '',
  shouldCalendarShowUploadingCircle = true,
  setIsCalendarGettingEvents,
  calendarOptions,
  setCalendarOptions,
  setSelectedFilters,
}) => {
  try {
    if (shouldCalendarShowUploadingCircle) {
      setIsCalendarGettingEvents(true)
    }

    let path = `${ROUTE_CALENDAR}?timezone=${selectedTimezone}&centralDate=${centralDate}`

    let dateFrom
    let dateTo
    let viewTitle
    let calendarPageViewOptionsType
    if (calendarRef && calendarRef.current) {
      const calendarData = calendarRef.current.getApi().getCurrentData()
      ;({ viewApi: { activeEnd: dateTo, activeStart: dateFrom } = {}, viewTitle } = calendarData)
      ;({ calendarPageViewOptionsType } = calendarRef.current)

      dateFrom = moment(dateFrom).format(DATE_FORMAT)
      dateTo = moment(dateTo).format(DATE_FORMAT)

      calendarOptions.viewTitle = viewTitle
      calendarOptions.calendarInitialDateFrom = dateFrom
      calendarOptions.calendarInitialDateTo = dateTo
      setCalendarOptions({ ...calendarOptions })

      const { type, hasControls } = calendarPageViewOptionsType || {}

      if (!type || type === CALENDAR_PAGE_VIEW_TYPE_CALENDAR) {
        path += `&dateFrom=${dateFrom}&dateTo=${dateTo}`
      } else {
        path += `&group=true`
        if (hasControls) {
          path += `&dateFrom=${dateFrom}&dateTo=${dateTo}`
        }
        if (type !== CALENDAR_PAGE_VIEW_TYPE_LIST_ALL_POSTS) {
          path += `&status=${type}`
        }
      }
    }

    if (profiles && profiles.length !== 0) {
      const profileIds = profiles.map(({ id }) => id).join(',')

      path += `&profileIds=${profileIds}`

      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({ user })

      if (selectedEntitiesWithSelectedProfiles.length !== 0) {
        path += `&entityIds=${selectedEntitiesWithSelectedProfiles.map(({ id }) => id)}`
      }

      const response = await request({
        path,
      })
      const { error, events = [], filters, redirect, message } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else if (redirect) {
        Alert.error(message, { timeout: 5000 })
        Router.push(redirect)
      } else {
        setCalendarEvents([...events])

        if (setSelectedFilters) {
          const {
            labels,
            users,
            statuses = [],
            messageEventsUsers = [],
            postContentTypes = [],
            queue_labels = [],
            boost_statuses = [],
            advocacy_statuses = [],
          } = filters || {}

          const filterFill = ({ data, filter, initial }) => {
            filter.forEach((status) => {
              const foundInitial = initial.find(({ id }) => id === status)
              if (foundInitial) {
                data.push({ value: foundInitial.id, label: foundInitial.name })
              }
            })
          }

          const updatedStatuses = []
          const updatedPostContentTypes = []
          const updatedBoostStatuses = []
          const updatedAdvocacyStatuses = []

          filterFill({ data: updatedStatuses, filter: statuses, initial: STATUS_FILTERS })
          filterFill({ data: updatedPostContentTypes, filter: postContentTypes, initial: POST_CONTENT_TYPE_FILTERS })
          filterFill({ data: updatedBoostStatuses, filter: boost_statuses, initial: BOOST_STATUS_FILTERS })
          filterFill({ data: updatedAdvocacyStatuses, filter: advocacy_statuses, initial: ADVOCACY_STATUS_FILTERS })

          setSelectedFilters({
            labels,
            users,
            statuses: updatedStatuses,
            messageEventsUsers,
            postContentTypes: updatedPostContentTypes,
            queue_labels,
            boost_statuses: updatedBoostStatuses,
            advocacy_statuses: updatedAdvocacyStatuses,
          })
        }
      }
    } else {
      setCalendarEvents([])
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getEvents',
    })
  } finally {
    if (shouldCalendarShowUploadingCircle) {
      setIsCalendarGettingEvents(false)
    }
  }
}

export const filterSocialProfiles = ({ socialNetworkFilter, data, filter }) => {
  const { socialNetworkFilterName = '', socialNetworkFilterSelectedProfiles = [] } = socialNetworkFilter
  let filteredSocialProfiles = data

  if (filter) {
    if (socialNetworkFilterName) {
      filteredSocialProfiles = filteredSocialProfiles.filter(({ name, username, nickname }) =>
        `${name || ''} ${username || ''} ${nickname || ''}`
          .toLowerCase()
          .includes(socialNetworkFilterName.toLowerCase())
      )
    }

    if (socialNetworkFilterSelectedProfiles.length !== 0) {
      filteredSocialProfiles = filteredSocialProfiles.filter(({ network: { code } }) =>
        socialNetworkFilterSelectedProfiles.includes(code)
      )
    }
  }

  return filteredSocialProfiles
}

export const getPinterestBoards = async ({ id, dataForCustomizations, setDataForCustomizations }) => {
  const { [id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}

  if (id && (!networkDataForCustomizations || !networkDataForCustomizations.id)) {
    if (!dataForCustomizations.networkDataForCustomizations) {
      dataForCustomizations.networkDataForCustomizations = {}
    }

    if (!dataForCustomizations.networkDataForCustomizations[id]) {
      dataForCustomizations.networkDataForCustomizations[id] = { id }
    }

    setDataForCustomizations({ ...dataForCustomizations })

    let boards = []

    try {
      const response = await request({
        path: `${ROUTE_PINTEREST_BOARDS}?profile_gid=${id}`,
      })

      const { items = [], error } = response

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        boards = items
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'getPinterestBoards',
      })
    } finally {
      dataForCustomizations.networkDataForCustomizations[id] = { id, boards, board_sections: {} }
      setDataForCustomizations({ ...dataForCustomizations })
    }
  }
}

export const getPinterestSections = async ({ id, board_id, dataForCustomizations, setDataForCustomizations }) => {
  const { [id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}

  if (
    networkDataForCustomizations.board_sections &&
    board_id &&
    !networkDataForCustomizations.board_sections[board_id]
  ) {
    dataForCustomizations.networkDataForCustomizations[id].board_sections[board_id] = []

    setDataForCustomizations({ ...dataForCustomizations })

    let sections = []

    try {
      const response = await request({
        path: `${ROUTE_PINTEREST_SECTIONS}?profile_gid=${id}&board_id=${board_id}`,
      })

      const { items = [], error } = response

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        sections = items
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'getPinterestSections',
      })
    } finally {
      dataForCustomizations.networkDataForCustomizations[id].board_sections[board_id] = sections
      setDataForCustomizations({ ...dataForCustomizations })
    }
  }
}

export const getAccounts = async ({
  value,
  network,
  id,
  ids,
  getSuggestedOptionsErrors,
  setGetSuggestedOptionsErrors,
  filterAccountsWithWarning = false,
  filterAccountsWithPlace = false,
}) => {
  const updatedValue = value.replace(/@/gi, '')
  if (updatedValue && updatedValue.length > 1) {
    try {
      const response = await request({
        method: 'POST',
        body: { network, q: updatedValue, id, ids },
        path: ROUTE_SEARCH_ACCOUNTS,
      })

      const { error } = response

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        getSuggestedOptionsErrors[network] = true
        setGetSuggestedOptionsErrors({ ...getSuggestedOptionsErrors })
        return []
      } else {
        let data = response

        if (filterAccountsWithWarning) {
          data = data.filter(({ warning }) => !warning)
        }

        if (filterAccountsWithPlace) {
          data = data.filter(({ place }) => place)
        }

        getSuggestedOptionsErrors[network] = data.length === 0
        setGetSuggestedOptionsErrors({ ...getSuggestedOptionsErrors })

        return data.map(({ id, name, username, link, picture_url, location, warning, ...props }) => ({
          value: id,
          label: name,
          username,
          link,
          picture_url,
          warning,
          location,
          ...props,
        }))
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'getAccounts',
      })
      return []
    }
  }
  return []
}

export const getInstagramProfileMedias = async ({
  id,
  dataForCustomizations,
  setDataForCustomizations,
  postIds,
  type = 'feed',
}) => {
  const { [id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}

  if (id && (!networkDataForCustomizations || !networkDataForCustomizations.medias)) {
    if (!dataForCustomizations.networkDataForCustomizations) {
      dataForCustomizations.networkDataForCustomizations = {}
    }

    if (!dataForCustomizations.networkDataForCustomizations[id]) {
      dataForCustomizations.networkDataForCustomizations[id] = { id }
    }

    if (!dataForCustomizations.networkDataForCustomizations[id][type]) {
      dataForCustomizations.networkDataForCustomizations[id][type] = { isGettingGridMedia: true }
      setDataForCustomizations({ ...dataForCustomizations })

      const dateFrom = moment()
        .clone()
        .startOf('day')
        .subtract(1, 'days')
        .format('YYYY-MM-DD')

      let medias = []

      try {
        const response = await request({
          path: `${ROUTE_INSTAGRAM_MEDIAS}?profile_gid=${id}&dateFrom=${dateFrom}&type=${type}`,
        })

        const { data = [], error } = response

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (postIds.length !== 0) {
          medias = data.filter((item) => !postIds.find((id) => id === item.postId))
        } else {
          medias = data
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: 'Calendar/helpers',
          functionName: 'getInstagramProfileMedias',
        })
      } finally {
        dataForCustomizations.networkDataForCustomizations[id][type].isGettingGridMedia = false
        dataForCustomizations.networkDataForCustomizations[id][type].medias = medias
        setDataForCustomizations({ ...dataForCustomizations })
      }
    }
  }
}

export const getDevices = async ({ entityIds }) => {
  let devicesByEntity = {}

  try {
    const response = await request({
      path: `${ROUTE_DEVICES}?ids=${entityIds.join(',')}`,
    })
    const { devices_by_entity, error } = response

    if (!response || error) {
      Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
    } else {
      devicesByEntity = devices_by_entity
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getDevices',
    })
  }
  return devicesByEntity
}

export const removePublicationDataFromLocalStorage = ({ key }) => {
  localStorage.removeItem(key)
}

const setPublicationDataToLocalStorage = async ({
  key,
  data,
  dataToLocalStorageOptions,
  setDataToLocalStorageOptions,
}) => {
  dataToLocalStorageOptions.lastTimeSavedMessage = `Saving ...`
  setDataToLocalStorageOptions({ ...dataToLocalStorageOptions })
  localStorage.setItem(key, JSON.stringify(data))

  await sleep(500)

  dataToLocalStorageOptions.lastTimeSavedMessage = 'Changes saved.'

  dataToLocalStorageOptions.lastTimeSaved = moment().valueOf()
  setDataToLocalStorageOptions({ ...dataToLocalStorageOptions })
}

export const setPublicationDataToLocalStorageDebounce = debounce((props) => {
  setPublicationDataToLocalStorage(props)
}, 1000)

export const getPublicationDataFromLocalStorage = ({
  key,
  confirm,
  setData,
  setDataToLocalStorageOptions,
  message,
  action = 'Yes, recover post',
}) => {
  let localStorageData
  const dataToLocalStorageOptions = { canSave: true, lastTimeSaved: 0, lastTimeSavedMessage: '' }
  try {
    localStorageData = localStorage.getItem(key)
    if (localStorageData) {
      localStorageData = JSON.parse(localStorageData)
    }

    if (localStorageData) {
      confirm({
        fn: () => () => {
          setDataToLocalStorageOptions({ ...dataToLocalStorageOptions })
          setData({ ...localStorageData })
          removePublicationDataFromLocalStorage({ key })
        },
        message,
        action,
        cancel: 'No, start fresh',
        cancelFn: () => () => {
          setDataToLocalStorageOptions({ ...dataToLocalStorageOptions })
          removePublicationDataFromLocalStorage({ key })
        },
      })
    } else {
      setDataToLocalStorageOptions({ ...dataToLocalStorageOptions })
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getPublicationDataFromLocalStorage',
      sendError: false,
      showAlert: false,
    })
    removePublicationDataFromLocalStorage({ key })
    setDataToLocalStorageOptions({ ...dataToLocalStorageOptions })
  }
}

export const variantOffsets = {
  bold: 'b',
  'bold italic': 'bi',
  'bold sans': 'bs',
  'bold script': 'bc',
  'bold italic sans': 'bis',

  doublestruck: 'd',
  // fullwidth: 'w',
  gothic: 'g',
  'gothic bold': 'bg',
  italic: 'i',
  'italic sans': 'is',
  monospace: 'm',
  parenthesis: 'p',
  sans: 's',
  script: 'c',
  squared: 'q',
  circled: 'o',
  'squared negative': 'qn',
  'circled negative': 'on',
}

export const flags = {
  underline: 'u',
  strike: 's',
  strike_and_underline: 'u,s',
}

export const toUnicodeVariant = (str, variant, flags) => {
  const offsets = {
    m: [0x1d670, 0x1d7f6],
    b: [0x1d400, 0x1d7ce],
    i: [0x1d434, 0x00030],
    bi: [0x1d468, 0x00030],
    c: [0x0001d49c, 0x00030],
    bc: [0x1d4d0, 0x00030],
    g: [0x1d504, 0x00030],
    d: [0x1d538, 0x1d7d8],
    bg: [0x1d56c, 0x00030],
    s: [0x1d5a0, 0x1d7e2],
    bs: [0x1d5d4, 0x1d7ec],
    is: [0x1d608, 0x00030],
    bis: [0x1d63c, 0x00030],
    o: [0x24b6, 0x2460],
    on: [0x0001f150, 0x2460],
    p: [0x249c, 0x2474],
    q: [0x1f130, 0x00030],
    qn: [0x0001f170, 0x00030],
    w: [0xff21, 0xff10],
    u: [0x2090, 0xff10],
  }

  // special characters (absolute values)
  const special = {
    m: {
      ' ': 0x2000,
      '-': 0x2013,
    },
    i: {
      h: 0x210e,
    },
    g: {
      C: 0x212d,
      H: 0x210c,
      I: 0x2111,
      R: 0x211c,
      Z: 0x2128,
    },
    d: {
      C: 0x2102,
      H: 0x210d,
      N: 0x2115,
      P: 0x2119,
      Q: 0x211a,
      R: 0x211d,
      Z: 0x2124,
    },
    o: {
      0: 0x24ea,
      1: 0x2460,
      2: 0x2461,
      3: 0x2462,
      4: 0x2463,
      5: 0x2464,
      6: 0x2465,
      7: 0x2466,
      8: 0x2467,
      9: 0x2468,
    },
    on: {},
    p: {},
    q: {},
    qn: {},
    w: {},
  }
  // support for parenthesized latin letters small cases
  // support for full width latin letters small cases
  // support for circled negative letters small cases
  // support for squared letters small cases
  // support for squared letters negative small cases
  ;['p', 'w', 'on', 'q', 'qn'].forEach((t) => {
    for (let i = 97; i <= 122; i++) {
      special[t][String.fromCharCode(i)] = offsets[t][0] + (i - 97)
    }
  })

  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const numbers = '0123456789'

  const getType = (variant) => {
    if (variantOffsets[variant]) return variantOffsets[variant]
    if (offsets[variant]) return variant
    return 'm' // monospace as default
  }

  const getFlag = (flag, flags) => {
    if (!flags) return false
    return flag.split('|').some((f) => flags.split(',').indexOf(f) > -1)
  }

  const type = getType(variant)
  const underline = getFlag('underline|u', flags)
  const strike = getFlag('strike|s', flags)
  let result = ''

  const normalizedStr = str.normalize('NFKC')
  const sourceStr = normalizedStr || str

  let hasError = false
  for (let c of sourceStr) {
    let index
    if (special[type] && special[type][c]) {
      c = String.fromCodePoint(special[type][c])
      result += c
    } else if (type && (index = chars.indexOf(c)) > -1) {
      result += String.fromCodePoint(index + offsets[type][0])
    } else if (type && (index = numbers.indexOf(c)) > -1) {
      result += String.fromCodePoint(index + offsets[type][1])
    } else {
      hasError = true
      result += c
    }
    if (underline) result += '\u0332' // add combining underline
    if (strike) result += '\u0336' // add combining strike
  }

  if (hasError) {
    return { text: str, transformed_text: result, error: true }
  } else {
    return { text: result, transformed_text: result, error: false }
  }
}

export const transformTextVariantsToUnicodeVariant = () => {
  return Object.keys(variantOffsets).map((key) => {
    const { transformed_text: label } = toUnicodeVariant(key, variantOffsets[key])
    return { value: variantOffsets[key], label }
  })
}

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
}

export const sanitize = (str) => str.replace(/[&<>"'\`=\\/]/g, (s) => entityMap[s])

export const variableRegExp = ({ str }) => str.replace(/{{.*?}}/g, (str) => `<span class="variable">${str}</span>`)

export const variableTransformer = ({ str, savedVariables, post, profileId }) => {
  let variableTransformedText = str

  const notFoundVariables = []

  if (variableTransformedText) {
    let foundVariables = str.match(/{{.*?}}/g)

    if (foundVariables && foundVariables.length !== 0) {
      foundVariables = [...new Set(foundVariables)]

      foundVariables.forEach((variable) => {
        // getting variable without {{ }} brackets
        const variableTemp = variable.replace(/{|}/g, '')

        // looking for variable in the list of variables
        const foundVariable = savedVariables.find(({ label }) => label === variableTemp)

        if (foundVariable) {
          const { id } = foundVariable
          // looking for profile value for found variable. "profile_variables" - is object "profile_variables" from server/routes/variable.routes.js GET "/"
          // profile_variables_temp[profile_gid][variable_gid] = { value, id: _id }
          const { [profileId]: profileVariables } = post.profile_variables || {}
          const { [id]: profileVariable } = profileVariables || {}

          if (profileVariable && profileVariable.value) {
            const { value } = profileVariable
            variableTransformedText = variableTransformedText.replace(variable, value)
          } else {
            notFoundVariables.push({ variable, for_edit: true })
          }
        } else {
          // this is for post preview. Each post has an object profileVariables[profile_gid][variable_name] = profile_variable_value
          const profilesVariables = post.profileVariables || {}
          const { [profileId]: profileVariables } = profilesVariables || {}

          if (profileVariables && profileVariables.hasOwnProperty(variableTemp)) {
            const label = profileVariables[variableTemp]
            variableTransformedText = variableTransformedText.replace(variable, label)
          } else {
            notFoundVariables.push({ variable, for_creation: true })
          }
        }
      })
    }
  }

  return { variableTransformedText, notFoundVariables }
}

export const checkPostTextForNotCreatedVariables = ({ post, selectedProfiles, savedVariables }) => {
  const { postText, customizations = {} } = post

  let profilesWithoutVariables = {}
  const notFoundVariablesForProfiles = {}

  selectedProfiles.forEach((profile) => {
    const { id, code } = profile
    let updatedPostText = postText
    if (customizations[code] && customizations[code][id]) {
      const { comments = [] } = customizations[code][id]
      const comment = comments && comments.length !== 0 ? comments.join(' ') : ''

      updatedPostText = `${customizations[code][id].message || postText} ${comment}`
    }

    const { notFoundVariables } = variableTransformer({ str: updatedPostText, profileId: id, savedVariables, post })

    if (notFoundVariables.length !== 0) {
      profilesWithoutVariables[id] = { ...profile, network: { code } }
      notFoundVariables.forEach((item) => {
        notFoundVariablesForProfiles[item.variable] = item
      })
    }
  })

  profilesWithoutVariables = Object.values(profilesWithoutVariables)

  return {
    shouldOpenVariablesModal: profilesWithoutVariables.length !== 0,
    variablesProfiles: profilesWithoutVariables,
    notFoundVariables: Object.values(notFoundVariablesForProfiles),
  }
}

export const transformTextToUnicode = ({ text, value }) => {
  if (text) {
    const DEFAULT_ERROR = `Links, mentions, hashtags and variables can not be converted to rich text.`
    let error = ''

    const hashtagsText = twitter.extractHashtags(text)

    if (hashtagsText.length !== 0) {
      error = DEFAULT_ERROR
    } else {
      const urls = twitter.extractUrls(text)
      if (urls.length !== 0) {
        error = DEFAULT_ERROR
      } else {
        const mentions = extractMentionsOrListsWithIndices(text)
        if (mentions.length !== 0) {
          error = DEFAULT_ERROR
        }
      }
    }

    if (error) {
      return { text, message: error, type: 'warning' }
    } else {
      const lines = text.split('\n')

      let hasError = false

      const updatedLines = []

      for (let i = 0; i < lines.length; i++) {
        if (hasError) {
          break
        }

        const updatedWords = []
        const words = lines[i].split(' ')
        for (let j = 0; j < words.length; j++) {
          const { text: word, error } = toUnicodeVariant(words[j], value)
          if (error) {
            hasError = true
            break
          } else {
            updatedWords.push(word)
          }
        }

        if (i === lines.length - 1) {
          updatedLines.push(updatedWords.join(' '))
        }
      }

      if (hasError) {
        return {
          text,
          message: `Only plain text that includes numbers and english letters can be converted to rich font.`,
          type: 'error',
        }
      } else {
        return { text: updatedLines.join('\n'), message: '', type: null }
      }
    }
  }
  return { text: '', message: '', type: null }
}

export const getFacebookGroupsOptions = async ({ id, dataForCustomizations, setDataForCustomizations }) => {
  const { [id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}

  if (id && (!networkDataForCustomizations || !networkDataForCustomizations.id)) {
    if (!dataForCustomizations.networkDataForCustomizations) {
      dataForCustomizations.networkDataForCustomizations = {}
    }

    if (!dataForCustomizations.networkDataForCustomizations[id]) {
      dataForCustomizations.networkDataForCustomizations[id] = { id }
    }

    setDataForCustomizations({ ...dataForCustomizations })

    let groupsOptions = []
    let groupsOptionsError = null

    try {
      const response = await request({
        path: `${ROUTE_FACEBOOK_GROUP_OPTIONS}?profile_gid=${id}`,
      })

      const { items = [], error } = response

      if (!response || error) {
        groupsOptionsError = error || ERROR_MESSAGE
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        groupsOptions = items
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'getFacebookGroupsOptions',
      })
    } finally {
      dataForCustomizations.networkDataForCustomizations[id] = {
        id,
        groupsOptions: groupsOptions.map(({ type, description }) => ({
          value: type,
          label: `Publish as ${description}`,
        })),
        groupsOptionsError,
      }
      setDataForCustomizations({ ...dataForCustomizations })
    }
  }
}

export const getInstagramMediaProportions = ({
  width,
  height,
  maxWidth,
  maxHeight,
  minAspectRatio,
  maxAspectRatio,
  type,
  media_gid,
  post,
}) => {
  let updatedWidth = width
  let updatedHeight = height
  if (!updatedWidth || !updatedHeight) {
    const element = document.getElementById(
      `${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${media_gid}`
    )

    if (element) {
      if (type === IMAGE) {
        updatedWidth = element.naturalWidth
        updatedHeight = element.naturalHeight
      } else if (type === VIDEO) {
        const { children: { 0: videoElement } = [] } = element
        const { videoHeight, videoWidth } = videoElement || element || {}

        updatedWidth = videoWidth
        updatedHeight = videoHeight
      }
    }
  }

  if (updatedWidth && updatedHeight) {
    const ratio = updatedWidth / updatedHeight

    let new_width = updatedWidth > maxWidth ? maxWidth : updatedWidth
    let new_height = Math.ceil((updatedHeight * new_width) / updatedWidth)
    let box_height = new_height
    let box_width = new_width

    if (minAspectRatio && maxAspectRatio) {
      if (ratio < minAspectRatio) {
        new_height = Math.ceil(new_width / minAspectRatio)
        box_height = new_height
      } else if (ratio > maxAspectRatio) {
        new_height = Math.ceil(new_width / maxAspectRatio)
        box_height = new_height
      }
    }

    if (new_height > maxHeight) {
      const heightRatio = maxHeight / new_height

      new_width = Math.ceil(new_width * heightRatio)
      box_width = new_width
      new_height = maxHeight
      box_height = new_height
    }

    return { new_width, new_height, box_height, box_width }
  }

  return { new_width: 0, new_height: 0 }
}

export const fitMediaProportionsToBox = ({ width, height, maxWidth, maxHeight, type, media_gid, post }) => {
  let updatedWidth = width
  let updatedHeight = height
  if (!updatedWidth || !updatedHeight) {
    const element = document.getElementById(
      `${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${media_gid}`
    )

    if (element) {
      if (type === IMAGE) {
        updatedWidth = element.naturalWidth
        updatedHeight = element.naturalHeight
      } else if (type === VIDEO) {
        const { children: { 0: videoElement } = [] } = element
        const { videoHeight, videoWidth } = videoElement || element || {}

        updatedWidth = videoWidth
        updatedHeight = videoHeight
      }
    }
  }

  if (updatedWidth && updatedHeight) {
    const ratio = updatedWidth / updatedHeight

    let new_width = updatedWidth > maxWidth ? maxWidth : updatedWidth
    let new_height = Math.ceil((updatedHeight * new_width) / updatedWidth)

    if (maxHeight && new_height > maxHeight) {
      const heightRatio = maxHeight / new_height

      new_width = Math.ceil(new_width * heightRatio)
      new_height = new_width / ratio
    }

    return { new_width, new_height }
  }

  return { new_width: 0, new_height: 0 }
}

export const setSavedSearches = async ({ type, id, result, dataForCustomizations, setDataForCustomizations }) => {
  const { [id]: { typeData: { [type]: savedResults = [] } = {} } = {} } =
    dataForCustomizations.networkDataForCustomizations || {}

  const { value } = result || {}

  const foundSavedResult = savedResults.find((saved) => saved.value === value)

  if (!foundSavedResult && value) {
    try {
      const response = await request({
        path: `${ROUTE_SAVED_SEARCHES}?profile_gid=${id}&type=${type}`,
        method: 'POST',
        body: { result },
      })
      const { data = [], error } = response

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        if (!dataForCustomizations.networkDataForCustomizations) {
          dataForCustomizations.networkDataForCustomizations = {}
        }

        if (!dataForCustomizations.networkDataForCustomizations[id]) {
          dataForCustomizations.networkDataForCustomizations[id] = { id }
        }

        if (!dataForCustomizations.networkDataForCustomizations[id].typeData) {
          dataForCustomizations.networkDataForCustomizations[id].typeData = { [type]: [] }
        }

        dataForCustomizations.networkDataForCustomizations[id].typeData[type] = data
        setDataForCustomizations({ ...dataForCustomizations })
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'setSavedSearches',
      })
    }
  }
}

const getSavedSearches = async ({ type, id }) => {
  let typeData = []

  try {
    const response = await request({
      path: `${ROUTE_SAVED_SEARCHES}?profile_gid=${id}&type=${type}`,
    })
    const { data = [], error } = response

    if (!response || error) {
      Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
    } else {
      typeData = data
    }
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'getSavedSearches',
    })
  }
  return typeData
}

export const dataForCustomizationsInGettingDataFunction = async ({
  id,
  type,
  dataForCustomizations,
  setDataForCustomizations,
  functionForSettingCustomizationsData,
}) => {
  const { [id]: typeDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}

  if (
    id &&
    (!typeDataForCustomizations || !typeDataForCustomizations.typeData || !typeDataForCustomizations.typeData[type])
  ) {
    if (!dataForCustomizations.networkDataForCustomizations) {
      dataForCustomizations.networkDataForCustomizations = {}
    }

    if (!dataForCustomizations.networkDataForCustomizations[id]) {
      dataForCustomizations.networkDataForCustomizations[id] = {
        id,
      }
    }

    if (!dataForCustomizations.networkDataForCustomizations[id].gettingData) {
      dataForCustomizations.networkDataForCustomizations[id].gettingData = { [type]: { isGettingTypeData: false } }
    }

    if (
      (!dataForCustomizations.networkDataForCustomizations[id].gettingData[type] ||
        !dataForCustomizations.networkDataForCustomizations[id].gettingData[type].isGettingTypeData) &&
      (!dataForCustomizations.networkDataForCustomizations[id].typeData ||
        !dataForCustomizations.networkDataForCustomizations[id].typeData[type])
    ) {
      if (!dataForCustomizations.networkDataForCustomizations[id].typeData) {
        dataForCustomizations.networkDataForCustomizations[id].typeData = { [type]: [] }
      }

      if (!dataForCustomizations.networkDataForCustomizations[id].typeData[type]) {
        dataForCustomizations.networkDataForCustomizations[id].typeData[type] = []
      }

      if (!dataForCustomizations.networkDataForCustomizations[id].gettingData[type]) {
        dataForCustomizations.networkDataForCustomizations[id].gettingData[type] = {}
      }

      dataForCustomizations.networkDataForCustomizations[id].gettingData[type].isGettingTypeData = true
      setDataForCustomizations({ ...dataForCustomizations })

      await functionForSettingCustomizationsData()

      dataForCustomizations.networkDataForCustomizations[id].gettingData[type].isGettingTypeData = false
      setDataForCustomizations({ ...dataForCustomizations })
    }
  }
}

export const getNetworkSavedSearches = async (props) => {
  if (props) {
    const { id, type, dataForCustomizations, setDataForCustomizations } = props

    await dataForCustomizationsInGettingDataFunction({
      id,
      type,
      dataForCustomizations,
      setDataForCustomizations,
      functionForSettingCustomizationsData: async () => {
        dataForCustomizations.networkDataForCustomizations[id].typeData[type] = await getSavedSearches({ type, id })
      },
    })
  }
}

export const getMediaTypeDropDownValuesForInstagram = ({ postComponent, postMixedMedias }) => {
  let MEDIA_TYPE_DROPDOWN_VALUES = []

  let updatedPostComponent = postComponent

  if (updatedPostComponent === POST_MIXED_MEDIA) {
    if (postMixedMedias.length === 1) {
      const { 0: { type } = {} } = postMixedMedias

      if (type === IMAGE || type === IMAGE_DYNAMIC) {
        updatedPostComponent = POST_IMAGE
      } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
        updatedPostComponent = POST_VIDEO
      }
    }
  }

  if (updatedPostComponent === POST_IMAGE || updatedPostComponent === POST_LINK) {
    MEDIA_TYPE_DROPDOWN_VALUES = [
      { value: PUBLISH_AS_IMAGE, label: 'Publish as Feed post' },
      { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
    ]
  } else if (updatedPostComponent === POST_VIDEO) {
    MEDIA_TYPE_DROPDOWN_VALUES = [
      // { value: PUBLISH_AS_VIDEO, label: 'Publish as Feed post' }, // as of 11/10/23 it appears not to be possible to schedule feed videos.
      { value: PUBLISH_AS_REELS, label: 'Publish as Reel' },
      { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
    ]
  } else if (updatedPostComponent === POST_MIXED_MEDIA) {
    MEDIA_TYPE_DROPDOWN_VALUES = [{ value: PUBLISH_AS_CAROUSEL, label: 'Publish as Carousel' }]
  }

  return MEDIA_TYPE_DROPDOWN_VALUES
}

export const getPublishAsDropDownValuesForFacebook = ({ postComponent, post }) => {
  const { postImages = [], postVideos = [], postMixedMedias = [] } = post || {}

  let PUBLISH_AS_DROPDOWN_VALUES = []

  if (postComponent === POST_IMAGE) {
    if (postImages) {
      if (postImages.length === 1) {
        PUBLISH_AS_DROPDOWN_VALUES = [
          { value: PUBLISH_AS_IMAGE, label: 'Publish as Image' },
          { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
        ]
      } else {
        // Removed https://trello.com/c/3Uzew0Vw/1192-remove-fb-slideshow-feature-until-fb-fixed-bug
        // { value: PUBLISH_AS_SLIDESHOW, label: 'Slideshow' }, // after uncommenting check Facebook preview
        PUBLISH_AS_DROPDOWN_VALUES = [
          { value: PUBLISH_AS_IMAGE, label: 'Publish as Image' },
          { value: PUBLISH_AS_CAROUSEL, label: 'Publish as Carousel (pages only)' },
        ]
      }
    }
  } else if (postComponent === POST_VIDEO) {
    if (postVideos) {
      if (postVideos.length === 1) {
        PUBLISH_AS_DROPDOWN_VALUES = [
          { value: PUBLISH_AS_REELS, label: 'Publish as Reel' },
          { value: PUBLISH_AS_VIDEO, label: 'Publish as Video' },
          { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
        ]
      } else {
        PUBLISH_AS_DROPDOWN_VALUES = [{ value: PUBLISH_AS_VIDEO, label: 'Publish as Video' }]
      }
    }
  } else if (postComponent === POST_LINK) {
    PUBLISH_AS_DROPDOWN_VALUES = [
      { value: PUBLISH_AS_ARTICLE, label: 'Publish as Article' },
      { value: PUBLISH_AS_IMAGE, label: 'Publish as Image' },
      { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
    ]
  } else if (postComponent === POST_MIXED_MEDIA) {
    if (postMixedMedias && postMixedMedias.length !== 0 && postMixedMedias[0].type) {
      if (postMixedMedias[0].type === IMAGE || postMixedMedias[0].type === IMAGE_DYNAMIC) {
        const postMixedMediasImagesLength = postMixedMedias.filter(
          ({ type }) => type === IMAGE || type === IMAGE_DYNAMIC
        ).length

        if (postMixedMediasImagesLength === 1) {
          PUBLISH_AS_DROPDOWN_VALUES = [
            { value: PUBLISH_AS_IMAGE, label: 'Publish as Image' },
            { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
          ]
        } else {
          PUBLISH_AS_DROPDOWN_VALUES = [
            { value: PUBLISH_AS_IMAGE, label: 'Publish as Image' },
            { value: PUBLISH_AS_CAROUSEL, label: 'Publish as Carousel (pages only)' },
          ]
        }
      } else if (postMixedMedias[0].type === VIDEO || postMixedMedias[0].type === VIDEO_DYNAMIC) {
        PUBLISH_AS_DROPDOWN_VALUES = [
          { value: PUBLISH_AS_REELS, label: 'Publish as Reel' },
          { value: PUBLISH_AS_VIDEO, label: 'Publish as Video' },
          { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
        ]
      }
    }
  }

  return PUBLISH_AS_DROPDOWN_VALUES
}

export const getPublishAsDropDownValuesForSnapchat = ({ postComponent, postMixedMedias }) => {
  let updatedPostComponent = postComponent

  if (updatedPostComponent === POST_MIXED_MEDIA) {
    if (postMixedMedias.length !== 0) {
      const { 0: { type } = {} } = postMixedMedias

      if (type === IMAGE || type === IMAGE_DYNAMIC) {
        updatedPostComponent = POST_IMAGE
      } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
        updatedPostComponent = POST_VIDEO
      }
    }
  }

  let MEDIA_TYPE_DROPDOWN_VALUES = []

  if (updatedPostComponent === POST_VIDEO) {
    MEDIA_TYPE_DROPDOWN_VALUES = [
      { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
      { value: PUBLISH_AS_SAVED_STORY, label: 'Publish as Saved Story' },
      { value: PUBLISH_AS_SPOTLIGHT, label: 'Publish as Spotlight' },
    ]
  } else if (updatedPostComponent === POST_IMAGE || updatedPostComponent === POST_LINK) {
    MEDIA_TYPE_DROPDOWN_VALUES = [
      { value: PUBLISH_AS_STORY, label: 'Publish as Story' },
      { value: PUBLISH_AS_SAVED_STORY, label: 'Publish as Saved Story' },
    ]
  }

  return MEDIA_TYPE_DROPDOWN_VALUES
}

export const getPublishAsDropDownValuesForVistaPage = ({ postComponent }) => {
  let MEDIA_TYPE_DROPDOWN_VALUES = []

  if (postComponent === POST_IMAGE || postComponent === POST_MIXED_MEDIA) {
    MEDIA_TYPE_DROPDOWN_VALUES = [
      { value: PUBLISH_AS_POST, label: 'Publish as posts' },
      { value: PUBLISH_TO_IMAGE_GRID, label: 'Publish to an image grid' },
    ]
  }
  return MEDIA_TYPE_DROPDOWN_VALUES
}

const delay = ({ value, delay = 1000 } = {}) => new Promise((resolve) => setTimeout(() => resolve(value), delay))

const waitForUrl = async ({ url, interval = 2000, attempts = 3 }) => {
  // const debug = console.log

  let attempt = 0
  while (attempt++ < attempts) {
    // debug(`attempting ${attempt} of ${attempts} getting media meta information from ${url}`)
    // let's wait for PDF
    // const url = `${process.env.NEXT_PUBLIC_CLOUDFRONT_URL}/${file}?v=${Date.now()}`

    let res

    try {
      res = await fetch(url)
    } catch (error) {
      errorHelper({
        error,
        componentName: 'Calendar/helpers',
        functionName: 'waitForUrl',
        sendError: false,
        showAlert: false,
      })
    }

    if (res && res.status === 200) {
      return await res.text()
    } else {
      // debug(
      //   `waiting for cloudfront url to become available ${url}, status ${
      //     res ? res.status : 404
      //   }, attempt ${attempt} of ${attempts}`
      // )

      await delay({ delay: interval })
    }
  }

  return undefined
}

export const uploadMediaToServerByFormDataHelper = async ({ file, uploadingId, replacement_type }) => {
  let tempId
  let media
  let error

  const { name, type: mimetype, size } = file

  try {
    const upload_start_response = await request({
      method: 'POST',
      body: {
        name,
        mimetype,
        size,
      },
      path: `${ROUTE_MEDIA}/upload/start?tempId=${uploadingId}&replacement_type=${replacement_type || ''}`,
    })

    console.log('upload_start_response', upload_start_response)
    ;({ tempId } = upload_start_response || {})

    if (!upload_start_response || upload_start_response.error) {
      error = upload_start_response ? upload_start_response.error : ERROR_MESSAGE
    } else {
      media = upload_start_response
    }
  } catch (e) {
    errorHelper({
      error: e,
      componentName: 'Calendar/helpers',
      functionName: 'uploadMediaToServerByFormDataHelper',
      showAlert: false,
    })
    error = ERROR_MESSAGE
  }

  const { upload_url, meta_url, type, mimetype: fixed_mimetype } = media || {}
  // eslint-disable-next-line prefer-destructuring
  const media_gid = media ? media.media_gid : null

  if (!media_gid && !error) {
    error = `Can't upload your media. Please contact support.`
  }

  if (!error) {
    try {
      const start = Date.now()

      const uploadFile = () =>
        new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest()
          xhr.upload.addEventListener('progress', (e) => {
            const mediaProgressIndicatorEl = document.getElementById(`media-upload-progress-${tempId}`)

            if (mediaProgressIndicatorEl) {
              let progress = Math.round((e.loaded / e.total) * 100)

              if (progress === 100) {
                progress = 99
              }

              mediaProgressIndicatorEl.innerText = `${progress}%`
            }
          })

          xhr.addEventListener('load', () => {
            resolve(xhr)
          })

          xhr.addEventListener('error', () => {
            reject(xhr)
          })

          xhr.addEventListener('abort', () => {
            reject(xhr)
          })

          xhr.open('PUT', upload_url, true)

          xhr.setRequestHeader('Content-Type', mimetype || fixed_mimetype)
          xhr.setRequestHeader('Content-Disposition', 'inline')
          xhr.setRequestHeader('Origin', process.env.NEXT_PUBLIC_SITE_URL)

          xhr.send(file)
        })

      const upload_response = await uploadFile()

      console.log(`completed upload ${upload_response.status}: ${upload_response.statusText} in ${Date.now() - start}`)

      if (upload_response.status !== 200) {
        error = `Unable to upload media: ${upload_response.status}`
      }
    } catch (e) {
      errorHelper({
        error: e,
        componentName: 'Calendar/helpers',
        functionName: 'uploadMediaToServerByFormDataHelper',
        showAlert: false,
      })
      error = ERROR_MESSAGE
    }
  }

  if (!error) {
    await request({
      method: 'POST',
      path: `${ROUTE_MEDIA}/upload/finish?tempId=${uploadingId}&id=${media_gid}&success=true&replacement_type=${
        type !== replacement_type && replacement_type ? replacement_type : ''
      }`,
    })

    let meta

    if (type !== DOCUMENT) {
      meta = await waitForUrl({ url: meta_url, interval: 500, attempts: 50 }) // 25 seconds
    }

    if (meta) {
      media = { ...media, ...JSON.parse(meta) }

      const { width, height } = media

      if (width && height) {
        media.aspect_ratio = Number((width / height).toFixed(2))
      }
    } else {
      console.log('very odd, missing meta???')
    }
  }

  if (error && media_gid) {
    await request({
      method: 'POST',
      path: `${ROUTE_MEDIA}/upload/finish?tempId=${uploadingId}&id=${media_gid}&success=false&error=${error}`,
    })
  }

  return { tempId, media_gid, error, media }
}

export const convertPostToEvent = ({ post }) => {
  const {
    type,
    id,
    postText,
    publish_at,
    profile_gid,
    attachments,
    result,
    link,
    customizations,
    timezone,
    status,
    postImages,
    postVideos,
    postDocuments,
    postMixedMedias,
    labels,
    startStr,
  } = post

  let postData = {}

  if (type && type === POST_TYPE_SOCIAL_POST) {
    postData = {
      event: { id, title: postText, startStr, extendedProps: { ...post } },
    }
  } else {
    postData = {
      event: {
        id,
        title: postText,
        startStr: publish_at,
        extendedProps: {
          profile_gid,
          attachments,
          result,
          link,
          customizations,
          timezone,
          status,
          postImages,
          postVideos,
          postDocuments,
          postMixedMedias,
          labels,
        },
      },
    }
  }

  return postData
}

export const generateTextHeaderForDeletePostModal = ({ post }) => {
  const { profile: { display = '', network } = {}, status } = post

  let headerText = `This post will be permanently removed from Vista Social. Are you sure?`

  if (status === PUBLISHED) {
    headerText = `This post will be permanently removed from ${display}. Are you sure?`
    if (DELETE_POST_NETWORK_LIMITATIONS.includes(network)) {
      headerText = `Due to limitations set by ${display}, this will only mark the post as removed on Vista Social. This post will not be removed from ${display}. Would you like to proceed?`
    }
  }
  return headerText
}

export const getTikTokProfileMedias = async ({
  id,
  dataForCustomizations,
  setDataForCustomizations,
  postIds,
  type = 'feed',
}) => {
  const { [id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}

  if (id && (!networkDataForCustomizations || !networkDataForCustomizations.medias)) {
    if (!dataForCustomizations.networkDataForCustomizations) {
      dataForCustomizations.networkDataForCustomizations = {}
    }

    if (!dataForCustomizations.networkDataForCustomizations[id]) {
      dataForCustomizations.networkDataForCustomizations[id] = { id }
    }

    if (!dataForCustomizations.networkDataForCustomizations[id][type]) {
      dataForCustomizations.networkDataForCustomizations[id][type] = { isGettingGridMedia: true }
      setDataForCustomizations({ ...dataForCustomizations })

      const dateFrom = moment()
        .clone()
        .startOf('day')
        .subtract(1, 'days')
        .format('YYYY-MM-DD')

      let medias = []

      try {
        const response = await request({
          path: `${ROUTE_TIKTOK_MEDIAS}?profile_gid=${id}&dateFrom=${dateFrom}`,
        })

        const { data = [], error } = response

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (postIds.length !== 0) {
          medias = data.filter((item) => !postIds.find((id) => id === item.postId))
        } else {
          medias = data
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: 'Calendar/helpers',
          functionName: 'getTikTokProfileMedias',
        })
      } finally {
        dataForCustomizations.networkDataForCustomizations[id][type].isGettingGridMedia = false
        dataForCustomizations.networkDataForCustomizations[id][type].medias = medias
        setDataForCustomizations({ ...dataForCustomizations })
      }
    }
  }
}

export const collectEntitiesAndProfiles = ({
  postEntities,
  entities,
  selectedEntities,
  selectedProfiles,
  updatedPost,
}) => {
  const selectedEntitiesForSelectedPost = []
  const selectedProfilesForSelectedPost = []

  const notSelectedEntitiesForEntitySelector = []
  const notSelectedProfilesForEntitySelector = []

  postEntities.forEach(({ entityId, targets }) => {
    let foundSelectedEntity = selectedEntities.find(({ id }) => id === entityId)
    if (!foundSelectedEntity) {
      foundSelectedEntity = entities.find(({ id }) => id === entityId)
      if (foundSelectedEntity) {
        foundSelectedEntity.isOpen = true
        notSelectedEntitiesForEntitySelector.push(foundSelectedEntity)
        selectedEntitiesForSelectedPost.push(foundSelectedEntity)
      }
    } else {
      selectedEntitiesForSelectedPost.push(foundSelectedEntity)
    }
    targets.forEach(({ id, publish_at }) => {
      let foundProfile = selectedProfiles.find((item) => item.id === id && entityId === item.entityId)

      if (!foundProfile) {
        foundProfile = foundSelectedEntity ? foundSelectedEntity.profiles.find((item) => item.id === id) : null

        if (!foundProfile) {
          for (let i = 0; i < entities.length; i++) {
            const foundTempProfile = entities[i].profiles.find((item) => item.id === id)
            if (foundTempProfile) {
              foundProfile = foundTempProfile
              foundSelectedEntity = entities[i]
              selectedEntitiesForSelectedPost.push(entities[i])
            }
          }
        }
        if (foundProfile) {
          notSelectedProfilesForEntitySelector.push(foundProfile)
          if (foundSelectedEntity) {
            foundProfile.entityType = foundSelectedEntity.type
            foundProfile.entityId = foundSelectedEntity.id
          }
          const {
            name,
            username,
            picture_url,
            entityType,
            profile_url,
            network: { code, display },
            network,
            description,
            status,
          } = foundProfile
          selectedProfilesForSelectedPost.push({
            id,
            entityId: foundProfile.entityId,
            picture_url,
            code,
            name,
            publish_at,
            entityType,
            username,
            display,
            profile_url,
            description,
            network,
            status,
          })
        }
      } else {
        if (foundSelectedEntity) {
          foundProfile.entityType = foundSelectedEntity.type
          foundProfile.entityId = foundSelectedEntity.id
        }

        if (foundProfile.network) {
          const { code, display } = foundProfile.network
          foundProfile.code = code
          foundProfile.display = display
        }

        foundProfile.publish_at = publish_at
        selectedProfilesForSelectedPost.push(foundProfile)
      }

      if (updatedPost && !updatedPost.profileNetwork && foundProfile) {
        updatedPost.profileNetwork = foundProfile.network.code
      }
    })
  })

  return {
    selectedEntitiesForSelectedPost,
    selectedProfilesForSelectedPost,
    notSelectedEntitiesForEntitySelector,
    notSelectedProfilesForEntitySelector,
  }
}

export const setPostComponent = ({ data }) => {
  if (data.link && data.link.active) {
    data.postComponent = POST_LINK
  } else if (data.postImages && data.postImages.length !== 0) {
    data.postComponent = POST_IMAGE
  } else if (data.postVideos && data.postVideos.length !== 0) {
    data.postComponent = POST_VIDEO
  } else if (data.postDocuments && data.postDocuments.length !== 0) {
    data.postComponent = POST_DOCUMENT
  } else if (data.postMixedMedias && data.postMixedMedias.length !== 0) {
    data.postComponent = POST_MIXED_MEDIA
  }

  if (data.customizations) {
    for (const network_code in data.customizations) {
      if (data.customizations.hasOwnProperty(network_code)) {
        for (const profile_gid in data.customizations[network_code]) {
          if (data.customizations[network_code].hasOwnProperty(profile_gid)) {
            setPostComponent({ data: data.customizations[network_code][profile_gid] })

            if (data.customizations[network_code][profile_gid].comments) {
              data.customizations[network_code][profile_gid].comments.forEach((comment) => {
                setPostComponent({ data: comment })
              })
            }
          }
        }
      }
    }
  }
}

export const handleCollectPostData = async ({
  eventInfo,
  noPostInformation = false,
  useStatus,
  user,
  entities,
  selectedTimezone,
  selectedProfiles,
  selectedEntities,
}) => {
  const {
    event: {
      id,
      title,
      openPostType,
      extendedProps: {
        type,
        profile_gid,
        result,
        link,
        customizations,
        insights,
        timezone,
        status,
        network = '',
        user_gid: postCreatorUserId,
      } = {},
      extendedProps,
      startStr,
    } = {},
  } = eventInfo

  let systemUserOnlyForView = true

  const { id: userId, entities: userEntities = [] } = user

  const checkIfSystemUserOnlyForView = ({ profile_gid }) => {
    for (let i = 0; i < entities.length; i++) {
      if (!systemUserOnlyForView) {
        break
      } else {
        const { profiles = [], id } = entities[i]
        const foundProfile = profiles.find(({ id }) => id === profile_gid)
        if (foundProfile) {
          const foundEntity = userEntities.find((entity) => entity.id === id)
          if (foundEntity) {
            const {
              entity_user: { [PERMISSION_PUBLISH]: entity_user_permission },
            } = foundEntity
            if (
              entity_user_permission === USER_ROLE_MANAGE ||
              (entity_user_permission === USER_ROLE_CONTRIBUTOR && userId === postCreatorUserId)
            ) {
              systemUserOnlyForView = false
            }
          }
        }
      }
    }
  }

  if (profile_gid) {
    checkIfSystemUserOnlyForView({ profile_gid })
  }

  let hasError = false
  let selectedEntitiesForSelectedPost = []
  let selectedProfilesForSelectedPost = []
  let notSelectedEntitiesForEntitySelector = []
  let notSelectedProfilesForEntitySelector = []
  let updatedPost = {}

  if (!type || type === POST_TYPE_SOCIAL_POST) {
    // this is when we are not opening by id from query params or shared calendar
    if (!noPostInformation) {
      updatedPost = {
        id,
        postText: title,
        publish_at: startStr,
        result,
        link,
        customizations,
        insights,
        profile_gid,
        timezone,
        status,
      }
    }

    if (type === POST_TYPE_SOCIAL_POST) {
      updatedPost = { id, ...extendedProps, systemUserOnlyForView }
    } else {
      let use_status = ''
      if (systemUserOnlyForView) {
        use_status = SPECIAL_UI_STATUS_PUBLISHING
      } else if (useStatus) {
        use_status = useStatus
      }

      try {
        const response = await request({
          method: 'GET',
          path: `${ROUTE_CALENDAR}/${id}?timezone=${selectedTimezone}&use_status=${use_status}&post_type=${openPostType ||
            ''}`,
        })

        const { error } = response

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          hasError = true
        } else {
          updatedPost = {
            ...updatedPost,
            ...response.post,
          }

          if (systemUserOnlyForView) {
            updatedPost.systemUserOnlyForView = checkIfSystemUserOnlyForView({ profile_gid: updatedPost.profile_gid })
          }

          if (updatedPost.link) {
            if (updatedPost.link.title || updatedPost.link.description || updatedPost.link.picture_url) {
              updatedPost.link.hasLinkUrlInformation = true
              updatedPost.link.isLinkUrlCorrect = true
              updatedPost.link.isLinkUrlParsed = false
            }
            if (!updatedPost.link.picture_url) {
              updatedPost.link.needsUpload = true
            }
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: 'Calendar/helpers',
          functionName: 'handleCollectPostData',
          errorMessage: `Cannot get additional information for post: ${title.substr(0, 100)}`,
        })
        hasError = true
      }
    }

    if (!hasError) {
      const { code: profileNetwork = network } =
        selectedProfiles.find((value) => value.id === updatedPost.profile_gid) || {}

      updatedPost.profileNetwork = profileNetwork

      setPostComponent({ data: updatedPost })

      if (updatedPost.postComponent === POST_LINK && updatedPost.link && updatedPost.link.url) {
        updatedPost.link.active = true
      }

      if (updatedPost.status === DRAFT) {
        updatedPost.draftId = updatedPost.id
        delete updatedPost.id
      }

      ;({
        selectedEntitiesForSelectedPost,
        selectedProfilesForSelectedPost,
        notSelectedEntitiesForEntitySelector,
        notSelectedProfilesForEntitySelector,
      } = collectEntitiesAndProfiles({
        postEntities: updatedPost.entities,
        entities,
        selectedEntities,
        selectedProfiles,
        updatedPost,
      }))
    }
  } else {
    hasError = true
  }

  return {
    hasError,
    selectedEntitiesForSelectedPost,
    selectedProfilesForSelectedPost,
    notSelectedEntitiesForEntitySelector,
    notSelectedProfilesForEntitySelector,
    updatedPost,
  }
}

export const purifyingPost = ({ post }) => {
  post.isFromFindContentModal = true
  const selectedPost = JSON.parse(JSON.stringify(post))

  // if (selectedPost.postImages) {
  //   selectedPost.postImages.forEach((media) => {
  //     // if (media.dynamic) {
  //     //   media.type = IMAGE_DYNAMIC
  //     // }
  //   })
  // }

  if (selectedPost.postVideos) {
    selectedPost.postVideos.forEach((media) => {
      // if (media.dynamic) {
      //   media.type = VIDEO_DYNAMIC
      // }
      delete media.s3_urls
    })
  }

  if (selectedPost.postMixedMedias) {
    selectedPost.postMixedMedias.forEach((media) => {
      // if (media.dynamic && !media.type) {
      //   media.type = VIDEO_DYNAMIC
      // }
      delete media.s3_urls
    })
  }

  if (selectedPost.customizations) {
    for (const network in selectedPost.customizations) {
      if (selectedPost.customizations.hasOwnProperty(network) && selectedPost.customizations[network]) {
        for (const profile_gid in selectedPost.customizations[network]) {
          if (
            selectedPost.customizations[network].hasOwnProperty(profile_gid) &&
            selectedPost.customizations[network][profile_gid]
          ) {
            if (selectedPost.customizations[network][profile_gid].postVideos) {
              selectedPost.customizations[network][profile_gid].postVideos.forEach((media) => {
                // if (media.dynamic) {
                //   media.type = VIDEO_DYNAMIC
                // }

                delete media.s3_urls
              })
            }

            if (selectedPost.customizations[network][profile_gid].postMixedMedias) {
              selectedPost.customizations[network][profile_gid].postMixedMedias.forEach((media) => {
                // if (media.dynamic) {
                //   media.type = VIDEO_DYNAMIC
                // }

                delete media.s3_urls
              })
            }

            // if (selectedPost.customizations[network][profile_gid].postImages) {
            //   selectedPost.customizations[network][profile_gid].postImages.forEach((media) => {
            //     if (media.dynamic) {
            //       media.type = IMAGE_DYNAMIC
            //     }
            //   })
            // }
          }
        }
      }
    }
  }

  if (selectedPost.originalPostText) {
    selectedPost.postText = selectedPost.originalPostText
  }

  const fields_for_removing = [
    'id',
    'created_at',
    'result',
    'publication_group_id',
    'status',
    'title',
    'profile_gid',
    'targets_blocked',
    'post_creator_user_gid',
    'postsData',
    'insights',
    'createdBy',
    'review',
    'workflow',
    'systemUserOnlyForView',
    'group_posts',
    'publication_group_data',
    'comments',
    'agencyMentions',
    'creator',
    'entity_gid',
    'has_boost',
    'originalPostText',
    'advocacy_groups',
  ]

  fields_for_removing.forEach((key) => {
    delete selectedPost[key]
  })

  return selectedPost
}

export const handleSendToCompose = ({
  publishModalOptions,
  setPublishModalOptions,
  post,
  selectedEntitiesForSelectedPost,
  selectedProfilesForSelectedPost,
}) => {
  publishModalOptions.selectedPost = purifyingPost({ post })

  publishModalOptions.selectedCalendarDate = publishModalOptions.selectedPost.publish_at
  publishModalOptions.isOpen = true
  if (selectedEntitiesForSelectedPost) {
    publishModalOptions.selectedEntitiesForSelectedPost = selectedEntitiesForSelectedPost
  }
  if (selectedProfilesForSelectedPost) {
    publishModalOptions.selectedProfilesForSelectedPost = selectedProfilesForSelectedPost
  }
  setPublishModalOptions({ ...publishModalOptions })
}

export const getRedditFlairs = async ({ id, dataForCustomizations, setDataForCustomizations, subreddit }) => {
  if (id) {
    if (!dataForCustomizations.networkDataForCustomizations) {
      dataForCustomizations.networkDataForCustomizations = {}
    }

    if (!dataForCustomizations.networkDataForCustomizations[id]) {
      dataForCustomizations.networkDataForCustomizations[id] = { id }
    }

    if (!dataForCustomizations.networkDataForCustomizations[id].isGettingFlairs) {
      dataForCustomizations.networkDataForCustomizations[id].isGettingFlairs = !!subreddit
      dataForCustomizations.networkDataForCustomizations[id].flairs = []
      setDataForCustomizations({ ...dataForCustomizations })

      if (subreddit) {
        let flairs = []

        try {
          const response = await request({
            path: `${ROUTE_REDDIT_FLAIRS}?profile_gid=${id}&subreddit=r/${subreddit}`,
          })

          const { data = [], error } = response

          if (!response || error) {
            Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          } else {
            flairs = data
          }
        } catch (error) {
          errorHelper({
            error,
            componentName: 'Calendar/helpers',
            functionName: 'getRedditFlairs',
          })
        } finally {
          dataForCustomizations.networkDataForCustomizations[id].isGettingFlairs = false
          dataForCustomizations.networkDataForCustomizations[id].flairs = flairs
          setDataForCustomizations({ ...dataForCustomizations })
        }
      }
    }
  }
}

export const getYouTubeCategoriesAndPlaylists = async ({
  id,
  type,
  dataForCustomizations,
  setDataForCustomizations,
}) => {
  await dataForCustomizationsInGettingDataFunction({
    id,
    type,
    dataForCustomizations,
    setDataForCustomizations,
    functionForSettingCustomizationsData: async () => {
      try {
        const { 0: playlists = [], 1: categories = [] } = await Promise.all([
          request({
            path: `${ROUTE_YOUTUBE_PLAYLISTS}?profile_gid=${id}`,
          }),
          request({
            path: `${ROUTE_YOUTUBE_CATEGORIES}?profile_gid=${id}`,
          }),
        ])

        dataForCustomizations.networkDataForCustomizations[id].typeData[type] = {
          playlists: playlists.error ? [] : playlists,
          categories: categories.error ? [] : categories,
        }
      } catch (error) {
        errorHelper({ error, componentName: 'Calendar/helpers', functionName: 'getYouTubeCategoriesAndPlaylists' })
      }
    },
  })
}

export const getVistaPageImagesGrid = async ({ id, type, dataForCustomizations, setDataForCustomizations }) => {
  await dataForCustomizationsInGettingDataFunction({
    id,
    type,
    dataForCustomizations,
    setDataForCustomizations,
    functionForSettingCustomizationsData: async () => {
      try {
        const response = await request({
          method: 'POST',
          path: `${ROUTE_VISTA_PAGE}/${id}/images-grid`,
        })

        const { error, data } = response || {}

        dataForCustomizations.networkDataForCustomizations[id].typeData[type] = {
          grid_images: error ? [] : data,
        }
      } catch (error) {
        errorHelper({ error, componentName: 'Calendar/helpers', functionName: 'getVistaPageImagesGrid' })
      }
    },
  })
}

export const defaultValuesForPost = ({
  selectedProfiles,
  post,
  dataForCustomizations,
  setDataForCustomizations,
  allowGetFacebookGroupsOptions = true,
  user,
}) => {
  let needUpdatePost = false

  const {
    id: postId,
    postComponent,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    customizations = {},
    defaultFields,
    link,
  } = post

  const { picture_url: link_picture_url } = link || {}

  selectedProfiles.forEach((profile) => {
    const { id, code, profile_url } = profile

    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[code]) {
      post.customizations[code] = {}
    }
    if (!post.customizations[code][id]) {
      post.customizations[code][id] = {}
    }

    let { [code]: { [id]: profileCustomizations = {} } = {} } = customizations

    const {
      media_type = null,
      publish_as_user = null,
      publish_as = null,
      video_title,
      notifySubscribers,
      embeddable,
      privacyStatus,
      is_paid_partnership,
      is_promotional_content,
      duets_enabled,
      stitches_enabled,
      comments_enabled,
      publishing_option,
      save_to_profile,
      locale,
      postImages: customizedPostImages = postImages,
      postVideos: customizedPostVideos = postVideos,
      postMixedMedias: customizedPostMixedMedias = postMixedMedias,
      comments,
      boost_gid,
    } = profileCustomizations

    if (defaultFields) {
      const { comments: defaultFieldsComments = [], defaultCustomizationsByNetwork } = defaultFields

      if (defaultFieldsComments) {
        const { commentTextMaxLimit, commentTextMaxLimitPaid } =
          NETWORK_LIMITS.find((network_limit) => network_limit.code === code) || {}

        if (typeof comments === 'undefined' && (commentTextMaxLimit || commentTextMaxLimitPaid)) {
          needUpdatePost = true

          profileCustomizations.comments = [...defaultFieldsComments]

          post.customizations[code][id] = profileCustomizations
        }
      }

      if (
        Object.keys(profileCustomizations).length <= 1 &&
        defaultCustomizationsByNetwork &&
        defaultCustomizationsByNetwork[code]
      ) {
        needUpdatePost = true

        profileCustomizations = { ...defaultCustomizationsByNetwork[code] }

        post.customizations[code][id] = profileCustomizations
      }
    }

    let { postComponent: customizedPostComponent } = profileCustomizations

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    if (!publishing_option) {
      profileCustomizations.publishing_option = PUBLISHING_OPTION_DIRECT
      post.customizations[code][id] = profileCustomizations
      needUpdatePost = true
    }

    if (code === INSTAGRAM) {
      let MEDIA_TYPE_DROPDOWN_VALUES = getMediaTypeDropDownValuesForInstagram({
        postComponent: customizedPostComponent,
        postMixedMedias: customizedPostMixedMedias,
      })

      let media_type_updated = null

      if (customizedPostComponent === POST_IMAGE || customizedPostComponent === POST_VIDEO) {
        let updatedPostComponent = customizedPostComponent
        let updatedPostVideos = customizedPostVideos
        if (
          customizedPostImages.length !== 0 &&
          customizedPostComponent === POST_IMAGE &&
          customizedPostImages.length === customizedPostImages.filter(({ extension }) => extension === 'gif').length
        ) {
          updatedPostComponent = POST_VIDEO
          updatedPostVideos = customizedPostImages
          MEDIA_TYPE_DROPDOWN_VALUES = getMediaTypeDropDownValuesForInstagram({
            postComponent: updatedPostComponent,
            postMixedMedias: customizedPostMixedMedias,
          })
        }

        if (updatedPostComponent === POST_IMAGE) {
          if (customizedPostImages.length === 1) {
            if (!media_type || media_type === PUBLISH_AS_CAROUSEL) {
              media_type_updated = PUBLISH_AS_IMAGE
            } else {
              const foundType = MEDIA_TYPE_DROPDOWN_VALUES.find(({ value }) => value === media_type)
              media_type_updated = foundType ? media_type : PUBLISH_AS_IMAGE
            }
          } else if (customizedPostImages.length > 1) {
            media_type_updated = PUBLISH_AS_CAROUSEL
          }
        } else if (updatedPostComponent === POST_VIDEO) {
          if (updatedPostVideos.length === 1) {
            if (!media_type || media_type === PUBLISH_AS_CAROUSEL) {
              media_type_updated = PUBLISH_AS_REELS
            } else {
              const foundType = MEDIA_TYPE_DROPDOWN_VALUES.find(({ value }) => value === media_type)
              media_type_updated = foundType ? media_type : PUBLISH_AS_REELS
            }
          } else if (updatedPostVideos.length > 1) {
            media_type_updated = PUBLISH_AS_CAROUSEL
          }
        }
      } else if (customizedPostComponent === POST_LINK) {
        if (!media_type || media_type === PUBLISH_AS_CAROUSEL) {
          media_type_updated = PUBLISH_AS_IMAGE
        } else {
          const foundType = MEDIA_TYPE_DROPDOWN_VALUES.find(({ value }) => value === media_type)
          media_type_updated = foundType ? media_type : PUBLISH_AS_IMAGE
        }
      } else if (customizedPostComponent === POST_MIXED_MEDIA) {
        if (customizedPostMixedMedias.length === 1) {
          const { 0: { type } = {} } = customizedPostMixedMedias

          const foundType = MEDIA_TYPE_DROPDOWN_VALUES.find(({ value }) => value === media_type)

          if (type === IMAGE || type === IMAGE_DYNAMIC) {
            media_type_updated = foundType ? media_type : PUBLISH_AS_IMAGE
          } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
            media_type_updated = foundType ? media_type : PUBLISH_AS_REELS
          }
        } else {
          media_type_updated = PUBLISH_AS_CAROUSEL
        }

        // instagram carousel posts can't be boosted
        if (boost_gid && media_type_updated === PUBLISH_AS_CAROUSEL) {
          needUpdatePost = true

          profileCustomizations.boost_gid = null

          post.customizations[code][id] = profileCustomizations
        }
      }

      if (media_type !== media_type_updated) {
        needUpdatePost = true

        profileCustomizations.media_type = media_type_updated

        post.customizations[code][id] = profileCustomizations
      }

      const profileType = getProfileFlavor({ profile })

      if (
        (profileType === PROFILE_TYPE_PROFILE ||
          (profileType === PROFILE_TYPE_CREATOR && media_type_updated === PUBLISH_AS_STORY)) &&
        publishing_option === PUBLISHING_OPTION_DIRECT
      ) {
        needUpdatePost = true
        profileCustomizations.publishing_option = PUBLISHING_OPTION_NOTIFICATION
        post.customizations[code][id] = profileCustomizations
      }

      if (typeof comments_enabled === 'undefined') {
        needUpdatePost = true

        profileCustomizations.comments_enabled = true

        post.customizations[code][id] = profileCustomizations
      }
    } else if (code === FACEBOOK) {
      const profileType = getProfileFlavor({ profile })

      const isFacebookGroup = profileType === PROFILE_TYPE_GROUP

      if (
        (isFacebookGroup || profileType === PROFILE_TYPE_PROFILE) &&
        (!publishing_option || publishing_option === PUBLISHING_OPTION_DIRECT)
      ) {
        needUpdatePost = true
        profileCustomizations.publishing_option = PUBLISHING_OPTION_NOTIFICATION
        post.customizations[code][id] = profileCustomizations
      }

      // if (isFacebookGroup && allowGetFacebookGroupsOptions) {
      //   getFacebookGroupsOptions({
      //     id,
      //     dataForCustomizations,
      //     setDataForCustomizations,
      //   })
      // }

      // const { [id]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
      // const { groupsOptions = [] } = networkDataForCustomizations || {}

      // if (!publish_as_user && groupsOptions && groupsOptions.length !== 0) {
      //   let foundGroupOption = groupsOptions[0]
      //   if (postId) {
      //     foundGroupOption =
      //       groupsOptions.find(({ value }) => value === PUBLISH_AS_USER_TYPE_PERSONAL) || groupsOptions[0]
      //   }
      //
      //   needUpdatePost = true
      //
      //   profileCustomizations.publish_as_user = { description: foundGroupOption.label, type: foundGroupOption.value }
      //
      //   post.customizations[code][id] = profileCustomizations
      // }

      const PUBLISH_AS_DROPDOWN_VALUES = getPublishAsDropDownValuesForFacebook({
        postComponent: customizedPostComponent,
        post,
      })

      let publish_as_updated = null

      if (customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_IMAGE) {
        if (customizedPostComponent === POST_IMAGE) {
          if (customizedPostImages.length === 1) {
            if (!publish_as || publish_as === PUBLISH_AS_CAROUSEL || publish_as === PUBLISH_AS_SLIDESHOW) {
              publish_as_updated = PUBLISH_AS_IMAGE
            } else {
              const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
              publish_as_updated = foundType ? publish_as : PUBLISH_AS_IMAGE
            }
          } else if (customizedPostImages.length > 1) {
            if (publish_as === PUBLISH_AS_STORY) {
              publish_as_updated = PUBLISH_AS_IMAGE
            } else {
              publish_as_updated = publish_as || PUBLISH_AS_IMAGE
            }
          }
        } else if (customizedPostComponent === POST_VIDEO) {
          if (customizedPostVideos.length === 1) {
            if (!publish_as) {
              publish_as_updated = PUBLISH_AS_REELS
            } else {
              const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
              publish_as_updated = foundType ? publish_as : PUBLISH_AS_REELS
            }
          } else if (customizedPostVideos.length > 1) {
            publish_as_updated = PUBLISH_AS_VIDEO
          }
        }
      } else if (customizedPostComponent === POST_LINK) {
        if (!publish_as || publish_as === PUBLISH_AS_CAROUSEL) {
          publish_as_updated = PUBLISH_AS_ARTICLE
        } else {
          const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
          publish_as_updated = foundType ? publish_as : PUBLISH_AS_ARTICLE
        }
      } else if (customizedPostComponent === POST_MIXED_MEDIA) {
        if (customizedPostMixedMedias.length !== 0 && customizedPostMixedMedias[0].type) {
          const { 0: { type } = {} } = customizedPostMixedMedias
          if (type === IMAGE || type === IMAGE_DYNAMIC) {
            const customizedPostMixedMediaImagesLength = customizedPostMixedMedias.filter(
              ({ type }) => type === IMAGE || type === IMAGE_DYNAMIC
            ).length

            if (customizedPostMixedMediaImagesLength === 1) {
              if (!publish_as || publish_as === PUBLISH_AS_CAROUSEL || publish_as === PUBLISH_AS_SLIDESHOW) {
                publish_as_updated = PUBLISH_AS_IMAGE
              } else {
                const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
                publish_as_updated = foundType ? publish_as : PUBLISH_AS_IMAGE
              }
            } else if (customizedPostMixedMediaImagesLength > 1) {
              if (publish_as !== PUBLISH_AS_IMAGE && publish_as !== PUBLISH_AS_CAROUSEL) {
                publish_as_updated = PUBLISH_AS_IMAGE
              } else {
                publish_as_updated = publish_as || PUBLISH_AS_IMAGE
              }
            }
          } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
            if (!publish_as) {
              publish_as_updated = PUBLISH_AS_REELS
            } else {
              const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
              publish_as_updated = foundType ? publish_as : PUBLISH_AS_REELS
            }
          }
        }
      }

      if (publish_as !== publish_as_updated && !isFacebookGroup) {
        needUpdatePost = true

        profileCustomizations.publish_as = publish_as_updated

        post.customizations[code][id] = profileCustomizations
      }
    } else if (code === YOUTUBE || code === VIMEO) {
      if (typeof video_title === 'undefined') {
        let customizedPostMedia = {}

        if (customizedPostComponent === POST_VIDEO) {
          customizedPostMedia = customizedPostVideos
        } else if (customizedPostComponent === POST_MIXED_MEDIA) {
          customizedPostMedia = customizedPostMixedMedias.find(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
        }

        const { 0: { title = '' } = {} } = customizedPostMedia || {}

        if (video_title !== title) {
          needUpdatePost = true

          profileCustomizations.video_title = title || ''

          post.customizations[code][id] = profileCustomizations
        }
      }

      if (code === YOUTUBE) {
        const data = [
          { key: 'notifySubscribers', value: notifySubscribers, defaultValue: true },
          { key: 'embeddable', value: embeddable, defaultValue: true },
          { key: 'privacyStatus', value: privacyStatus, defaultValue: 'public' },
        ]

        data.forEach(({ key, value, defaultValue }) => {
          if (typeof value === 'undefined') {
            needUpdatePost = true

            profileCustomizations[key] = defaultValue

            post.customizations[code][id] = profileCustomizations
          }
        })
      }
    } else if (code === TIKTOK) {
      let data
      if (
        (customizedPostComponent === POST_IMAGE && customizedPostImages.length > 0) ||
        (customizedPostComponent === POST_LINK && link_picture_url) ||
        (customizedPostComponent === POST_MIXED_MEDIA &&
          customizedPostMixedMedias.length > 0 &&
          (customizedPostMixedMedias[0].type === IMAGE || customizedPostMixedMedias[0].type === IMAGE_DYNAMIC))
      ) {
        data = [
          { key: 'is_promotional_content', value: is_promotional_content, defaultValue: false },
          { key: 'is_paid_partnership', value: is_paid_partnership, defaultValue: false },
          { key: 'duets_enabled', value: duets_enabled, defaultValue: false },
          { key: 'stitches_enabled', value: stitches_enabled, defaultValue: false },
        ]
      } else if (
        (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > 0) ||
        (customizedPostComponent === POST_MIXED_MEDIA &&
          customizedPostMixedMedias.length > 0 &&
          (customizedPostMixedMedias[0].type === VIDEO || customizedPostMixedMedias[0].type === VIDEO_DYNAMIC))
      ) {
        data = [
          { key: 'duets_enabled', value: duets_enabled, defaultValue: true },
          { key: 'stitches_enabled', value: stitches_enabled, defaultValue: true },
          { key: 'comments_enabled', value: comments_enabled, defaultValue: true },
        ]
      }

      if (data) {
        data.forEach(({ key, value, defaultValue }) => {
          if (typeof value === 'undefined') {
            needUpdatePost = true

            profileCustomizations[key] = defaultValue

            post.customizations[code][id] = profileCustomizations
          }
        })
      }
    } else if (code === SNAPCHAT) {
      let publish_as_updated = null

      if (
        customizedPostComponent === POST_VIDEO ||
        customizedPostComponent === POST_IMAGE ||
        customizedPostComponent === POST_MIXED_MEDIA ||
        customizedPostComponent === POST_LINK
      ) {
        const PUBLISH_AS_DROPDOWN_VALUES = getPublishAsDropDownValuesForSnapchat({
          postComponent: customizedPostComponent,
          postMixedMedias: customizedPostMixedMedias,
        })

        if (!publish_as) {
          publish_as_updated = PUBLISH_AS_STORY
        } else {
          const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
          publish_as_updated = foundType ? publish_as : PUBLISH_AS_STORY
        }
      }

      if (publish_as !== publish_as_updated) {
        needUpdatePost = true

        profileCustomizations.publish_as = publish_as_updated

        post.customizations[code][id] = profileCustomizations
      }

      if (publish_as_updated === PUBLISH_AS_SPOTLIGHT) {
        const data = [{ key: 'save_to_profile', value: save_to_profile, defaultValue: true }]

        data.forEach(({ key, value, defaultValue }) => {
          if (typeof value === 'undefined') {
            needUpdatePost = true

            profileCustomizations[key] = defaultValue

            post.customizations[code][id] = profileCustomizations
          }
        })
      }

      if (typeof locale === 'undefined' && process.browser) {
        const { locales: formDataLocales = [] } = dataForCustomizations

        const foundUserLocale = navigator.language
          ? formDataLocales.find(({ value }) => value === navigator.language)
          : null

        if (foundUserLocale) {
          needUpdatePost = true

          profileCustomizations.locale = foundUserLocale

          post.customizations[code][id] = profileCustomizations
        }
      }
    } else if (code === VISTAPAGE) {
      let publish_as_updated = null

      if (
        customizedPostComponent === POST_IMAGE ||
        (customizedPostComponent === POST_MIXED_MEDIA &&
          customizedPostMixedMedias.length > 0 &&
          customizedPostMixedMedias.find((media) => media.type === IMAGE || media.type === IMAGE_DYNAMIC))
      ) {
        const PUBLISH_AS_DROPDOWN_VALUES = getPublishAsDropDownValuesForVistaPage({
          postComponent: customizedPostComponent,
          post,
        })

        if (!publish_as) {
          publish_as_updated = PUBLISH_AS_POST
        } else {
          const foundType = PUBLISH_AS_DROPDOWN_VALUES.find(({ value }) => value === publish_as)
          publish_as_updated = foundType ? publish_as : PUBLISH_AS_POST
        }
      }

      if (publish_as !== publish_as_updated) {
        needUpdatePost = true

        profileCustomizations.publish_as = publish_as_updated

        post.customizations[code][id] = profileCustomizations
      }
    } else if (code === BLUESKY) {
      let publish_as_updated = null

      if (
        (customizedPostComponent === POST_IMAGE && customizedPostImages.length > 0) ||
        (customizedPostComponent === POST_MIXED_MEDIA &&
          customizedPostMixedMedias.length > 0 &&
          customizedPostMixedMedias.find((media) => media.type === IMAGE || media.type === IMAGE_DYNAMIC))
      ) {
        const medias =
          customizedPostComponent === POST_IMAGE && customizedPostImages.length > 0
            ? customizedPostImages
            : customizedPostMixedMedias

        if (medias.length === 1) {
          publish_as_updated = PUBLISH_AS_IMAGE
        } else if (medias.length > 1) {
          publish_as_updated = PUBLISH_AS_CAROUSEL
        }
      }

      if (publish_as !== publish_as_updated) {
        needUpdatePost = true

        profileCustomizations.publish_as = publish_as_updated

        post.customizations[code][id] = profileCustomizations
      }
    }
  })

  return { post, needUpdatePost }
}

export const googleDrivePickerConfiguration = ({
  access_token,
  mime_types,
  view_id,
  callbackFunction,
  multiSelect = true,
}) => {
  const my_drive = new google.picker.DocsView(google.picker.ViewId[view_id])
    .setOwnedByMe(true)
    .setMimeTypes(mime_types)
    .setIncludeFolders(true)

  const shared_drive = new google.picker.DocsView(google.picker.ViewId[view_id])
    .setOwnedByMe(false)
    .setMimeTypes(mime_types)
    .setIncludeFolders(true)

  const customViewsArray = [my_drive, shared_drive]

  const config = {
    clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    developerKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    viewId: view_id,
    token: access_token,
    showUploadView: false,
    showUploadFolders: false,
    setSelectFolderEnabled: true,
    supportDrives: true,
    setIncludeFolders: true,
    multiselect: multiSelect,
    disableDefaultView: true,
    viewMimeTypes: mime_types,
    customViews: customViewsArray,
    customScopes: ['https://www.googleapis.com/auth/drive.readonly'],
    callbackFunction: (data) => {
      if (data.action === 'picked') {
        callbackFunction(data)
      }
    },
  }

  return { config }
}

export const dropboxPickerConfiguration = ({ extensions, callbackFunction, multiSelect = true }) => {
  const config = {
    success(files) {
      callbackFunction(files)
    },
    cancel() {},
    linkType: 'direct',
    multiselect: multiSelect,
    extensions,
    folderselect: false,
  }

  return { config }
}

export const oneDrivePickerConfiguration = ({ extensions, callbackFunction, multiSelect = true }) => {
  const config = {
    clientId: process.env.NEXT_PUBLIC_ONE_DRIVE_API_KEY,
    action: 'download',
    multiSelect,
    openInNewWindow: true,
    advanced: {
      redirectUri: `${process.env.NEXT_PUBLIC_SITE_URL}/onedrive`,
      filter: `folder,${extensions}`,
    },
    success(files) {
      callbackFunction(files)
    },
    cancel() {},
    error() {},
  }

  return { config }
}

const replacements = [
  {
    targets: ['\r\n ', ' \r\n'],
    replacement: '\r\n',
  },
  {
    // targets: ['\r\n.\r\n', '\r\n\r\n'],
    targets: ['\r\n\r\n'], // I think if people want to continue to use dots for line breaks - let them.
    replacement: '\nㅤ\n', // this is not a space, this is a special invisible character, do not change
  },
  {
    // targets: ['\n.\n', '\n\n'],
    targets: ['\n\n'], // I think if people want to continue to use dots for line breaks - let them.
    replacement: '\nㅤ\n', // this is not a space, this is a special invisible character, do not change
  },
  {
    targets: [' \n', '\n ', '\r\n'],
    replacement: '\n',
  },
]

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const instagramTextSymbolCounter = ({ message }) => {
  let text = message

  for (const { targets, replacement } of replacements) {
    for (const sequence of targets) {
      text = text.replace(new RegExp(escapeRegExp(sequence), 'g'), replacement)
    }
  }

  return text.length
}

export const generateSyncMediaKeys = ({ selectedProfiles, post }) => {
  const postComponentAccordance = {
    [POST_IMAGE]: 'postImages',
    [POST_VIDEO]: 'postVideos',
    [POST_DOCUMENT]: 'postDocuments',
    [POST_MIXED_MEDIA]: 'postMixedMedias',
  }

  const generate = ({ data }) => {
    data.sync_media_keys = ''

    let updatedPostComponent = data.postComponent

    if (typeof updatedPostComponent === 'undefined') {
      updatedPostComponent = post.postComponent
    }

    const media_key = updatedPostComponent ? postComponentAccordance[updatedPostComponent] || '' : ''

    let data_media_key = data[media_key]

    if (media_key) {
      if (!data_media_key) {
        data_media_key = post[media_key]
      }

      if (data_media_key && data_media_key.length !== 0) {
        data_media_key.forEach(({ media_gid }) => {
          data.sync_media_keys += `${data.sync_media_keys ? '_' : ''}${media_gid}`
        })
      }
    }
  }

  generate({ data: post })

  const { customizations = {} } = post

  selectedProfiles.forEach((profile) => {
    const { code, id } = profile

    const { [code]: networkCustomizations } = customizations || {}
    const { [id]: profileCustomizations = {} } = networkCustomizations || {}

    generate({ data: profileCustomizations || {} })

    let profileCustomizationsPostComponent = profileCustomizations.postComponent

    if (typeof profileCustomizationsPostComponent === 'undefined') {
      profileCustomizationsPostComponent = post.postComponent
    }

    if (
      profileCustomizations.sync_media_keys === post.sync_media_keys &&
      post.postComponent === profileCustomizationsPostComponent
    ) {
      if (profileCustomizationsPostComponent) {
        const media_key = postComponentAccordance[profileCustomizationsPostComponent] || ''

        if (media_key) {
          delete profileCustomizations[media_key]
        }

        delete profileCustomizations.postComponent
      }
    }
  })
}

export const syncComposerMediasWithCustomizationMedias = ({ selectedProfiles, postInStore, post_media_key, media }) => {
  const syncData = ({ data }) => {
    const foundCustomizationsMediaIndex = data[post_media_key]
      ? data[post_media_key].findIndex(({ media_gid }) => media_gid === media.media_gid)
      : -1

    if (foundCustomizationsMediaIndex > -1) {
      data[post_media_key][foundCustomizationsMediaIndex] = media
    }
  }

  const { customizations = {} } = postInStore

  selectedProfiles.forEach((profile) => {
    const { code, id } = profile

    const { [code]: networkCustomizations } = customizations || {}
    const { [id]: profileCustomizations } = networkCustomizations || {}

    if (
      profileCustomizations &&
      profileCustomizations[post_media_key] &&
      profileCustomizations[post_media_key].length !== 0
    ) {
      syncData({ data: profileCustomizations })
    }
  })

  syncData({ data: postInStore })
}

export const handleUpdateCustomizationBulk = ({ selectedProfiles, post, network, profileId }) => {
  const {
    customizations: {
      [network]: { [profileId]: profileCustomizations = {} },
    },
  } = post

  const mediaKeysForChecking = ['postImages', 'postVideos', 'postDocuments', 'postMixedMedias']

  mediaKeysForChecking.forEach((media_key) => {
    let isSame = true
    if (post[media_key] && profileCustomizations[media_key]) {
      if (post[media_key].length !== profileCustomizations[media_key].length) {
        isSame = false
      } else {
        for (let i = 0; i < post[media_key].length; i++) {
          if (post[media_key][i].id !== profileCustomizations[media_key][i].id) {
            isSame = false
            break
          }
        }
      }
    } else if (!post[media_key] && profileCustomizations[media_key] && profileCustomizations[media_key].length !== 0) {
      isSame = false
    }

    if (isSame) {
      delete profileCustomizations[media_key]
      delete post.customizations[network][profileId][media_key]
    }
  })

  post.customizations[network][profileId] = { ...post.customizations[network][profileId], ...profileCustomizations }

  generateSyncMediaKeys({ selectedProfiles, post })

  const { sync_media_keys } = post.customizations[network][profileId] || {}

  if (
    post.sync_media_keys === sync_media_keys &&
    post.postComponent === post.customizations[network][profileId].postComponent
  ) {
    delete post.customizations[network][profileId].postComponent
  }
}

export const deHibernateMedias = async ({ medias = [] }) => {
  try {
    await request({
      path: `${ROUTE_MEDIA}/dehibernate-media`,
      method: 'POST',
      body: { medias: medias.map(({ id, mime, type }) => ({ media_gid: id, mime, type })) },
    })
  } catch (error) {
    errorHelper({
      error,
      componentName: 'Calendar/helpers',
      functionName: 'deHibernateMedias',
      showAlert: false,
    })
  }
}

export const collectPostAttachments = ({ post }) => {
  let attachments = null

  if (post.postComponent === POST_IMAGE) {
    attachments = post.postImages
      ? post.postImages.map(({ id, dynamic }) => {
          const data = { media_gid: id, type: IMAGE.toUpperCase() }
          if (dynamic) {
            data.type = IMAGE_DYNAMIC.toUpperCase()
          }
          return data
        })
      : []
  } else if (post.postComponent === POST_VIDEO) {
    attachments = post.postVideos
      ? post.postVideos.map(({ id, dynamic }) => {
          const data = { media_gid: id, type: VIDEO.toUpperCase() }
          if (dynamic) {
            data.type = VIDEO_DYNAMIC.toUpperCase()
          }
          return data
        })
      : []
  } else if (post.postComponent === POST_DOCUMENT) {
    attachments = post.postDocuments
      ? post.postDocuments.map(({ id }) => ({ media_gid: id, type: DOCUMENT.toUpperCase() }))
      : []
  } else if (post.postComponent === POST_MIXED_MEDIA) {
    attachments = post.postMixedMedias
      ? post.postMixedMedias.map(({ id, type }) => ({ media_gid: id, type: type.toUpperCase() }))
      : []
  }

  return attachments
}

export const collectAdvocacyDataBeforePublishing = ({ data, advocacy, advocacy_gid }) => {
  if (advocacy?.enabled || advocacy_gid) {
    data.advocacy = JSON.parse(JSON.stringify(advocacy || {}))

    if (advocacy_gid) {
      data.advocacy.is_advocacy_only = true

      if (data.advocacy.draft) {
        data.draft = true
      }
    }

    delete data.advocacy.status
    delete data.advocacy.publish_at
    delete data.advocacy.whenToPublish
    delete data.advocacy.enabled
  }
}

export const blueskyLinkPreviewChanger = ({ word }) => {
  let updated_text = word ? word.replace(/^(?:https?:\/\/(?:www\.)?)?(.*)$/, '$1') : ''
  if (updated_text.length > 28) {
    updated_text = `${updated_text.substring(0, 28)}...`
  }

  return { text: updated_text }
}

export const blueskyLinkSymbolCounter = ({ word, post }) => {
  let shorten = false

  let link_length = 0

  const { link } = post || {}

  if (link && link.active) {
    const { type: shorten_type = 'DEFAULT' } = link.shorten || {}

    shorten = shorten_type === 'DEFAULT'

    if (!shorten) {
      const { tracking, utm_tracking } = link

      if (utm_tracking) {
        const { params = [] } = tracking

        const params_length = params.reduce((a, b) => {
          if (b.value) {
            return a + b.value.length + b.name.length + 1
          }

          return a
        }, 0)

        link_length = word.length + params_length + (params_length ? 1 : 0)
      } else {
        link_length = word.length
      }
    } else {
      link_length = 26 // https://vist.ly/{some_text}
    }
  }

  return link_length
}

export const blueskyGetPostLength = ({ text, post }) => {
  let text_length = 0

  if (text) {
    const lines = text.split('\n')

    lines.forEach((line) => {
      const words = line.split(' ')

      words.forEach((word) => {
        const res = word.match(URL_REGEXP)
        if (res !== null) {
          const link_length = blueskyLinkSymbolCounter({ word, post })

          text_length += link_length
        } else {
          text_length += word.length
        }
      })

      text_length += words.length - 1
    })

    text_length += lines.length - 1
  }

  return text_length
}

import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Slider from 'react-slick'
import { radius } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import ProfileItemTooltip from './ProfileItemTooltip'
import SocialProfileImage from './SocialProfileImage'
import CalendarItemTooltip from './CalendarItemTooltip'

const StyledSelectedProfileWrapper = styled(Flex)`
  &:hover {
    div {
      display: flex;
    }
  }
`

const StyledDismissWrapper = styled(Flex)`
  display: none;
  cursor: pointer;
  background: #000;
  z-index: 999;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: ${radius.m};
`

const StyledIconWrapper = styled(Flex)`
  display: flex !important;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(0px, -50%);
  z-index: 2;
  cursor: pointer;
`

const VistaSocialDashboardArrowLeft = styled(Icon.VistaSocialDashboardArrow)`
  fill: ${({ theme }) => theme.colors.primaryText};
  width: 16px;
  height: 16px;
`

const VistaSocialDashboardArrowRight = styled(Icon.VistaSocialDashboardArrow)`
  fill: ${({ theme }) => theme.colors.primaryText};
  transform: rotate(-180deg);
  width: 16px;
  height: 16px;
`

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: (
    <StyledIconWrapper right="-16px">
      <VistaSocialDashboardArrowRight />
    </StyledIconWrapper>
  ),
  prevArrow: (
    <StyledIconWrapper left="-16px">
      <VistaSocialDashboardArrowLeft />
    </StyledIconWrapper>
  ),
  className: 'social-profiles-slider',
}

const SelectedProfilesSlider = ({ selectedProfiles, handleClickRemoveProfile }) => {
  const tooltipRef = useRef(null)
  return (
    <Fragment>
      <Slider {...settings}>
        {selectedProfiles.map(
          ({ id, picture_url, code, profile_url, name, username, display, entityId, profile_id, type }, index) => {
            return (
              <StyledSelectedProfileWrapper
                alignItems="center"
                key={`${id}${index}`}
                mx="s"
                maxWidth="24px"
                width="24px"
                maxHeight="44px"
                height="100%"
                position="relative"
              >
                <Flex alignItems="center">
                  <SocialProfileImage picture_url={picture_url} code={code} />
                </Flex>
                <StyledDismissWrapper
                  position="absolute"
                  onMouseEnter={(e) => {
                    tooltipRef.current.handleShowTooltip({
                      contentComp: (
                        <ProfileItemTooltip
                          profile={{ code, picture_url, profile_url, name, username, display, profile_id, type }}
                        />
                      ),
                      wrapperComp: e.currentTarget,
                    })
                  }}
                  onMouseLeave={() => {
                    tooltipRef.current.handleHideTooltip()
                  }}
                  onClick={() => {
                    handleClickRemoveProfile({ id, entityId })
                    tooltipRef.current.handleHideTooltip()
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image width="12px" height="12px" src="/assets/clear.svg" />
                </StyledDismissWrapper>
              </StyledSelectedProfileWrapper>
            )
          }
        )}
      </Slider>
      <CalendarItemTooltip ref={tooltipRef} />
    </Fragment>
  )
}

SelectedProfilesSlider.defaultProps = {
  selectedProfiles: [],
  handleClickRemoveProfile: () => {},
}

SelectedProfilesSlider.propTypes = {
  selectedProfiles: PropTypes.array,
  handleClickRemoveProfile: PropTypes.func,
}

export default SelectedProfilesSlider

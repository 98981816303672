import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import { transparentize } from 'polished'
import { radius, COLOR_CONSTANTS, space, fontWeights, breakpoints } from 'theme'
import {
  INSTAGRAM,
  IMAGE,
  VIDEO,
  FACEBOOK,
  FEATURE_POST_TAGGING,
  FEATURE_POST_ENGAGEMENT,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  PROFILE_TYPE_PROFILE,
  PROFILE_TYPE_CREATOR,
} from 'consts'
import { linkTransformer } from 'helpers'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Counter from 'components/molecules/Counter'
import Switch from 'components/atoms/Switch'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Tooltip from 'components/molecules/Tooltip'
import MediaCarousel from 'components/molecules/MediaCarousel'
import DynamicMedia from 'components/molecules/DynamicMedia'
import DropDown from 'shared/DropDown'
import { getProfileFlavor } from 'routes/Settings/Profiles/helpers'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import InstagramGrid from './components/InstagramGrid'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
// eslint-disable-next-line import/no-cycle
import InstagramImageMentionModal from './components/InstagramImageMentionModal'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  INSTAGRAM_COLORS,
  VIEW_OPTIONS,
  VIEW_FEED,
  VIEW_GRID,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  SAVED_SEARCH_TYPE_INSTAGRAM_LOCATION,
  SAVED_SEARCH_TYPE_INSTAGRAM_COLLABORATOR_TAGS,
  PUBLISH_AS_VIDEO,
  PUBLISH_AS_IMAGE,
  PUBLISH_AS_CAROUSEL,
  PUBLISH_AS_STORY,
  PUBLISH_AS_REELS,
  PUBLISHING_OPTION_DIRECT,
  POST_MIXED_MEDIA,
} from '../../consts'
// eslint-disable-next-line import/no-cycle
import {
  mentionsTransformer,
  getInstagramProfileMedias,
  getAccounts,
  getNetworkSavedSearches,
  setSavedSearches,
  getMediaTypeDropDownValuesForInstagram,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
  instagramTextSymbolCounter,
} from '../../helpers'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent'
import ProductTaggingModal from './components/ProductTaggingModal'
// eslint-disable-next-line import/no-cycle
import { generateInstagramMediaComponent } from './helper'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import InstagramMediaCollaboratorsModal from './components/InstagramMediaCollaboratorsModal'
import BoostsDropdownComponent from './components/BoostsDropdownComponent'

const StyledFlex = styled(Flex)`
  border-radius: 0.25em;
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  font-size: ${({ isEditable }) => (isEditable ? '12px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
  margin: 0 auto;
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
`

const SourceWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const StyledLinkReelsFooter = styled(Text)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledViewSwitchWrapper = styled(Flex)`
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 2;
  cursor: pointer;
`

const StyledGrayButton = styled(Button.Gray)`
  background-color: ${({ theme }) => theme.colors.white};
  min-width: ${({ minWidth }) => minWidth};
  ${({ isFirst }) => isFirst && `border-top-right-radius: 0px; border-bottom-right-radius: 0px;`}
  ${({ isLast }) => isLast && `border-top-left-radius: 0px; border-bottom-left-radius: 0px;`}
`

const StyledTextWrapper = styled(Flex)`
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const StyledLocationText = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledLocationIconWrapper = styled(Flex)`
  cursor: pointer;
`

const StyledStoryHeaderWrapper = styled(Flex)`
  align-items: stretch;
  background: linear-gradient(180deg, rgba(38, 38, 38, 0.8) 0%, rgba(38, 38, 38, 0) 100%);
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 1.25em 1em 2em 1em;
  position: absolute;
  top: 0;
  vertical-align: baseline;
  width: 100%;
  z-index: 1;
`

const StyledStoryHeaderItem = styled(Flex)`
  align-items: stretch;
  background: rgba(255, 255, 255, ${({ isActive }) => (isActive ? 1 : 0.35)});
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: baseline;
`

const StyledStoryFooterWrapper = styled(Flex)`
  background: linear-gradient(180deg, rgba(38, 38, 38, 0) 0%, rgba(38, 38, 38, 0.6) 100%);
  align-items: stretch;
  border: 0;
  bottom: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  height: 150px;
  justify-content: flex-end;
  left: 0;
  margin: 0;
  margin-top: auto;
  padding: 0 ${space.m} ${space.m};
  pointer-events: none;
  position: absolute;
  vertical-align: baseline;
  width: 100%;
  z-index: 1;
`

const StyledGridTypeWrapper = styled(Flex)`
  cursor: pointer;
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${({ isSelected, theme }) => (isSelected ? transparentize(0.9, theme.colors.primary) : 'transparent')};
  &:hover {
    background: ${({ theme }) => transparentize(0.9, theme.colors.primary)};
  }
`

const StyledCommentWrapper = styled(Flex)`
  width: 100%;
  white-space: pre-wrap;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 4px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  z-index: 2;
`

const ReelsWrapper = styled(Flex)`
  background-color: ${COLOR_CONSTANTS.BLACK};
  position: relative;
  box-sizing: border-box;
  -webkit-background-size: cover;
  background-size: cover;
  cursor: pointer;
  border-radius: 0.25em;
  overflow: hidden;
  width: ${({ boxWidth }) => boxWidth || '100%'};
  height: ${({ boxHeight }) => boxHeight || '100%'};
  margin: 0 auto;
  &:before {
    z-index: -1;
    content: '';
    width: ${({ boxWidth }) => boxWidth || '100%'};
    height: ${({ boxHeight }) => boxHeight || '225px'};
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const StyledControlWrapper = styled(Flex)`
  width: 1.75em;
  align-items: center;
  justify-content: center;
`

const StyledReelRightColumnStatisticsWrapper = styled(Flex)`
  align-items: center;
  filter: drop-shadow(0 0 0.75px rgba(0, 0, 0, 0.42)) drop-shadow(0 1px 0.5px rgba(0, 0, 0, 0.18))
    drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
  margin-top: 0.25em;
`

const StyledReelFooterWrapper = styled(Flex)`
  align-items: stretch;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1) 5.5%, rgba(0, 0, 0, 0.4));
  border: 0;
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  position: absolute;
  vertical-align: baseline;
  width: 100%;
  z-index: 1;
  height: 12.5em;
`

const StyledRightColumnImageWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.white};
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.25em;
`

const StyledHtmlCollapseInnerWrapper = styled(Flex)`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: unset;
`

const StyledHtmlCollapseText = styled(Text)`
  font-size: 0.875em;
  white-space: pre-line;
  display: inline;
  color: ${({ theme }) => theme.colors.white};
  span,
  a {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`

const StyledHtmlCollapseBodyWrapper = styled(Box)`
  width: 100%;
  text-overflow: ellipsis;
  line-clamp: 2;
  box-orient: vertical;
  max-height: 1.35em;
  overflow-y: unset;
  &:before {
    content: '';
    height: calc(100% - 1.35em);
    float: right;
    width: 0;
    margin-top: 0px;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    `
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1) 5.5%, rgba(0, 0, 0, 0.4));
    border-radius: ${radius.l};
    max-height: 10em;
    overflow-y: scroll;
    width: calc(100% + 17px);
    margin-right: -17px;
  `}
`

const CollapseWrapper = styled(Flex)`
  float: right;
  clear: both;
  cursor: pointer;
  align-items: center;
  margin-right: 1.7em;
`

const CollapseButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: ${fontWeights.bold};
  color: ${({ theme }) => transparentize(0.3, theme.colors.white)};
  font-size: 0.875em;
  line-height: 1.8em;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  cursor: pointer;
`

export const formatOptionLabel = ({ value, label, picture_url, location }) => {
  const { country, city, street } = location || {}

  const address = [street, city, country].filter((item) => item).join(', ')

  return (
    <Flex alignItems="center" key={value}>
      <ImageWithFallback source={picture_url} width="20px" height="20px" />
      <Flex ml="s" flexDirection="column">
        <Text fontSize="xs">{label}</Text>
        {address && (
          <Text color="secondaryText" fontSize="xs">
            {address}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

const MAX_TAG_DROPDOWN_HEIGHT = 82
const MAX_TAG_DROPDOWN_WIDTH = 161

const networkLimits = NETWORK_LIMITS.find(({ code }) => code === INSTAGRAM)

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  minVideoAspectRatio,
  maxVideoAspectRatio,
  minReelAspectRatio,
  maxReelAspectRatio,
  minReelAspectRatioVideoProcessingOff,
  maxReelAspectRatioVideoProcessingOff,
  minCarouselVideoAspectRatio,
  maxCarouselVideoAspectRatio,
} = networkLimits

const DEFAULT_USER_MENTION_MODAL_OPTIONS = { isOpen: false, data: {} }
const DEFAULT_PRODUCT_TAG_MODAL_OPTIONS = { isOpen: false, data: {} }
const DEFAULT_COLLABORATOR_TAGS_MODAL_OPTIONS = { isOpen: false, data: {} }

const MAX_USER_TAGS = 20
const MAX_PRODUCT_TAGS_PER_MEDIA = 5
const MAX_PRODUCT_TAGS = 20
const MAX_COLLABORATOR_TAGS_PER_MEDIA = 3
const MAX_COLLABORATOR_TAGS_PER_MEDIA_MESSAGE = `Only ${MAX_COLLABORATOR_TAGS_PER_MEDIA} collaborator tags are allowed per post.`

const PUBLISH_AS_FEED = 'feed'

const MEDIA_TYPE_TABS = [
  { value: PUBLISH_AS_FEED, icon: '/assets/vistasocial/instagram_grid.svg' },
  { value: PUBLISH_AS_REELS, icon: '/assets/vistasocial/instagram_reel.svg' },
  { value: PUBLISH_AS_STORY, icon: '/assets/vistasocial/instagram_story.svg' },
]

const PROCESSED_VIDEO_ENDING_REEL = '_facebook_reel'
const PROCESSED_VIDEO_ENDING_STORY = '_instagram_story'

const HtmlCollapse = ({ html }) => {
  const maxLength = 40
  const [isOpen, setIsOpen] = useState(false)
  const messageEnd = html && html.length > maxLength ? html.substr(maxLength) : ''

  return (
    <StyledHtmlCollapseInnerWrapper>
      <StyledHtmlCollapseBodyWrapper
        $isOpen={isOpen}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}
      >
        {messageEnd && (
          <CollapseWrapper>
            <CollapseButton
              onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen)
              }}
            >
              {isOpen ? '' : '...'}
            </CollapseButton>
          </CollapseWrapper>
        )}
        <StyledHtmlCollapseText
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </StyledHtmlCollapseBodyWrapper>
    </StyledHtmlCollapseInnerWrapper>
  )
}

HtmlCollapse.propTypes = {
  html: PropTypes.string.isRequired,
}

const InstagramPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  boosts,
  blockCommentsMediaAttachment,
  isSmallPreview,
  isAdvocacy,
  ...props
}) => {
  const imageWrapperRef = useRef(null)
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)
  const collaboratorsTagsRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [agencyMentions, setAgencyMentions] = useState([])

  const [userMentionModalOptions, setUserMentionModalOptions] = useState({ ...DEFAULT_USER_MENTION_MODAL_OPTIONS })
  const [productTagModalOptions, setProductTagModalOptions] = useState({ ...DEFAULT_PRODUCT_TAG_MODAL_OPTIONS })
  const [collaboratorTagsModalOptions, setCollaboratorTagsModalOptions] = useState({
    ...DEFAULT_COLLABORATOR_TAGS_MODAL_OPTIONS,
  })

  const [showImageMentions, setShowImageMentions] = useState(false)
  const [showProductTags, setShowProductTags] = useState(false)

  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})
  const [userTagDragInformation, setUserTagDragInformation] = useState({ mouseX: 0, mouseY: 0 })
  const [mousePositionClick, setMousePositionClick] = useState({ mouseClickPositionX: -1, mouseClickPositionY: -1 })
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0)
  const [activeMediaTypeTab, setActiveMediaTypeTab] = useState(null)
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_HEIGHT = smallPreview ? 444.44 : 600
  const MAX_PREVIEW_WIDTH = smallPreview ? 250 : 337.503375034

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type,
  } = profile

  const profileType = getProfileFlavor({ profile: { code: network, profile_url: profileUrl, profile_id, type } })

  const {
    id: postId,
    publish_at,
    postText,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    zoneShort,
    insights = {},
    postComponent,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let comments = []
  let comments_enabled = true
  let user_tags = []
  let product_tags = []
  let collaborator_tags = []
  let location = null
  let device_gid = null
  let view = VIEW_OPTIONS[0].value
  let media_type = null
  let share_to_feed = true
  let publishing_option = null
  // let audio_name = ''
  let boost_gid = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    // TODO:: this code below was for multi Instagram posting. After discussing with Vitaly remove or finish the work (07/26/2022 still not discussed)
    // const { isMultiPost = false, activeMediaId, mediasCustomizations = {} } = profileCustomizations
    //
    // let previewCustomizations = { ...profileCustomizations }
    //
    // if (isMultiPost) {
    //   let mediaId = activeMediaId
    //   if (!mediaId) {
    //     if (postComponent === POST_IMAGE) {
    //       mediaId = postImages[0].id
    //     } else if (postComponent === POST_VIDEO) {
    //       mediaId = postVideos[0].id
    //     } else if (postComponent === POST_LINK) {
    //       mediaId = link ? link.id : null
    //     }
    //   }
    //
    //   if (mediaId && mediasCustomizations[mediaId]) {
    //     previewCustomizations = mediasCustomizations[mediaId]
    //   }
    // }

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      comments = [],
      comments_enabled = true,
      user_tags = [],
      product_tags = [],
      collaborator_tags = [],
      location = null,
      view = VIEW_OPTIONS[0].value,
      device_gid = null,
      media_type = null,
      share_to_feed = true,
      publishing_option = null,
      // audio_name = '',
      boost_gid = null,
    } = profileCustomizations)
  }

  let customizedPostComponentForPreview = customizedPostComponent

  if (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 1) {
    const { 0: { type } = {} } = customizedPostMixedMedias

    if (type === IMAGE || type === IMAGE_DYNAMIC) {
      customizedPostComponentForPreview = POST_IMAGE
      customizedPostImages = [customizedPostMixedMedias[0]]
    } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
      customizedPostComponentForPreview = POST_VIDEO
      customizedPostVideos = [customizedPostMixedMedias[0]]
    }
  }

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  const postVideosUpdated =
    customizedPostComponent === POST_VIDEO ? customizedPostVideos.filter(({ tempId }) => !tempId) : []

  const postMixedMediasUpdated =
    customizedPostComponent === POST_MIXED_MEDIA ? customizedPostMixedMedias.filter(({ tempId }) => !tempId) : []

  let hasAutoChangedPostComponent = false

  if (
    customizedPostImages.length !== 0 &&
    customizedPostComponentForPreview === POST_IMAGE &&
    customizedPostImages.length === customizedPostImages.filter(({ extension }) => extension === 'gif').length
  ) {
    customizedPostComponentForPreview = POST_VIDEO
    customizedPostVideos = [...customizedPostImages]
    hasAutoChangedPostComponent = true
  }

  let video_processing = true

  if (customizations) {
    ;({ video_processing } = customizations)
  }

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setMousePositionClick({ mouseClickPositionX: -1, mouseClickPositionY: -1 })
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
    if (isEditable) {
      getNetworkSavedSearches({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        type: SAVED_SEARCH_TYPE_INSTAGRAM_LOCATION,
      })
      getNetworkSavedSearches({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        type: SAVED_SEARCH_TYPE_INSTAGRAM_COLLABORATOR_TAGS,
      })
    } else {
      setShowImageMentions(true)
      setShowProductTags(true)
    }
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const MEDIA_TYPE_DROPDOWN_VALUES = getMediaTypeDropDownValuesForInstagram({
    postComponent: customizedPostComponent,
    postMixedMedias: customizedPostMixedMedias,
  })

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      commentTextMaxLimit,
      postImageAspectRatioLimit,
      hashtagsCommentLengthLimit,
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postImageSizeLimit,
      deviceLengthLimit,
      notificationDeviceLengthLimit,
      postVideoAspectRatioLimit,
    } = {},
    flags,
    warning_flags,
  } = profilesWithErrors[profileId] || {}

  const { published_link } = result || {}
  const { picture_url } = link || {}

  const { transformed_text_to_html_for_textarea, instagram_text_symbol_counter } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: INSTAGRAM_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: updatedText,
      color: INSTAGRAM_COLORS.LAST_LIGHT_BLUE,
      transformHashstag: true,
      hashtagColor: INSTAGRAM_COLORS.LAST_LIGHT_BLUE,
    })

    const instagram_text_symbol_counter = instagramTextSymbolCounter({ message: updatedPostTextWithMentions })

    return {
      transformed_text_to_html_for_textarea,
      instagram_text_symbol_counter,
    }
  }, [updatedPostText, savedVariables, agencyMentions])

  let MIN_VIDEO_ASPECT_RATIO = minVideoAspectRatio
  let MAX_VIDEO_ASPECT_RATIO = maxVideoAspectRatio

  if (media_type === PUBLISH_AS_REELS) {
    if (minReelAspectRatioVideoProcessingOff) {
      MIN_VIDEO_ASPECT_RATIO = minReelAspectRatioVideoProcessingOff
    }
    if (maxReelAspectRatioVideoProcessingOff) {
      MAX_VIDEO_ASPECT_RATIO = maxReelAspectRatioVideoProcessingOff
    }
  } else if (media_type === PUBLISH_AS_CAROUSEL) {
    if (minCarouselVideoAspectRatio) {
      MIN_VIDEO_ASPECT_RATIO = minCarouselVideoAspectRatio
    }
    if (maxCarouselVideoAspectRatio) {
      MAX_VIDEO_ASPECT_RATIO = maxCarouselVideoAspectRatio
    }
  }

  const MIN_VIDEO_ASPECT_RATIO_NUMBER = MIN_VIDEO_ASPECT_RATIO
    ? Number(
        MIN_VIDEO_ASPECT_RATIO.split(':')
          .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
          .toFixed(2)
      )
    : null

  const MAX_VIDEO_ASPECT_RATIO_NUMBER = MAX_VIDEO_ASPECT_RATIO
    ? Number(
        MAX_VIDEO_ASPECT_RATIO.split(':')
          .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
          .toFixed(2)
      )
    : null

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  const [customizationsSchema, setCustomizationsSchema] = useState({ location })

  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { [activeMediaTypeTab]: showGridTypeMedias = {}, typeData } = networkDataForCustomizations || {}
  const { medias = [], isGettingGridMedia = true } = showGridTypeMedias || {}
  const {
    [SAVED_SEARCH_TYPE_INSTAGRAM_LOCATION]: SAVED_LOCATION_OPTIONS = [],
    [SAVED_SEARCH_TYPE_INSTAGRAM_COLLABORATOR_TAGS]: SAVED_COLLABORATOR_TAGS = [],
  } = typeData || {}

  useEffect(() => {
    if (view === VIEW_GRID && isEditable && activeMediaTypeTab) {
      getInstagramProfileMedias({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        postIds: [postId],
        type: activeMediaTypeTab,
      })
    }
  }, [view, activeMediaTypeTab])

  let c_likes = 0
  let c_shares = 0
  let c_comments = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, shares: c_shares = 0, comments: c_comments = 0 } = profileInsights)
  }

  c_comments =
    c_comments ||
    comments.filter(({ message, postImages }) => message || (postImages && postImages.length !== 0)).length

  const handleClickCloseImageMentionModal = () => {
    setUserMentionModalOptions({ ...DEFAULT_USER_MENTION_MODAL_OPTIONS })
  }

  const getMouseClickPosition = ({ event }) => {
    const rect = event.target.getBoundingClientRect()
    const { left, top, width, height } = rect

    let mouseClickPositionX = event.clientX - left
    let mouseClickPositionY = event.clientY - top

    let x = (mouseClickPositionX / width).toFixed(2)
    if (x > 1) {
      x = 1
    } else if (x < 0) {
      x = 0
    }
    let y = (mouseClickPositionY / height).toFixed(2)
    if (y > 1) {
      y = 1
    } else if (y < 0) {
      y = 0
    }

    if (mouseClickPositionY < MAX_TAG_DROPDOWN_HEIGHT) {
      if (mouseClickPositionY < 8) {
        mouseClickPositionY = 8
      }
    } else {
      mouseClickPositionY -= MAX_TAG_DROPDOWN_HEIGHT
    }

    if (MAX_PREVIEW_WIDTH - mouseClickPositionX < MAX_TAG_DROPDOWN_WIDTH) {
      mouseClickPositionX -= MAX_TAG_DROPDOWN_WIDTH
      if (mouseClickPositionX < 0) {
        mouseClickPositionX = 0
      }
    }

    return { x, y, mouseClickPositionX, mouseClickPositionY }
  }

  const handleClickAddUserTag = (mousePositionClickTemp) => {
    if (featuresEnabled[FEATURE_POST_TAGGING] && featuresEnabled[FEATURE_POST_TAGGING].enabled) {
      const { x, y, media_gid } = mousePositionClickTemp || mousePositionClick

      setUserMentionModalOptions({ isOpen: true, data: { x, y, username: '', media_gid } })
      setMousePositionClick({ mouseClickPositionX: -1, mouseClickPositionY: -1 })
    } else {
      Alert.error(featuresEnabled[FEATURE_POST_TAGGING].description, { timeout: 5000 })
    }
  }

  const handleClickDeleteUserTag = ({ x, y, media_gid }) => {
    handleUpdateCustomization({
      type: 'user_tags',
      value: user_tags.filter(
        (tag) => tag.media_gid !== media_gid || (tag.media_gid === media_gid && tag.x !== x && tag.y !== y)
      ),
    })
  }

  const handleClickSaveUserTag = (userTag) => {
    const { username, isForEdit } = userTag

    if (username) {
      let foundUserTagIndex = -1

      if (isForEdit) {
        foundUserTagIndex = user_tags.findIndex(
          ({ username, media_gid }) => username === isForEdit.username && media_gid === userTag.media_gid
        )
      } else {
        foundUserTagIndex = user_tags.findIndex(
          ({ username, media_gid }) => username === userTag.username && media_gid === userTag.media_gid
        )
      }

      delete userTag.isForEdit

      if (foundUserTagIndex > -1) {
        user_tags[foundUserTagIndex] = userTag
      } else if (user_tags.length < MAX_USER_TAGS) {
        user_tags.push(userTag)
        handleUpdateCustomization({ type: 'user_tags', value: user_tags })
        setShowImageMentions(true)
      } else {
        Alert.error(`Only ${MAX_USER_TAGS} user tags are allowed.`, { timeout: 5000 })
      }
    }

    handleClickCloseImageMentionModal()
  }

  const handleClickCloseProductTagModal = () => {
    setProductTagModalOptions({ ...DEFAULT_PRODUCT_TAG_MODAL_OPTIONS })
  }

  const handleClickAddProductTag = (mousePositionClickTemp) => {
    if (featuresEnabled[FEATURE_POST_TAGGING] && featuresEnabled[FEATURE_POST_TAGGING].enabled) {
      const { x, y, media_gid } = mousePositionClickTemp || mousePositionClick

      if (product_tags.length < MAX_PRODUCT_TAGS) {
        const product_tags_per_media = product_tags.filter((tag) => tag.media_gid === media_gid)

        if (product_tags_per_media.length < MAX_PRODUCT_TAGS_PER_MEDIA) {
          setProductTagModalOptions({ isOpen: true, data: { x, y, product_id: '', product_name: '', media_gid } })
          setMousePositionClick({ mouseClickPositionX: -1, mouseClickPositionY: -1 })
        } else {
          Alert.error(`Only ${MAX_PRODUCT_TAGS_PER_MEDIA} product tags per media are allowed.`, { timeout: 5000 })
        }
      } else {
        Alert.error(`Only ${MAX_PRODUCT_TAGS} product tags are allowed.`, { timeout: 5000 })
      }
    } else {
      Alert.error(featuresEnabled[FEATURE_POST_TAGGING].description, { timeout: 5000 })
    }
  }

  const handleClickDeleteProductTag = ({ x, y, media_gid }) => {
    handleUpdateCustomization({
      type: 'product_tags',
      value: product_tags.filter(
        (tag) => tag.media_gid !== media_gid || (tag.media_gid === media_gid && tag.x !== x && tag.y !== y)
      ),
    })
  }

  const handleClickSaveProductTag = (productTag) => {
    const { product_id, isForEdit } = productTag

    if (product_id) {
      let foundProductTagIndex = -1

      if (isForEdit) {
        foundProductTagIndex = product_tags.findIndex(
          ({ product_id, media_gid }) => product_id === isForEdit.product_id && media_gid === productTag.media_gid
        )
      } else {
        foundProductTagIndex = product_tags.findIndex(
          ({ product_id, media_gid }) => product_id === productTag.product_id && media_gid === productTag.media_gid
        )
      }

      delete productTag.isForEdit

      if (foundProductTagIndex > -1) {
        product_tags[foundProductTagIndex] = productTag
      } else {
        product_tags.push(productTag)
      }

      handleUpdateCustomization({ type: 'product_tags', value: product_tags })
      setShowProductTags(true)
    }

    handleClickCloseProductTagModal()
  }

  const handleClickCloseCollaboratorTagsModal = () => {
    setCollaboratorTagsModalOptions({ ...DEFAULT_COLLABORATOR_TAGS_MODAL_OPTIONS })
  }

  const handleClickAddCollaboratorTags = () => {
    if (featuresEnabled[FEATURE_POST_TAGGING] && featuresEnabled[FEATURE_POST_TAGGING].enabled) {
      setCollaboratorTagsModalOptions({
        isOpen: true,
        data: { collaborator_tags },
      })
      setMousePositionClick({ mouseClickPositionX: -1, mouseClickPositionY: -1 })
    } else {
      Alert.error(featuresEnabled[FEATURE_POST_TAGGING].description, { timeout: 5000 })
    }
  }

  const handleClickSaveCollaboratorTags = ({ collaborator_tags }) => {
    handleUpdateCustomization({ type: 'collaborator_tags', value: collaborator_tags })

    handleClickCloseCollaboratorTagsModal()

    setSavedSearches({
      type: SAVED_SEARCH_TYPE_INSTAGRAM_COLLABORATOR_TAGS,
      id: profileId,
      result: { value: collaborator_tags },
      dataForCustomizations,
      setDataForCustomizations,
    })
  }

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => callback([...options]))
    }, 500),
    []
  )

  const handleDragStart = ({ target }) => {
    const rectChild = target.currentTarget.getBoundingClientRect()

    setUserTagDragInformation({
      mouseX: target.clientX - rectChild.left - rectChild.width / 2,
      mouseY: target.clientY - rectChild.top,
    })
  }

  const handleDragOverCount = ({ target }) => {
    const rectParent = target.currentTarget.parentElement.getBoundingClientRect()

    const { left, top, width, height } = rectParent
    let x = ((target.clientX - left - userTagDragInformation.mouseX) / width).toFixed(2)
    if (x > 1) {
      x = 1
    } else if (x < 0) {
      x = 0
    }
    let y = ((target.clientY - top - userTagDragInformation.mouseY) / height).toFixed(2)
    if (y > 1) {
      y = 1
    } else if (y < 0) {
      y = 0
    }
    return { x, y }
  }

  const handleDragOverUserMention = ({ media_gid, index, target }) => {
    target.persist()

    const { x, y } = handleDragOverCount({ target })

    const user_tags_filtered = user_tags.filter((tag) => tag.media_gid === media_gid)[index]
    user_tags_filtered.x = x
    user_tags_filtered.y = y

    target.preventDefault()
    handleUpdateCustomization({
      type: 'user_tags',
      value: user_tags,
    })
    return false
  }

  const handleDragOverProductTag = ({ media_gid, index, target }) => {
    target.persist()

    const { x, y } = handleDragOverCount({ target })

    const product_tags_filtered = product_tags.filter((tag) => tag.media_gid === media_gid)[index]
    product_tags_filtered.x = x
    product_tags_filtered.y = y

    target.preventDefault()
    handleUpdateCustomization({
      type: 'product_tags',
      value: product_tags,
    })
    return false
  }

  let slideToIndex

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const postImageError = postImageSizeLimit || postImageAspectRatioLimit

  if (postImageError || postVideoError) {
    if (postComponent === POST_MIXED_MEDIA) {
      slideToIndex = postMixedMediasUpdated.findIndex(({ id }) => id === (postImageError || postVideoError))
    } else if (postImageError) {
      slideToIndex = postImagesUpdated.findIndex(({ id }) => id === postImageError)
    } else if (postVideoError) {
      slideToIndex = postVideosUpdated.findIndex(({ id }) => id === postVideoError)
    }
  }

  if (slideToIndex === -1) {
    slideToIndex = 0
  }

  // let sourceTypeTemplate = ''

  let stories = []
  let storyHeaderWrapper = null
  let storyFooterWrapper = null
  if (
    (view === VIEW_FEED && media_type === PUBLISH_AS_STORY) ||
    (view === VIEW_GRID && activeMediaTypeTab === PUBLISH_AS_STORY.toLowerCase())
  ) {
    if (view === VIEW_GRID) {
      if (media_type === PUBLISH_AS_STORY) {
        let storyMedia = {}
        switch (customizedPostComponentForPreview) {
          case POST_IMAGE:
            if (postImagesUpdated.length !== 0) {
              storyMedia = {
                id: postImagesUpdated[0].id,
                url: postImagesUpdated[0].url,
                thumbnail_url: postImagesUpdated[0].thumbnail_url,
                media_type: postImagesUpdated[0].type,
                post,
                profiles: [profile],
                ...postImagesUpdated[0],
              }
            }
            break
          case POST_VIDEO:
            if (postVideosUpdated.length !== 0) {
              storyMedia = {
                id: postVideosUpdated[0].id,
                url: postVideosUpdated[0].url,
                thumbnail_url: postVideosUpdated[0].thumbnail_url,
                media_type: postVideosUpdated[0].type,
                ...postVideosUpdated[0],
              }
            }
            break
          case POST_LINK:
            if (picture_url) {
              storyMedia = { id: link.id, url: link.picture_url, thumbnail_url: link.picture_url, media_type: IMAGE }
            }
            break
          default:
            break
        }

        stories = [...medias, storyMedia]
      } else {
        stories = medias
      }
    }

    storyHeaderWrapper = (
      <StyledStoryHeaderWrapper>
        <Flex justifyContent="space-between" mb="0.5em">
          {stories.map(({ id }, index) => {
            return (
              <Flex key={id} flexGrow="1" height="2px" mr="xxs" position="relative">
                <StyledStoryHeaderItem isActive={index === currentMediaIndex} />
              </Flex>
            )
          })}
        </Flex>
        <Flex flex="0 0 auto" justifyContent="space-between" alignItems="center" alignContent="stretch">
          <Flex alignItems="center">
            {isEditable ? (
              <StyledImg
                source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                fallbackSource={fallbackLogoImage}
                width="2em"
                height="2em"
              />
            ) : (
              <SocialProfileImage
                code={network}
                picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                width="2em"
                height="2em"
                imageBorderRadius={radius.pill}
              />
            )}
            <Flex ml="0.5em" flex="0 0 auto" justifyContent="flex-start" alignItems="flex-start" alignContent="stretch">
              <Text color="white" fontSize="0.875em">
                {profileUsername || profileName}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </StyledStoryHeaderWrapper>
    )

    storyFooterWrapper = (
      <StyledStoryFooterWrapper>
        <Flex alignItems="center" justifyContent="flex-end">
          <Flex alignItems="center">
            <Flex p="0.5em" alignItems="center" justifyContent="center">
              <Image src="/assets/instagram_heart_white.svg" width="1.5em" height="1.5em" />
            </Flex>
            <Flex p="0.5em" alignItems="center" justifyContent="center">
              <Image src="/assets/instagram_send_white.svg" width="1.5em" height="1.5em" />
            </Flex>
          </Flex>
        </Flex>
      </StyledStoryFooterWrapper>
    )
  }

  let previewComponent = null
  let instagramGridParams = null

  const getCollaboratorsString = () => {
    let collaborators_string = ``

    if (collaborator_tags && collaborator_tags.length !== 0) {
      collaborators_string = `${
        collaborator_tags.length === 1 ? `and ${collaborator_tags[0]}` : `with ${collaborator_tags.length} others`
      }`
    }

    return { collaborators_string }
  }

  const feedPostHeaderComponent = ({ media }) => {
    if (media_type === PUBLISH_AS_STORY) {
      return null
    }

    const { collaborators_string } = getCollaboratorsString({ media })

    return (
      <Flex pt="0.5em" minHeight="3.25em" alignItems="center" position="relative">
        <Flex alignItems={location ? 'center' : 'initial'} ml="0.5em">
          {isEditable ? (
            <StyledImg
              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
              fallbackSource={fallbackLogoImage}
              width="2em"
              height="2em"
            />
          ) : (
            <SocialProfileImage
              code={network}
              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
              width="2em"
              height="2em"
              imageBorderRadius={radius.pill}
            />
          )}
          <Flex ml="0.5em" flexDirection="column" justifyContent="center">
            <Flex
              alignItems="center"
              onMouseEnter={(e) => {
                if (!isMultipleProfilesPreview) {
                  tooltipRef.current.handleShowTooltip({
                    contentComp: (
                      <ProfileItemTooltip
                        profile={{
                          code: network,
                          picture_url: profilePictureUrl,
                          profile_url: profileUrl,
                          name: profileName,
                          username: profileUsername,
                          display,
                          profile_id,
                          type,
                        }}
                        message=""
                      />
                    ),
                    wrapperComp: e.currentTarget,
                  })
                }
              }}
              onMouseLeave={() => {
                tooltipRef.current.handleHideTooltip()
              }}
              flexWrap="wrap"
            >
              <StyledLink
                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                target="_blank"
                color={INSTAGRAM_COLORS.GLUON_GREY}
                fontWeight="bold"
                fontSize="0.875em"
              >
                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
              </StyledLink>
              {collaborators_string && (
                <Fragment>
                  &nbsp;
                  <Text as="span" color={INSTAGRAM_COLORS.GLUON_GREY} fontWeight="bold" fontSize="0.875em">
                    {collaborators_string}
                  </Text>
                </Fragment>
              )}
            </Flex>
            <Flex>
              {location && (
                <Flex alignItems="center">
                  <StyledLink
                    as={profileUrl ? 'a' : 'p'}
                    href={`http://www.google.com/maps/place/${location.latitude},${location.longitude}`}
                    target="_blank"
                    color={INSTAGRAM_COLORS.GLUON_GREY}
                    fontWeight="normal"
                    fontSize="0.625em"
                  >
                    {location.name}
                    &nbsp;{'·'}&nbsp;
                  </StyledLink>
                </Flex>
              )}
              <StyledLink
                fontWeight="normal"
                fontSize="0.625em"
                color={INSTAGRAM_COLORS.GLUON_GREY}
                as={published_link ? 'a' : 'p'}
                href={published_link || ''}
                target="_blank"
              >
                {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''}` : 'Now'}
              </StyledLink>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  const feedPostFooterComponent =
    media_type !== PUBLISH_AS_REELS && media_type !== PUBLISH_AS_STORY ? (
      <Flex flexDirection="column">
        <Flex my="0.5em" px="0.5em" justifyContent="space-between">
          <Flex>
            <Flex alignItems="center">
              <Image src="/assets/instagram_heart.svg" alt="Likes" mr="0.25em" width="1.5em" height="1.5em" />
            </Flex>
            <Flex alignItems="center" ml="0.5em">
              <Image src="/assets/instagram_comment.svg" alt="Comments" mr="0.25em" width="1.5em" height="1.5em" />
            </Flex>
            <Flex alignItems="center" ml="0.5em">
              <Image src="/assets/instagram_send.svg" alt="Share" mr="0.25em" width="1.5em" height="1.5em" />
            </Flex>
          </Flex>
          <Flex alignItems="center" ml="0.5em">
            <Image src="/assets/instagram_bookmark.svg" alt="Bookmark" mr="0.25em" width="1.5em" height="1.5em" />
          </Flex>
        </Flex>
        {c_likes > 0 && (
          <Text ml="0.5em" mb="0.5em" fontWeight="bold" color={INSTAGRAM_COLORS.GLUON_GREY} fontSize="0.875em">
            Likes: {c_likes}
          </Text>
        )}
        {comments.map((comment) => {
          const { id, message } = comment

          if (!message) {
            return null
          }

          const { variableTransformedText } = variableTransformer({
            str: message,
            savedVariables,
            post,
            profileId,
          })

          return (
            <Flex ml="0.5em" pb="0.5em" key={id}>
              {isEditable ? (
                <StyledImg
                  source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                  fallbackSource={fallbackLogoImage}
                  width="2em"
                  height="2em"
                />
              ) : (
                <Fragment>
                  {!profilePictureUrl || isMultipleProfilesPreview ? (
                    <SocialProfileImage
                      code={network}
                      picture_url={fallbackLogoImage}
                      width="2em"
                      height="2em"
                      imageBorderRadius={radius.pill}
                    />
                  ) : (
                    <SocialProfileImage
                      code={network}
                      picture_url={profilePictureUrl}
                      width="2em"
                      height="2em"
                      imageBorderRadius={radius.pill}
                    />
                  )}
                </Fragment>
              )}
              <Flex flexDirection="column" ml="0.25em">
                <StyledCommentWrapper flexDirection="column">
                  <StyledLink
                    as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                    href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                    target="_blank"
                    color={INSTAGRAM_COLORS.GLUON_GREY}
                    fontWeight="bold"
                    fontSize="0.875em"
                    mb="0.5em"
                  >
                    {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                  </StyledLink>
                  <Text
                    fontSize="0.875em"
                    whiteSpace="pre-line"
                    color={INSTAGRAM_COLORS.GLUON_GREY}
                    dangerouslySetInnerHTML={{
                      __html: variableTransformedText,
                    }}
                  />
                </StyledCommentWrapper>
              </Flex>
            </Flex>
          )
        })}
        <StyledLink
          fontSize="0.75em"
          my="0.5em"
          mx="0.5em"
          color={INSTAGRAM_COLORS.TARNISHED_SILVER}
          as={published_link ? 'a' : 'p'}
          href={published_link || ''}
          target="_blank"
        >
          {publish_at ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''}` : 'Now'}
        </StyledLink>
      </Flex>
    ) : null

  const feedPostTextComponent =
    media_type !== PUBLISH_AS_STORY ? (
      <Flex flexDirection="column" mx="0.5em">
        <Text
          dangerouslySetInnerHTML={{
            __html: transformed_text_to_html_for_textarea,
          }}
          mt="0.5em"
          color={INSTAGRAM_COLORS.GLUON_GREY}
          whiteSpace="pre-line"
        />
      </Flex>
    ) : null

  if (view === VIEW_FEED) {
    switch (media_type) {
      case PUBLISH_AS_IMAGE:
      case PUBLISH_AS_VIDEO:
      case PUBLISH_AS_STORY:
      case noVideoAttachment:
        // eslint-disable-next-line no-case-declarations
        let media = null
        // eslint-disable-next-line no-case-declarations
        let mediaProportionsType = IMAGE

        if (customizedPostComponentForPreview === POST_IMAGE) {
          // eslint-disable-next-line prefer-destructuring
          media = customizedPostImages[0]
        } else if (customizedPostComponentForPreview === POST_VIDEO) {
          // eslint-disable-next-line prefer-destructuring
          media = customizedPostVideos[0]

          if (noVideoAttachment) {
            mediaProportionsType = IMAGE
          } else if (hasAutoChangedPostComponent) {
            mediaProportionsType = IMAGE
          } else {
            mediaProportionsType = VIDEO
          }
        } else if (customizedPostComponentForPreview === POST_LINK) {
          if (link && link.picture_url) {
            media = { url: link.picture_url, id: link.id, width: link.width, height: link.height, type: IMAGE }
          }
        }

        // eslint-disable-next-line no-case-declarations
        let mediaComponent
        // eslint-disable-next-line no-case-declarations
        let box_width
        // eslint-disable-next-line no-case-declarations
        let box_height

        if (media) {
          ;({ mediaComponent, box_width, box_height } = generateInstagramMediaComponent({
            media,
            network: INSTAGRAM,
            tooltipRef,
            post,
            profile,
            media_type,
            maxPreviewWidth: MAX_PREVIEW_WIDTH,
            maxPreviewHeight: MAX_PREVIEW_HEIGHT,
            maxAspectRatioNumber: MAX_VIDEO_ASPECT_RATIO_NUMBER,
            minAspectRatioNumber: MIN_VIDEO_ASPECT_RATIO_NUMBER,
            mediaProportionsType,
            isEditable,
            getMouseClickPosition,
            setMousePositionClick,
            noVideoAttachment,
            hasAutoChangedPostComponent,
            imageWrapperRef,
            showImageMentions,
            setShowImageMentions,
            showProductTags,
            setShowProductTags,
            user_tags,
            product_tags,
            collaborator_tags,
            handleDragStart,
            handleDragOverUserMention,
            setUserMentionModalOptions,
            handleClickDeleteUserTag,
            handleDragOverProductTag,
            setProductTagModalOptions,
            handleClickDeleteProductTag,
            mousePositionClick,
            handleClickAddProductTag,
            handleClickAddUserTag,
            handleClickAddCollaboratorTags,
            featuresEnabled,
            isProductTaggingAllowed: media_type !== PUBLISH_AS_STORY,
            isUserTaggingAllowed: media_type !== PUBLISH_AS_STORY,
            dynamicMediaIsSmall: smallPreview,
            processedVideoEnding: PUBLISH_AS_STORY ? PROCESSED_VIDEO_ENDING_STORY : PROCESSED_VIDEO_ENDING_REEL,
          }))
        }

        previewComponent = (
          <Flex
            flexDirection="column"
            width={media_type === PUBLISH_AS_STORY ? `${MAX_PREVIEW_WIDTH}px` : `${box_width || MAX_PREVIEW_WIDTH}px`}
            height={media_type === PUBLISH_AS_STORY ? `${MAX_PREVIEW_HEIGHT}px` : 'auto'}
            mx="auto"
            bg={media_type === PUBLISH_AS_STORY ? COLOR_CONSTANTS.BLACK : 'initial'}
            justifyContent={media_type === PUBLISH_AS_STORY ? 'center' : 'initial'}
          >
            {storyHeaderWrapper}
            {feedPostHeaderComponent({ media })}

            <SourceWrapper
              width={`${box_width || MAX_PREVIEW_WIDTH}px`}
              height={`${box_height || MAX_PREVIEW_HEIGHT}px`}
              bg={media_type === PUBLISH_AS_STORY ? COLOR_CONSTANTS.BLACK : INSTAGRAM_COLORS.BACKGROUND}
              mx="auto"
            >
              {mediaComponent}
            </SourceWrapper>

            {feedPostTextComponent}
            {storyFooterWrapper}
            {feedPostFooterComponent}
          </Flex>
        )

        break
      case PUBLISH_AS_REELS:
        // eslint-disable-next-line prefer-destructuring
        media = customizedPostVideos[0]

        if (media) {
          const { audio_name } = media

          const { collaborators_string } = getCollaboratorsString({ media })

          const { mediaComponent, box_width, box_height } = generateInstagramMediaComponent({
            media,
            network: INSTAGRAM,
            tooltipRef,
            post,
            profile,
            media_type,
            maxPreviewWidth: MAX_PREVIEW_WIDTH,
            maxPreviewHeight: MAX_PREVIEW_HEIGHT,
            maxAspectRatioNumber: MAX_VIDEO_ASPECT_RATIO_NUMBER,
            minAspectRatioNumber: MIN_VIDEO_ASPECT_RATIO_NUMBER,
            mediaProportionsType,
            isEditable,
            getMouseClickPosition,
            setMousePositionClick,
            noVideoAttachment,
            hasAutoChangedPostComponent,
            imageWrapperRef,
            showImageMentions,
            setShowImageMentions,
            showProductTags,
            setShowProductTags,
            user_tags,
            product_tags,
            collaborator_tags,
            handleDragStart,
            handleDragOverUserMention,
            setUserMentionModalOptions,
            handleClickDeleteUserTag,
            handleDragOverProductTag,
            setProductTagModalOptions,
            handleClickDeleteProductTag,
            mousePositionClick,
            handleClickAddProductTag,
            handleClickAddUserTag,
            handleClickAddCollaboratorTags,
            featuresEnabled,
            isProductTaggingAllowed: true,
            isUserTaggingAllowed: true,
            dynamicMediaIsSmall: smallPreview,
            videoProcessing: false, // literally it is only for changing the view. 12-06-2024 we have updated our reel processing
            processedVideoEnding: PROCESSED_VIDEO_ENDING_REEL,
          })
          previewComponent = (
            <ReelsWrapper
              flexDirection="column"
              boxWidth={`${box_width || MAX_PREVIEW_WIDTH}px`}
              boxHeight={`${box_height || MAX_PREVIEW_HEIGHT}px`}
            >
              <SourceWrapper width="100%" height="100%" bg={INSTAGRAM_COLORS.BACKGROUND}>
                {mediaComponent}
              </SourceWrapper>

              <Flex
                position="absolute"
                bottom="1.25em"
                right="0.75em"
                width="1.75em"
                zIndex="6"
                maxHeight="16em"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Flex flexDirection="column" alignItems="center">
                  <Flex mb="1.25em" flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/instagram_reel_heart.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                    <StyledReelRightColumnStatisticsWrapper>
                      <Text color="white" fontSize="0.75em">
                        {c_likes}
                      </Text>
                    </StyledReelRightColumnStatisticsWrapper>
                  </Flex>
                  <Flex mb="1.25em" flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/instagram_reel_comment.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                    <StyledReelRightColumnStatisticsWrapper>
                      <Text color="white" fontSize="0.75em">
                        {c_comments}
                      </Text>
                    </StyledReelRightColumnStatisticsWrapper>
                  </Flex>
                  <Flex mb="1.75em" flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/instagram_reel_direct.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                  </Flex>
                  <Flex mb="1.75em" flexDirection="column" alignItems="center">
                    <StyledControlWrapper>
                      <Image src="/assets/instagram_reel_more.svg" width="1.5em" height="1.5em" />
                    </StyledControlWrapper>
                  </Flex>
                  <StyledRightColumnImageWrapper>
                    <ImageWithFallback
                      source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage}
                      fallbackSource={fallbackLogoImage}
                      borderRadius="0.25em"
                      width="1.5em"
                      height="1.5em"
                    />
                  </StyledRightColumnImageWrapper>
                </Flex>
              </Flex>
              <StyledReelFooterWrapper justifyContent="flex-end" bottom={0}>
                <Flex
                  width="calc(100% - 3.5em)"
                  mr="0.5em"
                  position="absolute"
                  bottom={
                    isEditable || product_tags.length !== 0 || user_tags.length !== 0
                      ? `${isCustomizationsOpen ? '2.55em' : '2.75em'}`
                      : 0
                  }
                  left="0.75em"
                  zIndex="5"
                  flexDirection="column"
                >
                  <Flex alignItems="center">
                    <Flex alignItems="center">
                      <Flex
                        alignItems="center"
                        onMouseEnter={(e) => {
                          if (!isMultipleProfilesPreview) {
                            tooltipRef.current.handleShowTooltip({
                              contentComp: (
                                <ProfileItemTooltip
                                  profile={{
                                    code: network,
                                    picture_url: profilePictureUrl,
                                    profile_url: profileUrl,
                                    name: profileName,
                                    username: profileUsername,
                                    display,
                                    profile_id,
                                    type,
                                  }}
                                  message=""
                                />
                              ),
                              wrapperComp: e.currentTarget,
                            })
                          }
                        }}
                        onMouseLeave={() => {
                          tooltipRef.current.handleHideTooltip()
                        }}
                      >
                        {!isMultipleProfilesPreview && (
                          <StyledLinkReelsFooter
                            as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                            href={(!isMultipleProfilesPreview && profileUrl) || ''}
                            target="_blank"
                            color="white"
                            fontSize="1em"
                            fontWeight="bold"
                          >
                            <ImageWithFallback
                              source={
                                isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl || fallbackLogoImage
                              }
                              fallbackSource={fallbackLogoImage}
                              borderRadius={radius.pill}
                              width="2em"
                              height="2em"
                            />
                            <Text as="span" ml="0.75em" fontSize="0.875em" color="white">
                              {isMultipleProfilesPreview
                                ? 'Multiple Instagram Profiles'
                                : profileUsername || profileName}
                            </Text>
                            {collaborators_string ? (
                              <Fragment>
                                &nbsp;
                                <Text as="span" fontSize="0.875em" color="white">
                                  {collaborators_string}
                                </Text>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Text as="span" mx="0.375em" fontSize="0.875em" color="white">
                                  •
                                </Text>
                                <Text as="span" fontSize="0.875em" color="white">
                                  Follow
                                </Text>
                              </Fragment>
                            )}
                          </StyledLinkReelsFooter>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  {transformed_text_to_html_for_textarea && (
                    <Flex mt="1em">
                      <HtmlCollapse html={transformed_text_to_html_for_textarea} title="Post" />
                    </Flex>
                  )}
                  <Flex mt="1em" alignItems="center">
                    <Flex alignItems="center" maxWidth="10em" width="100%">
                      <Image src="/assets/instagram_reel_audio.svg" width="0.75em" height="0.75em" />
                      <Text ml="0.25em" fontSize="0.875em" color="white">
                        {audio_name || 'Original audio'}
                      </Text>
                    </Flex>
                    {(user_tags.length > 0 || collaborator_tags.length > 0) && (
                      <Flex alignItems="center">
                        <Image src="/assets/instagram_reel_person.svg" width="0.75em" height="0.75em" />
                        <Text ml="0.25em" fontSize="0.875em" color="white">
                          {user_tags.length + collaborator_tags.length}{' '}
                          {user_tags.length + collaborator_tags.length === 1 ? 'person' : 'people'}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </StyledReelFooterWrapper>
            </ReelsWrapper>
          )
        }
        break
      case PUBLISH_AS_CAROUSEL:
      default:
        // eslint-disable-next-line no-case-declarations
        let postMediasUpdated = []
        // eslint-disable-next-line no-case-declarations
        let mediaProportionsTypeCarousel = IMAGE

        if (customizedPostComponentForPreview === POST_IMAGE) {
          postMediasUpdated = postImagesUpdated
        } else if (customizedPostComponentForPreview === POST_VIDEO) {
          postMediasUpdated = postVideosUpdated
          if (hasAutoChangedPostComponent) {
            mediaProportionsTypeCarousel = IMAGE
          } else {
            mediaProportionsTypeCarousel = VIDEO
          }
        } else if (customizedPostComponentForPreview === POST_LINK) {
          if (link && link.picture_url) {
            postMediasUpdated = [
              { url: link.picture_url, id: link.id, width: link.width, height: link.height, type: IMAGE },
            ]
          }
        } else if (customizedPostComponentForPreview === POST_MIXED_MEDIA) {
          postMediasUpdated = postMixedMediasUpdated

          mediaProportionsTypeCarousel = postMediasUpdated?.length ? postMediasUpdated[0].type : null
        }

        // eslint-disable-next-line no-case-declarations
        let box_width_carousel = MAX_PREVIEW_WIDTH
        // eslint-disable-next-line no-case-declarations
        let box_height_carousel = MAX_PREVIEW_HEIGHT

        if (postMediasUpdated.length > 0) {
          const { mediaComponent, ...dimensions } = generateInstagramMediaComponent({
            media: postMediasUpdated[0],
            network: INSTAGRAM,
            tooltipRef,
            post,
            profile,
            media_type,
            maxPreviewWidth: MAX_PREVIEW_WIDTH,
            maxPreviewHeight: MAX_PREVIEW_HEIGHT,
            maxAspectRatioNumber: null,
            minAspectRatioNumber: null,
            mediaProportionsType: mediaProportionsTypeCarousel,
            isEditable,
            getMouseClickPosition,
            setMousePositionClick,
            noVideoAttachment,
            hasAutoChangedPostComponent,
            imageWrapperRef,
            showImageMentions,
            setShowImageMentions,
            showProductTags,
            setShowProductTags,
            user_tags,
            product_tags,
            collaborator_tags,
            handleDragStart,
            handleDragOverUserMention,
            setUserMentionModalOptions,
            handleClickDeleteUserTag,
            handleDragOverProductTag,
            setProductTagModalOptions,
            handleClickDeleteProductTag,
            mousePositionClick,
            handleClickAddProductTag,
            handleClickAddUserTag,
            handleClickAddCollaboratorTags,
            featuresEnabled,
            isProductTaggingAllowed: true,
            isUserTaggingAllowed: mediaProportionsTypeCarousel !== VIDEO,
            dynamicMediaIsSmall: smallPreview,
            carouselMediasAmount: 1,
            processedVideoEnding:
              media_type === PUBLISH_AS_STORY ? PROCESSED_VIDEO_ENDING_STORY : PROCESSED_VIDEO_ENDING_REEL,
          })

          // eslint-disable-next-line prefer-destructuring
          box_width_carousel = dimensions.box_width
          // eslint-disable-next-line prefer-destructuring
          box_height_carousel = dimensions.box_height
        }

        previewComponent = (
          <Flex
            flexDirection="column"
            width={`${box_width_carousel || MAX_PREVIEW_WIDTH}px`}
            minHeight={`${box_height_carousel || MAX_PREVIEW_HEIGHT}px`}
            mx="auto"
          >
            {storyHeaderWrapper}
            {feedPostHeaderComponent({})}
            {postMediasUpdated.length > 0 && (
              <SourceWrapper
                width={`${box_width_carousel || MAX_PREVIEW_WIDTH}px`}
                height={`${box_height_carousel || MAX_PREVIEW_HEIGHT}px`}
                bg={media_type === PUBLISH_AS_STORY ? COLOR_CONSTANTS.BLACK : INSTAGRAM_COLORS.BACKGROUND}
              >
                {storyHeaderWrapper}
                <MediaCarousel
                  post={post}
                  width={box_width_carousel || MAX_PREVIEW_WIDTH}
                  height={box_height_carousel || MAX_PREVIEW_HEIGHT}
                  medias={postMediasUpdated}
                  activeDotsColor={COLOR_CONSTANTS.WHITE}
                  slideToIndex={slideToIndex}
                  showLegend={media_type !== PUBLISH_AS_STORY}
                  showFullHeight={media_type === PUBLISH_AS_STORY || !isEditable}
                  sliderElements={postMediasUpdated.map((media, index) => {
                    const { mediaComponent } = generateInstagramMediaComponent({
                      media,
                      network: INSTAGRAM,
                      tooltipRef,
                      post,
                      profile,
                      media_type,
                      maxPreviewWidth: box_width_carousel || MAX_PREVIEW_WIDTH,
                      maxPreviewHeight: box_height_carousel || MAX_PREVIEW_HEIGHT,
                      maxAspectRatioNumber: null,
                      minAspectRatioNumber: null,
                      mediaProportionsType: mediaProportionsTypeCarousel || media.type,
                      isEditable,
                      getMouseClickPosition,
                      setMousePositionClick,
                      noVideoAttachment,
                      hasAutoChangedPostComponent,
                      imageWrapperRef,
                      showImageMentions,
                      setShowImageMentions,
                      showProductTags,
                      setShowProductTags,
                      user_tags,
                      product_tags,
                      collaborator_tags,
                      handleDragStart,
                      handleDragOverUserMention,
                      setUserMentionModalOptions,
                      handleClickDeleteUserTag,
                      handleDragOverProductTag,
                      setProductTagModalOptions,
                      handleClickDeleteProductTag,
                      mousePositionClick,
                      handleClickAddProductTag,
                      handleClickAddUserTag,
                      handleClickAddCollaboratorTags,
                      featuresEnabled,
                      isProductTaggingAllowed: true,
                      isUserTaggingAllowed: mediaProportionsTypeCarousel !== VIDEO,
                      dynamicMediaIsSmall: smallPreview,
                      carouselMediasAmount: postMediasUpdated.length,
                      processedVideoEnding:
                        media_type === PUBLISH_AS_STORY ? PROCESSED_VIDEO_ENDING_STORY : PROCESSED_VIDEO_ENDING_REEL,
                    })

                    return (
                      <SourceWrapper
                        width={`${MAX_PREVIEW_WIDTH}px`}
                        maxHeight={`${MAX_PREVIEW_HEIGHT}px`}
                        key={media.id || index}
                      >
                        {mediaComponent}
                      </SourceWrapper>
                    )
                  })}
                />
                {storyFooterWrapper}
              </SourceWrapper>
            )}
            {feedPostTextComponent}
            {storyFooterWrapper}
            {feedPostFooterComponent}
          </Flex>
        )
        break
    }
  } else if (view === VIEW_GRID && isEditable) {
    if (customizedPostComponentForPreview === POST_IMAGE) {
      if (activeMediaTypeTab === PUBLISH_AS_REELS.toLowerCase()) {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          postMedias: [],
          showAsReels: true,
          post,
          profiles: [profile],
        }
      } else {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          isCarousel: media_type === PUBLISH_AS_STORY ? false : postImagesUpdated.length > 1,
          postMedias: media_type === PUBLISH_AS_STORY ? [] : [postImagesUpdated[0] || {}],
          showAsFeed: media_type !== PUBLISH_AS_STORY,
          post,
          profiles: [profile],
        }
      }
    } else if (customizedPostComponentForPreview === POST_VIDEO) {
      if (activeMediaTypeTab === PUBLISH_AS_REELS.toLowerCase()) {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          isCarousel: media_type === PUBLISH_AS_REELS ? postVideosUpdated.length > 1 : false,
          postMedias: media_type === PUBLISH_AS_REELS ? [postVideosUpdated[0] || {}] : [],
          showAsReels: true,
          post,
          profiles: [profile],
        }
      } else {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          isCarousel:
            ((media_type === PUBLISH_AS_REELS && share_to_feed) ||
              media_type === PUBLISH_AS_VIDEO ||
              media_type === PUBLISH_AS_CAROUSEL) &&
            media_type !== PUBLISH_AS_STORY
              ? postVideosUpdated.length > 1
              : 0,
          postMedias:
            ((media_type === PUBLISH_AS_REELS && share_to_feed) ||
              media_type === PUBLISH_AS_VIDEO ||
              media_type === PUBLISH_AS_CAROUSEL) &&
            media_type !== PUBLISH_AS_STORY
              ? [postVideosUpdated[0] || {}]
              : [],
            showAsFeed: media_type !== PUBLISH_AS_STORY,
          post,
          profiles: [profile],
        }
      }
    } else if (customizedPostComponentForPreview === POST_LINK) {
      if (activeMediaTypeTab === PUBLISH_AS_REELS.toLowerCase()) {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          postMedias: [],
          showAsReels: true,
          post,
          profiles: [profile],
        }
      } else {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          postMedias: media_type === PUBLISH_AS_STORY ? [] : [{ url: picture_url }],
          showAsFeed: media_type !== PUBLISH_AS_STORY,
          post,
          profiles: [profile],
        }
      }
    } else if (customizedPostComponentForPreview === POST_MIXED_MEDIA) {
      if (activeMediaTypeTab === PUBLISH_AS_REELS.toLowerCase()) {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          postMedias: [],
          showAsReels: true,
          post,
          profiles: [profile],
        }
      } else {
        instagramGridParams = {
          medias,
          isGettingGridMedia,
          isCarousel: true,
          postMedias: postMixedMediasUpdated,
          showAsFeed: media_type !== PUBLISH_AS_STORY,
          post,
          profiles: [profile],
        }
      }
    }

    let gridComponent = null

    if (instagramGridParams && activeMediaTypeTab !== PUBLISH_AS_STORY.toLowerCase()) {
      gridComponent = <InstagramGrid {...instagramGridParams} isSmall={smallPreview} />
    } else if (activeMediaTypeTab === PUBLISH_AS_STORY.toLowerCase()) {
      gridComponent = (
        <Flex flexDirection="column" position="relative" bg={COLOR_CONSTANTS.BLACK} height="100%">
          {storyHeaderWrapper}
          <MediaCarousel
            post={post}
            width={MAX_PREVIEW_WIDTH}
            height={MAX_PREVIEW_HEIGHT}
            medias={stories}
            activeDotsColor="transparent"
            showLegend={false}
            sliderElements={stories.map((story) => {
              const { id, url, thumbnail_url, media_type = IMAGE } = story

              return (
                <Flex width={`${MAX_PREVIEW_WIDTH}px`} height={`${MAX_PREVIEW_HEIGHT}px`} key={id}>
                  <Flex
                    position="relative"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    bg={INSTAGRAM_COLORS.BACKGROUND}
                  >
                    <SourceWrapper
                      alignItems="center"
                      justifyContent="center"
                      bg={INSTAGRAM_COLORS.GLUON_GREY}
                      height={`${MAX_PREVIEW_HEIGHT}px`}
                      width={`${MAX_PREVIEW_WIDTH}px`}
                    >
                      {media_type.toLowerCase() === IMAGE && url && (
                        <ImageWithFallback src={url} width="100%" height="100%" maxHeight={`${MAX_PREVIEW_HEIGHT}px`} />
                      )}

                      {(media_type.toLowerCase() === IMAGE_DYNAMIC || media_type.toLowerCase() === VIDEO_DYNAMIC) && (
                        <DynamicMedia media={story} post={post} profiles={[profile]} isSmall={smallPreview} />
                      )}

                      {media_type.toLowerCase() === VIDEO && (
                        <VideoWithFallback
                          url={thumbnail_url ? null : url}
                          controls={false}
                          width="100%"
                          style={{ position: 'relative', borderRadius: radius.l }}
                          height="100%"
                          config={{
                            file: {
                              attributes: {
                                poster: thumbnail_url || '',
                              },
                            },
                          }}
                          name={id}
                          fallbackSourceImage={thumbnail_url}
                          processedVideoEnding={PROCESSED_VIDEO_ENDING_STORY}
                        />
                      )}
                    </SourceWrapper>
                  </Flex>
                </Flex>
              )
            })}
            setCurrentMedia={setCurrentMediaIndex}
          />
          {storyFooterWrapper}
        </Flex>
      )
    }

    previewComponent = (
      <Flex flexDirection="column">
        {feedPostHeaderComponent({})}
        <Grid gridTemplateColumns="repeat(3, 1fr)" gridGap="0.5em" px="0.125em" mt="0.5em">
          {MEDIA_TYPE_TABS.map(({ value, icon }) => (
            <StyledGridTypeWrapper
              key={value}
              alignItems="center"
              justifyContent="center"
              isSelected={activeMediaTypeTab === value.toLowerCase()}
              onClick={() => {
                setActiveMediaTypeTab(value.toLowerCase())
              }}
            >
              <Image src={icon} height="1.125em" width="1.125em" />
            </StyledGridTypeWrapper>
          ))}
        </Grid>
        {gridComponent}
      </Flex>
    )
  }

  if (
    view === VIEW_GRID &&
    ((customizedPostComponent === POST_IMAGE && customizedPostImages.length === 0) ||
      (customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 0) ||
      (customizedPostComponent === POST_MIXED_MEDIA && customizedPostMixedMedias.length === 0) ||
      (customizedPostComponent === POST_LINK && !picture_url))
  ) {
    handleUpdateCustomization({
      type: 'view',
      value: VIEW_FEED,
    })
    setActiveMediaTypeTab(null)
  }

  const { name, latitude, longitude } = location || {}

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `${MAX_PREVIEW_WIDTH + 32}px calc(100% - ${MAX_PREVIEW_WIDTH + 50}px)`

      if (process.browser) {
        if (window.innerWidth < Number(breakpoints.desktop.replace('px', ''))) {
          gridTemplateColumns = 'repeat(1, 1fr)'
        }
      }
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(${MAX_PREVIEW_WIDTH}px, auto)`
  } else {
    gridTemplateColumns = '1fr'
  }

  return (
    <Flex flexDirection="column" height="100%">
      {isEditable && (
        <PreviewPromptComponent
          networkLimits={networkLimits}
          hasErrors={hasErrors}
          flags={flags}
          warning_flags={warning_flags}
          network={network}
          display={display}
          customizedPostComponent={customizedPostComponent}
          customizedPostImages={customizedPostImages}
          customizedPostVideos={customizedPostVideos}
          customizedPostMixedMedias={postMixedMediasUpdated}
          initialCustomizedPostMixedMedias={customizedPostMixedMedias}
          video_processing={video_processing}
          media_type={media_type}
        />
      )}

      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            position="relative"
            bg={isEditable ? 'background_post_preview' : 'transparent'}
            p={isEditable ? 'm' : 0}
          >
            <Flex flexDirection="column" width="100%" height="100%" position="relative">
              <StyledFlex
                minHeight="max-content"
                flexDirection="column"
                isEditable={smallPreview}
                isPostFormDirty={isPostFormDirty}
                position="relative"
                width={view === VIEW_GRID ? '100%' : 'fit-content'}
              >
                {isEditable &&
                  (postImagesUpdated.length !== 0 ||
                    postVideosUpdated.length !== 0 ||
                    postMixedMediasUpdated.length !== 0 ||
                    picture_url) && (
                    <StyledViewSwitchWrapper>
                      <Tooltip
                        wrapperComp={
                          <StyledGrayButton
                            onClick={() => {
                              handleUpdateCustomization({
                                type: 'view',
                                value: view === VIEW_FEED ? VIEW_GRID : VIEW_FEED,
                              })

                              let updatedMediaType = null

                              if (view === VIEW_FEED) {
                                updatedMediaType = PUBLISH_AS_FEED
                                if (media_type === PUBLISH_AS_STORY || media_type === PUBLISH_AS_REELS) {
                                  updatedMediaType = media_type.toLowerCase()
                                }
                              }

                              setActiveMediaTypeTab(updatedMediaType)
                              setCurrentMediaIndex(0)
                            }}
                            height="2.125em"
                            minWidth="2.125em"
                            isFirst
                            isLast
                          >
                            <Flex alignItems="center" justifyContent="center">
                              <Image
                                source={view === VIEW_FEED ? '/assets/grid.svg' : '/assets/landscape.svg'}
                                width="1em"
                                height="1em"
                              />
                            </Flex>
                          </StyledGrayButton>
                        }
                        defaultTopOffset={-115}
                        message={
                          view === VIEW_FEED
                            ? 'See how this post will appear alongside your other media once published'
                            : 'Return to post preview'
                        }
                        isTriangleVisible={false}
                        left="initial"
                        right="0"
                      />
                    </StyledViewSwitchWrapper>
                  )}
                {isPostFormDirty ? (
                  <Flex
                    flexDirection="column"
                    width="100%"
                    height="100%"
                    position="relative"
                    bg="white"
                    onClick={() => {
                      if (published_link) {
                        window.open(published_link)
                      }
                    }}
                  >
                    {previewComponent ? <Fragment>{previewComponent}</Fragment> : <Box mt="s" px="s" mb="s" />}
                  </Flex>
                ) : (
                  <Box mt="m" px="s" mb="s" height="100%">
                    <NoPost />
                  </Box>
                )}
              </StyledFlex>
            </Flex>
          </Flex>
        )}

        {isEditable && (
          <Flex minHeight="max-content" flexDirection="column" height="100%" borderRadius={radius.l}>
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex flexDirection="column" borderRadius={radius.l} pr="s" pb="m">
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={instagram_text_symbol_counter}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              if (isEditable) {
                                setIsPostTextEditOpen(true)
                              }
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                color={
                                  transformed_text_to_html_for_textarea ? 'primaryText' : 'input_placeholder_color'
                                }
                                whiteSpace="pre-line"
                              />
                            </BodyWrapper>
                            <Box>
                              <Counter counter={instagram_text_symbol_counter} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                            </Box>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={INSTAGRAM} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="calc(100% - 18px)">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  <Flex flexDirection="column">
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      checkChangeOptionFunction={() => {
                        if (profileType === PROFILE_TYPE_PROFILE) {
                          Alert.error(
                            `Direct publishing is not available for Instagram personal profiles. <a href="https://support.vistasocial.com/hc/en-us/articles/14736118034715-Auto-Publishing-vs-Notification-Reminders" target='_blank'>Learn more</a>`,
                            {
                              timeout: 5000,
                            }
                          )
                          return false
                        } else if (profileType === PROFILE_TYPE_CREATOR && media_type === PUBLISH_AS_STORY) {
                          Alert.error(
                            `Direct publishing of stories is not available for Instagram creator profiles. <a href="https://support.vistasocial.com/hc/en-us/articles/14736118034715-Auto-Publishing-vs-Notification-Reminders" target='_blank'>Learn more</a>`,
                            {
                              timeout: 5000,
                            }
                          )
                          return false
                        }

                        return true
                      }}
                      notificationDeviceLengthLimit={deviceLengthLimit || notificationDeviceLengthLimit}
                    />
                    {((customizedPostComponent === POST_VIDEO && postVideosUpdated.length === 1) ||
                      (customizedPostComponent === POST_IMAGE && postImagesUpdated.length === 1) ||
                      customizedPostComponent === POST_MIXED_MEDIA ||
                      (customizedPostComponent === POST_LINK && picture_url)) && (
                      <Flex flexDirection="column">
                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <DropDown
                              id="media-type-dropdown"
                              placeholder="Select publishing option"
                              value={
                                media_type ? MEDIA_TYPE_DROPDOWN_VALUES.find(({ value }) => value === media_type) : null
                              }
                              onChange={(option) => {
                                handleUpdateCustomization({
                                  type: 'media_type',
                                  value: option.value,
                                })
                              }}
                              options={MEDIA_TYPE_DROPDOWN_VALUES}
                              openMenuOnFocus
                              isSearchable={false}
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="publishing_post_options" network={INSTAGRAM} />
                        </Flex>
                        {/* {postVideoAspectRatioLimit && ( */}
                        {/*  <Flex flexDirection="column" width="100%" mt="m" mb={`-${space.m}`}> */}
                        {/*    <PromptComponent */}
                        {/*      text={previewPromptMessageText_postVideoAspectRatioLimit} */}
                        {/*      isShown={isEditable || !previewPromptMessage.isHidden} */}
                        {/*      type="warning" */}
                        {/*    /> */}
                        {/*  </Flex> */}
                        {/* )} */}

                        {/* {media_type === PUBLISH_AS_STORY && ( */}
                        {/*  <Flex mt="m" flexDirection="column" width="100%"> */}
                        {/*    <Text */}
                        {/*      as="a" */}
                        {/*      href="https://support.vistasocial.com/hc/en-us/articles/7612460663579" */}
                        {/*      fontSize="xs" */}
                        {/*      color="primary" */}
                        {/*      target="_blank" */}
                        {/*    > */}
                        {/*      Learn more about story publishing */}
                        {/*    </Text> */}
                        {/*  </Flex> */}
                        {/* )} */}
                      </Flex>
                    )}

                    {media_type !== PUBLISH_AS_STORY &&
                      publishing_option === PUBLISHING_OPTION_DIRECT &&
                      ((customizedPostComponent === POST_VIDEO && postVideosUpdated.length > 0) ||
                        (customizedPostComponent === POST_IMAGE && postImagesUpdated.length > 0) ||
                        (customizedPostComponent === POST_MIXED_MEDIA && postMixedMediasUpdated.length > 0) ||
                        (customizedPostComponent === POST_LINK && picture_url)) && (
                        <Flex mt="m" flexDirection="column" position="relative">
                          {featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled && (
                            <CustomizationsBlockComponent
                              showUpgradeMessage={false}
                              showUpgradePlan={false}
                              message={featuresEnabled[FEATURE_POST_TAGGING].description}
                            />
                          )}

                          <Flex
                            flexDirection="column"
                            px={
                              featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled
                                ? 's'
                                : '0'
                            }
                            py={
                              featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled
                                ? 'm'
                                : '0'
                            }
                          >
                            <Flex alignItems="center" width="100%">
                              <Flex flexDirection="column" width="100%">
                                <DropDown
                                  innerRef={collaboratorsTagsRef}
                                  isMulti
                                  label=""
                                  placeholder="Invite collaborators"
                                  value={
                                    collaborator_tags
                                      ? collaborator_tags.map((item) => ({ value: item, label: item }))
                                      : []
                                  }
                                  onChange={(options) => {
                                    if (options) {
                                      if (options.length <= MAX_COLLABORATOR_TAGS_PER_MEDIA) {
                                        handleUpdateCustomization({
                                          type: 'collaborator_tags',
                                          value: options.map(({ value }) => value.replace('@', '')),
                                        })
                                      } else {
                                        Alert.error(MAX_COLLABORATOR_TAGS_PER_MEDIA_MESSAGE, { timeout: 5000 })

                                        handleUpdateCustomization({
                                          type: 'collaborator_tags',
                                          value: options
                                            .slice(0, MAX_COLLABORATOR_TAGS_PER_MEDIA)
                                            .map(({ value }) => value.replace('@', '')),
                                        })
                                      }
                                    } else {
                                      handleUpdateCustomization({
                                        type: 'collaborator_tags',
                                        value: [],
                                      })
                                    }
                                  }}
                                  options={SAVED_COLLABORATOR_TAGS}
                                  openMenuOnFocus
                                  dropDownType="CreatableSelect"
                                  noOptionsMessage={() => {
                                    return 'Invite collaborators'
                                  }}
                                  onMenuClose={() => {
                                    if (
                                      collaboratorsTagsRef &&
                                      collaboratorsTagsRef.current &&
                                      collaboratorsTagsRef.current.state.inputValue
                                    ) {
                                      const options = [
                                        ...new Set([
                                          ...collaboratorsTagsRef.current.props.value.map(({ value }) => value),
                                          collaboratorsTagsRef.current.state.inputValue,
                                        ]),
                                      ].map((item) => item.replace('@', ''))

                                      if (options.length <= MAX_COLLABORATOR_TAGS_PER_MEDIA) {
                                        handleClickSaveCollaboratorTags({
                                          collaborator_tags: options,
                                        })
                                      } else {
                                        Alert.error(MAX_COLLABORATOR_TAGS_PER_MEDIA_MESSAGE, { timeout: 5000 })

                                        handleUpdateCustomization({
                                          type: 'collaborator_tags',
                                          value: options.slice(0, MAX_COLLABORATOR_TAGS_PER_MEDIA),
                                        })
                                      }
                                    }
                                  }}
                                />
                              </Flex>
                              <TooltipPromptComponent prompt_key="collaborator_tags" network={INSTAGRAM} />
                            </Flex>
                          </Flex>
                        </Flex>
                      )}
                  </Flex>

                  {media_type !== PUBLISH_AS_STORY && publishing_option === PUBLISHING_OPTION_DIRECT && (
                    <Flex mt="m" minHeight="max-content" flexDirection="column" position="relative">
                      {/* {customizedPostComponent === POST_IMAGE && customizedPostImages && customizedPostImages.length > 0 && view === VIEW_FEED && ( */}
                      {/*  <Flex flexDirection="column" width="100%"> */}
                      {/*    <PromptComponent */}
                      {/*      text="Click on the image to tag Instagram users or a product." */}
                      {/*      isShown */}
                      {/*      specialColor={colors.secondaryText} */}
                      {/*    /> */}
                      {/*  </Flex> */}
                      {/* )} */}

                      <Flex flexDirection="column" position="relative">
                        {featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                          !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                            <CustomizationsBlockComponent
                              showUpgradeMessage={false}
                              showUpgradePlan={false}
                              message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                            />
                          )}

                        <Flex
                          flexDirection="column"
                          p={
                            featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                            !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                              ? 's'
                              : '0'
                          }
                        >
                          <Flex alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <CommentsComponent
                                user={user}
                                network={INSTAGRAM}
                                profileId={profileId}
                                post={post}
                                handleUpdateCustomization={handleUpdateCustomization}
                                handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                                commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                                comments={comments}
                                commentTextMaxLimit={commentTextMaxLimit}
                                hashtagsCommentLengthLimit={hashtagsCommentLengthLimit}
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                textVariantOffsets={textVariantOffsets}
                                hasPreview={hasPreview}
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="comments" />
                          </Flex>

                          {media_type === PUBLISH_AS_REELS && (
                            <Flex mt="m" alignItems="center" width="100%">
                              <Flex flexDirection="column" width="100%">
                                <Switch
                                  isOn={share_to_feed}
                                  onClick={() => {
                                    handleUpdateCustomization({
                                      type: 'share_to_feed',
                                      value: !share_to_feed,
                                    })
                                  }}
                                  text="Show Reel in Feed"
                                  fontWeight="normal"
                                />
                              </Flex>
                              <TooltipPromptComponent prompt_key="share_to_feed" network={INSTAGRAM} />
                            </Flex>
                          )}

                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={comments_enabled}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'comments_enabled',
                                    value: !comments_enabled,
                                  })
                                }}
                                text={`Comments ${comments_enabled ? 'enabled' : 'disabled'}`}
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="comments_enabled" network={INSTAGRAM} />
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex mt="m" flexDirection="column" position="relative">
                        {featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled && (
                          <CustomizationsBlockComponent
                            showUpgradeMessage={false}
                            showUpgradePlan={false}
                            message={featuresEnabled[FEATURE_POST_TAGGING].description}
                          />
                        )}

                        <Flex
                          flexDirection="column"
                          px={
                            featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled
                              ? 's'
                              : '0'
                          }
                          py={
                            featuresEnabled[FEATURE_POST_TAGGING] && !featuresEnabled[FEATURE_POST_TAGGING].enabled
                              ? 'm'
                              : '0'
                          }
                        >
                          <Flex alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={customizationsSchema.location}
                                onClick={() => {
                                  const updatedValue = !customizationsSchema.location
                                  customizationsSchema.location = updatedValue
                                  setCustomizationsSchema({ ...customizationsSchema })
                                  if (!updatedValue) {
                                    handleUpdateCustomization({
                                      type: 'location',
                                      value: null,
                                    })
                                  }
                                }}
                                text="Location"
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="location" network={INSTAGRAM} />
                          </Flex>

                          {customizationsSchema.location && (
                            <Flex mt="m" flexDirection="column" width="100%">
                              {location ? (
                                <Flex width="100%" alignItems="center">
                                  <StyledTextWrapper flex="1" alignItems="center">
                                    <StyledLocationText
                                      as="a"
                                      href={`http://www.google.com/maps/place/${latitude},${longitude}`}
                                      color="primary"
                                      target="_blank"
                                    >
                                      {name}
                                    </StyledLocationText>
                                  </StyledTextWrapper>
                                  <Flex alignItems="center" ml="m">
                                    <StyledLocationIconWrapper
                                      alignItems="center"
                                      justifyContent="flex-end"
                                      ml="s"
                                      onClick={() => {
                                        handleUpdateCustomization({ type: 'location', value: null })
                                      }}
                                      width="16px"
                                    >
                                      <Icon.Trash width="16px" height="16px" />
                                    </StyledLocationIconWrapper>
                                  </Flex>
                                </Flex>
                              ) : (
                                <DropDown
                                  placeholder="Search for location"
                                  value={null}
                                  onChange={(option) => {
                                    if (option) {
                                      const { value, location, label } = option
                                      const { latitude, longitude } = location || {}
                                      handleUpdateCustomization({
                                        type: 'location',
                                        value: { id: value, name: label, latitude, longitude },
                                      })

                                      setSavedSearches({
                                        type: SAVED_SEARCH_TYPE_INSTAGRAM_LOCATION,
                                        id: profileId,
                                        result: option,
                                        dataForCustomizations,
                                        setDataForCustomizations,
                                      })
                                    } else {
                                      handleUpdateCustomization({
                                        type: 'location',
                                        value: null,
                                      })
                                    }
                                  }}
                                  loadOptions={(value, callback) =>
                                    loadSuggestedOptions(
                                      {
                                        value,
                                        network: FACEBOOK,
                                        id: profileId,
                                        getSuggestedOptionsErrors,
                                        setGetSuggestedOptionsErrors,
                                        filterAccountsWithPlace: true,
                                      },
                                      callback
                                    )
                                  }
                                  defaultValue={[]}
                                  isClearable
                                  defaultOptions={SAVED_LOCATION_OPTIONS}
                                  openMenuOnFocus
                                  dropDownType="AsyncSelect"
                                  cacheOptions
                                  showDropDownArrow={false}
                                  formatOptionLabel={({ ...props }) => {
                                    return formatOptionLabel({ ...props })
                                  }}
                                  noOptionsMessage={() => {
                                    return getSuggestedOptionsErrors[FACEBOOK] ? 'No results found' : null
                                  }}
                                />
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  )}

                  {!isAdvocacy && (
                    <BoostsDropdownComponent
                      boosts={boosts && boosts.length !== 0 ? boosts : null}
                      handleUpdateCustomization={handleUpdateCustomization}
                      boostId={boost_gid}
                      user={user}
                      profile={profile}
                      isBlocked={media_type === PUBLISH_AS_CAROUSEL ? 'Carousel posts can not be boosted.' : ''}
                    />
                  )}

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>

      {userMentionModalOptions.isOpen && (
        <InstagramImageMentionModal
          handleDismiss={handleClickCloseImageMentionModal}
          handleClickSaveUserTag={handleClickSaveUserTag}
          mention={userMentionModalOptions.data}
          isOpen={userMentionModalOptions.isOpen}
          profileId={profileId}
        />
      )}

      {productTagModalOptions.isOpen && (
        <ProductTaggingModal
          handleDismiss={handleClickCloseProductTagModal}
          handleClickSaveProductTag={handleClickSaveProductTag}
          product={productTagModalOptions.data}
          isOpen={productTagModalOptions.isOpen}
          profileId={profileId}
          profileName={profileName}
        />
      )}

      {collaboratorTagsModalOptions.isOpen && (
        <InstagramMediaCollaboratorsModal
          handleDismiss={handleClickCloseCollaboratorTagsModal}
          handleClickSaveCollaboratorTags={handleClickSaveCollaboratorTags}
          data={collaboratorTagsModalOptions.data}
          isOpen={collaboratorTagsModalOptions.isOpen}
          savedCollaboratorTags={SAVED_COLLABORATOR_TAGS}
        />
      )}

      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

InstagramPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  blockCommentsMediaAttachment: false,
  isSmallPreview: false,
  isAdvocacy: false,
}

InstagramPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  blockCommentsMediaAttachment: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

export default InstagramPost

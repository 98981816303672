import React from 'react'
import styled, { withTheme } from 'styled-components'
import { space as styledSpace } from 'styled-system'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { space, radius, COLOR_CONSTANTS } from 'theme'
import { Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'

const StyledDialogOverlay = styled(DialogOverlay)`
  background-color: ${({ theme }) =>
    transparentize(0.2, theme?.colors?.background_modal_overlay || COLOR_CONSTANTS.SALUTE)};
  z-index: 2147483004;
`

const StyledDialogContent = styled(DialogContent)`
  background-color: ${({ theme }) => theme?.colors?.background_card || COLOR_CONSTANTS.WHITE};
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width || '100%'} !important;
  padding: ${space.l};
  border-radius: ${radius.l};
  ${styledSpace};
`

const IconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme?.colors?.background_card || COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const Modal = ({ isOpen, handleDismiss, children, maxwidth, isDisabled, ...props }) => {
  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width={['90vw', '50vw']}>
        <StyledDialogContent maxWidth={maxwidth} {...props}>
          {children}
          <IconWrapper
            className="modal-close-icon"
            isDisabled={isDisabled}
            onClick={() => {
              if (!isDisabled) {
                handleDismiss()
              }
            }}
          >
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </IconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

Modal.defaultProps = {
  maxwidth: '450px',
  isDisabled: false,
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  handleDismiss: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxwidth: PropTypes.string,
  isDisabled: PropTypes.bool,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Modal)

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { radius, space } from 'theme'
import { IMAGE, VIDEO, IMAGE_DYNAMIC, VIDEO_DYNAMIC } from 'consts'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'

const StyledMediaWrapper = styled(Box)`
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 153px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${radius.l};
  overflow: hidden;
`

const StyledPostMedia = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
`

const StyledMedia = styled(ImageWithFallback)`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: ${radius.l};
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background: ${({ theme }) => theme.colors.background};
`

const StyledBox = styled(Box)`
  cursor: pointer;
  ${({ isScheduled }) => isScheduled && `border: 3px solid #64A7DB;`}
  width: 100%;
  max-width: 153px;
  border-radius: ${radius.l};
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const MAX_MEDIAS = 18
const MAX_COLUMNS = 3
const MAX_ROWS = 3
const DEFAULT_FALLBACK_IMAGE = '/assets/landscape.svg'

const InstagramGrid = ({
  medias,
  postMedias = [],
  isGettingGridMedia,
  isCarousel,
  showAsReels,
  showAsFeed,
  post,
  profiles,
  isSmall,
}) => {
  // const postMediasCount = postMedias.length

  const postMediasCount = 1

  const lastMedias = medias.slice(0, MAX_MEDIAS - postMediasCount)

  let boxHeight = '9.5625em'

  if (showAsReels) {
    boxHeight = '17em'
  } else if (showAsFeed) {
    boxHeight = isSmall ? '7.9677em' : '11.87em'
  } else if (isSmall) {
    boxHeight = '6.456em'
  }

  return (
    <Flex width="100%" flexDirection="column" position="relative">
      <StyledProgressWrapper
        width="100%"
        height="29.6875em"
        display={isGettingGridMedia ? 'flex' : 'none'}
        alignItems="center"
        justifyContent="center"
        className="cliploader-wrapper"
      >
        <ClipLoader size="100" />
      </StyledProgressWrapper>
      <Grid gridTemplateColumns={`repeat(${MAX_COLUMNS}, 1fr)`} gridGap="s">
        {postMedias.map((media, index) => {
          const { id, url, thumbnail_url, isNew, type = IMAGE } = media

          const videoProps = {}
          if (type === VIDEO && !isCarousel) {
          }

          let videoThumbnailUrl = thumbnail_url || ''
          if (typeof isNew === 'undefined' && thumbnail_url) {
            videoThumbnailUrl = thumbnail_url
          }

          return (
            <StyledMediaWrapper key={id || index} height={boxHeight}>
              {isCarousel && (
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <Image src="/assets/vistasocial/instagram_carousel.svg" />
                </Box>
              )}
              {type === IMAGE && <StyledPostMedia source={url} fallbackSource={DEFAULT_FALLBACK_IMAGE} name={id} />}
              {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                <DynamicMedia media={media} post={post} profiles={profiles} isSmall />
              )}
              {type === VIDEO && (
                <VideoWithFallback
                  url={url}
                  controls={false}
                  width="100%"
                  style={{ position: 'relative', borderTopLeftRadius: radius.l, borderTopRightRadius: radius.l }}
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        poster: videoThumbnailUrl,
                      },
                    },
                  }}
                  playIcon={
                    <Box position="absolute" top={space.xs} right={space.xs}>
                      <StyledPlayImage
                        src={
                          isCarousel
                            ? '/assets/vistasocial/instagram_carousel.svg'
                            : '/assets/vistasocial/instagram_play.svg'
                        }
                      />
                    </Box>
                  }
                  name={id}
                />
              )}
            </StyledMediaWrapper>
          )
        })}
        {lastMedias.map((media) => {
          const { id, url, media_type, thumbnail_url, permalink, is_scheduled } = media
          let template = ''

          if (media_type.toLowerCase() === IMAGE || (media_type === 'CAROUSEL_ALBUM' && !thumbnail_url)) {
            template = <StyledMedia source={url} fallbackSource={DEFAULT_FALLBACK_IMAGE} name={id} />
          } else if (media_type.toLowerCase() === VIDEO || (media_type === 'CAROUSEL_ALBUM' && thumbnail_url)) {
            const videoProps = {}
            if (media_type !== 'CAROUSEL_ALBUM') {
              videoProps.playIcon = (
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                </Box>
              )
            }
            template = (
              <VideoWithFallback
                url={url || thumbnail_url}
                controls
                width="100%"
                style={{ position: 'relative', borderRadius: radius.l }}
                height="100%"
                config={{
                  file: {
                    attributes: {
                      poster: thumbnail_url || '',
                    },
                  },
                }}
                {...videoProps}
                name={id}
              />
            )
          } else if (media_type === IMAGE_DYNAMIC) {
            template = <DynamicMedia media={media} post={post} profiles={profiles} isSmall />
          }
          return (
            <StyledBox
              key={id}
              position="relative"
              width="100%"
              height={boxHeight}
              isScheduled={is_scheduled}
              onClick={() => {
                if (permalink) {
                  window.open(permalink, '_blank')
                }
              }}
            >
              {media_type === 'CAROUSEL_ALBUM' && (
                <Box position="absolute" top={space.xs} right={space.xs}>
                  <Image src="/assets/vistasocial/instagram_carousel.svg" />
                </Box>
              )}
              {template}
            </StyledBox>
          )
        })}
      </Grid>
    </Flex>
  )
}

InstagramGrid.defaultProps = {
  medias: [],
  postMedias: [],
  isGettingGridMedia: false,
  isCarousel: false,
  showAsReels: false,
  showAsFeed: false,
  post: null,
  profiles: null,
  isSmall: false,
}

InstagramGrid.propTypes = {
  medias: PropTypes.array,
  postMedias: PropTypes.array,
  isGettingGridMedia: PropTypes.bool,
  isCarousel: PropTypes.bool,
  showAsReels: PropTypes.bool,
  showAsFeed: PropTypes.bool,
  post: PropTypes.object,
  profiles: PropTypes.array,
  isSmall: PropTypes.bool,
}

export default InstagramGrid

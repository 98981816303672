import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Flex } from 'components/atoms/Layout'
import AvatarEditor from 'react-avatar-editor'
import { CustomView, isMobile } from 'react-device-detect'
import Modal from 'shared/Modal'
import { Slider } from '@material-ui/core'
import { radius } from 'theme'
import { Text } from 'components/atoms/Typography'
import Button from 'components/atoms/Button'
import Icon from 'components/atoms/Icon'
import ImageWithFallback from 'components/atoms/ImageWithFallback'

const IconWrapper = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledLabel = styled.label`
  cursor: pointer;
  position: relative;
  width: inherit;
  &:hover {
    opacity: 0.8;
  }
`

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0px;
`

const StyledImage = styled(ImageWithFallback)`
  cursor: pointer;
  object-fit: contain;
  opacity: 0.3;
  border-radius: ${radius.pill};
`

const ACCEPT = '.png,.jpg,.jpeg,.svg'
const SCALE = 1
const POSITION = { x: 0.5, y: 0.5 }

const AvatarUpload = ({
  onSave,
  onCancel,
  onChange,
  source,
  fallbackSource,
  showEdit,
  width,
  height,
  id,
  ContentComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [scale, setScale] = useState(SCALE)
  const [editor, setEditor] = useState()
  const [position, setPosition] = useState(POSITION)
  const [image, setImage] = useState(source)

  useEffect(() => {
    setImage(source)
  }, [source])

  const editAvatar = (e) => {
    onChange(e)
    setScale(SCALE)
    setPosition(POSITION)
    setIsOpen(true)
  }

  const handleChangeScale = (event, newValue) => {
    setScale(newValue)
  }

  const handleXPosition = (event, newValue) => {
    setPosition({ ...position, ...{ x: newValue } })
  }

  const handleYPosition = (event, newValue) => {
    setPosition({ ...position, ...{ y: newValue } })
  }

  const setEditorRef = (editor) => {
    setEditor(editor)
  }

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas()
      onSave(canvasScaled.toDataURL())
      setIsOpen(false)
    }
  }

  const handleCancel = () => {
    onCancel()
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <StyledLabel htmlFor={`avatar${id}`}>
        {ContentComponent || (
          <Fragment>
            <StyledImage width={width} height={height} source={image} fallbackSource={fallbackSource} />
            {showEdit && (
              <IconWrapper position="absolute">
                <Icon.Camera />
              </IconWrapper>
            )}
          </Fragment>
        )}
      </StyledLabel>
      <StyledInput type="file" accept={ACCEPT} onChange={(e) => editAvatar(e)} id={`avatar${id}`} />
      <Modal isOpen={isOpen} handleDismiss={() => handleCancel()} maxwidth="520px">
        <Flex flexDirection="column" alignItems="center">
          <AvatarEditor
            ref={setEditorRef}
            image={image}
            position={isMobile ? position : null}
            width={150}
            height={150}
            border={50}
            color={[253, 83, 89, 0.3]}
            scale={scale}
            rotate={0}
          />
          <Flex mt="m" width="100%" alignItems="center">
            <Box width={100}>
              <Text>Scale:</Text>
            </Box>
            <Slider
              defaultValue={scale}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={0.1}
              min={0}
              max={2}
              value={scale}
              onChange={handleChangeScale}
            />
          </Flex>
          <CustomView condition={isMobile === true} style={{ width: '100%' }}>
            <Flex mt="m" width="100%" alignItems="center">
              <Box width={100}>
                <Text>X Position:</Text>
              </Box>
              <Slider
                defaultValue={position.x}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.01}
                value={position.x}
                onChange={handleXPosition}
              />
            </Flex>
            <Flex mt="m" width="100%" alignItems="center">
              <Box width={100}>
                <Text>Y Position:</Text>
              </Box>
              <Slider
                defaultValue={position.y}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                min={0}
                max={1}
                step={0.01}
                value={position.y}
                onChange={handleYPosition}
              />
            </Flex>
          </CustomView>
          <Flex width="100%" mt="m" justifyContent="flex-end">
            <Button.Gray
              mr="m"
              onClick={() => {
                handleCancel()
              }}
              isSmall
            >
              Cancel
            </Button.Gray>
            <Button.Gradient onClick={handleSave} type="submit" isSmall>
              <Text fontWeight="medium">Save</Text>
            </Button.Gradient>
          </Flex>
        </Flex>
      </Modal>
    </React.Fragment>
  )
}

AvatarUpload.defaultProps = {
  source: null,
  showEdit: false,
  width: 80,
  height: 80,
  id: '',
  ContentComponent: null,
  fallbackSource: null,
}

AvatarUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  source: PropTypes.any,
  showEdit: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
  fallbackSource: PropTypes.string,
  ContentComponent: PropTypes.node,
}

export default AvatarUpload

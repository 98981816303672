import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import NextNprogress from 'nextjs-progressbar'
import Alert from 'react-s-alert'
import { theme, GlobalStyles } from 'theme'
import { LIGHT_THEME, COLOR_THEME_OPTIONS, LOCAL_STORAGE_KEY_THEME, DARK_THEME, SYSTEM_THEME } from 'consts'
import AlertTemplate from 'shared/AlertTemplate'
import '@material-ui/styles'
import '@reach/dialog/styles.css'
import 'sanitize.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-quill/dist/quill.snow.css'
import 'fonts.css'
import 'slick-carousel/slick/slick.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'intro.js/introjs.css'
import withoutPageDataProps from 'utils/withoutPageDataProps'
import ErrorBoundary from './ErrorBoundary'

const App = ({ user, children }) => {
  const [selectedTheme, setSelectedTheme] = useState({
    ...theme,
    colors: { ...COLOR_THEME_OPTIONS[LIGHT_THEME] },
    theme_name: LIGHT_THEME,
    theme_color: LIGHT_THEME,
  })
  const [changeThemeTo, setChangeThemeTo] = useState(null)
  const [changeTestTheme, setChangeTestTheme] = useState(false)

  useEffect(() => {
    const { settings } = user || {}

    let { color_theme: user_theme_name } = settings || {}

    if (!user_theme_name) {
      const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_KEY_THEME)

      if (localStorageTheme) {
        user_theme_name = localStorageTheme
      }
    }

    let user_theme_color = user_theme_name

    if (user_theme_name === SYSTEM_THEME && process.browser) {
      const system_match = window.matchMedia('(prefers-color-scheme: dark)')

      user_theme_name = SYSTEM_THEME
      user_theme_color = system_match.matches ? DARK_THEME : LIGHT_THEME
    }

    if (
      user_theme_color &&
      COLOR_THEME_OPTIONS[user_theme_color] &&
      !selectedTheme.must_not_auto_change &&
      (selectedTheme.theme_name !== user_theme_name || selectedTheme.theme_color !== user_theme_color)
    ) {
      selectedTheme.colors = { ...COLOR_THEME_OPTIONS[user_theme_color] }

      selectedTheme.theme_name = user_theme_name
      selectedTheme.theme_color = user_theme_color

      setSelectedTheme({ ...selectedTheme })

      localStorage.setItem(LOCAL_STORAGE_KEY_THEME, user_theme_name)
    }
  }, [selectedTheme])

  const changeTheme = () => {
    const { theme_color, theme_name, check_if_system_theme_allowed } = changeThemeTo

    let allowed_to_change = true

    if (check_if_system_theme_allowed) {
      if (selectedTheme.theme_name !== SYSTEM_THEME) {
        allowed_to_change = false
      }
    }

    if (allowed_to_change) {
      localStorage.setItem(LOCAL_STORAGE_KEY_THEME, theme_name)

      if (!user.settings) {
        user.settings = {}
      }

      user.settings.color_theme = theme_name

      delete selectedTheme.must_not_auto_change

      selectedTheme.auto_force_update = new Date()

      setSelectedTheme({ ...selectedTheme })

      window.dispatchEvent(new CustomEvent('handleChangeThemeInWithConfirm', { detail: { theme_color, theme_name } }))
    }
  }

  useEffect(() => {
    if (changeThemeTo) {
      changeTheme()

      setChangeThemeTo(null)
    }
  }, [changeThemeTo])

  useEffect(() => {
    if (changeTestTheme) {
      const objectKeysColorTheme = Object.keys(COLOR_THEME_OPTIONS)

      const colorThemeIndex = objectKeysColorTheme.findIndex((key) => key === selectedTheme.theme_color)

      let theme_color
      let theme_name

      if (objectKeysColorTheme[colorThemeIndex + 1]) {
        theme_color = COLOR_THEME_OPTIONS[objectKeysColorTheme[colorThemeIndex + 1]]
        theme_name = objectKeysColorTheme[colorThemeIndex + 1]
      } else {
        theme_color = COLOR_THEME_OPTIONS[objectKeysColorTheme[0]]
        // eslint-disable-next-line prefer-destructuring
        theme_name = objectKeysColorTheme[0]
      }

      selectedTheme.must_not_auto_change = true

      selectedTheme.colors = { ...theme_color }

      selectedTheme.theme_name = theme_name
      selectedTheme.theme_color = theme_name

      setSelectedTheme({ ...selectedTheme })

      window.dispatchEvent(
        new CustomEvent('handleChangeThemeInWithConfirm', { detail: { theme_color: theme_name, theme_name } })
      )

      setChangeTestTheme(false)
    }
  }, [changeTestTheme])

  const handleClickChangeTheme = (event) => {
    const { detail = {} } = event
    const { theme_color, theme_name } = detail || {}

    setChangeThemeTo({ ...{ theme_color, theme_name } })
  }

  const handleChangeThemeForTests = (event) => {
    if (process.env.NODE_ENV === 'development') {
      if (event.altKey && event.shiftKey && event.keyCode === 69) {
        setChangeTestTheme(true)
      }
    }
  }

  const handleChangeThemeFromOperationSystem = (event) => {
    setChangeThemeTo({
      ...{
        theme_name: SYSTEM_THEME,
        theme_color: event.matches ? DARK_THEME : LIGHT_THEME,
        check_if_system_theme_allowed: true,
      },
    })
  }

  useEffect(() => {
    window.addEventListener('handleUpdateUserTheme', handleClickChangeTheme)
    window.addEventListener('keydown', handleChangeThemeForTests)

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleChangeThemeFromOperationSystem)

    return () => {
      window.removeEventListener('handleUpdateUserTheme', handleClickChangeTheme)
      window.removeEventListener('keydown', handleChangeThemeForTests)
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleChangeThemeFromOperationSystem)
    }
  }, [])

  return (
    <ErrorBoundary>
      <ThemeProvider theme={selectedTheme}>
        <React.Fragment>
          <NextNprogress
            color={selectedTheme.colors.primary}
            startPosition={0.3}
            stopDelayMs={200}
            options={{ easing: 'ease', speed: 500, showSpinner: false }}
            height={3}
          />
          <GlobalStyles theme={selectedTheme} />
          {children}
          <Alert stack={{ limit: 3 }} contentTemplate={AlertTemplate} position="top-right" />
        </React.Fragment>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

App.defaultProps = {
  user: null,
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
}

export default withoutPageDataProps(App)
